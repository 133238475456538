import React, { useEffect, useState } from 'react'
import Modal from '../../../UI/Modal/Modal'
import Button from '../../../UI/Button';
import ExcursionsApi from '../../../../api/ExcursionsApi';
import { useSelector } from 'react-redux';
import cl from '../Calendar.module.scss';

const Calendar = ({modal, setModal, data, setData, error, setError}) => {

    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState({});
    const [time, setTime] = useState([]);

    const promocodes = useSelector(state => state.promocodes.promocodes);

    const [days, setDays] = useState([]);

    const save = e => {

        e.preventDefault();

        if(!year || !month || !day.day) return;

        setData({
            ...data,
            date: `${year}-${month > 9 ? month : `0${month}`}-${day.day > 9 ? day.day : `0${day.day}`}`,
            startTime: '',
            endTime: '',
            time: {
                ...time,
                start: time.start.map((item, index) => ({key: item, value: `${item} ${data.excursionType == 'ticket' ? `(${time.tickets[index]}) квитків` : `(${time.tickets_min[index]}-${time.tickets_max[index]})`}`, disabled: !time.disabled[index]})),
                disabled: time.disabled,
            },
            solo: {
                ...data.solo,
                promocodes: promocodes.filter(item => item.excursion.find(_ex => _ex.id == data.excursionId) !== undefined && item.numberOfApplications < item.applicationLimit && new Date(`${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`) / 1000 < item.time.end.unix)?.map(item => ({
                    key: item.id,
                    value: item.name.find(name => name?.default_language).text
                })),
            },
            tickets: [],
        });

        setModal(!modal);
        setError({
            ...error,
            date: '',
        })
    }

    const prevMonth = e => {
        e.preventDefault();
        const _year = month - 1 <= 0 ? year - 1 : year,
              _month = month - 1 <= 0  ? 1 : month - 1;

        setMonth(_month);
        setYear(_year);
        setDay({});
        updateCells(_year, _month);
    }
    
    const nextMonth = e => {
        e.preventDefault();

        const _year = month + 1 > 12 ? year + 1 : year,
              _month = month + 1 > 12 ? 1 : month + 1;

        setMonth(_month);
        setYear(_year);
        setDay({});
        updateCells(_year, _month);
    }

    const changeMonth = e => {
        const _year = e.target.value.split('-')[0],
              _month = Number(e.target.value.split('-')[1]);

        setYear(_year);
        setMonth(_month);
        setDay({});
        updateCells(_year, _month);
    }

    const getMonthName = (monthNum) => {
        return [
            'Січень',
            'Лютий',
            'Березень',
            'Квітень',
            'Травень',
            'Червень',
            'Липень',
            'Серпень',
            'Вересень',
            'Жовтень',
            'Листопад',
            'Грудень',
        ][monthNum - 1];
    }

    const updateCells = async (year, month) => {
        const getDate = await ExcursionsApi.getMonth(data.excursionId, year, month);

        if(getDate.data.message) {
            return;
        } 
        
        setDays(getDate.data);
    }

    const getDayRow = day => {
        return '';
    }

    const getDayClasses = _day => {

        const classes = [cl.day, cl.cell];

        if(!_day.status) classes.push(cl.noactive);
        if(_day.day == day.day && year == day.year && month == day.month) classes.push(cl.checked);

        return classes;
    }

    const getDayColumn = day => {
        const dayOfWeek = Number(day.day_of_week);
        return dayOfWeek;
    }

    const selectDay = day => {
        if(!day.status) return;
        setDay(day);
        setTime(day.time)
    }


    useEffect(() => {
        if(data.excursionId) {
            setYear(new Date().getFullYear());
            setMonth(new Date().getMonth() + 1);
            updateCells(new Date().getFullYear(), new Date().getMonth() + 1)
        }
    }, [data.excursionId])

    return (
        <Modal 
            active={modal} 
            title="Змініть дату"
        >
            <div className={cl.calendar}>
                <div className={cl.calendarHeader}>
                    <button className={[cl.monthButton, cl.left].join(' ')} onClick={prevMonth}>◀</button>
                    <div className={cl.label}>
                        <label htmlFor="calendarMonth" className={cl.labelText}>
                            {`${getMonthName(month)} ${year}`}
                        </label>
                        <input 
                            type="month" 
                            id='calendarMonth' 
                            value={`${year}-${month > 9 ? month : `0${Number(month)}`}`} 
                            onChange={changeMonth} 
                            name='calendarMonth' 
                            className={cl.input}
                        />
                    </div>
                    <button className={[cl.monthButton, cl.right].join(' ')} onClick={nextMonth}>▶</button>
                </div>
                <div className={cl.calendarContent}>
                    <div className={cl.daysOfWeek}>
                        <div className={[cl.dayOfWeek, cl.cell].join(' ')}>Пн</div>
                        <div className={[cl.dayOfWeek, cl.cell].join(' ')}>Вт</div>
                        <div className={[cl.dayOfWeek, cl.cell].join(' ')}>Ср</div>
                        <div className={[cl.dayOfWeek, cl.cell].join(' ')}>Чт</div>
                        <div className={[cl.dayOfWeek, cl.cell].join(' ')}>Пт</div>
                        <div className={[cl.dayOfWeek, cl.cell].join(' ')}>Сб</div>
                        <div className={[cl.dayOfWeek, cl.cell].join(' ')}>Нд</div>
                    </div>
                    <div className={cl.month}>
                        {days.map((day, index) => 
                            <div 
                                className={getDayClasses(day).join(' ')}
                                style={{
                                    gridRowStart: getDayRow(day),
                                    gridColumnStart: getDayColumn(day),
                                }}
                                key={`${day.year}${day.month}${day.day}`}
                                onClick={e => selectDay(day)}
                            >
                                {day.day}
                                </div>
                        )}
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20}}>
                <Button
                    callBack={e => {e.preventDefault(); setModal(false)}}
                >
                    Скасувати
                </Button>
                <Button
                    callBack={save}
                >
                    Зберегти
                </Button>
            </div>
        </Modal>
    )
}

export default Calendar

// import React, { useEffect, useState } from 'react'
// import Modal from '../../../UI/Modal/Modal'
// import Button from '../../../UI/Button';
// import CustomSelect from '../../../UI/CustomSelect/CustomSelect';
// import Row from './Row';
// import ExcursionsApi from '../../../../api/ExcursionsApi';
// import { useSelector } from 'react-redux';

// const Calendar = ({modal, setModal, data, setData, error, setError}) => {

//     const [currDate, setCurrDate] = useState(new Date());
//     const [validity, setValidity] = useState(null);

//     const [year, setYear] = useState([]);
//     const [month, setMonth] = useState([]);
//     const [day, setDay] = useState([]);
//     const [time, setTime] = useState([]);

//     const promocodes = useSelector(state => state.promocodes.promocodes);

//     const getMonthName = (monthNum) => {
//         return [
//             'Січень',
//             'Лютий',
//             'Березень',
//             'Квітень',
//             'Травень',
//             'Червень',
//             'Липень',
//             'Серпень',
//             'Вересень',
//             'Жовтень',
//             'Листопад',
//             'Грудень',
//         ][monthNum - 1];
//     }

//     const changeYear = e => {
//         setYear(year.map(item => ({...item, selected: item.key == e.selectedItem.key ? true : false})));
        
//         if(e.selectedItem.key == currDate.getUTCFullYear() && currDate.getUTCFullYear() != validity.getUTCFullYear()) {
//             const arr = [];

//             for(let i = currDate.getUTCMonth() + 1; i <= 12; i++) {
//                 arr.push({key: i, value: getMonthName(i)});
//                 if(i == 12) setMonth(arr);
//             }
//         }
//         else if (e.selectedItem.key == currDate.getUTCFullYear() && currDate.getUTCFullYear() == validity.getUTCFullYear()) {
//             const arr = [];

//             // for(let i = currDate.getUTCMonth(); i <= validity.getUTCMonth(); i++) {
//             for(let i = 1; i <= validity.getUTCMonth(); i++) {
//                 arr.push({key: i, value: getMonthName(i)});
//                 if(i == validity.getUTCMonth()) setMonth(arr);
//             }
//         }
//         else if(e.selectedItem.key == validity.getUTCFullYear() && currDate.getUTCFullYear() != validity.getUTCFullYear()) {
//             const arr = [];

//             for(let i = 1; i <= validity.getUTCMonth(); i++) {
//                 arr.push({key: i, value: getMonthName(i)});
//                 if(i == validity.getUTCMonth()) setMonth(arr);
//             }
//         }
//         else {
//             setMonth([
//                 {key: 1, value: 'Січень'},
//                 {key: 2, value: 'Лютий'},
//                 {key: 3, value: 'Березень'},
//                 {key: 4, value: 'Квітень'},
//                 {key: 5, value: 'Травень'},
//                 {key: 6, value: 'Червень'},
//                 {key: 7, value: 'Липень'},
//                 {key: 8, value: 'Серпень'},
//                 {key: 9, value: 'Вересень'},
//                 {key: 10, value: 'Жовтень'},
//                 {key: 11, value: 'Листопад'},
//                 {key: 12, value: 'Грудень'},
//             ]);
//         }
//         setDay([]);
//     }

//     const changeMonth = async e => {
//         setMonth(month.map(item => ({...item, selected: item.key == e.selectedItem.key ? true : false})));
        
//         const getDate = await ExcursionsApi.getMonth(data.excursionId, year.find(item => item.selected == true).key, e.selectedItem.key);

//         console.log(getDate.data)

//         setDay(getDate.data.map(item => ({key: item.day, value: item.day, disabled: !item.status ? true : false, time: item.time})))
//     }

//     const changeDay = e => {
//         setDay(day.map(item => ({...item, selected: e.selectedItem.key == item.key ? true : false})))
//         setTime(e.selectedItem.time);
//     }

//     const save = e => {

//         let _year  = year.find(item => item.selected)?.key,
//             _month = month.find(item => item.selected)?.key,
//             _day   = day.find(item => item.selected)?.key;

//         if(!_year || !_month || !_day) return;

//         if(_month < 10) _month = `0${_month}`;
//         if(_day < 10) _day = `0${_day}`;

//         setData({
//             ...data,
//             date: `${_year}-${_month}-${_day}`,
//             startTime: '',
//             endTime: '',
//             time: {
//                 ...time,
//                 start: time.start.map((item, index) => ({key: item, value: `${item} ${data.excursionType == 'ticket' ? `(${time.tickets[index]}) квитків` : `(${time.tickets_min[index]}-${time.tickets_max[index]})`}`}))
//             },
//             solo: {
//                 ...data.solo,
//                 promocodes: promocodes.filter(item => item.excursion.find(_ex => _ex.id == data.excursionId) !== undefined && item.numberOfApplications < item.applicationLimit && new Date(`${_year}-${_month}-${_day}`) / 1000 < item.time.end.unix)?.map(item => ({
//                     key: item.id,
//                     value: item.name.find(name => name?.default_language).text
//                 })),
//             },
//             tickets: [],
//         });

//         setModal(!modal);
//         setError({
//             ...error,
//             date: '',
//         })
//     }

//     useEffect(()=> {
//         if(data.currentExcursion) {
//             const arr = [],
//                   val = new Date(data.currentExcursion.validity * 1000);
                  
//             setValidity(val)



//             for(let i = currDate.getUTCFullYear() - 5; i <= val.getUTCFullYear(); i++) {
//                 arr.push({key: i, value: i, selected: i == currDate.getUTCFullYear()});
//                 if(i == val.getUTCFullYear()) setYear(arr)
//             }

//             const curr = arr.find(item => item.selected).key
        
//             if(curr == currDate.getUTCFullYear() && currDate.getUTCFullYear() != val.getUTCFullYear()) {
//                 const _arr = [];
    
//                 for(let i = currDate.getUTCMonth() + 1; i <= 12; i++) {
//                     _arr.push({key: i, value: getMonthName(i)});
//                     if(i == 12) setMonth(_arr);
//                 }
//             }
//             else if (curr == currDate.getUTCFullYear() && currDate.getUTCFullYear() == val.getUTCFullYear()) {
//                 const _arr = [];
    
//                 for(let i = currDate.getUTCMonth(); i <= val.getUTCMonth(); i++) {
//                     _arr.push({key: i, value: getMonthName(i)});
//                     if(i == val.getUTCMonth()) setMonth(_arr);
//                 }
//             }
//         }
//         setDay([]);
//     }, [data.currentExcursion])

//     return (
//         <Modal 
//             active={modal} 
//             title="Змініть дату"
//         >
//             <div className="content">
//                 <Row
//                     title="Виберіть рік"
//                     style={{marginBottom: 20}}
//                 >
//                     <CustomSelect 
//                         items={year}
//                         onChange={changeYear}
//                     />
//                 </Row>
//                 <Row
//                     title="Виберіть місяць"
//                     style={{marginBottom: 20}}
//                 >
//                     <CustomSelect 
//                         items={month}
//                         onChange={changeMonth}
//                     />
//                 </Row>
//                 <Row
//                     title="Виберіть день"
//                 >
//                     <CustomSelect 
//                         items={day}
//                         onChange={changeDay}
//                     />
//                 </Row>
//             </div>
//             <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 20}}>
//                 <Button
//                     callBack={e => setModal(false)}
//                 >
//                     Скасувати
//                 </Button>
//                 <Button
//                     callBack={save}
//                 >
//                     Зберегти
//                 </Button>
//             </div>
//         </Modal>
//     )
// }

// export default Calendar