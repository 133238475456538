import React from "react";
import classes from "./TableItem.module.scss";

const TableItem = ({columns, children, style}) => {
    let styleElem = {
        ...columns,
        ...style
    }
    return(
        <div className={classes.item} style={styleElem}>
            {children}
        </div>
    )
}

export default TableItem;