import ReportsApi from "../../api/ReportsApi";

const SET_REPORTS = "SET_REPORTS";
const CHANGE_FILTERS_REPORTS = "CHANGE_FILTERS_REPORTS"
const CHANGE_CURRENT_PAGE_REPORTS = "CHANGE_CURRENT_PAGE_REPORTS"

const defaultState = {
    reports: [],
    currentPage: 1,
    filters: {
        date: {
            from: "",
            to: "",
        },
        guide: null,
    }
}

export const reportsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_REPORTS:
            return {...state, reports: action.reports}
        case CHANGE_FILTERS_REPORTS:
            return {
                ...state,
                filters: action.filters,
            }
        case CHANGE_CURRENT_PAGE_REPORTS:
            return {
                ...state,
                currentPage: action.page,
            }
        default:
            return state;
    }
}


export const setReports = (reports) => ({type: SET_REPORTS, reports});
export const changeFiltersReports = (filters) => ({type: CHANGE_FILTERS_REPORTS, filters});
export const changeCurrentPageReports = (page) => ({type: CHANGE_CURRENT_PAGE_REPORTS, page});


export const getReports = () => async (dispatch) => {
    // await ReportsApi.getAll(localStorage.getItem('auth_token')).then(response => {
    //     // dispatch(setReports(response.data))
    //     let data = [
    //         {
    //             type: "reports",
    //             id: 2,
    //             guide:  {
    //                 type: "guide",
    //                 id: 1,
    //                 name: "Петро",
    //                 surname: "Петренко",
    //                 patronymic: "Петрович",
    //                 photo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Portrait_of_Pope_Francis_%282021%29.jpg/274px-Portrait_of_Pope_Francis_%282021%29.jpg",
    //                 basePrice: 250,
    //                 downTimePrice: 90,
    //                 surchargePrice: 50,
    //             },
    //             hoursWorked: 6,
    //             downTime: 2,
    //             totalPrice: 2850,
    //             time: {
    //                 date: "23.12.2022",
    //             }
    //         }
    //     ]
    //     dispatch(setReports(data))
    // })
}
export const createReports = (data) => async (dispatch) => {
    await ReportsApi.create(data).then(response => {
        let array = response.data; //массив, можно использовать массив объектов
        let size = 10; //размер подмассива
        let subarray = []; //массив в который будет выведен результат.
        for (let i = 0; i < Math.ceil(array.length/size); i++){
            subarray[i] = array.slice((i*size), (i*size) + size);
        }
        dispatch(setReports(subarray))
    })
}