import React from 'react';
import {useNavigate} from "react-router-dom";
import Page from "../../UI/Page/Page";
import TableGroup from "../../UI/Table/TableGroup";

const Group = () => {
    const navigate = useNavigate()
    return (
        <Page
            pageName={"Групи"}
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/group/create')
                }, icon: 'add', color: ''
            }}
        >
            <TableGroup/>
        </Page>
    )
};

export default Group;