import React, {useEffect, useState} from "react";
import {ReactSVG} from "react-svg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {
    changeCurrentDiscount,
    changeStateDiscountSaveButton,
    createDiscount,
    editDiscount, toArchiveDiscount
} from "../../../../redux/reducers/discountsReducer";
import LanguageInputs from "../../../UI/LanguageFields/LanguageInputs/LanguageInputs";
import Page from "../../../UI/Page/Page";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import LanguageAreas from "../../../UI/LanguageFields/LanguageAreas/LanguageAreas";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import Error from "../../../UI/Error/Error";
import Modal from "../../../UI/Modal/Modal";
import publish from "../../../../assets/images/publish.svg";


const DiscountsCreate = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const discounts = useSelector(state => state.discounts.discounts);
    const newDiscount = useSelector(state => state.discounts.newDiscount);
    const stateSaveButton = useSelector(state => state.discounts.disabledButton);
    let data = useSelector(state => state.discounts.currentDiscount);
    let pageName = (data && data.name && data.name.length !== 0) ? data.name.find(name => name.default_language === true).text : "";

    const [modalToArchive, changeModalToArchive] = useState(false)

    const showModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(true)
    }
    const hideModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(false)
    }
    const toArchive = (e) => {
        e.preventDefault()
        if(data.id){
            dispatch(toArchiveDiscount(data.id))
        }
        changeModalToArchive(false)
    }
    let discountsSubmit = (dataForm) => {
        if (data.id) {
            dataForm.id = data.id;
            dispatch(editDiscount(dataForm));
        } else {
            dispatch(createDiscount(dataForm, navigate));
        }

    };

    useEffect(() => {
        if (params?.id) {
            let discountForUrl = discounts.find(discount => discount.id == params.id);
            dispatch(changeCurrentDiscount(discountForUrl));
        } else {
            dispatch(changeCurrentDiscount(newDiscount));
        }
        return () => {
            dispatch(changeCurrentDiscount(false));
        }
    }, []);

    if (data) {
        return (
            <Page
                breadcrumbs={{link: '/discounts', text: 'Знижки'}}
                pageName={pageName}
                rightButton={{
                    content: 'Зберегти', callback: () => {
                    }, icon: 'add', color: '', form: 'discounts_form', disabled: stateSaveButton,
                }}
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>

                    <form id={"discounts_form"} onSubmit={handleSubmit(discountsSubmit)}>

                        <div>

                            <LanguageInputs
                                keyIndex={"name"}
                                label={"Знижка"}
                                fields={data.name}
                                control={control}
                                changeButton={() => {
                                    dispatch(changeStateDiscountSaveButton(false))
                                }}
                            />

                            <LanguageAreas
                                keyIndex={"description"}
                                label={"Пояснення (?)"}
                                fields={data.description}
                                control={control}
                                changeButton={() => {
                                    dispatch(changeStateDiscountSaveButton(false))
                                }}
                            />

                            <div>
                                <h3 style={{marginTop: '0px'}}>%</h3>

                                <input
                                    type={"number"}
                                    className={errors?.percent ? "error" : ""}
                                    min={0}
                                    max={100}
                                    onWheel={(e) => e.target.blur()}
                                    {...register('percent', {
                                        onChange: () => {
                                            dispatch(changeStateDiscountSaveButton(false))
                                        },
                                        minLength: 0,
                                        maxLength: 100,
                                        required: "Обов'язкове поле",
                                        value: data.percent,
                                        valueAsNumber: true
                                    })}
                                />

                                {errors?.percent &&
                                    <Error> {errors?.percent?.message} </Error>
                                }

                            </div>

                            {params?.id &&
                                <ButtonHalfTransparent onClick={showModalToArchive}>
                                    {data.deleted === false &&
                                        <>
                                            <ReactSVG src={deleteSVG}/>
                                            В архів
                                        </>
                                    }
                                    {data.deleted === true &&
                                        <>
                                            <ReactSVG src={publish}/>
                                            Опублікувати
                                        </>
                                    }
                                </ButtonHalfTransparent>
                            }

                        </div>

                        <div></div>

                        <div></div>

                    </form>

                </div>

                {params?.id &&
                    <Modal active={modalToArchive} title={data.deleted === false ? "Відправити в архів" : "Опублікувати"} type={"error"}>

                        <div>
                            {data.deleted === false &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете відправити знижку в архів?
                                </p>
                            }
                            {data.deleted === true &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете опублікувати знижку?
                                </p>
                            }
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px"
                        }}>
                            <button
                                style={{background: "#eee"}}
                                onClick={hideModalToArchive}
                            >
                                Скасувати
                            </button>
                            <button onClick={toArchive}>
                                {data.deleted === false &&
                                    <>
                                        В архів
                                    </>
                                }
                                {data.deleted === true &&
                                    <>
                                        Опублікувати
                                    </>
                                }
                            </button>
                        </div>

                    </Modal>
                }

            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
}

export default DiscountsCreate;