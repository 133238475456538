import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";
import TableItemImg from "./TableItem/Items/TableItemImg/TableItemImg";
import TableItemLogo from "./TableItem/Items/TableItemLogo/TableItemLogo";

const TableGuides = ({visible}) => {
    const guides = useSelector(state => state.guides.guides);
    const excursions = useSelector(state => state.excursions.excursions)
    const [items, changeItems] = useState(guides.filter(item => item.deleted === false))

    let header = [
        {
            width: 1,
            component: () => <TableHeaderText itemName="Фото"/>,
        },
        {
            width: 3,
            component: () => <TableHeaderText itemName="Гід"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Телефон"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Пошта"/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="Екскурсії"/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName=" "/>,
        },
    ];
    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    useEffect(() => {
        if(visible) {
            changeItems(guides.filter(item => item.deleted === false))
        } else {
            changeItems(guides.filter(item => item.deleted === true))
        }
    }, [visible])

    return (
        <div>
            <Table header={header} items={items} columns={columns}>
                {
                    items.map(item => {
                        let guides = excursions.map(excursion => excursion.guides).flat()
                        let countExcursions = guides.filter(guide => guide.id == item.id).length

                        let styleItem = false

                        if(item.deleted === true){
                            styleItem = {background: "#f1f1f1"}
                        }
                        return (
                            <TableItem key={item.id} columns={columns} style={styleItem}>
                                <TableItemLogo link={item.photo}/>
                                <TableItemText text={item.name + " " + item.patronymic + " " + item.surname}/>
                                <TableItemText text={item.phone}/>
                                <TableItemText text={item.email}/>
                                <TableItemText text={countExcursions}/>
                                <TableItemEdit type={item.type} id={item.id}/>
                            </TableItem>
                        )
                    })
                }
            </Table>
        </div>
    );
};

export default TableGuides;