import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DateFilter from "./Items/DateFilter/DateFilter";
import CustomSelect from "../CustomSelect/CustomSelect";
import Filters from "./Filters";
import {changeParamsExcursion, changeParamsGuide, changeParamsTime} from "../../../redux/reducers/calendarReducer";


const FiltersCalendar = ({style, currentTime}) => {
    const dispatch = useDispatch();
    const paramsRequest = useSelector(state => state.calendar.params)
    const [date, changeDate] = useState(null)
    const guides = useSelector(state => state.guides.guides)
    const objDate = new Date(date)
    const formatDay = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}

    //Даты
    const changeDateCallback = (date) => {
        dispatch(changeParamsTime(date))
    }
    const prevDate = (e) => {
        e.preventDefault()
        const newDate = new Date(objDate.valueOf() - (86400 * 1000))
        dispatch(changeParamsTime(newDate.valueOf()))
    }
    const nextDate = (e) => {
        e.preventDefault()
        const newDate = new Date(objDate.valueOf() + (86400 * 1000))
        dispatch(changeParamsTime(newDate.valueOf()))
    }
    useEffect(() => {
        if(paramsRequest.time){
            let timeObj = new Date(paramsRequest.time)
            let year = timeObj.getFullYear()
            let month = timeObj.getMonth() + 1 < 10 ? `0${timeObj.getMonth() + 1}` : timeObj.getMonth() + 1
            let day = timeObj.getDate() < 10 ? `0${timeObj.getDate()}` :  timeObj.getDate()

            let value =`${year}-${month}-${day}`

            changeDate(value)
        }
    }, [paramsRequest])

    //Экскурсии
    let excursions = useSelector(state => state.excursions.excursions);
    const [itemsExcursions, setItemsExcursions] = useState( excursions.map(excursion => {
        return {
            key: excursion.id,
            value: excursion.name.find(lang => lang.default_language).text + ` (${excursion.typeExcursion.name})`,
            selected: excursion.id == paramsRequest.excursion ? true : false,
        }
    }));
    useEffect(() => {
        let newStateExcursions = excursions.map(excursion => {
            return {
                key: excursion.id,
                value: excursion.name.find(lang => lang.default_language).text + ` (${excursion.typeExcursion.name})`,
                selected: excursion.id == paramsRequest.excursion ? true : false,
            }
        });
        newStateExcursions.unshift({
            key: "all",
            value: "Всі екскурсії",
            selected: false
        })
        setItemsExcursions(newStateExcursions);
    },[paramsRequest, excursions])
    const itemsExcursionsOnChange = (e) => {
        let selectedItem = e.selectedItem;
        dispatch(changeParamsExcursion(selectedItem.key == "all" ? null : selectedItem.key));
    }

    //Гіди
    const [itemsGuides, changeGuides] = useState([
        {
            key: "all",
            value: "Всі гіди",
            selected: false,
        },
        ...[...guides].map(guide => {
            return {
                key: guide.id,
                value: guide.name,
                selected: false,
            }
        })
    ])
    const itemsGuidesOnChange = (e) => {
        let selectedItem = e.selectedItem;
        let newItems = itemsGuides.map(item => {
            if(item.key == selectedItem.key){
                item.selected = true;
                return item
            } else {
                item.selected = false;
                return item
            }
        })
        dispatch(changeParamsGuide(selectedItem.key == "all" ? null : selectedItem.key))
        changeGuides(newItems);
    }

    return (
        <>
            <h3 style={{textTransform: "capitalize", marginTop: "0px"}}>
                {objDate.toLocaleString('uk-UA', formatDay)}
            </h3>

            <Filters style={{gridTemplateColumns: "repeat(auto-fit, 300px)", ...style}}>
                <DateFilter
                    callback={changeDateCallback}
                    value={date}
                    prevCallback={prevDate}
                    nextCallback={nextDate}
                />
                <CustomSelect
                    items={itemsExcursions}
                    onChange={itemsExcursionsOnChange}
                    placeholder={'Всі екскурсії'}
                    contentAbsolute={true}
                />
                <CustomSelect
                    items={itemsGuides}
                    onChange={itemsGuidesOnChange}
                    placeholder={'Всі гіди'}
                    contentAbsolute={true}
                />
            </Filters>
        </>
    );
};

export default FiltersCalendar;