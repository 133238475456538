import React from 'react'
import Page from "../../UI/Page/Page";
import {useNavigate} from "react-router-dom";
import TableBookings from "../../UI/Table/TableBookings";
import FiltersBookings from "../../UI/Filters/FiltersBookings";
import Search from "../../UI/Search/Search";
import {useDispatch, useSelector} from "react-redux";
import {changeFilters} from "../../../redux/reducers/bookingsReducer";

const Bookings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const filters = useSelector(state => state.bookings.filters)

    const onSubmitSearch = (e) => {
        e.preventDefault();
        let value = e.currentTarget.elements.field.value;

        let newFilters = {
            ...filters,
            page: 1,
            search: value ? value : null,
        }
        dispatch(changeFilters(newFilters))
    }
    const onDefaultState = () => {
        let newFilters = {
            ...filters,
            page: 1,
            search: null,
        }
        dispatch(changeFilters(newFilters))
    }

    return (
        <Page
            pageName={"Бронювання"}
            rightRest={
                <Search
                    onSubmit={onSubmitSearch}
                    onDefaultState={onDefaultState}
                    placeholder={"Номер телефону"}
                />
            }
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/bookings/create')
                }, icon: 'add', color: ''
            }}
            contentStyle={{
                height: "100%",
                maxWidth: "100%",
                overflow: "hidden",
            }}
        >
            <FiltersBookings/>
            <TableBookings/>
        </Page>
    )
}

export default Bookings