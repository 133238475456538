import React from "react";
import classes from "./TableItemColorLabel.module.scss";

const TableItemColorLabel = ({text, description, color, style}) => {
    return (
        <div className={classes.cell} style={style}>
            <div className={classes.label} style={{background: color}}></div>
            <div>
                <div>
                    {text}
                </div>
                <div>
                    {description}
                </div>
            </div>

        </div>
    )
}

export default TableItemColorLabel;