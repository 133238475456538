import React, { useEffect, useRef, useState } from 'react'
import Page from '../../../UI/Page/Page'
import cl from './LanguagesCreate.module.scss'
import editSVG from '../../../../assets/images/Vector (Stroke).svg'
import deleteSVG from '../../../../assets/images/Path 114.svg'
import { ReactSVG } from 'react-svg'
import ButtonHalfTransparent from '../../../UI/ButtonHalfTransparent/ButtonHalfTransparent'
import { useDispatch, useSelector } from 'react-redux/es/exports'
import { useNavigate, useParams } from 'react-router-dom'
import LanguagesApi from '../../../../api/LanguagesApi'
import { useFetching } from '../../../../hooks/useFetching'
import PageLoader from '../../../UI/PageLoader/PageLoader'
import Error from '../../../UI/Error/Error'
import {createGuide, editGuide} from "../../../../redux/reducers/guidesReducer";
import Modal from "../../../UI/Modal/Modal";
import {toArchiveLanguage} from "../../../../redux/reducers/languagesReducer";
import publish from "../../../../assets/images/publish.svg";

const LanguagesCreate = () => {
    const currentLanguage = useSelector(state => state.languages.currentEditLanguage);
    const [rightButtonDisabled, setRightButtonDisabled] = useState(true);
    const languages = useSelector(state => state.languages.languages)
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [language, setLanguage] = useState(false)
    const [fetching, isLoading, error] = useFetching(async () => {
        const response = await LanguagesApi.get(localStorage.getItem('auth_token'), params?.id)
        setLanguage(response.data)
    })
    const imageRef = useRef()
    const fileRef = useRef()
    const [validateError, setValidateError] = useState({
        image: '',
        name: '',
        price: '',
        default: '',
    })
    useEffect(() => {
        fetching();
    }, [])


    const [modalToArchive, changeModalToArchive] = useState(false)

    const showModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(true)
    }

    const hideModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(false)
    }

    const toArchive = (e) => {
        e.preventDefault()
        if(language.id){
            dispatch(toArchiveLanguage(language.id))
        }
        changeModalToArchive(false)
    }

    const validate = () => {
        if(validateError.image || validateError.name || validateError.price || validateError.default) {
            return false
        }
        else if(language && !fileRef.current.files.length && !language.logo) {
            setValidateError({...validateError, logo: 'Необхідно додати зображення!'})
            return false
        }
        else if(!language && !fileRef.current.files.length) {
            setValidateError({...validateError, logo: 'Необхідно додати зображення!'})
            return false
        }
        else if((language && !language.name) || (!language && !currentLanguage.name)) {
            setValidateError({...validateError, name: 'Необхідно вказати назву мови!'})
            return false
        }
        else if((language && (!language.price && language.price != 0)) || (!language && (!currentLanguage.price && currentLanguage.price != 0))) {
            setValidateError({...validateError, price: 'Необхідно вказати ціну!'})
            return false
        }
        else if(language) {
            const thisLang = languages.find(lang => lang.id == language.id)
            if(thisLang.default_language && !language.default_language) {
                setValidateError({...validateError, default: 'Error!!!'})
                return false
            }
        }
        return true
    }

    const saveFunc = async () => {
        if(!validate()) return
        const formData = new FormData();

        const logoArgs = [
            'logo',
            language ? fileRef.current.files[0] : currentLanguage.logo.file
        ]
        if(typeof logoArgs[1] !== 'undefined') logoArgs.push(language ? fileRef.current.files[0].name : currentLanguage.logo.file.name)

        formData.append('token', localStorage.getItem('auth_token'))
        formData.append('id', language ? language.id : '')
        formData.append('name', language ? language.name : currentLanguage.name)
        formData.append('price', language ? language.price : currentLanguage.price)
        formData.append('default_language', language ? language.default_language : currentLanguage.default_language)
        formData.append('slug', language ? language.slug : currentLanguage.slug)
        formData.append(...logoArgs)

        if(language) {
            const response = await LanguagesApi.patch(formData);
            dispatch({type: "SET_LANGUAGES", languages: [...languages.map(lang => lang.id == response.data.id ? {...lang, logo: response.data.logo} : lang)]})
            return
        }
        const response = await LanguagesApi.put(formData);
        dispatch({type: "SET_LANGUAGES", languages: [...languages, response.data]})
        dispatch({type: 'RESET_CURRENT_LANGUAGE'})
        setRightButtonDisabled(true);
        navigate('/languages')
    }

    const deleteFunc = async (e) => {
        e.preventDefault()

        const formData = new FormData();
        formData.append('token', localStorage.getItem('auth_token'));
        formData.append('id', language.id);

        const response = await LanguagesApi.delete(formData);
        dispatch({type: 'SET_LANGUAGES', languages: [...languages.filter(language => language.id != response.data.id)]});
        navigate('/languages')
    }

    const changeName = (e) => {
        setValidateError({...validateError, name: ''})
        setRightButtonDisabled(false);
        if(language) {
            setLanguage({...language, name: e.target.value})
            return
        }
        dispatch({type: 'CHANGE_CURRENT_LANGUAGE', payload: {name: e.target.value}})
        
    }

    const changePrice = (e) => {
        setValidateError({...validateError, price: ''})
        setRightButtonDisabled(false);
        if(language) {
            setLanguage({...language, price: e.target.value})
            return
        }
        dispatch({type: 'CHANGE_CURRENT_LANGUAGE', payload: {price: e.target.value}})
        
    }

    const changeDefault = (e) => {
        setValidateError({...validateError, default: ''})
        setRightButtonDisabled(false);
        if(language) {
            setLanguage({...language, default_language: !language.default_language})
            return
        }
        dispatch({type: 'CHANGE_CURRENT_LANGUAGE', payload: {default_language: !currentLanguage.default_language}})
        
    }

    const editImage = () => {
        fileRef.current.click()
    }

    const changeImage = (e) => {
        if(!['image/svg+xml', 'image/png', 'image/jpeg'].includes(fileRef.current.files[0].type)) {
            setValidateError({...validateError, logo: 'Підтримуються лише svg, png, jpg, jpeg'})
            return
        }
        setValidateError({...validateError, logo: ''})
        const fr = new FileReader()
        fr.onload = () => {
            if(language) {
                setLanguage({...language, logo: fr.result})
                return
            }
            dispatch({type: 'CHANGE_CURRENT_LANGUAGE', payload: {logo: {file: fileRef.current.files[0], src: fr.result}}})
        }
        fr.readAsDataURL(fileRef.current.files[0])
        setRightButtonDisabled(false);

    }

    const deleteImage = () => {
        fileRef.current.value = null
        if(language) {
            setLanguage({...language, logo: ''})
            return
        }
        setRightButtonDisabled(false);
        dispatch({type: 'CHANGE_CURRENT_LANGUAGE', payload: {logo: null}})
    }

    const changeSlug = e => {
        setValidateError({...validateError, slug: ''})
        setRightButtonDisabled(false);
        if(language) {
            setLanguage({...language, slug: e.target.value})
            return
        }
        dispatch({type: 'CHANGE_CURRENT_LANGUAGE', payload: {slug: e.target.value}})
    }

    if(isLoading) {
        return(
            <PageLoader />
        )
    }

    return(
        <Page
            breadcrumbs={{link: '/languages', text: 'Мова проведення'}}
            pageName={language ? language.name : ''}
            rightButton={{content: 'Зберегти', callback: saveFunc, icon: 'save', color: '', disabled: rightButtonDisabled}}
        >
            <div className={cl.formEdit} style={{height: '100%', background: '#fff'}}>
                <form action="" style={{padding: 19}}>
                    <div className="">
                        <div className={cl.logoBlock}>
                            <figure>
                                <img ref={imageRef} src={language ? language.logo : currentLanguage.logo.src} alt="" />
                            </figure>
                            <div className={cl.logoBlockBottom}>
                                <div className='svg' onClick={editImage}><ReactSVG src={editSVG} /></div>
                                <div className='svg' onClick={deleteImage}><ReactSVG src={deleteSVG} /></div>
                            </div>
                            {validateError.logo && <Error>{validateError.logo}</Error>}
                            <input ref={fileRef} onChange={changeImage} type="file" style={{display: 'none'}}/>
                        </div>
                        <div className="">
                            <label htmlFor="">
                                    Мова
                                <input 
                                    type="text"
                                    value={language ? language.name : currentLanguage.name} 
                                    onChange={e => changeName(e)} 
                                />
                                {validateError.name && <Error>{validateError.name}</Error>}
                            </label>
                        </div>
                        <div className="">
                            <label htmlFor="">
                                    Ціна (грн)
                                <input 
                                    type="number" 
                                    value={language ? language.price : currentLanguage.price} 
                                    onChange={e => changePrice(e)} 
                                />
                                {validateError.price && <p style={{color: 'red', margin: 0}}>{validateError.price}</p>}
                            </label>
                        </div>
                        <div className="">
                            <label htmlFor="">
                                    Слаг
                                <input 
                                    type="text" 
                                    value={language ? language.slug : currentLanguage.slug} 
                                    onChange={e => changeSlug(e)} 
                                />
                                {validateError.slug && <p style={{color: 'red', margin: 0}}>{validateError.slug}</p>}
                            </label>
                        </div>
                        <div className={cl.checkbox}>
                            <input 
                                type="checkbox" 
                                checked={language ? language.default_language : currentLanguage.default_language} 
                                onChange={e => changeDefault(e)} 
                            />
                            <span>Мова за замовчуванням</span>
                        </div>
                        {validateError.default && <Error>{validateError.default}</Error>}
                        {params?.id &&
                            <ButtonHalfTransparent onClick={showModalToArchive}>
                                {language.deleted === false &&
                                    <>
                                        <ReactSVG src={deleteSVG}/>
                                        В архів
                                    </>
                                }
                                {language.deleted === true &&
                                    <>
                                        <ReactSVG src={publish}/>
                                        Опублікувати
                                    </>
                                }
                            </ButtonHalfTransparent>
                        }
                    </div>
                </form>
            </div>
            {params?.id &&
                <Modal
                    active={modalToArchive}
                    title={language.deleted === false ? "Відправити в архів" : "Опублікувати"}
                    type={"error"}
                >

                    <div>
                        {language.deleted === false &&
                            <p style={{textAlign: "center"}}>
                                Ви впевнені що хочете відправити мову в архів?
                            </p>
                        }
                        {language.deleted === true &&
                            <p style={{textAlign: "center"}}>
                                Ви впевнені що хочете опублікувати мову?
                            </p>
                        }
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        <button
                            style={{background: "#eee"}}
                            onClick={hideModalToArchive}
                        >
                            Скасувати
                        </button>
                        <button onClick={toArchive}>
                            {language.deleted === false &&
                                <>
                                    В архів
                                </>
                            }
                            {language.deleted === true &&
                                <>
                                    Опублікувати
                                </>
                            }
                        </button>
                    </div>

                </Modal>
            }
        </Page>
    )
}

export default LanguagesCreate