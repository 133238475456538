import BookingsApi from "../../api/BookingsApi";

const CHANGE_CURRENT_BOOKING = "CHANGE_CURRENT_BOOKING";
const CLEAR_CURRENT_BOOKING = "CLEAR_CURRENT_BOOKING";
const ADD_BOOKING = "ADD_BOOKING";
const CHANGE_BOOKING = "CHANGE_BOOKING";
const SET_BOOKINGS = "SET_BOOKINGS";
const SET_COUNT_PAGES = "SET_COUNT_PAGES";
const UPDATE_BOOKINGS = "UPDATE_BOOKINGS";
const CHANGE_STATE_BOOKING_SAVE_BUTTON = "CHANGE_STATE_BOOKING_SAVE_BUTTON";
const CHANGE_TYPE_DISCOUNT = "CHANGE_TYPE_DISCOUNT";
const CHANGE_TYPE_DISCOUNT_FOR_TYPE_TICKET = "CHANGE_TYPE_DISCOUNT_FOR_TYPE_TICKET";
const CHNAGE_EXCURSION_FOR_BOOKING = "CHNAGE_EXCURSION_FOR_BOOKING";
const FILTER_BY_DATE = "FILTER_BY_DATE";
const FILTER_BY_EXCURSION = "FILTER_BY_EXCURSION";
const FILTER_BY_STATUS = "FILTER_BY_STATUS";
// const APPLY_FILTER = "APPLY_FILTER";
const CHANGE_FILTERS = "CHANGE_FILTERS"
const CHECK_ALL_BOOKINGS = "CHECK_ALL_BOOKINGS";
const CHECK_BOOKING = "CHECK_BOOKING";
const DELETE_BOOKING = "DELETE_BOOKING";

const defaultState = {
    bookings: null,
    countPages: null,
    currentBooking: false,
    newBooking: {
        type: "booking",
        id: "",
        client: {
            name: null,
            surname: null,
            email: null,
            phone: null,
        },
        guests: null,
        time: {
            create: {
                unix: 0,
                date: "00.00.0000",
                time: null,
            },
            start: {
                unix: 0,
                date: "00.00.0000",
                time: null,
            },
            end: {
                unix: 0,
                date: "00.00.0000",
                time: null,
            },
            duration: {
                unix: 0,
                time: "00:00 - 00:00"
            }
        },
        excursion: {
            type: "excursion",
            id: null,
        },
        guides: [],
        language: {
            type: "language",
            id: null,
        },
        comment: null,
        confirmationMethod: null,
        discounts: {
            key: null
        },
        additionalOptions: false,
        price: null,
        payment: {
            price: null,
            status: {
                key: "paid",
                name: "Сплачено"
            }
        },
        status: {
            key: "expected",
            name: "Очікується"
        },
        additionalComment: null,
    },
    disabledButton: false,
    filters: {
        page: 1,
        branch: null,
        time: {
            from: null,
            to: null
        },
        excursion: null, // id
        guide: null, // id
        status: null, //all expected completed cancelled
        sort: {
            create: null, // "new" "old"
            date: null,  // "new" "old"
        },
        search: null,
    },
}

export const bookingsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_BOOKINGS:
            return {
                ...state,
                bookings: action.bookings,
            }
        case DELETE_BOOKING:
            return {
                ...state,
                bookings: [...state.bookings].filter(booking => booking.id != action.id),
            }
        case SET_COUNT_PAGES:
            return {
                ...state,
                countPages: action.count,
            }
        case CHANGE_FILTERS:
            return {
                ...state,
                filters: action.filters,
            }
        case CHANGE_CURRENT_BOOKING:
            return {...state, currentBooking: action.booking}
        case CLEAR_CURRENT_BOOKING:
            return {...state, currentBooking: false}
        case ADD_BOOKING:
            return {
                ...state,
                bookings: [
                    ...state.bookings,
                    action.booking
                ]
            }
        case CHANGE_BOOKING:
            return {
                ...state,
                bookings: [...state.bookings].map(booking => {
                    if(booking.id == action.booking.id){
                        return (action.booking)
                    } else {
                        return(booking)
                    }
                })
            }
        case UPDATE_BOOKINGS:
            return {
                ...state,
                bookings: Array.isArray(state.bookings) ? [...state.bookings].map(booking => {
                    if (booking.id == action.booking.id) {
                        return action.booking;
                    }
                    return booking;
                }) : state.bookings,
            }
        case CHANGE_STATE_BOOKING_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        case CHANGE_TYPE_DISCOUNT:
            return {
                ...state,
                currentBooking: {
                    ...state.currentBooking,
                    discounts: {
                        key: action.typeDiscount,
                    }

                }
            }
        case CHANGE_TYPE_DISCOUNT_FOR_TYPE_TICKET:
            return {
                ...state,
                currentBooking: {
                    ...state.currentBooking,
                    tickets: state.currentBooking.tickets.map(item => {
                        if (item.id == action.id) {
                            return {
                                ...item,
                                discounts: {
                                    ...item.discounts,
                                    key: action.typeDiscount
                                }
                            }
                        } else {
                            return item;
                        }
                    })

                }
            }
        case CHNAGE_EXCURSION_FOR_BOOKING:
            return {
                ...state,
                currentBooking: {
                    ...state.currentBooking,
                    excursion: action.excursion,

                }
            }
        case FILTER_BY_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    date: action.date,
                }
            }
        case FILTER_BY_EXCURSION:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    excursion: action.excursion,
                }
            }
        case FILTER_BY_STATUS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    status: action.status,
                }
            }
        // case APPLY_FILTER:
        //     let newBookings = [...state.bookings];
        //     const filters = {...state.filters};
        //
        //     for (let i = 0; i < newBookings.length; i++) {
        //         const booking = newBookings[i];
        //
        //         if (filters.date && filters.date !== "all" && (booking.time.start.unix > filters.date || booking.time.end.unix < filters.date)) {
        //             delete newBookings[i];
        //         }
        //         if (filters.excursion && filters.excursion !== "all" && booking.excursion.id !== filters.excursion) {
        //             delete newBookings[i];
        //         }
        //         if (filters.status && filters.status !== "all" && booking.status.key !== filters.status) {
        //             delete newBookings[i];
        //         }
        //     }
        //
        //     return {
        //         ...state,
        //         bookings: newBookings.filter(booking => booking)
        //     }
        case CHECK_ALL_BOOKINGS:
            return {
                ...state,
                bookings: [...state.bookings].map(booking => {
                    booking.checked = action.value;
                    return booking;
                })
            }
        case CHECK_BOOKING:
            return {
                ...state,
                bookings: state.bookings.map(booking => {
                    if (booking.id == action.id) {
                        booking.checked = action.value == true ? false : true;
                    }
                    return booking;

                })
            }
        default:
            return state;
    }
}

export const setBookings = (bookings) => ({type: SET_BOOKINGS, bookings});
export const setCountPages = (count) => ({type: SET_COUNT_PAGES, count});
export const changeFilters = (filters) => ({type: CHANGE_FILTERS, filters});
export const changeCurrentBooking = (booking) => ({type: CHANGE_CURRENT_BOOKING, booking});
export const clearCurrentBooking = () => ({type: CLEAR_CURRENT_BOOKING});
export const addBooking = (booking) => ({type: ADD_BOOKING, booking});
export const changeBooking = (booking) => ({type: CHANGE_BOOKING, booking});
export const updateBookings = (booking) => ({type: UPDATE_BOOKINGS, booking});
export const changeStateBookingSaveButton = (value) => ({type: CHANGE_STATE_BOOKING_SAVE_BUTTON, value})
export const changeTypeDiscount = (typeDiscount) => ({type: CHANGE_TYPE_DISCOUNT, typeDiscount})
export const changeTypeDiscountForTicket = (typeDiscount, id) => ({
    type: CHANGE_TYPE_DISCOUNT_FOR_TYPE_TICKET,
    typeDiscount,
    id
})
export const changeExcursionForBooking = (excursion) => ({type: CHNAGE_EXCURSION_FOR_BOOKING, excursion})
export const checkAllBookings = (value) => ({type: CHECK_ALL_BOOKINGS, value})
export const checkBooking = (id, value) => ({type: CHECK_BOOKING, id, value})
export const deleteBookingInBookings = (id) => ({type: DELETE_BOOKING, id})


export const getBookings = (data) => async (dispatch) => {
    await BookingsApi.getAll(localStorage.getItem('auth_token'), data).then(response => {

        if(response.data) {
            if(response.data?.bookings) {
                let newData = response.data.bookings.map(item => {
                    item.checked = false;
                    return item
                });

                dispatch(setCountPages(response.data.pages))
                dispatch(setBookings(newData))
            }
        }
    })
}
export const createBooking = (data, navigate) => async (dispatch) => {
    await BookingsApi.put(data).then(response => {
        dispatch(addBooking(response.data));
        dispatch(clearCurrentBooking());
        navigate(`/bookings/${response.data.id}`)
    });
}
export const editBooking = (data) => async (dispatch) => {
    await BookingsApi.patch(data).then(response => {
        dispatch(changeCurrentBooking(response.data));
        dispatch(updateBookings(response.data));
        dispatch(changeStateBookingSaveButton(true));
    });
}
export const setAdditionalComment = (data) => async (dispatch) => {
    await BookingsApi.setAdditionalComment(data).then(response => {
        dispatch(changeBooking(response.data))
    });
}
export const cancelBooking = (data) => async (dispatch) => {
    await BookingsApi.cancelBooking(data).then(response => {
        dispatch(updateBookings(response.data));
    });
}
export const invoiceBooking = (data) => async (dispatch) => {
    await BookingsApi.invoiceBooking(data).then(response => {
    });
}
export const deleteBooking = (data) => async (dispatch) => {
    await BookingsApi.deleteBooking(data).then(response => {
        if(response.data){
            dispatch(deleteBookingInBookings(response.data.id));
        }
    });
}

export const filterByDate = (date) => ({type: FILTER_BY_DATE, date});
export const filterByExcursion = (excursion) => ({type: FILTER_BY_EXCURSION, excursion});
export const filterByStatus = (status) => ({type: FILTER_BY_STATUS, status});
