import PromocodesApi from "../../api/PromocodesApi";

const CHANGE_CURRENT_PROMOCOD = "CHANGE_CURRENT_PROMOCOD";
const CLEAR_CURRENT_PROMOCOD = "CLEAR_CURRENT_PROMOCOD";
const ADD_PROMOCOD = "ADD_PROMOCOD";
const SET_PROMOCODES = "SET_PROMOCODES";
const UPDATE_PROMOCODES = "UPDATE_PROMOCODES";
const CHANGE_EXCURSIONS_FOR_CURRENT_PROMOCOD = "CHANGE_EXCURSIONS_FOR_CURRENT_PROMOCOD";
const CHANGE_STATE_PROMOCOD_SAVE_BUTTON = "CHANGE_STATE_PROMOCOD_SAVE_BUTTON";

const defaultState = {
    promocodes: [],
    currentPromocod: false,
    newPromocod: {
        type: "promocodes",
        id: "",
        name: [],
        code: null,
        percent: null,
        applicationLimit: null,
        numberOfApplications: null,
        time: {
            start: {
                unix: 0,
                date: "00.00.0000",
            },
            end: {
                unix: 0,
                date: "00.00.0000",
            },
        },
        excursion: [],
    },
    disabledButton: true,
}

export const promocodesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_PROMOCODES:
            return {
                ...state,
                promocodes: action.promocodes,
            }
        case CHANGE_CURRENT_PROMOCOD:
            return {...state, currentPromocod: action.promocod}
        case CLEAR_CURRENT_PROMOCOD:
            return {...state, currentPromocod: false}
        case ADD_PROMOCOD:
            return {
                ...state,
                promocodes: [
                    ...state.promocodes,
                    action.promocod
                ]
            }
        case UPDATE_PROMOCODES:
            return {
                ...state,
                promocodes: state.promocodes.map(promocod => {
                    if (promocod.id == action.promocod.id) {
                        return action.promocod;
                    }
                    return promocod;
                })
            }
        case CHANGE_EXCURSIONS_FOR_CURRENT_PROMOCOD:
            return {
                ...state,
                currentPromocod: {
                    ...state.currentPromocod,
                    excursion: action.excursions,
                }
            }
        case CHANGE_STATE_PROMOCOD_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        default:
            return state;
    }
}

export const setPromocodes = (promocodes) => ({type: SET_PROMOCODES, promocodes});
export const changeCurrentPromocod = (promocod) => ({type: CHANGE_CURRENT_PROMOCOD, promocod});
export const clearCurrentPromocod = () => ({type: CLEAR_CURRENT_PROMOCOD});
export const addPromocod = (promocod) => ({type: ADD_PROMOCOD, promocod});
export const updatePromocodes = (promocod) => ({type: UPDATE_PROMOCODES, promocod});
export const changeExcursionsForCurrentPromocod = (excursions) => ({
    type: CHANGE_EXCURSIONS_FOR_CURRENT_PROMOCOD,
    excursions
});
export const changeStatePromocodSaveButton = (value) => ({type: CHANGE_STATE_PROMOCOD_SAVE_BUTTON, value})

export const createPromocod = (data, navigate) => async (dispatch) => {
    dispatch(changeStatePromocodSaveButton(true))
    await PromocodesApi.put(data).then(response => {
        dispatch(addPromocod(response.data));
        dispatch(clearCurrentPromocod());
        navigate(`/promocodes/${response.data.id}`)
    });
}
export const editPromocod = (data) => async (dispatch) => {
    dispatch(changeStatePromocodSaveButton(true))
    await PromocodesApi.patch(data).then(response => {
        dispatch(changeCurrentPromocod(response.data));
        dispatch(updatePromocodes(response.data));
    });
}
export const getPromocodes = () => async (dispatch) => {
    await PromocodesApi.getAll(localStorage.getItem('auth_token')).then(response => {
        dispatch(setPromocodes(response.data))
    })
}
export const toArchivePromocode = (data) => async (dispatch) => {
    dispatch(changeStatePromocodSaveButton(true))
    await PromocodesApi.deletePromocode(data).then(response => {
        dispatch(changeCurrentPromocod(response.data));
        dispatch(updatePromocodes(response.data));
    })
}