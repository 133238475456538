import React from "react";
import classes from "./TableItemColor.module.scss";

const TableItemColor = ({color}) => {
    return(
        <div className={classes.cell}>
            <div className={classes.color} style={{background:color}}>

            </div>
        </div>
    )
}

export default TableItemColor;