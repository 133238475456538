import GroupApi from "../../api/GroupApi";

const CHANGE_CURRENT_GROUP = "CHANGE_CURRENT_GROUP";
const SET_GROUPS = "SET_GROUPS"
const CHANGE_STATE_GROUP_SAVE_BUTTON = "CHANGE_STATE_GROUP_SAVE_BUTTON"
const CHANGE_EXCURSIONS_FOR_CURRENT_GROUP = "CHANGE_EXCURSIONS_FOR_CURRENT_GROUP"
const UPDATE_GROUPS = "UPDATE_GROUPS"
const CLEAR_CURRENT_GROUP = "CLEAR_CURRENT_GROUP";
const ADD_GROUP = "ADD_GROUP";
const DELETE_GROUP_IN_GROUPS = "DELETE_GROUP_IN_GROUPS";

const defaultState = {
    groups: [],
    currentGroup: false,
    newGroup: {
        type: "group",
        id: null,
        name: [],
        excursions: []
    },
    disabledButton: false,
}

export const groupReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_GROUPS:
            return {
                ...state,
                groups: action.groups,
            }
        case ADD_GROUP:
            return {
                ...state,
                groups: [
                    ...state.groups,
                    action.group
                ]
            }
        case CLEAR_CURRENT_GROUP:
            return {
                ...state,
                currentGroup: false
            }
        case CHANGE_CURRENT_GROUP:
            return {
                ...state,
                currentGroup: action.group
            }
        case CHANGE_STATE_GROUP_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        case CHANGE_EXCURSIONS_FOR_CURRENT_GROUP:
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    excursions: action.excursions,
                }
            }
        case UPDATE_GROUPS:
            return {
                ...state,
                groups: state.groups.map(group => {
                    if (group.id == action.group.id) {
                        return action.group;
                    }
                    return group;
                })
            }
        case DELETE_GROUP_IN_GROUPS:
            return {
                ...state,
                groups: [...state.groups].filter(group => group.id != action.id)
            }
        default:
            return state;
    }
}


export const setGroups = (groups) => ({type: SET_GROUPS, groups});
export const changeCurrentGroup = (group) => ({type: CHANGE_CURRENT_GROUP, group});
export const changeStateGroupSaveButton = (value) => ({type: CHANGE_STATE_GROUP_SAVE_BUTTON, value})
export const changeExcursionsForCurrentGroup = (excursions) => ({
    type: CHANGE_EXCURSIONS_FOR_CURRENT_GROUP,
    excursions
});
export const updateGroups = (group) => ({type: UPDATE_GROUPS, group});
export const clearCurrentGroup = () => ({type: CLEAR_CURRENT_GROUP});
export const addGroup = (group) => ({type: ADD_GROUP, group});
export const deleteGroupInGroups = (id) => ({type: DELETE_GROUP_IN_GROUPS, id});

export const getGroups = (branch) => async (dispatch) => {
    await GroupApi.getAll(localStorage.getItem('auth_token'), branch).then(response => {

        dispatch(setGroups(response.data))
    })
}
export const editGroup = (data) => async (dispatch) => {
    dispatch(changeStateGroupSaveButton(true))
    await GroupApi.patch(data).then(response => {
        dispatch(changeCurrentGroup(response.data));
        dispatch(updateGroups(response.data));
    });
}
export const deleteGroup = (data, navigate) => async (dispatch) => {
    await GroupApi.deleteGroup(data).then(response => {
        if (response.data) {
            dispatch(deleteGroupInGroups(response.data.id))
            navigate('/group')
        }
    });
}
export const createGroup = (data, navigate) => async (dispatch) => {
    dispatch(changeStateGroupSaveButton(true))
    await GroupApi.put(data).then(response => {
        dispatch(addGroup(response.data));
        dispatch(clearCurrentGroup());
        navigate(`/group/${response.data.id}`)
    });
}