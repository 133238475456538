import React, {useState} from "react";
import classes from "./TableHeaderCheckbox.module.scss";

const TableHeaderCheckbox = ({value, callback}) => {

    let onChange = () => {
        callback(value === false ? true : false );
    }

    return(
        <div className={classes.item}>
            <input type={"checkbox"} onChange={onChange} checked={value}/>
        </div>
    )
}

export default TableHeaderCheckbox;