import React from 'react'
import CustomSelect from '../../../UI/CustomSelect/CustomSelect'
import Status from '../../../UI/Status/Status'
import Row from '../BookingsCreate/Row'

const BookingsEditThirdColumn = ({data, setData, error, setError, turnOnSaveButton}) => {
    
    const changeAction = e => {

        setData({
            ...data,
            action: data.action.map(item => ({
                ...item,
                selected: item.key == e.selectedItem.key
            }))
        })

        turnOnSaveButton();

    }
    
    return (
        <div>
            <Row
                title="Замовлення дії"
            >
                <CustomSelect 
                    items={data.action}
                    onChange={changeAction}
                />
            </Row>

            <Row>
                <Status>
                    {data.status.name}
                </Status>
            </Row>
        </div>
    )
}

export default BookingsEditThirdColumn