import React from 'react';
import classes from "./TableItemComment.module.scss";

const TabelItemComment = ({callback, bookingId, comment, style}) => {
    const callbackAdditionalComment = (e) => {
        e.preventDefault()
            callback(bookingId, comment)
    }
    return (
        <div className={classes.cell} style={style}>

                <div className={classes.button} onClick={callbackAdditionalComment}>
                    {comment &&
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 48 48" fill="#B5B5B5">
                            <path fillRule="evenodd"
                                  d="M24.5 33.002a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9m-3.06-4.44L20.5 6h8l-.94 22.563A1.5 1.5 0 0 1 26.061 30h-3.122a1.5 1.5 0 0 1-1.499-1.437"/>
                        </svg>
                    }
                    {comment == false || comment == null &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#B5B5B5"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                    }
                </div>

        </div>
    );
};

export default TabelItemComment;