import React from "react";
import classes from "./TableItemText.module.scss";

const TableItemText = ({text, description, colorDescription, strong, style}) => {
    return(
        <div className={classes.cell} style={style}>
            {strong == true
            ?
                <strong style={{fontWeight: '500'}}>{text}</strong>
            :
                text
            }
            <span style={{color: colorDescription ? colorDescription : "#000"}}>
                {description}
            </span>
        </div>
    )
}

export default TableItemText;