import React from 'react'
import CertificatesApi from '../../../../api/CertificatesApi'
import ButtonHalfTransparent from '../../../UI/ButtonHalfTransparent/ButtonHalfTransparent'
import CustomSelect from '../../../UI/CustomSelect/CustomSelect'
import Error from '../../../UI/Error/Error'
import Row from '../BookingsCreate/Row'
import { useSelector } from 'react-redux'
import { ReactSVG } from 'react-svg'
import deleteSVG from '../../../../assets/images/Path 114.svg';
import Button from '../../../UI/Button'

const BookingsEditSecondColumn = ({data, setData, error, setError, turnOnSaveButton}) => {

    const discountsSelector = useSelector(state => state.discounts.discounts);
    const certificateSelector = useSelector(state => state.сertificates.сertificates);
    const promocodesSelector = useSelector(state => state.promocodes.promocodes);

    const changeSelectList = e => {

        if(e.selectedItem.key == null) {
            turnOnSaveButton();
        }

        setData({
            ...data,
            solo: {
                ...data.solo,
                selectList: data.solo.selectList.map(item => ({...item, selected: item.key == e.selectedItem.key ? true : false})),
                discounts: data.solo.discounts.map(item => ({...item, selected: false})),
                promocodes: data.solo.promocodes.map(item => ({...item, selected: false})),
                certificate: '',
            }
        })
    }

    const changeDiscounts = e => {

        setData({
            ...data,
            solo: {
                ...data.solo,
                discounts: data.solo.discounts.map(disc => ({...disc, selected: disc.key == e.selectedItem.key})),
            }
        })

        turnOnSaveButton();

    }

    const changeCertificate = e => {
        if(document.certificateListener) clearTimeout(document.certificateListener)

        document.certificateListener = setTimeout(async () => {

            const response = await CertificatesApi.checkCertificate({
                excursion_id: data.excursionId,
                code: e.target.value,
                time: {
                    date: data.date ? data.date : null,
                    startTime: data.startTime ? data.startTime : null,
                }
            });

            if(response.data.success || e.target.value == data.discountId) {
                setError({
                    ...error,
                    solo: {
                        ...error.solo,
                        certificate: '',
                    }
                });
            }
            else {
                setError({
                    ...error,
                    solo: {
                        ...error.solo,
                        certificate: response.data.message,
                    }
                });
            }
        }, 1000);

        setData({
            ...data,
            solo: {
                ...data.solo,
                certificate: e.target.value,
            }
        })
    }

    const changePromocodes = e => {
        setData({
            ...data,
            solo: {
                ...data.solo,
                promocodes: data.solo.promocodes.map(item => ({...item, selected: item.key == e.selectedItem.key})),
            }
        })

        turnOnSaveButton();
    }

    const changeAdditionalOptions = e => {
        setData({
            ...data,
            additionalOptions: data.additionalOptions.map(opt => ({...opt, selected: opt.key == e.selectedItem.key ? !opt.selected : opt.selected}))
        });

        turnOnSaveButton();
    }

    const changeTicketCertificate = (e, ticket) => {

        
        if(!document.listeners) document.listeners = [];

        const obj = document.listeners.find(item => item.id == ticket.id);
        const tickets = data.tickets.filter(item => item.id !== ticket.id && item.selectList.find(_item => _item.selected)?.key == ticket.selectList.find(_item => _item.selected)?.key && item.certificate == e.target.value);

        const cert = certificateSelector.find(item => item.code == e.target.value);

        if(cert && tickets.length > cert.quantityPerson + data._tickets.filter(item => item.selectList.find(_item => _item.selected)?.key == 1 && item.certificate == e.target.value).length) {
            setError({
                ...error,
                tickets: [
                    ...error.tickets.filter(item => item.id !== ticket.id),
                    {
                        id: ticket.id,
                        certificate: `Ліміт на застосування цього сертифікату ${cert.quantityPerson}`,
                    }
                ]
            })
        }

        if(obj) {
            clearTimeout(obj.timeout);
            document.listeners = document.listeners.filter(item => item.id !== ticket.id)
            document.listeners.push({
                id: ticket.id,
                timeout: setTimeout(async () => {
                    const response = await CertificatesApi.checkCertificate({
                        excursion_id: data.excursionId,
                        code: e.target.value,
                        time: {
                            date: data.date ? data.date : null,
                            startTime: data.startTime ? data.startTime : null,
                        }
                    })
                    if(response.data.success || tickets.length <= cert.quantityPerson + data._tickets.filter(item => item.selectList.find(_item => _item.selected)?.key == 1 && item.certificate == e.target.value).length) {
                        setError({
                            ...error,
                            tickets: [
                                ...error.tickets.filter(item => item.id !== ticket.id),
                            ]
                        })
                    }
                    else {
                        setError({
                            ...error,
                            tickets: [
                                ...error.tickets.filter(item => item.id !== ticket.id),
                                {
                                    id: ticket.id,
                                    certificate: response.data.message,
                                }
                            ]
                        })
                    }
                }, 1000)
            })
        }
        else {
            document.listeners.push({
                id: ticket.id,
                timeout: setTimeout(async () => {
                    const response = await CertificatesApi.checkCertificate({
                        excursion_id: data.excursionId,
                        code: e.target.value,
                        time: {
                            date: data.date ? data.date : null,
                            startTime: data.startTime ? data.startTime : null,
                        }
                    })
                    if(response.data.success || tickets.length < cert.quantityPerson + data._tickets.filter(item => item.selectList.find(_item => _item.selected)?.key == 1 && item.certificate == e.target.value).length) {
                        setError({
                            ...error,
                            tickets: [
                                ...error.tickets.filter(item => item.id !== ticket.id),
                            ]
                        })
                    }
                    else {
                        setError({
                            ...error,
                            tickets: [
                                ...error.tickets.filter(item => item.id !== ticket.id),
                                {
                                    id: ticket.id,
                                    error: response.data.message,
                                }
                            ]
                        })
                    }
                }, 1000)
            })
        }

        setData({
            ...data,
            tickets: data.tickets.map(item => item.id != ticket.id ? item : ({
                ...ticket,
                certificate: e.target.value,
            }))
        })

    }

    const changeTicketPromocode = (e, ticket) => {

        setData({
            ...data,
            tickets: data.tickets.map(item => item.id == ticket.id ? ({
                ...ticket,
                promocodes: ticket.promocodes.map(_item => ({..._item, selected: _item.key == e.selectedItem.key ? true : false}))
            }) : ({
                ...item,
                promocodes: item.promocodes.find(prom => prom.selected) ? item.promocodes : promocodesSelector.filter(item => {

                    const usedPromocodes = data.tickets.filter(t => t.selectList.find(sl => sl.selected)?.key == 2 && t.promocodes.find(pr => pr.selected)?.key == item.id)

                    if(item.id == e.selectedItem.key) usedPromocodes.push(1);
                    
                    return  item.excursion.find(ex => ex.id == data.excursionId) !== undefined && 
                            new Date(data.date) / 1000 < item.time.end.unix && 
                            item.numberOfApplications + usedPromocodes.length < item.applicationLimit
                    })
                    ?.map(item => ({
                        key: item.id, 
                        value: item.name.find(name => name?.default_language == true).text
                    })),
            }))
        })
    }

    const addTicket = () => {
        
        setError({
            ...error,
            emptyTickets: '',
        })

        setData({
            ...data,
            tickets: [
                ...data.tickets,
                {
                    id: Date.now(),
                    selectList: [
                        {key: null, value: '...', selected: true},
                        {key: 0, value: 'Знижка'},
                        {key: 1, value: 'Сертифікат'},
                        {key: 2, value: 'Промокод'},
                    ],
                    discounts: discountsSelector.filter(disc => !disc?.deleted).map(item => ({key: item.id, value: item.name.find(name => name?.default_language == true).text})),
                    certificate: '',
                    promocodes: promocodesSelector.filter(prom => !prom?.deleted).filter(item => {

                        const usedPromocodes = data.tickets.filter(t => t.selectList.find(sl => sl.selected)?.key == 2 && t.promocodes.find(pr => pr.selected)?.key == item.id)

                        const usedInExcursion = data._tickets.filter(_item => _item.discount_type == 2 && _item.discount_id == item.id)

                        return item.excursion.find(ex => ex.id == data.excursionId) !== undefined && 
                               new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) / 1000 < item.time.end.unix &&
                               item.numberOfApplications + usedPromocodes.length < item.applicationLimit + usedInExcursion.length
                        })
                        ?.map(item => ({
                            key: item.id, 
                            value: item.name.find(name => name?.default_language == true).text
                        })),
                    additionalOptions: data.currentExcursion.additionalOptions.filter(opt => !opt?.deleted).map(opt => ({
                        key: opt.id, 
                        value: opt.name.find(n => n.default_language).text, 
                        price: opt.price,
                    }))
                }
            ]
        });

        turnOnSaveButton();
    }

    // 2 - 11

    return (
        <div>
            {data.currentExcursion.typeExcursion.key != 'ticket' &&

                <div>

                    {(data.baseSolo.selectList.find(item => item.selected)?.key == 1 || data.baseSolo.selectList.find(item => item.selected)?.key == 2) &&
                    
                        <div>
                            <Row
                                title="Знижка/Сертифікат/Промокод"
                            >
                                <input
                                    type="text" 
                                    style={{backgroundColor: '#dedede'}}
                                    disabled={true}
                                    value={data.baseSolo.selectList.find(item => item.selected)?.key == 1 ? 'Сертифікат' : 'Промокод'}
                                />
                            </Row>

                            <Row
                                title={data.baseSolo.selectList.find(item => item.selected)?.key == 1 ? 'Введіть сертифікат' : 'Оберіть промокод'}
                            >
                                <input
                                    type="text" 
                                    style={{backgroundColor: '#dedede'}}
                                    disabled={true}
                                    value={data.baseSolo.selectList.find(item => item.selected)?.key == 1 ? data.solo.certificate : data.solo.promocodes.find(pr => pr.selected).value}
                                />
                            </Row>
                        </div>
                    
                    }

                    {(data.baseSolo.selectList.find(item => item.selected)?.key != 1 && data.baseSolo.selectList.find(item => item.selected)?.key != 2) &&
                    
                        <div>

                            <Row
                                className="selectList"
                                title="Знижка/Сертифікат/Промокод"
                                style={{marginBottom: 15}}
                            >
                                <CustomSelect 
                                    items={data.solo.selectList}
                                    onChange={changeSelectList}
                                />
                            </Row>

                            {data.solo.selectList.find(item => item.selected == true)?.key == 0 &&
                            
                                <Row
                                    className=""
                                    title="Оберіть знижку"
                                >
                                    <CustomSelect 
                                        items={data.solo.discounts}
                                        onChange={changeDiscounts}
                                    />
                                </Row>
                            
                            }

                            {data.solo.selectList.find(item => item.selected == true)?.key == 1 &&
                            
                                <Row
                                    className=""
                                    title="Введіть сертифікат"
                                >
                                    <input 
                                        type="text"
                                        className={error?.solo?.certificate ? 'error' : ''}
                                        value={data.solo.certificate}
                                        onChange={changeCertificate}
                                    />

                                    {error?.solo?.certificate &&
                                        <Error>{error.solo.certificate}</Error>
                                    }
                                </Row>
                            
                            }

                            {data.solo.selectList.find(item => item.selected == true)?.key == 2 &&
                            
                                <Row
                                    className=""
                                    title="Оберіть промокод"
                                >
                                    <CustomSelect 
                                        items={data.solo.promocodes}
                                        onChange={changePromocodes}
                                    />
                                </Row>
                            
                            }

                        </div>
                    
                    }

                    <Row
                        title="Додаткові опції"
                        style={{marginBottom: 10, marginTop: 15}}
                    >
                        <CustomSelect 
                            items={data.additionalOptions}
                            onChange={changeAdditionalOptions}
                            multi={true}
                        />
                    </Row>

                </div>
            }

            {data.currentExcursion.typeExcursion.key == 'ticket' &&
                <div>
                    <div>
                        {data.tickets.map((ticket, index) => 
                        
                            <div key={ticket.id} style={{marginBottom: 20}}>

                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15}}>
                                    <h3 style={{marginTop: 0, marginBottom: 0}}>Квиток {index + 1}</h3>
                                    <ButtonHalfTransparent
                                        onClick={e => {

                                            const filteredTickets = data.tickets.filter(item => item.id !== ticket.id)

                                            setData({
                                                ...data,
                                                tickets: filteredTickets.map(t => t.promocodes.find(temp => temp.selected) ? t : ({
                                                    ...t,
                                                    promocodes: promocodesSelector.filter(item => {

                                                        const usedPromocodes = filteredTickets.filter(tick => tick.selectList.find(sl => sl.selected)?.key == 2 && tick.promocodes.find(pr => pr.selected)?.key == item.id);

                                                        const usedInExcursion = data._tickets.filter(_item => _item.discount_type == 2 && _item.discount_id == item.id)

                                                        return item.excursion.find(ex => ex.id == data.excursionId) !== undefined && 
                                                               new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) / 1000 < item.time.end.unix &&
                                                               item.numberOfApplications + usedPromocodes.length < item.applicationLimit + usedInExcursion.length
                                                        })
                                                        ?.map(item => ({
                                                            key: item.id, 
                                                            value: item.name.find(name => name?.default_language == true).text
                                                        })),
                                                }))
                                            })
                                        }}
                                    >
                                        <ReactSVG src={deleteSVG}/>
                                        <span style={{fontSize: 15}}>Видалити квиток</span>
                                    </ButtonHalfTransparent>
                                </div>
                                <Row
                                    title="Знижка/Сертифікат/Промокод"
                                    style={{marginBottom: 15}}
                                >
                                    <CustomSelect 
                                        items={ticket.selectList}
                                        onChange={e => {
                                            setData({
                                                ...data,
                                                tickets: data.tickets.map(item => item.id != ticket.id ? item : ({
                                                    ...ticket,
                                                    selectList: ticket.selectList.map(_item => ({..._item, selected: _item.key == e.selectedItem.key ? true : false})),
                                                    certificate: '',
                                                    discounts: item.discounts.map(disc => ({...disc, selected: false})),
                                                    promocodes: item.promocodes.map(prom => ({...prom, selected: false})),
                                                }))
                                            })
                                        }}
                                        readonly={ticket?.defaulted && (ticket?.baseDiscountType == 1 || ticket?.baseDiscountType == 2)}
                                    />
                                </Row>
                                
                                {ticket.selectList.find(item => item.selected == true)?.key == 0 &&
                                    <Row
                                        className=""
                                        title="Оберіть знижку"
                                    >
                                        <CustomSelect 
                                            classError={error.tickets.find(item => item.id == ticket.id)?.discount ? 'error' : ''}
                                            items={ticket.discounts}
                                            onChange={e => {
                                                setData({
                                                    ...data,
                                                    tickets: data.tickets.map(item => item.id != ticket.id ? item : ({
                                                        ...ticket,
                                                        discounts: ticket.discounts.map(_item => ({..._item, selected: _item.key == e.selectedItem.key ? true : false}))
                                                    }))
                                                })
                                            }}
                                        />

                                        {error.tickets.find(item => item.id == ticket.id)?.discount && 
                                            <Error>{error.tickets.find(item => item.id == ticket.id)?.discount}</Error>
                                        }
                                    </Row>
                                }

                                {ticket.selectList.find(item => item.selected == true)?.key == 1 &&
                                    <Row
                                        className=""
                                        title="Введіть сертифікат"
                                    >
                                        <input 
                                            type="text"
                                            className={error.tickets.find(item => item.id == ticket.id)?.certificate ? 'error' : ''}
                                            value={ticket.certificate}
                                            onChange={e => changeTicketCertificate(e, ticket)}
                                            readOnly={ticket?.defaulted && (ticket?.baseDiscountType == 1 || ticket?.baseDiscountType == 2)}
                                        />
                                        {error.tickets.find(item => item.id == ticket.id)?.certificate && 
                                            <Error>{error.tickets.find(item => item.id == ticket.id)?.certificate}</Error>
                                        }
                                    </Row>
                                } 

                                {ticket.selectList.find(item => item.selected == true)?.key == 2 &&
                                    <Row
                                        className=""
                                        title="Оберіть промокод"
                                    >
                                        <CustomSelect 
                                            items={ticket.promocodes}
                                            classError={error.tickets.find(item => item.id == ticket.id)?.promocode ? 'error' : ''}
                                            onChange={e => changeTicketPromocode(e, ticket)}
                                            readonly={ticket?.defaulted}
                                        />
                                        {error.tickets.find(item => item.id == ticket.id)?.promocode &&
                                            <Error>{error.tickets.find(item => item.id == ticket.id)?.promocode}</Error>
                                        }
                                    </Row>
                                } 

                                <Row
                                    title="Додаткові опції"
                                    style={{marginTop: 15}}
                                >
                                    <CustomSelect 
                                        items={ticket.additionalOptions}
                                        onChange={e =>
                                            setData({
                                                ...data,
                                                tickets: data.tickets.map(editedTicket => ({
                                                    ...editedTicket,
                                                    additionalOptions: editedTicket.id != ticket.id ? editedTicket.additionalOptions : ticket.additionalOptions.map(opt => ({
                                                        ...opt,
                                                        selected: opt.key == e.selectedItem.key ? !e.selectedItem.selected : opt.selected,
                                                    }))
                                                }))
                                            })
                                        }
                                        multi={true}
                                    />
                                </Row>
                            </div>
                        
                        )}
                        {data.tickets.length < data.baseTickets &&
                            <div
                                style={{
                                    border: error.emptyTickets ? 'solid 1px #ff5c5c' : '',
                                    padding: error.emptyTickets ? '15px' : '',
                                }}
                            >
                                <Button 
                                    icon="add" 
                                    color="#FFD52F"
                                    callBack={addTicket}
                                    style={{minHeight: 0, paddingTop: 10, paddingBottom: 10}}
                                >
                                    Додати
                                </Button>
                                {error.emptyTickets &&
                                    <Error>{error.emptyTickets}</Error>
                                }
                            </div>
                        }
                    </div>
                </div>
            }

            <Row
                title="Статус оплати"
            >
                <CustomSelect 
                    items={data.paymentStatus}
                    onChange={e => {
                        setData({
                            ...data,
                            paymentStatus: data.paymentStatus.map(item => ({...item, selected: item.key == e.selectedItem.key}))
                        })
                        turnOnSaveButton();
                    }}
                />
            </Row>

        </div>
    )
}

export default BookingsEditSecondColumn