import CalendarApi from "../../api/CalendarApi";
import WorkTableApi from "../../api/WorkTableApi";

const SET_EDIT_BOOKING = "SET_EDIT_BOOKING"
const SET_BOOKINGS_IN_CALENDAR = "SET_BOOKINGS_IN_CALENDAR"
const SET_START_DAY = "SET_START_DAY"
const CHANGE_PARAMS_TIME = "CHANGE_PARAMS_TIME"
const CHANGE_PARAMS_EXCURSION = "CHANGE_PARAMS_EXCURSION"
const CHANGE_PARAMS_GUIDE = "CHANGE_PARAMS_GUIDE"
const CHANGE_AVAILABEL_GUIDES = "CHANGE_AVAILABEL_GUIDES"
const UPDATE_BOOKINGS_CALENDAR = "UPDATE_BOOKINGS_CALENDAR"

const defaultState = {
    params: {
        time: null,
        excursion: null,
        guide: null,
    },
    currenTime: null,
    bookings: null,
    startDay: null,
    editBooking: null,
    availableGuides: null,
}

export const calendarReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_EDIT_BOOKING:
            return {
                ...state,
                editBooking: action.editBooking,
            }
        case SET_BOOKINGS_IN_CALENDAR:
            return {
                ...state,
                bookings: action.bookings,
            }
        case SET_START_DAY:
            return {
                ...state,
                startDay: action.startDay,
            }
        case CHANGE_PARAMS_TIME:
            return {
                ...state,
                params: {
                    ...state.params,
                    time: action.unix,
                }
            }
        case CHANGE_PARAMS_EXCURSION:
            return {
                ...state,
                params: {
                    ...state.params,
                    excursion: action.id,
                }
            }
        case CHANGE_PARAMS_GUIDE:
            return {
                ...state,
                params: {
                    ...state.params,
                    guide: action.id,
                }
            }
        case CHANGE_AVAILABEL_GUIDES:
            return {
                ...state,
                availableGuides: action.obj
            }
        case UPDATE_BOOKINGS_CALENDAR:
            let newBookings = [...state.bookings]
            newBookings.map(arrBooking => {
                let arr = arrBooking.map(booking => {
                    if(booking.type == "booking" && action.bookings.find(item => item.id == booking.id)){
                        return action.bookings.find(item => item.id == booking.id)
                    } else {
                        return booking
                    }
                })
                return arr
            })
            return {
                ...state,
                bookings: newBookings
            }
        default:
            return state;
    }
}


export const setEditObject = (editBooking) => ({type: SET_EDIT_BOOKING, editBooking});
export const setBookingsInCalendar = (bookings) => ({type: SET_BOOKINGS_IN_CALENDAR, bookings});
export const setStartDay = (startDay) => ({type: SET_START_DAY, startDay});
export const changeParamsTime = (unix) => ({type: CHANGE_PARAMS_TIME, unix})
export const changeParamsExcursion = (id) => ({type: CHANGE_PARAMS_EXCURSION, id})
export const changeParamsGuide = (id) => ({type: CHANGE_PARAMS_GUIDE, id})
export const changeAvailabelGuides = (obj) => ({type: CHANGE_AVAILABEL_GUIDES, obj})
export const updateBookingsCalendar = (bookings) => ({type: UPDATE_BOOKINGS_CALENDAR, bookings})

export const getTimeTable = (data) => async (dispatch) => {    console.log(data)
    await CalendarApi.getTable(data).then(response => {
        if(response.data.length != 0){
            const arrBookings = response.data
            let newArrBookings = [];

            for (let i = 0; i < arrBookings.length; i++) {
                const bookings = arrBookings[i]
                const refactorBookings = [];

                for (let j = 0; j < bookings.length; j++) {

                    const currentBooking = bookings[j]

                    if(currentBooking.excursion.typeExcursion.key == "ticket"){
                        const identicalBookings = bookings.filter(item => item.time.start.unix == currentBooking.time.start.unix && item.time.end.unix == currentBooking.time.end.unix)

                        if(identicalBookings.length > 1 ){
                            let refactorBooking = {
                                type: "concat_booking",
                                id: identicalBookings[0].id,
                                time: currentBooking.time,
                                excursion: currentBooking.excursion,
                                guides: currentBooking.guides,
                                bookings: identicalBookings
                            }

                            if(!refactorBookings.find(item => item.id == refactorBooking.id)){
                                refactorBookings.push(refactorBooking)
                            }

                        } else {
                            refactorBookings.push(currentBooking)
                        }
                    } else {
                        refactorBookings.push(currentBooking)
                    }


                }

                arrBookings[i] = refactorBookings;
            }

            for (let z = 0; z < arrBookings.length; z++) {
                const bookings = arrBookings[z];
                const bookingRows = [];

                for (let i = 0; i < bookings.length; i++) {
                    let booking = bookings[i]
                    let prevBooking = (i != 0) ? bookings[i - 1] : null
                    let concatBooking = null

                    if (i == 0) {
                        booking.column = 1
                    }
                    if (i !== 0 && booking.time.start.unix >= prevBooking.time.end.unix) {
                        booking.column = 1
                    }
                    if (i !== 0 && booking.time.start.unix < prevBooking.time.end.unix) {
                        booking.column = prevBooking.column + 1
                    }

                    if (bookingRows[booking.column - 1]) {
                        bookingRows[booking.column - 1].push(booking)
                    } else {
                        bookingRows[booking.column - 1] = []
                        bookingRows[booking.column - 1].push(booking)
                    }
                }

                newArrBookings.push(bookingRows);
            }

            //START DAY
            const bookingsSort = arrBookings.flat();
            bookingsSort.sort(function (a, b) {
                if (a.time.start.unix > b.time.start.unix && a.time.end.unix > b.time.end.unix) {
                    return 1;
                } else if (a.time.start.unix > b.time.start.unix && a.time.end.unix < b.time.end.unix) {
                    return -1;
                }
                if (a.time.start.unix < b.time.start.unix) {
                    return -1;
                }
                return 0;
            });
            let startDay = bookingsSort[0].time.start.time

            dispatch(setStartDay(startDay))
            dispatch(setBookingsInCalendar(newArrBookings));
        } else {
            dispatch(setStartDay(null))
            dispatch(setBookingsInCalendar(response.data));
        }
    });
}
export const checkGuides = (data) => async (dispatch) => {
    await WorkTableApi.get(data).then(response => {
        dispatch(changeAvailabelGuides(response.data))

    });
}
export const updateGuidesForBooking = (data) => async (dispatch) => {
    await CalendarApi.updateGuides(data).then(response => {
        dispatch(getTimeTable(data.paramsRequest))
        dispatch(setEditObject(false))
    });
}
