import ExcursionsApi from "../../api/ExcursionsApi";

const SET_EXCURSIONS = "SET_EXCURSIONS";
const CHANGE_COLOR = "CHANGE_COLOR";
const SET_ACTIVE_DAY_EXCURSION = "SET_ACTIVE_DAY_EXCURSION";
const CHANGE_CURRENT_EXCURSION = "CHANGE_CURRENT_EXCURSION"
const CHANGE_STATE_EXCURSION_SAVE_BUTTON = "CHANGE_STATE_EXCURSION_SAVE_BUTTON"
const CHANGE_LANGUAGES_FOR_CURRENT_EXCURSION = "CHANGE_LANGUAGES_FOR_CURRENT_EXCURSION"
const CHANGE_DISCOUNTS_FOR_CURRENT_EXCURSION = "CHANGE_DISCOUNTS_FOR_CURRENT_EXCURSION"
const CHANGE_OPTIONS_FOR_CURRENT_EXCURSION = "CHANGE_OPTIONS_FOR_CURRENT_EXCURSION"
const CHANGE_GUIDES_FOR_CURRENT_EXCURSION = "CHANGE_GUIDES_FOR_CURRENT_EXCURSION"
const CHANGE_BUFFER_EXCURSIONS_FOR_CURRENT_EXCURSION = "CHANGE_BUFFER_EXCURSIONS_FOR_CURRENT_EXCURSION"
const CHANGE_TIME_BUFFER_EXCURSION = "CHANGE_TIME_BUFFER_EXCURSION"
const CHANGE_ONLINE_PAYMENT = "CHANGE_ONLINE_PAYMENT"
const ADD_EXCURSION = "ADD_EXCURSION";
const CLEAR_CURRENT_EXCURSION = "CLEAR_CURRENT_EXCURSION";
const UPDATE_EXCURSIONS = "UPDATE_EXCURSIONS";
const CHANGE_TYPE_EXCURSION = "CHANGE_TYPE_EXCURSION";
const ADD_PRICE_PER_PERSON = "ADD_PRICE_PER_PERSON";
const REMOVE_PRICE_PER_PERSON = "REMOVE_PRICE_PER_PERSON";
const SET_BRANCH_TIME_TABLE = "SET_BRANCH_TIME_TABLE";


const defaultState = {
    excursions: [],
    currentExcursion: false,
    newExcursion: {
        type: "excursion",
        id: "",
        color: "#000000",
        status: {
            key: "noactive",
            name: "Деактивовано",
        },
        name: [],
        description: [],
        typeExcursion: {
            key: "ticket",
            name: "Поквитково"
        },
        baseTickets: null,
        durationExcursion: null,
        basePrice: null,
        onlinePayment: false,
        languages: [],
        discounts: [],
        additionalOptions: [],
        pricePerPerson: [],
        guides: [],
        timeTable: [
            {
                key: 1,
                name: {
                    small: "Пн",
                    large: "Понеділок",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 2,
                name: {
                    small: "Вт",
                    large: "Вівторок",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 3,
                name: {
                    small: "Ср",
                    large: "Середа",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 4,
                name: {
                    small: "Чт",
                    large: "Четвер",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 5,
                name: {
                    small: "Пт",
                    large: "П'ятниця",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 6,
                name: {
                    small: "Сб",
                    large: "Субота",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 7,
                name: {
                    small: "Нд",
                    large: "Неділя",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
        ],
        bufferExcursions: []
    },
    disabledButton: true,
}

export const excursionsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_EXCURSIONS:
            return {
                ...state,
                excursions: action.excursions,
            }
        case CHANGE_COLOR:

            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    color: action.color
                },
            }
        case SET_ACTIVE_DAY_EXCURSION:
            let stateCopy = {...state};
            stateCopy.currentExcursion.timeTable.forEach(item => {
                if (item.key == action.key) {
                    item.status = action.value;
                }
            })
            return stateCopy;
        case CHANGE_CURRENT_EXCURSION:
            return {...state, currentExcursion: action.branch}
        case CHANGE_STATE_EXCURSION_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        case CHANGE_LANGUAGES_FOR_CURRENT_EXCURSION:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    languages: action.languages,
                }
            }
        case CHANGE_DISCOUNTS_FOR_CURRENT_EXCURSION:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    discounts: action.discounts,
                }
            }
        case CHANGE_OPTIONS_FOR_CURRENT_EXCURSION:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    additionalOptions: action.options,
                }
            }
        case CHANGE_GUIDES_FOR_CURRENT_EXCURSION:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    guides: action.guides,
                }
            }
        case CHANGE_BUFFER_EXCURSIONS_FOR_CURRENT_EXCURSION:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    bufferExcursions: action.excursions,
                }
            }
        case CHANGE_TIME_BUFFER_EXCURSION:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    bufferExcursions: [...state.currentExcursion.bufferExcursions].map(excursion => {
                        if (excursion.id == action.id) {
                            return {
                                ...excursion,
                                time: action.time
                            }
                        } else {
                            return excursion
                        }
                    }),
                }
            }
        case CHANGE_ONLINE_PAYMENT:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    onlinePayment: action.value,
                }
            }
        case CLEAR_CURRENT_EXCURSION:
            return {...state, currentExcursion: false}
        case ADD_EXCURSION:
            return {
                ...state,
                excursions: [
                    ...state.excursions,
                    action.excursion
                ]
            }
        case UPDATE_EXCURSIONS:
            return {
                ...state,
                excursions: state.excursions.map(excursion => {
                    if (excursion.id == action.excursion.id) {
                        return action.excursion;
                    }
                    return excursion;
                })
            }
        case CHANGE_TYPE_EXCURSION:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    typeExcursion: {
                        key: action.typeExcursion,
                        name: action.textExcursion
                    },
                }
            }
        case ADD_PRICE_PER_PERSON:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    pricePerPerson: [
                        ...state.currentExcursion.pricePerPerson,
                        {
                            id: Math.floor(Math.random() * (1000 - 0) + 0),
                            price: null,
                            persons: null,
                        }
                    ]
                },
            }
        case REMOVE_PRICE_PER_PERSON:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    pricePerPerson: [...state.currentExcursion.pricePerPerson].filter(item => item.id != action.id)
                },
            }
        case SET_BRANCH_TIME_TABLE:
            return {
                ...state,
                currentExcursion: {
                    ...state.currentExcursion,
                    timeTable: action.table
                }

            }
        default:
            return state;
    }
}


export const setExcursions = (excursions) => ({type: SET_EXCURSIONS, excursions});
export const changeColor = (color) => ({type: CHANGE_COLOR, color})
export const setActiveDayExcursion = (key, value) => ({type: SET_ACTIVE_DAY_EXCURSION, key, value});
export const changeCurrentExcursion = (branch) => ({type: CHANGE_CURRENT_EXCURSION, branch});
export const changeStateExcursionSaveButton = (value) => ({type: CHANGE_STATE_EXCURSION_SAVE_BUTTON, value})
export const changeLanguagesForCurrentExcursion = (languages) => ({
    type: CHANGE_LANGUAGES_FOR_CURRENT_EXCURSION,
    languages
});
export const changeDiscountsForCurrentExcursion = (discounts) => ({
    type: CHANGE_DISCOUNTS_FOR_CURRENT_EXCURSION,
    discounts
});
export const changeOptionsForCurrentExcursion = (options) => ({type: CHANGE_OPTIONS_FOR_CURRENT_EXCURSION, options});
export const changeGuidesForCurrentExcursion = (guides) => ({type: CHANGE_GUIDES_FOR_CURRENT_EXCURSION, guides});
export const changeBufferExcursionsForCurrentExcursion = (excursions) => ({
    type: CHANGE_BUFFER_EXCURSIONS_FOR_CURRENT_EXCURSION,
    excursions
});
export const changeTimeBufferExcursion = (id, time) => ({type: CHANGE_TIME_BUFFER_EXCURSION, id, time});
export const changeOnlinePayment = (value) => ({type: CHANGE_ONLINE_PAYMENT, value})
export const changeTypeExcursion = (typeExcursion, textExcursion) => ({
    type: CHANGE_TYPE_EXCURSION,
    typeExcursion,
    textExcursion
})
export const addExcursion = (excursion) => ({type: ADD_EXCURSION, excursion});
export const clearCurrentExcursion = () => ({type: CLEAR_CURRENT_EXCURSION})
export const updateExcursions = (excursion) => ({type: UPDATE_EXCURSIONS, excursion})
export const addPricePerPerson = () => ({type: ADD_PRICE_PER_PERSON})
export const removePricePerPerson = (id) => ({type: REMOVE_PRICE_PER_PERSON, id})
export const setBranchTimeTable = (table) => ({type: SET_BRANCH_TIME_TABLE, table})

export const getExcursions = (branch) => async (dispatch) => {
    await ExcursionsApi.getAll(localStorage.getItem('auth_token'), branch).then(response => {
        dispatch(setExcursions(response.data))
    })
}
export const createExcursion = (data, navigate) => async (dispatch) => {
    dispatch(changeStateExcursionSaveButton(true));

    await ExcursionsApi.put(data).then(response => {
        dispatch(addExcursion(response.data));
        dispatch(clearCurrentExcursion());
        navigate(`/excursions/${response.data.id}`)
        // dispatch(changeStateExcursionSaveButton(true));
    });
}
export const editExcursion = (data) => async (dispatch) => {
    dispatch(changeStateExcursionSaveButton(true));
    await ExcursionsApi.patch(data).then(response => {
        //dispatch(clearCurrentExcursion(response.data));
        dispatch(updateExcursions(response.data));

        // document.location.reload();
    });
}

export const editExcursionStatus = (key, id) => async (dispatch) => {
    await ExcursionsApi.setActiveStatus(key, id).then(response => {
        dispatch(changeCurrentExcursion(response.data));
        dispatch(updateExcursions(response.data));
    });
}
