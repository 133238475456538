import React from "react";
import classes from "./DayWeek.module.scss";

const DayWeek = ({day, dayOfWeek, month}) => {
    const DaysOfWeeKLabel = ["Пн","Вт","Ср","Чт","Пт","Сб","Нд"];
    const monthes = [
        "Січня",
        "Лютого",
        "Березня",
        "Квітня",
        "Травня",
        "Червня",
        "Липня",
        "Серпня",
        "Вересня",
        "Жовтня",
        "Листопада",
        "Грудня"
    ]

    return(
        <div className={classes.day}>
            {`${DaysOfWeeKLabel[dayOfWeek - 1]}, ${day} ${monthes[month - 1]}`}
        </div>
    )
}

export default DayWeek;