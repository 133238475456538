import React, {useEffect, useState} from 'react'
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";
import TableItemColorLabel from "./TableItem/Items/TableItemColorLabel/TableItemColorLabel";
import TableItemCheckbox from "./TableItem/Items/TableItemCheckbox/TableItemCheckbox";
import TableHeaderCheckbox from "./TableHeader/Items/TableHeaderCheckbox";
import {useDispatch, useSelector} from "react-redux";
import {checkAllCertificates, checkCertificate} from "../../../redux/reducers/сertificatesReducer";
import TableItemColorText from "./TableItem/Items/TableItemColorText/TableItemColorText";

const TableCertificates = ({visible}) => {
    const certificates = useSelector(state => state.сertificates.сertificates);
    const excursions = useSelector(state => state.excursions.excursions);
    const filters = useSelector( state => state.сertificates.filters)
    const dispatch = useDispatch();
    const [checked, changeChecked] = useState(false);
    const [items, changeItems] = useState(certificates.filter(item => item.deleted === false))

    let header = [
        {
            width: 2,
            component: () => <TableHeaderText itemName="Код сертифікату"/>,
        },
        {
            width: 4,
            component: () => <TableHeaderText itemName="Екскурсія"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Початок дії"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Термін дії"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="%"/>,
        },
        {
            width: 3,
            component: () => <TableHeaderText itemName="Статус"/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName=" "/>,
        },
        {
            width: 1,
            component: () => <TableHeaderCheckbox value={checked} callback={allCheck}/>,
        },
    ];
    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    let allCheck = (value) => {
        dispatch(checkAllCertificates(value))
        changeChecked(value)
    }
    let itemCheck = (id, value) => {
        dispatch(checkCertificate(id, value))
    }

    useEffect(() => {
        if(visible) {
            changeItems(certificates.filter(item => item.deleted === false))
        } else {
            changeItems(certificates.filter(item => item.deleted === true))
        }
    }, [certificates, visible])

    if(items.length !== 0){
        return (
            <Table header={header} items={items} columns={columns}>
                {
                    items.map(item => {
                        let currentExcursion = excursions.find(excursion => excursion.id == item.excursion.id)
                        let styleItem = false

                        if(currentExcursion && currentExcursion.status.key == "noactive"){
                            styleItem = {background: "#fbfafa"}
                        }
                        if(item.deleted === true){
                            styleItem = {background: "#f1f1f1"}
                        }
                        if(item){
                            return (
                                <TableItem key={item.id} columns={columns} style={styleItem}>
                                    <TableItemText text={item.code}/>
                                    <TableItemColorLabel
                                        text={item.excursion.name.find(item => item.default_language).text}
                                        description={`(${item.excursion.typeExcursion.name})` + (styleItem ? " - Деактивовано" : "")}
                                        color={item.excursion.color}
                                    />
                                    <TableItemText text={item.time.start.date}/>
                                    <TableItemText
                                        text={item.time.end.date}
                                        description={item.time.end.unix * 1000 < Date.now() ? "не дійсний" : ""}
                                    />
                                    <TableItemText text={item.percent}/>
                                    <TableItemColorText
                                        text={item.status.name}
                                        status_key={item.status.key}
                                    />
                                    <TableItemEdit
                                        type={item.type}
                                        id={item.id}/>
                                    <TableItemCheckbox
                                        type={item.type}
                                        id={item.id}
                                        callback={itemCheck}
                                        value={item.checked}
                                    />
                                </TableItem>
                            )
                        }

                    })
                }
            </Table>
        )
    } else {
        return (
            <h3 style={{textAlign: "center", margin: "50px 0px"}}>
                Сертифікатів не знайдено
            </h3>
        )
    }

}

export default TableCertificates