import React, {useState} from 'react'
import Page from "../../UI/Page/Page";
import TablePromoCodes from "../../UI/Table/TablePromoCodes";
import {useNavigate} from "react-router-dom";
import {useRole} from '../../../hooks/useRole';
import Button from "../../UI/Button";

const PromoCodes = () => {
    const navigate = useNavigate()
    const role = useRole();

    const [visible, changeVisible] = useState(true)

    const changeVisibleCallback = (e) => {
        e.preventDefault()
        changeVisible(visible ? false : true)
    }

    return (
        <Page
            pageName={"Промокоди"}
            rightRest={
                <Button icon={"switchIcon"} callBack={changeVisibleCallback} color={"#d7d7d7"}>
                    {visible === true &&
                        <>Архів</>
                    }
                    {visible === false &&
                        <>Опубліковані</>
                    }
                </Button>
            }
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/promocodes/create')
                }, 
                icon: 'add', 
                color: '',
                disabled: role > 1,
            }}
        >
            <TablePromoCodes visible={visible}/>
        </Page>
    )
}

export default PromoCodes