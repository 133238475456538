import React, {useEffect, useState} from 'react';
import Page from "../../UI/Page/Page";
import classes from "./KeysPage.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {
    changeStateKeysSaveButton,
    getPrivateKey,
    getPublicKey,
    setPrivate, setPrivateKey,
    setPublic, setPublicKey
} from "../../../redux/reducers/keysReducer";
import PageLoader from "../../UI/PageLoader/PageLoader";

const KeysPage = () => {
    const dispatch = useDispatch()

    const privateKey = useSelector(state => state.keys.privateKey)
    const publicKey = useSelector(state => state.keys.publicKey)
    const stateSaveButton = useSelector(state => state.keys.stateKeysSaveButton)

    const [readOnlyPublic, changeReadOnlyPublic] = useState(true)
    const [readOnlyPrivate, changeReadOnlyPrivate] = useState(true)

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(setPrivateKey(privateKey))
        dispatch(setPublicKey(publicKey))
        changeReadOnlyPrivate(true)
        changeReadOnlyPublic(true)
    }
    const onChangePublic = (e) => {
        let value = e.currentTarget.value
        dispatch(changeStateKeysSaveButton(false))
        dispatch(setPublic(value))
    }
    const onChangePrivate = (e) => {
        let value = e.currentTarget.value
        dispatch(changeStateKeysSaveButton(false))
        dispatch(setPrivate(value))
    }

    useEffect(() => {
        if (publicKey == null) {
            dispatch(getPublicKey())
        }
        if (privateKey == null) {
            dispatch(getPrivateKey())
        }
    }, [])

    if(privateKey == null || publicKey == null) {
        return(
            <PageLoader/>
        )
    } else {
        return (
            <Page
                pageName={"Ключі"}
                rightButton={{
                    content: 'Зберегти',
                    callback: () => {
                    },
                    icon: 'add',
                    color: '',
                    form: 'keys_form',
                    disabled: stateSaveButton,
                }}
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>
                    <form id="keys_form" onSubmit={onSubmit}>

                        <div>

                            <div className={classes.row}>
                                <div>
                                    <label htmlFor="private_key">
                                        private_key
                                    </label>

                                    <input
                                        id={"private_key"}
                                        value={privateKey}
                                        type={"text"}
                                        readOnly={readOnlyPrivate}
                                        onChange={onChangePrivate}
                                    />
                                </div>

                                <div className={classes.row}>
                                    <input
                                        id={"edit_private_key"}
                                        type={"checkbox"}
                                        checked={!readOnlyPrivate}
                                        onChange={(e) => {
                                            changeReadOnlyPrivate(prevState => {
                                                return prevState ? false : true
                                            })
                                        }}
                                    />

                                    <label htmlFor="edit_private_key">
                                        Редагувати
                                    </label>
                                </div>
                            </div>

                            <div className={classes.row}>
                                <div>
                                    <label htmlFor="public_key">
                                        public_key
                                    </label>

                                    <input
                                        id={"public_key"}
                                        type={"text"}
                                        value={publicKey}
                                        readOnly={readOnlyPublic}
                                        onChange={onChangePublic}
                                    />
                                </div>

                                <div className={classes.row}>

                                    <input
                                        id={"edit_public_key"}
                                        type={"checkbox"}
                                        checked={!readOnlyPublic}
                                        onChange={(e) => {
                                            changeReadOnlyPublic(prevState => {
                                                return prevState ? false : true
                                            })
                                        }}
                                    />

                                    <label htmlFor="edit_public_key">
                                        Редагувати
                                    </label>

                                </div>
                            </div>

                        </div>

                        <div></div>

                        <div></div>

                    </form>
                </div>
            </Page>
        );
    }

};

export default KeysPage;