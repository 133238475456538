import axios from "axios";

export default class WorkTableApi {

    static async getWorkTable(data) {
        const response = await axios.post('https://api.0300.com.ua/api/guideschedule/getall', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async get(data) {
        const response = await axios.post('https://api.0300.com.ua/api/guideschedule/get', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async edit(data) {
        const response = await axios.post('https://api.0300.com.ua/api/guideschedule/edit', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

}