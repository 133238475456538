import React, { useEffect, useState } from 'react'
import cl from './CustomSelect.module.scss'
import arrow from '../../../assets/images/arrow.svg'
import { ReactSVG } from 'react-svg'

const CustomSelect = ({ classError, defaultValue, items, onChange, placeholder, contentAbsolute, multi, readonly}) => {

    const [newItems, setNewItems] = useState(items);

    useEffect(() => {

        if(defaultValue && items.find(item => item.key == defaultValue)) {
            setNewItems(newItems.map(item => ({...item, selected: item.key == defaultValue ? true : false})));
        }
        else setNewItems(items);

    }, [defaultValue, items]);
    const [isOpen, setIsOpen] = useState(false)

    const classes = [cl.customSelect]
    const contentClasses = [cl.customSelectContent]
    if(isOpen && !readonly) classes.push(cl.active)
    if(readonly) classes.push(cl.readonly);
    if(items.length > 3) contentClasses.push(cl.scroll)

    const toggle = () => {
        if(readonly) return;
        setIsOpen(!isOpen)
    }

    const itemClickHandle = (e, selectedItem) => {
        if(readonly) return;
        if(selectedItem?.disabled) return;
        if(!multi) setIsOpen(false)
        e.selectedItem = selectedItem;
        onChange(e)
    }

    if(contentAbsolute) contentClasses.push(cl.contentAbsolute)

    return (
        <div className={classes.join(' ')}>
            <div
                className={cl.customSelectHeader}
                onClick={toggle}
                style={classError ? {borderColor: "#ff5c5c"} : {}}
            >
                <div className={cl.customSelectHeaderInner}>
                    {newItems.find(item => item.selected) && newItems.find(item => item.selected).img &&
                        <img className={cl.item_img} src={newItems.find(item => item.selected).img} />
                    }
                    {multi &&
                        <>
                            {newItems.filter(item => item.selected).map(item => item.value).join(', ') || (multi && (placeholder ? placeholder : '...'))}
                        </>
                    }
                    {(!multi && newItems.find(item => item.selected)?.value) || (!multi && (placeholder ? placeholder : '...'))}
                    <ReactSVG className={cl.arrow} src={arrow} />
                </div>
            </div>
            <div className={contentClasses.join(' ')}>
                {newItems.map(item =>
                    <div
                        key={item.key}
                        className={cl.customSelectItem}
                        style={{
                            backgroundColor: item?.disabled ? '#dedede' : '',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                        data-disabled={item.disabled}
                        onClick={e => itemClickHandle(e, item)}
                    >
                        {item.img &&
                            <img className={cl.item_img} src={item.img} />
                        }
                        {item.value}
                        {multi &&
                            <>
                                {/* <input 
                                    type="checkbox"
                                    checked={item.selected}
                                    onChange={e => e}
                                    readOnly={true}
                                /> */}
                                <div className={[cl.item_checkbox, item.selected ? cl.selected : ''].join(' ')}></div>
                            </> 
                        }
                    </div>
                )}
            </div>
        </div>
    )
}

export default CustomSelect