import React from 'react'
import cl from './AuthBlock.module.scss';
import logo from '../../../assets/images/logo.png';

const AuthBlock = ({children, style, contentStyle}) => {
    return (
        <div className={cl.authPage}>
            <div className={cl.authBlock}>
                <div className={cl.authBlockHeader}>
                    <div className={cl.authBlockHeaderBG}>
                        <img src={logo} alt="" />
                    </div>
                </div>
                <div className={cl.authBlockContent} style={contentStyle}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default AuthBlock