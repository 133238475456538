import {useSelector} from "react-redux";
import {Controller} from "react-hook-form";
import LanguageField from "../LanguageField/languageField";
import React from "react";
import Error from "../../Error/Error";

const LanguageAreas = ({keyIndex, label, control, fields, changeButton}) => {
    const languages = useSelector(state => state.languages.languages);

    return (
        <div>
            {
                label
                    ?
                    <h3 style={{marginTop: '0px'}}> {label} </h3>
                    :
                    ""
            }
            {
                languages.map(language => {
                    if(language.deleted === false){
                        let objLang = fields.find(field => field.id == language.id);
                        let valueLang = (objLang) ? objLang.text : "";
                        let name = keyIndex + ".lang_" + language.id;

                        return (

                            <Controller
                                key={name}
                                control={control}
                                name={name}
                                defaultValue={valueLang}
                                rules={{ required: "Обов'язкове поле",}}
                                render={({
                                             field: {onChange, value, name, ref},
                                             fieldState: {invalid, isTouched, isDirty, error},
                                             formState,
                                         }) => (
                                    <div>
                                        <LanguageField
                                            addClass = {error ? "error" : ""}
                                            onChange = {
                                                (e) => {
                                                    onChange(e);
                                                    if(changeButton){
                                                        changeButton()
                                                    }
                                                }
                                            }
                                            value={value ? value : ""}
                                            logo={language.logo}
                                            type={"area"}
                                        />
                                        {error &&
                                            <Error> {error?.message} </Error>
                                        }
                                    </div>

                                )}
                            />
                        )
                    }

                })
            }
        </div>

    )
}

export default LanguageAreas;