import React from 'react'
import AuthBlock from '../AuthBlock/AuthBlock'
import AuthForm from './AuthForm'

const Auth = () => {

    return (
        <AuthBlock>
            <AuthForm />
        </AuthBlock>
    )
}

export default Auth