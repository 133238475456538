import GuidesApi from "../../api/GuidesApi";

const CHANGE_CURRENT_GUIDE = "CHANGE_CURRENT_GUIDE";
const CLEAR_CURRENT_GUIDE = "CLEAR_CURRENT_GUIDE";
const SET_GUIDES = "SET_GUIDES";
const ADD_GUIDE = "ADD_GUIDE";
const UPDATE_GUIDES = "UPDATE_GUIDES";
const CHANGE_STATE_GUIDE_SAVE_BUTTON = "CHANGE_STATE_GUIDE_SAVE_BUTTON";

const defaultState = {
    guides: [
        // {
        //     type: "guide",
        //     id: 1,
        //     name: "Петро",
        //     surname: "Петренко",
        //     patronymic: "Петрович",
        //     phone: "+380998887776",
        //     email: "pppp@gmail.com",
        //     photo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Portrait_of_Pope_Francis_%282021%29.jpg/274px-Portrait_of_Pope_Francis_%282021%29.jpg",
        //     basePrice: 250,
        //     downTimePrice: 90,
        //     surchargePrice: 50,
        //     excursions: [],
        // },
    ],
    currentGuide: false,
    newGuide: {
        type: "guide",
        id: "",
        name: null,
        surname: null,
        patronymic: null,
        phone: null,
        email: null,
        photo: null,
        basePrice: null,
        downTimePrice: null,
        surchargePrice: null,
        excursions: [],
    },
    disabledButton: true,
}

export const guidesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_CURRENT_GUIDE:
            return {...state, currentGuide: action.guide}
        case SET_GUIDES:
            return {
                ...state,
                guides: action.guides,
            }
        case ADD_GUIDE:
            return {
                ...state,
                guides: [
                    ...state.guides,
                    action.guide
                ]
            }
        case CLEAR_CURRENT_GUIDE:
            return {...state, currentGuide: false}
        case UPDATE_GUIDES:
            return {
                ...state,
                guides: state.guides.map(guide => {
                    if (guide.id == action.guide.id) {
                        return action.guide;
                    }
                    return guide;
                })
            }
        case CHANGE_STATE_GUIDE_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }

        default:
            return state;
    }
}

export const setGuides = (guides) => ({type: SET_GUIDES, guides});
export const changeCurrentGuide = (guide) => ({type: CHANGE_CURRENT_GUIDE, guide});
export const clearCurrentGuide = () => ({type: CLEAR_CURRENT_GUIDE});
export const addGuide = (guide) => ({type: ADD_GUIDE, guide});
export const updateGuides = (guide) => ({type: UPDATE_GUIDES, guide});
export const changeStateGuideSaveButton = (value) => ({type: CHANGE_STATE_GUIDE_SAVE_BUTTON, value})

export const getGuides = (data) => async (dispatch) => {
    await GuidesApi.getAll(data).then(response => {
        dispatch(setGuides(response.data))
    });
}
export const createGuide = (data, navigate) => async (dispatch) => {
    dispatch(changeStateGuideSaveButton(true))
    await GuidesApi.put(data).then(response => {
        dispatch(addGuide(response.data));
        dispatch(clearCurrentGuide());
        navigate(`/guides/${response.data.id}`)

    });
}
export const editGuide = (data) => async (dispatch) => {
    dispatch(changeStateGuideSaveButton(true))
    await GuidesApi.patch(data).then(response => {
        dispatch(changeCurrentGuide(response.data));
        dispatch(updateGuides(response.data));
    });
}
export const toArchiveGuide = (data) => async (dispatch) => {
    dispatch(changeStateGuideSaveButton(true))
    await GuidesApi.deleteGuide(data).then(response => {
        dispatch(changeCurrentGuide(response.data));
        dispatch(updateGuides(response.data));

    });
}