import TranslateApi from "../../api/TranslateApi";
import {changeStateOptionSaveButton} from "./additionalOptionsReducer";

const SET_FIELDS = "SET_FIELDS";
const CHANGE_CURRENT_FIELD = "CHANGE_CURRENT_FIELD";
const CHANGE_STATE_TRANSLATE_SAVE_BUTTON = "CHANGE_STATE_TRANSLATE_SAVE_BUTTON";

const defaultState = {
    fields: [],
    currentField: null,
    newField: {
        type: "field",
        id: "",
        identifier: "",
        text: []
    },
    disabledButton: true,
}

export const translateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_FIELDS:
            return {...state, fields: action.fields}
        case CHANGE_CURRENT_FIELD:
            return {...state, currentField: action.field}
        case CHANGE_STATE_TRANSLATE_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        default:
            return state;
    }
}


export const setFields = (fields) => ({type: SET_FIELDS, fields});
export const changeCurrentField = (field) => ({type: CHANGE_CURRENT_FIELD, field});
export const changeStateTranslateSaveButton = (value) => ({type: CHANGE_STATE_TRANSLATE_SAVE_BUTTON, value})


export const getFields = () => async (dispatch) => {
    await TranslateApi.getAll().then(response => {
        dispatch(setFields(response.data))
    })
}
export const createField = (data, navigate) => async (dispatch) => {
    dispatch(changeStateTranslateSaveButton(true))
    await TranslateApi.create(data).then(response => {
        dispatch(changeCurrentField(response.data))
        dispatch(changeStateTranslateSaveButton(true))
        navigate(`/translate_fields/${response.data.id}`)
    })
}
export const deleteField = (data, navigate) => async (dispatch) => {
    await TranslateApi.delete(data).then(response => {
        navigate(`/translate_fields/`)
    })
}
export const editField = (data) => async (dispatch) => {
    dispatch(changeStateTranslateSaveButton(true))
    await TranslateApi.edit(data).then(response => {
        dispatch(changeCurrentField(response.data))
    })
}
