import React, {useEffect} from 'react';
import classes from "./EditBooking.module.scss";
import {useDispatch, useSelector} from "react-redux";
import Status from "../../../UI/Status/Status";
import Button from "../../../UI/Button";
import SelectItem from "../../../UI/SelectItem/SelectItem";
import Modal from "../../../UI/Modal/Modal";
import {useState} from "react";
import {checkGuides, setEditObject, updateGuidesForBooking} from "../../../../redux/reducers/calendarReducer";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import {NavLink, useNavigate} from "react-router-dom";
import BookingInfo from "./BookingInfo/BookingInfo";
import bookings from "../../Bookings/Bookings";
import {useRole} from "../../../../hooks/useRole";

const EditBooking = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const edit = useSelector(state => state.calendar.editBooking)
    const availableGuides = useSelector(state => state.calendar.availableGuides)
    const paramsRequest = useSelector(state => state.calendar.params)
    const branch = useSelector(state => state.account.branch)
    const guides = useSelector(state => state.guides.guides)
    const role = useRole()
    const [guidesModal, changeGuidesModal] = useState({
        active: false,
        guides: []
    });
    let classSidebar = [classes.sidebar]
    if (edit) classSidebar.push(classes.show)

    const [slide, changeSlide] = useState(1)
    const prevSlide = () => {
        if (slide - 1 == 0) {
            return false
        } else {
            changeSlide(slide - 1)
        }
    }
    const nextSlide = () => {
        if (slide + 1 > edit.bookings.length) {
            return false
        } else {
            changeSlide(slide + 1)
        }
    }
    const onClickCloseEdit = (e) => {
        e.preventDefault();
        dispatch(setEditObject(false));
    }
    const checkedGuide = (guide) => {
        let newGuides = [...guidesModal.guides].map(item => {
            if (guide.id == item.id) {
                return {
                    ...guide,
                    checked: guide.checked ? false : true
                }
            } else {
                return item
            }
        })
        changeGuidesModal({
            active: true,
            guides: newGuides,
        })
    }
    const addGuides = (e) => {
        e.preventDefault();
        const objTime = new Date(paramsRequest.time)
        const objRequestTable = {
            time: {
                day: objTime.getDate(),
                month: objTime.getMonth() + 1,
                year: objTime.getFullYear(),
            },
            excursion: paramsRequest.excursion,
            guide: paramsRequest.guide,
            branch_id: branch
        }
        let objRequest = {
            booking_id: [],
            guides: guidesModal.guides.filter(guide => guide.checked).map(guide => guide.id),
            paramsRequest: objRequestTable,
        }
        if (edit.type == "concat_booking") {
            objRequest.booking_id = edit.bookings.map(booking => booking.id)

        }
        if (edit.type == "booking") {
            objRequest.booking_id.push(edit.id)
        }

        dispatch(updateGuidesForBooking(objRequest))

        changeGuidesModal({
            ...guidesModal,
            active: false,
        });
    }

    useEffect(() => {
        if (edit) {
            let timeSatrt = edit.time.start.date.split(".")
            let objRequest = {
                day: Number(timeSatrt[0]),
                month: Number(timeSatrt[1]),
                year: Number(timeSatrt[2]),
                branch_id: branch,
            }

            dispatch(checkGuides(objRequest))
        }

    }, [edit])
    useEffect(() => {
        if (guides && availableGuides && edit) {

            let newGuidesModal = []

            guides.forEach(guide => {

                if (edit.guides.find(item => item.id == guide.id)) {
                    newGuidesModal.push({
                        ...guide,
                        checked: true,
                        available: true,
                    })
                } else if (availableGuides.guides.find(item => item.id == guide.id)) {
                    newGuidesModal.push({
                        ...guide,
                        checked: false,
                        available: true,
                    })
                } else {
                    newGuidesModal.push({
                        ...guide,
                        checked: false,
                        available: false,
                    })
                }
            })
            changeGuidesModal({
                active: false,
                guides: newGuidesModal
            })
        }
    }, [guides, availableGuides, edit])

    return (
        <>
            <div className={classSidebar.join(' ')}>
                {edit &&
                    <div className={classes.scroll}>

                        <header className={classes.header} style={{background: edit.excursion.color}}>

                        <span className={classes.name_excursion}>
                            {edit.excursion.name.find(lang => lang.default_language).text}
                        </span>

                            <button className={classes.close} onClick={onClickCloseEdit}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289Z"
                                          fill="#FFFBEE"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
                                          fill="#FFFBEE"/>
                                </svg>
                            </button>

                        </header>

                        <form className={classes.form}>

                            <div className={classes.row}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19 4H18V2H16V4H8V2H6V4H5C4.73786 4.00013 4.47833 4.05202 4.2363 4.1527C3.99427 4.25338 3.7745 4.40086 3.58961 4.58668C3.40471 4.77251 3.25833 4.99301 3.15887 5.23554C3.05941 5.47808 3.00882 5.73787 3.01 6L3 20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5299 21.9984 20.0377 21.7872 20.4125 21.4125C20.7872 21.0377 20.9984 20.5299 21 20V6C20.9984 5.47005 20.7872 4.96227 20.4125 4.58753C20.0377 4.2128 19.5299 4.00158 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM9 14H7V12H9V14ZM13 14H11V12H13V14ZM17 14H15V12H17V14ZM9 18H7V16H9V18ZM13 18H11V16H13V18ZM17 18H15V16H17V18Z"
                                        fill="#B5B5B5"/>
                                </svg>
                                <span>{edit.time.start.date}</span>
                            </div>

                            <div className={classes.row}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                          fill="#B5B5B5"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M12 6.20001C12.5523 6.20001 13 6.64773 13 7.20001V11.382L15.6472 12.7056C16.1412 12.9526 16.3414 13.5532 16.0944 14.0472C15.8474 14.5412 15.2468 14.7414 14.7528 14.4944L11.5528 12.8944C11.214 12.725 11 12.3788 11 12V7.20001C11 6.64773 11.4477 6.20001 12 6.20001Z"
                                          fill="#B5B5B5"/>
                                </svg>
                                <span>{edit.time.start.time} - {edit.time.end.time}</span>
                            </div>

                            <div>

                                <h3>Гіди</h3>

                                {edit.guides.map((guide, index) => {
                                    return (
                                        <SelectItem
                                            key={`guide_${guide.id}`}
                                            logo={guide.photo}
                                            style={guide.deleted === true ? {background: "#ffdcdc"} : {}}
                                            onClick={() => {
                                            }}
                                            readonly={true}
                                        >
                                            {guide.name}
                                        </SelectItem>
                                    )
                                })}

                                {role <= 1 &&
                                    <Button
                                        icon={"add"}
                                        style={{padding: "10px 15px"}}
                                        callBack={(e) => {
                                            e.preventDefault()
                                            changeGuidesModal({
                                                ...guidesModal,
                                                active: true,
                                            });
                                        }}
                                    >
                                        Змінити гідів
                                    </Button>
                                }

                            </div>

                            <div>

                                {edit.type == "booking" &&
                                    <BookingInfo edit={edit}/>
                                }
                                {edit.type == "concat_booking" &&
                                    edit.bookings.map((booking, index) => {
                                        return (
                                            <>
                                                <BookingInfo
                                                    key={`concat_booking_${booking.id}_${Date.now()}`}
                                                    index={index + 1}
                                                    edit={booking}
                                                    slider={true}
                                                    active={(slide == index + 1) ? true : false}
                                                />
                                            </>

                                        )
                                    })

                                }

                            </div>


                        </form>

                        {edit.type == "concat_booking" &&
                            <footer className={classes.bottom}>

                                <button onClick={prevSlide}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                                              fill="#676767"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z"
                                              fill="#676767"/>
                                    </svg>

                                </button>

                                <div className={classes.counter}>
                                    {slide} / {edit.bookings.length}
                                </div>

                                <button onClick={nextSlide}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                                              fill="#676767"/>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z"
                                              fill="#676767"/>
                                    </svg>
                                </button>

                            </footer>
                        }

                    </div>
                }
                {edit != true &&
                    <PageLoader/>
                }
            </div>

            <Modal
                active={guidesModal.active}
                title={'Додати гіда'}
                style={{
                    width: "calc(100vw - 256px)",
                    left: "unset",
                }}
            >

                <div style={{maxHeight: "400px", overflow: "scroll"}}>
                    {guidesModal.guides && guidesModal.guides.lenght != 0 &&
                        guidesModal.guides.map(guide => {

                            let backgroundGuide = "unset"
                            if(guide.available == false){
                                backgroundGuide = "#eee"
                            }
                            if(guide.deleted === true){
                                backgroundGuide = "#ffdcdc"
                            }

                            if(guide.deleted === false || (guide.deleted === true && guide.checked == true) ){
                                return (
                                    <div
                                        key={`excursion__item__${guide.id}`}
                                        className={'row'}
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "auto 1fr auto",
                                            gap: "10px",
                                            alignItems: "center",
                                            padding: "9px 16px",
                                            border: "1px solid #ECECEC",
                                            borderRadius: "6px",
                                            background: backgroundGuide
                                        }}>

                                        <img
                                            src={guide.photo}
                                            style={{
                                                width: "25px",
                                                height: "25px",
                                                minWidth: "25px",
                                                minHeight: "25px",
                                                maxWidth: "25px",
                                                maxHeight: "25px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                                overflow: "hidden"
                                            }}
                                        />

                                        <label
                                            htmlFor={`excursion_${guide.id}`}
                                            style={{fontWeight: 400}}
                                        >
                                            {guide.name}

                                        </label>

                                        {guide.available == false &&
                                            <span> (Вихідний)</span>
                                        }

                                        {guide.available &&
                                            <input
                                                id={`excursion_${guide.id}`}
                                                type={"checkbox"}
                                                onChange={() => {
                                                    checkedGuide(guide)
                                                }}
                                                checked={guide.checked}
                                            />
                                        }

                                    </div>
                                )
                            }




                        })
                    }

                </div>

                <div>
                    <NavLink
                        to={'/workschedules'}
                        style={{
                            marginLeft: "auto",
                            display: "table",
                            color: "#002c9c",
                            fontWeight: "500",
                            lineHeight: "18px",
                        }}
                    >
                        Робочий графік
                    </NavLink>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px"
                }}>
                    <Button
                        color={"#ECECEC"}
                        callBack={(e) => {
                            e.preventDefault();
                            changeGuidesModal({
                                ...guidesModal,
                                active: false,
                            });
                        }}
                    >
                        Скасувати
                    </Button>
                    <Button
                        callBack={addGuides}
                    >
                        Зберегти
                    </Button>
                </div>

            </Modal>
        </>
    );
};

export default EditBooking;