import React from 'react'

const Row = ({className, title, style, children}) => {
  return (
    <div className={className} style={style}>
        <h3 style={{marginTop: 0, marginBottom: 3}}>{title}</h3>
        {children}
    </div>
  )
}

export default Row