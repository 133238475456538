import React from 'react';
import classes from "./ButtonHeaderDay.module.scss";
import {useState} from "react";

const ButtonHeaderDay = ({callback, description, children}) => {
    const [isShown, setIsShown] = useState(false);
    const classesDescription = [classes.description]
    if(isShown) classesDescription.push(classes.description__active)

    return (
        <button
            className={classes.button}
            onClick={callback}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            <div className={classes.icon}>
                {children}
            </div>

            {description &&
                <div className={classesDescription.join(" ")}>{description}</div>
            }
        </button>
    );
};

export default ButtonHeaderDay;