import React from "react";
import Filters from "./Filters";
import DateFilter from "./Items/DateFilter/DateFilter";
import CustomSelect from "../CustomSelect/CustomSelect";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    applyFiltersCertificate,
    changeFiltersCertificate,
    getCertificates,
    setCertificates
} from "../../../redux/reducers/сertificatesReducer";

const FiltersCertificate = () => {
    const dispatch = useDispatch();
    const filters = useSelector( state => state.сertificates.filters)
    const branchId = useSelector(state => state.account.branch);

    //Даты
    const changeDateFrom = (date) => {
        dispatch(changeFiltersCertificate({
            ...filters,
            date: {
               ...filters.date,
                from: date / 1000
            }
        }))
    }
    const changeDateTo = (date) => {
        dispatch(changeFiltersCertificate({
            ...filters,
            date: {
                ...filters.date,
                to: date / 1000
            }
        }))
    }

    //Экскурсии
    let excursions = useSelector(state => state.excursions.excursions);
    const [itemsExcursions, setItemsExcursions] = useState( excursions.map(excursion => {
        return {
            key: excursion.id,
            value: `${excursion.name.find(lang => lang.default_language).text} (${excursion.typeExcursion.name})`,
            selected: false,
        }
    }));
    useEffect(() => {
        let newStateExcursions = excursions.map(excursion => {
            return {
                key: excursion.id,
                value: `${excursion.name.find(lang => lang.default_language).text} (${excursion.typeExcursion.name})`,
                selected: false,
            }
        });
        newStateExcursions.unshift({
            key: "all",
            value: "Всі екскурсії",
            selected: false
        })
        setItemsExcursions(newStateExcursions);
    },[excursions])
    const itemsExcursionsOnChange = (e) => {
        let selectedItem = e.selectedItem;
        let newItemsExcursions = itemsExcursions.map(excursion => {
            if(excursion.key == selectedItem.key){
                excursion.selected = true;
                return excursion
            } else {
                excursion.selected = false;
                return excursion
            }
        })
        setItemsExcursions(newItemsExcursions);

        dispatch(changeFiltersCertificate({
            ...filters,
            excursion: selectedItem.key == "all" ? null : selectedItem.key
        }))
    }

    //Статусы
    const [itemsStatuses, changeStatuses] = useState([
        {
            key: "all",
            value: "Всі статуси",
            selected: false,
        },
        {
            key: "active",
            value: "Активні",
            selected: false,
        },
        {
            key: "used",
            value: "Використані",
            selected: false,
        },
        {
            key: "overdue",
            value: "Протерміновані",
            selected: false,
        },
    ])
    const itemsStatusesOnChange = (e) => {
        let selectedItem = e.selectedItem;
        let newItems = itemsStatuses.map(item => {
            if(item.key == selectedItem.key){
                item.selected = true;
                return item
            } else {
                item.selected = false;
                return item
            }
        })
        changeStatuses(newItems);

        dispatch(changeFiltersCertificate({
            ...filters,
            status: selectedItem.key == "all" ? null : selectedItem.key
        }))
    }

    //Действия
    const [itemsAction, changeAction] = useState([
        {
            key: "active",
            value: "Видалити",
            selected: false,
        },
    ])

    useEffect(() => {
        // dispatch(setCertificates([]))

        // setTimeout(() => {
            dispatch(getCertificates(branchId))
        // }, 300)

    }, [filters])

    return(
        <Filters>
            <DateFilter prefix={"від"} callback={changeDateFrom}/>
            <DateFilter prefix={"до"} callback={changeDateTo}/>
            <CustomSelect items={itemsExcursions} onChange={itemsExcursionsOnChange} placeholder={'Всі екскурсії'} contentAbsolute={true}/>
            <CustomSelect items={itemsStatuses} onChange={itemsStatusesOnChange} placeholder={'Всі статуси'} contentAbsolute={true}/>
            <CustomSelect items={itemsAction} placeholder={'Виконати дію'} contentAbsolute={true}/>
        </Filters>
    )
}

export default FiltersCertificate;