import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cl from './HeaderUserInfo.module.scss'
import AccountApi from '../../../api/AccountApi'
import ButtonHalfTransparent from '../../UI/ButtonHalfTransparent/ButtonHalfTransparent'
import exitLogo from './../../../assets/images/Path 28.svg';
import accountLogo from './../../../assets/images/Vector.svg';
import { ReactSVG } from 'react-svg'
import ButtonTransparent from '../../UI/ButtonTransparent/ButtonTransparent'

const HeaderUserInfo = () => {

    const email = useSelector(state => state.account.email)
    const dispatch = useDispatch()

    const logout = async () => {
        const response = await AccountApi.logout();
        if(response.data?.message == 'ok') {
            localStorage.removeItem('auth_token')
            localStorage.removeItem('email')
            localStorage.removeItem('role')
            localStorage.removeItem('branch')
            dispatch({type: 'SET_AUTH_FALSE'})
        }
    }

    const account = () => {
        console.log('account')
    }

    return (
        <div className={cl.headerUserInfo}>
            <ButtonTransparent onClick={account}>
                <ReactSVG src={accountLogo} />
                <span>{email}</span>
            </ButtonTransparent>
            <ButtonHalfTransparent onClick={logout}>
                <ReactSVG src={exitLogo} />
                <span>Вихід</span>
            </ButtonHalfTransparent>
        </div>
    )
}

export default HeaderUserInfo