import AdditionalOptions from "../components/Pages/AdditionalOptions/AdditionalOptions";
import Bookings from "../components/Pages/Bookings/Bookings";
import Branches from "../components/Pages/Branches/Branches";
import Calendar from "../components/Pages/Calendar/Calendar";
import Certificates from "../components/Pages/Certificates/Certificates";
import Discounts from "../components/Pages/Discounts/Discounts";
import Excursions from "../components/Pages/Excursions/Excursions";
import Guides from "../components/Pages/Guides/Guides";
import Languages from "../components/Pages/Languages/Languages";
import PromoCodes from "../components/Pages/PromoCodes/PromoCodes";
import Reports from "../components/Pages/Reports/Reports";
import Roles from "../components/Pages/Roles/Roles";
import WorkSchedules from "../components/Pages/WorkSchedules/WorkSchedules";
import {Navigate} from "react-router-dom";
import LanguagesCreate from "../components/Pages/Languages/LanguagesCreate/LanguagesCreate";
import BranchesCreate from "../components/Pages/Branches/BranchesCreate/BranchesCreate";
import AdditionalOptionsCreate
    from "../components/Pages/AdditionalOptions/AdditionalOptionsCreate/AdditionalOptionsCreate";
import DiscountsCreate from "../components/Pages/Discounts/DiscountsCreate/DiscountsCreate";
import PromoCodesCreate from "../components/Pages/PromoCodes/PromoCodesCreate/PromoCodesCreate";
import CertificatesCreate from "../components/Pages/Certificates/CertificatesCreate/CertificatesCreate";
import RolesCreate from "../components/Pages/Roles/RolesCreate/RolesCreate";
import RolesEdit from "../components/Pages/Roles/RolesEdit/RolesEdit";
import BookingsCreate from "../components/Pages/Bookings/BookingsCreate/BookingsCreate";
import BookingsEdit from "../components/Pages/Bookings/BookingsEdit/BookingsEdit";
import ExcursionsCreate from "../components/Pages/Excursions/ExcursionsCreate/ExcursionsCreate";
import ExcursionTimeTable from "../components/Pages/Excursions/ExcursionTimeTable/ExcursionTimeTable";
import GuidesCreate from "../components/Pages/Guides/GuidesCreate/GuidesCreate";
import Group from "../components/Pages/Group/Group";
import GroupCreate from "../components/Pages/Group/GroupCreate/GroupCreate";
import TranslateFields from "../components/Pages/TranslateFields/TranslateFields";
import TranslateFieldsCreate from "../components/Pages/TranslateFields/TranslateFieldsCreate/TranslateFieldsCreate";
import guidesIcon from "../assets/images/menu/guides.svg"
import bookingsIcon from "../assets/images/menu/bookings.svg"
import calendarIcon from "../assets/images/menu/calendar.svg"
import paramsIcon from "../assets/images/menu/params.svg"
import settingsIcon from "../assets/images/menu/settings.svg"
import KeysPage from "../components/Pages/Keys/KeysPage";

export const privateRoutes = [
    {nav: true, name: "Календар", icon: calendarIcon, path: '/calendar', component: () => <Calendar/>},

    {nav: true, name: "Бронювання", icon: bookingsIcon, path: '/bookings', component: () => <Bookings/>},
    {name: "", path: '/bookings/create', component: () => <BookingsCreate/>},
    {name: "", path: '/bookings/:id', component: () => <BookingsEdit/>},


    //Гіди
    {nav: true, name: "Гіди", group: {key: 1, name: "Гіди", icon: guidesIcon}, path: '/guides', component: () => <Guides/>},
    {name: "", path: '/guides/create', component: () => <GuidesCreate/>},
    {name: "", path: '/guides/:id', component: () => <GuidesCreate/>},

    {nav: true, name: "Робочі графіки", group: {key: 1, name: "Гіди", icon: guidesIcon}, path: '/workschedules', component: () => <WorkSchedules/>},

    {role: 1, nav: true, name: "Звіти", group: {key: 1, name: "Гіди", icon: guidesIcon}, path: '/reports', component: () => <Reports/>},
    //


    //Параметри екскурсій
    {nav: true, name: "Екскурсії", path: '/excursions', group: {key: 2, name: "Параметри екскурсій", icon: paramsIcon}, component: () => <Excursions/>},
    {name: "", path: '/excursions/create', component: () => <ExcursionsCreate/>},
    {name: "", path: '/excursions/:id', component: () => <ExcursionsCreate/>},
    {name: "", path: '/excursions/timetable/:id', component: () => <ExcursionTimeTable/>},

    {nav: true, name: "Групи", path: '/group', group: {key: 2, name: "Параметри екскурсій", icon: paramsIcon}, component: () => <Group/>},
    {name: "", path: '/group/create', component: () => <GroupCreate/>},
    {name: "", path: '/group/:id', component: () => <GroupCreate/>},

    {nav: true, name: "Мови проведення", path: '/languages', group: {key: 2, name: "Параметри екскурсій", icon: paramsIcon}, component: () => <Languages/>},
    {role: 1, name: "", path: '/languages/create', component: () => <LanguagesCreate/>},
    {role: 1, name: "", path: '/languages/:id', component: () => <LanguagesCreate/>},

    {nav: true, name: "Додаткові опції", path: '/additionaloptions', group: {key: 2, name: "Параметри екскурсій", icon: paramsIcon}, component: () => <AdditionalOptions/>},
    {role: 1, name: "", path: '/additionaloptions/create', component: () => <AdditionalOptionsCreate/>},
    {role: 1, name: "", path: '/additionaloptions/:id', component: () => <AdditionalOptionsCreate/>},

    {nav: true, name: "Сертифікати", path: '/certificates', group: {key: 2, name: "Параметри екскурсій", icon: paramsIcon}, component: () => <Certificates/>},
    {name: "", path: '/certificates/create', component: () => <CertificatesCreate/>},
    {name: "", path: '/certificates/:id', component: () => <CertificatesCreate/>},

    {nav: true, name: "Знижки", path: '/discounts', group: {key: 2, name: "Параметри екскурсій", icon: paramsIcon}, component: () => <Discounts/>},
    {role: 1, name: "", path: '/discounts/create', component: () => <DiscountsCreate/>},
    {role: 1, name: "", path: '/discounts/:id', component: () => <DiscountsCreate/>},

    {nav: true, name: "Промокоди", path: '/promocodes', group: {key: 2, name: "Параметри екскурсій", icon: paramsIcon}, component: () => <PromoCodes/>},
    {role: 1, name: "", path: '/promocodes/create', component: () => <PromoCodesCreate/>},
    {role: 1, name: "", path: '/promocodes/:id', component: () => <PromoCodesCreate/>},
    //


    //Загальні налаштування
    {role: 0, nav: true, name: "Ролі", path: '/roles', group: {key: 3, name: "Загальні налаштування", icon: settingsIcon}, component: () => <Roles/>},
    {role: 0, name: "", path: '/roles/create', component: () => <RolesCreate/>},
    {role: 0, name: "", path: '/roles/:id', component: () => <RolesEdit/>},

    {nav: true, name: "Філії", path: '/branches', group: {key: 3, name: "Загальні налаштування", icon: settingsIcon}, component: () => <Branches/>},
    {name: "", path: '/branches/create', component: () => <BranchesCreate/>},
    {name: "", path: '/branches/:id', component: () => <BranchesCreate/>},

    {nav: true, name: "Переклад полів", path: '/translate_fields', group: {key: 3, name: "Загальні налаштування", icon: settingsIcon}, component: () => <TranslateFields/>},
    {name: "", path: '/translate_fields/create', component: () => <TranslateFieldsCreate/>},
    {name: "", path: '/translate_fields/:id', component: () => <TranslateFieldsCreate/>},
    {role: 0, nav: true, name: "Ключі", path: '/keys', group: {key: 3, name: "Загальні налаштування", icon: settingsIcon}, component: () => <KeysPage/>},
    //



    {path: '/*', component: () => <Navigate to="/calendar" replace={true}/>},
]