import React from 'react'
import Page from '../../UI/Page/Page'
import TableRoles from '../../UI/Table/TableRoles'
import { useNavigate } from 'react-router-dom'

const Roles = () => {

    const navigate = useNavigate()

    return (
        <Page
            pageName={'Ролі'}
            rightButton={{content: 'Створити', callback: () => navigate('/roles/create'), icon: 'add', color: ''}}
        >
            <TableRoles />
        </Page>
    )
}

export default Roles