import axios from "axios";

export default class LanguagesApi {

    static async getAll() {

        const token = localStorage.getItem('auth_token')

        const response = await axios.post('https://api.0300.com.ua/api/languages/getall', {
            token: token
        })

        return response;
    }

    static async get(token, id) {
        if(!id) return false

        const response = await axios.post('https://api.0300.com.ua/api/languages/get', {
            token: token,
            id: id,
        })

        return response;
    }

    static async put(formData) {

        const response = await axios.post('https://api.0300.com.ua/api/languages/put', formData, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })

        return response;
    }

    static async patch(formData) {

        const response = await axios.post('https://api.0300.com.ua/api/languages/patch', formData, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })

        return response;
    }

    static async deleteLanguage(data) {
        const response = await axios.post('https://api.0300.com.ua/api/languages/delete', {
            token: localStorage.getItem('auth_token'),
            id: data
        }, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });

        return response
    }

}