import axios from "axios";

export default class AdditionalOptionsApi {

    static async getAll(token) {
        const response = await axios.post('https://api.0300.com.ua/api/options/getall', {
            token: token
        })

        return response;
    }

    static async put(data) {
        const response = await axios.post('https://api.0300.com.ua/api/options/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async patch(data) {
        const response = await axios.post('https://api.0300.com.ua/api/options/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async deleteOption(data) {
        const response = await axios.post('https://api.0300.com.ua/api/options/delete', {
            token: localStorage.getItem('auth_token'),
            id: data
        });

        return response
    }

}