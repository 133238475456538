import React from 'react';
import classes from "./Schedule.module.scss";

const Schedule = ({style, translateTime, widthTime}) => {
    let data = {
        time: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
        ]
    };

    return (
        <div className={classes.schedule} style={style}>

            <div className={classes.schedule_header} style={{width: widthTime}}>
                {
                    data.time.map(time => {
                        return(
                            <div key={time} className={classes.schedule_time}>
                                <span>
                                    {time}
                                </span>
                            </div>
                        )
                    })
                }
            </div>

            <div className={classes.schedule_content}>
                {
                    data.time.map(time => {
                        return(
                            <div key={time} className={classes.schedule_column}>

                            </div>
                        )
                    })
                }
            </div>

        </div>
    );
};

export default Schedule;