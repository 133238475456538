import React from "react";
import classes from "./Table.module.scss";

const Table = ({header, items, columns, children, style}) => {
    return (
        <div className={classes.table} style={style}>

            <header className={classes.header} style={columns}>
                { header.map(item => <item.component key={Math.random()}/> ) }
            </header>

            <div className={classes.content}>
                { children }
            </div>

        </div>
    )
}

export default Table;