import DiscountsApi from "../../api/DiscountsApi";

const CHANGE_CURRENT_DISCOUNT = "CHANGE_CURRENT_DISCOUNT";
const CLEAR_CURRENT_DISCOUNT = "CLEAR_CURRENT_DISCOUNT";
const ADD_DISCOUNT = "ADD_DISCOUNT";
const SET_DISCOUNTS = "SET_DISCOUNTS";
const UPDATE_DISCOUNTS = "UPDATE_DISCOUNTS";
const CHANGE_STATE_DISCOUNT_SAVE_BUTTON = "CHANGE_STATE_DISCOUNT_SAVE_BUTTON";

const defaultState = {
    discounts: [],
    currentDiscount: false,
    newDiscount: {
        type: "discounts",
        id: "",
        name: [],
        description: [],
        percent: null,
    },
    disabledButton: true,
}

export const discountsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_DISCOUNTS:
            return {
                ...state,
                discounts: action.discounts,
            }
        case CHANGE_CURRENT_DISCOUNT:
            return {...state, currentDiscount: action.discount}
        case CLEAR_CURRENT_DISCOUNT:
            return {...state, currentDiscount: false}
        case ADD_DISCOUNT:
            return {
                ...state,
                discounts: [
                    ...state.discounts,
                    action.discount
                ]
            }
        case UPDATE_DISCOUNTS:
            return {
                ...state,
                discounts: state.discounts.map(discount => {
                    if (discount.id == action.discount.id) {
                        return action.discount;
                    }
                    return discount;
                })
            }
        case CHANGE_STATE_DISCOUNT_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        default:
            return state;
    }
}

export const setDiscounts = (discounts) => ({type: SET_DISCOUNTS, discounts});
export const changeCurrentDiscount = (discount) => ({type: CHANGE_CURRENT_DISCOUNT, discount});
export const clearCurrentDiscount = () => ({type: CLEAR_CURRENT_DISCOUNT});
export const addDiscount = (discount) => ({type: ADD_DISCOUNT, discount});
export const updateDiscounts = (discount) => ({type: UPDATE_DISCOUNTS, discount});
export const changeStateDiscountSaveButton = (value) => ({type: CHANGE_STATE_DISCOUNT_SAVE_BUTTON, value})


export const createDiscount = (data, navigate) => async (dispatch) => {
    dispatch(changeStateDiscountSaveButton(true));
    await DiscountsApi.put(data).then(response => {
        dispatch(addDiscount(response.data));
        dispatch(clearCurrentDiscount());
        navigate(`/discounts/${response.data.id}`)
    });
}
export const editDiscount = (data) => async (dispatch) => {
    dispatch(changeStateDiscountSaveButton(true));
    await DiscountsApi.patch(data).then(response => {
        dispatch(changeCurrentDiscount(response.data));
        dispatch(updateDiscounts(response.data));
    });
}
export const getDiscounts = () => async (dispatch) => {
    await DiscountsApi.getAll(localStorage.getItem('auth_token')).then(response => {
        dispatch(setDiscounts(response.data))
    })
}
export const toArchiveDiscount = (data) => async (dispatch) => {
    dispatch(changeStateDiscountSaveButton(true));
    await DiscountsApi.deleteDiscount(data).then(response => {
        dispatch(changeCurrentDiscount(response.data));
        dispatch(updateDiscounts(response.data));
    })
}