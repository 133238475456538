import axios from "axios";

export default class TimeTableApi {

    static async post(data) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/schedule/get', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }
    static async edit(data) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/schedule/edit', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }
    static async setDayStatus(data) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/schedule/setdaystatus', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }
    static async resetShedule(excursion_id) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/schedule/resetschedule', {
            token: localStorage.getItem('auth_token'),
            excursion_id: excursion_id
        });

        return response
    }
    static async resetDay(data) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/schedule/resetday', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }
    static async setValidity(data) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/setvalidity', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

}