import Auth from "../components/Auth/Auth/Auth";
import { Navigate } from "react-router-dom";
import ResetPassword from "../components/Auth/ResetPassword/ResetPassword";
import WritePassword from "../components/Auth/WritePassword/WritePassword";

export const publicRoutes = [
    {path: '/auth', component: () => <Auth /> },
    {path: '/reset-password', component: () => <ResetPassword /> },
    {path: '/resetpassword/:token', component: () => <WritePassword /> },
    {path: '/*', component: () => <Navigate to="/auth" replace={true} />}
]
