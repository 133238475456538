import axios from "axios";

export default class DiscountsApi {

    static async getAll(token) {
        const response = await axios.post('https://api.0300.com.ua/api/discounts/getall', {
            token: token
        })

        return response;
    }

    // static async get(token, id) {
    //     if(!id) return null;
    //
    //     const response = await axios.post('https://api.0300.com.ua/api/branches/getall', {
    //         token: token,
    //         id: id
    //     })
    //
    //     return response;
    // }

    static async put(data) {
        const response = await axios.post('https://api.0300.com.ua/api/discounts/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async patch(data) {
        const response = await axios.post('https://api.0300.com.ua/api/discounts/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async deleteDiscount(data) {
        const response = await axios.post('https://api.0300.com.ua/api/discounts/delete', {
            token: localStorage.getItem('auth_token'),
            id: data
        });

        return response
    }


}