import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useRole} from "../../../hooks/useRole";
import Page from "../../UI/Page/Page";
import TableTranslate from "../../UI/Table/TableTranslate";
import {getFields} from "../../../redux/reducers/translateReducer";
import PageLoader from "../../UI/PageLoader/PageLoader";

const TranslateFields = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const role = useRole()

    return (
        <Page
            pageName={"Переклад полів"}
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/translate_fields/create')
                },
                icon: 'add',
                color: '',
                disabled: role > 1,
            }}
        >
            <TableTranslate/>
        </Page>
    )


};

export default TranslateFields;
