import React, {useState} from 'react';
import classes from "./DayWorkSchedules.module.scss";
import GuideWorkSchedules from "./GuideWorkSchedules/GuideWorkSchedules";
import Modal from "../../../../UI/Modal/Modal";

const DayWorkSchedules = ({slot, editSlot, blocked, showAddGuide, deleteCallback, showDeleteModal}) => {
    const dateObj = new Date( slot.date.year, slot.date.month - 1, slot.date.day )
    const options = {
        day: 'numeric',
        weekday: 'short',
    };
    const date = dateObj.toLocaleString("ua", options)
    const classesDay = [classes.day]
    const dateDelete = {
        day: slot.date.day,
        month: slot.date.month,
        year: slot.date.year,
    }
    if(blocked) {
        classesDay.push(classes.day_blocked)
    }
    const addGuide = (e) => {
        e.preventDefault()
        showAddGuide(slot)
    }
    const onDelete = (e) => {
        e.preventDefault()


        deleteCallback(dateDelete)
    }
    const showModal = (e) => {
        showDeleteModal(e, dateDelete)
    }
    return (
        <>
            <div className={classesDay.join(" ")}>

                <header className={classes.header}>
                    <span className={classes.date}>
                        {date}
                    </span>

                    <div className={classes.buttons}>
                        <button className={classes.add} onClick={addGuide}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="#FFD52F"/>
                            </svg>
                        </button>

                        <button className={classes.delete} onClick={showModal}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 19C6.00158 19.5299 6.2128 20.0377 6.58753 20.4125C6.96227 20.7872 7.47005 20.9984 8 21H16C16.5299 20.9984 17.0377 20.7872 17.4125 20.4125C17.7872 20.0377 17.9984 19.5299 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="#B5B5B5"/>
                            </svg>
                        </button>
                    </div>
                </header>

                <main className={classes.main}>
                    {slot.guides.map(guide => {
                        return(
                            <GuideWorkSchedules
                                key={`${Date.now()}_${Math.random()}_${guide.id}`}
                                guide={guide}
                                editSlot={() => {editSlot(slot, guide.id)}}
                            />
                        )
                    })}
                </main>

                {blocked &&
                    <div className={classes.blocked}></div>
                }

            </div>
        </>


    );
};

export default DayWorkSchedules;