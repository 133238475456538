import React from "react";
import classes from "./TableItemCalendar.module.scss";
import {Link} from "react-router-dom";

const TableItemCalendar = ({type, id}) => {
    let link = `timetable/${id}`;
    return(
        <div className={classes.cell}>
            <Link to={link}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 4H18V2H16V4H8V2H6V4H5C4.73786 4.00013 4.47833 4.05202 4.2363 4.1527C3.99427 4.25338 3.7745 4.40086 3.58961 4.58668C3.40471 4.77251 3.25833 4.99301 3.15887 5.23554C3.05941 5.47808 3.00882 5.73787 3.01 6L3 20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5299 21.9984 20.0377 21.7872 20.4125 21.4125C20.7872 21.0377 20.9984 20.5299 21 20V6C20.9984 5.47005 20.7872 4.96227 20.4125 4.58753C20.0377 4.2128 19.5299 4.00158 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM9 14H7V12H9V14ZM13 14H11V12H13V14ZM17 14H15V12H17V14ZM9 18H7V16H9V18ZM13 18H11V16H13V18ZM17 18H15V16H17V18Z" fill="#B5B5B5"/>
                </svg>
            </Link>
        </div>
    )
}

export default TableItemCalendar;