import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomSelect from '../../../UI/CustomSelect/CustomSelect';
import Error from '../../../UI/Error/Error';
import Row from './Row';
import Calendar from './Calendar';
import InputMask from 'react-input-mask';

const BookingsCreateFirstColumn = ({data, setData, error, setError}) => {

    const excursions = useSelector(state => state.excursions.excursions);
    const promocodes = useSelector(state => state.promocodes.promocodes);
    const discounts  = useSelector(state => state.discounts.discounts);

    const changeExcursion = (e) => {

        setError({
            ...error,
            excursion: '',
            emptyTickets: '',
        })

        const ex = excursions.find(item => item.id == e.selectedItem.key);

        setData({...data, 
            excursionsItems: data.excursionsItems.map(item => ({...item, selected: item.key == e.selectedItem.key ? true : false})),
            excursionId: e.selectedItem.key,
            excursionType: ex.typeExcursion.key,
            currentExcursion: ex,
            date: '',
            time: {
                start: [],
                end: [],
            },
            endTime: '',
            solo: {
                selectList: data.solo.selectList.map(item => ({...item, selected: false})),
                discounts: ex.discounts.filter(item => !item?.deleted).map(item => ({
                    key: item.id,
                    value: item.name.find(item => item?.default_language).text,
                })),
                promocodes: promocodes.filter(item => !item?.deleted).filter(item => item.excursion.find(_ex => _ex.id == e.selectedItem.key) !== undefined && item.numberOfApplications < item.applicationLimit)?.map(item => ({
                    key: item.id,
                    value: item.name.find(name => name?.default_language).text
                })),
                certificate: '',
            },
            languages: ex.languages.map(item => ({key: item.id, value: item.name, price: item.price, selected: item?.default_language == true})),
            guestsCount: 0,
            guestsCountMin: ex.typeExcursion.key == 'ticket' ? 0 : ex.ticketsMin,
            guestsCountMax: ex.typeExcursion.key == 'ticket' ? 0 : ex.ticketsMax,
            tickets: [],
            baseCost: ex.basePrice,
            baseTickets: ex.baseTickets,
            additionalOptions: excursions.find(ex => ex.id == e.selectedItem.key).additionalOptions.filter(opt => !opt?.deleted).map(opt => ({key: opt.id, value: opt.name.find(n => n.default_language).text, price: opt.price}))
        });
    }


    const changeDate = (e) => {
        setData({
            ...data,
            date: e.target.value
        })
    }

    const changeStartTime = (e) => {

        setError({
            ...error,
            startTime: '',
        })

        const index = data.time.start.findIndex(item => item.key == e.selectedItem.key)

        setData({
            ...data,
            time: {
                ...data.time,
                start: data.time.start.map(item => ({...item, selected: e.selectedItem.key == item.key ? true : false}))
            },
            startTime: e.selectedItem.key,
            endTime: data.time.end[index],
            guestsCountMin: data.time.tickets_min[index],
            guestsCountMax: data.time.tickets_max[index],
            baseCost: data.time.costs[index],
            baseTickets: data.time.tickets[index],
            tickets: [],
        })
    }

    const changeEndTime = (e) => {
        setData({
            ...data,
            endTime: e.target.value
        })
    }

    const changeName = (e) => {

        setError({
            ...error,
            name: '',
        });

        setData({
            ...data,
            name: e.target.value
        })
    }

    const changeSurname = (e) => {

        setError({
            ...error,
            surname: '',
        });

        setData({
            ...data,
            surname: e.target.value
        })
    }

    const changeEmail = (e) => {

        setError({
            ...error,
            email: '',
        });

        setData({
            ...data,
            email: e.target.value
        })
    }

    const changeGuestsCount = (e) => {

        setError({
            ...error,
            guestsCount: e.target.value < data.guestsCountMin ? `Мінімальная кількість гостей ${data.guestsCountMin}` :
                            e.target.value > data.guestsCountMax ? `Максимальна кількість гостей ${data.guestsCountMax}` : '',
        });

        setData({
            ...data,
            guestsCount: e.target.value,
        })
    }

    const changePhoneNumber = (e) => {
        
        setError({
            ...error,
            phoneNumber: '',
        });

        setData({
            ...data,
            phoneNumber: e.target.value
        })
    }

    const changeComment = (e) => {

        setData({
            ...data,
            comment: e.target.value,
        })
    }

    const changeAdditionalComemnt = (e) => {
        setData({
            ...data,
            additionalComment: e.target.value,
        })
    }

    const changeConfirmationMethod = (e) => {

        setError({
            ...error,
            confirmationMethod: '',
        })

        setData({
            ...data,
            confirmationMethod: data.confirmationMethod.map(item => ({...item, selected: item.key == e.selectedItem.key ? true : false})),
        })
    }

    const changeLanguage = (e) => {

        setError({
            ...error,
            languages: '',
        })

        setData({
            ...data,
            languages: data.languages.map(item => ({...item, selected: item.key == e.selectedItem.key ? true : false})),
        })
    }

    const [modal, setModal] = useState(false)

    const openCalendar = e => {
        if(!data.currentExcursion) return;
        setModal(true)
    }

    return (
        <div>

            <Calendar 
                modal={modal}
                setModal={setModal}
                data={data}
                setData={setData}
                error={error}
                setError={setError}
            />

            <div style={{marginBottom: '15px'}}>
                <Row
                    title="Екскурсія"
                >
                    <CustomSelect
                        classError={error.excursion ? "error" : ""}
                        onChange={changeExcursion}
                        placeholder={"Виберіть екскурсію"}
                        items={data.excursionsItems}
                    />
                </Row>
            </div>

            {data.excursionId &&
                <div
                    className="time"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridColumnGap: "10px"
                    }}
                >
                    <div style={{gridColumn: '1/-1'}}>
                        <input 
                            onChange={changeDate}
                            className={error.date ? 'error' : ''}
                            id="start"
                            name="start"
                            type="date"
                            readOnly={true}
                            onClick={openCalendar}
                            value={data.date}
                            style={{userSelect: 'none'}}
                        />
                        {error.date &&
                            <Error>{error?.date}</Error>
                        }
                    </div>

                    {data.date &&
                        <>
                            <div>
                                <CustomSelect 
                                    classError={error.startTime ? 'error' : ''}
                                    items={data.time ? data.time.start : []}
                                    onChange={changeStartTime}
                                />

                                {error.startTime &&
                                    <Error> {error?.startTime} </Error>
                                }
                            </div>

                            <div>
                                <input
                                    onChange={changeEndTime}
                                    id="end"
                                    name="end"
                                    type="time"
                                    value={data.endTime}
                                    readOnly={true}
                                />
                            </div>
                        </>
                    }

                </div>
            }

            <Row
                className="name"
                title="Ім’я"
            >
                <input
                    type="text"
                    className={error?.name ? "error" : ""}
                    value={data.name}
                    onChange={changeName}
                />

                {error?.name &&
                    <Error> {error?.name} </Error>
                }
            </Row>

            <Row
                className="surname"
                title="Прізвище"
            >
                <input
                    type="text"
                    className={error?.surname ? "error" : ""}
                    value={data.surname}
                    onChange={changeSurname}
                />

                {error?.surname &&
                    <Error> {error?.surname} </Error>
                }
            </Row>

            <Row
                className="email"
                title="E-mail"
            >
                <input
                    type="text"
                    className={error?.email ? "error" : ""}
                    value={data.email}
                    onChange={changeEmail}
                />

                {error?.email &&
                    <Error> {error?.email} </Error>
                }
            </Row>

            <Row
                className="phoneNumber"
                title="Телефон"
            >
                <InputMask 
                    type="tel" 
                    mask="+380999999999" 
                    maskChar={null}
                    className={error?.phoneNumber ? "error" : ""}
                    value={data.phoneNumber}
                    onChange={changePhoneNumber}
                />

                {error?.phoneNumber &&
                    <Error> {error?.phoneNumber} </Error>
                }
            </Row>

            <Row
                className="confirmationMethod"
                title="Спосіб підтвердження бронювання"
                style={{marginBottom: 15}}
            >
                <CustomSelect 
                    classError={error.confirmationMethod ? 'error' : ''}
                    style={{marginBottom: 15}}
                    onChange={changeConfirmationMethod}
                    placeholder="Виберіть спосіб підтвердження"
                    items={data.confirmationMethod}
                />

                {error.confirmationMethod &&
                    <Error>{error.confirmationMethod}</Error>
                }
            </Row>

            {(!data.excursionType || data.excursionType != 'ticket') &&
                <Row
                    className="guestsCount"
                    title="Кількість гостей"
                >
                    <input 
                        className={error?.guestsCount ? 'error' : ''}
                        type="number"
                        min={data.guestsCountMin}
                        max={data.guestsCountMax}
                        value={data.guestsCount}
                        onChange={changeGuestsCount}
                    />

                    {error?.guestsCount &&
                        <Error> {error?.guestsCount} </Error>
                    }
                </Row>
            }

            <Row
                title="Мова проведення"
                style={{marginBottom: 10}}
            >
                <CustomSelect 
                    classError={error.languages ? 'error' : ''}
                    items={data.languages}
                    onChange={changeLanguage}
                />
                {error?.languages &&
                    <Error>{error.languages}</Error>
                }
            </Row>

            <Row
                className="comment"
                title="Комментар"
            >
                <textarea 
                    value={data.comment}
                    onChange={changeComment}
                >
                </textarea>
            </Row>

            <Row
                className="additionalComment"
                title="Додатковий комментар"
            >
                <textarea 
                    value={data.additionalComment}
                    onChange={changeAdditionalComemnt}
                >
                </textarea>
            </Row>

        </div>
    )
}

export default BookingsCreateFirstColumn