import React, {useEffect, useRef, useState} from 'react';
import classes from "./EditSlot.module.scss";
import Button from "../../../Button";
import {useDispatch, useSelector} from "react-redux";
import {
    changeAccept,
    changeEditCountFrom, changeEditCountTo, changeEditDay,
    changeEditObject,
    changeEditPrice, changeEditStatus,
    changeEditTickets, changeEditTimeEnd, changeEditTimeStart, editSlot, setEditInDay
} from "../../../../../redux/reducers/timeTableExcursionReducer";
import PageLoader from "../../../PageLoader/PageLoader";
import {useParams} from "react-router-dom";
import ToggleCheckbox from "../../../ToggleCheckbox/ToggleCheckbox";
import ButtonHalfTransparent from "../../../ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../../assets/images/Path 114.svg";
import slots from "../Slots";
import Error from "../../../Error/Error";

const EditSlot = ({data}) => {
    const dispatch = useDispatch();
    const params = useParams()
    const excursions = useSelector(state => state.excursions.excursions)
    const edit = useSelector(state => state.timeTablesExcursions.editObject)
    const day = useSelector(state => state.timeTablesExcursions.editDay)
    const time = useSelector(state => state.timeTablesExcursions.data.time)
    const [durationExcursion, changeDurationExcursion] = useState(null)
    const [bufferDuration, changeBufferDuration] = useState(null)
    const [errorRequest, changeErrorRequest] = useState(null)
    const [formData, changeFormData] = useState(null)
    const [moveStatus, changeMoveStatus] = useState(false)
    const currentExcursion = excursions.find(excursion => excursion.id == params.id)
    let classSidebar = [classes.sidebar]
    if (edit) classSidebar.push(classes.show)
    if (moveStatus) classSidebar.push(classes.sidebar__left)

    let classMove = [classes.move]
    if (moveStatus) classMove.push(classes.move__left)

    const onClickCloseEdit = (e) => {
        e.preventDefault();
        dispatch(changeEditObject(null))
        dispatch(changeEditDay(null))
        changeFormData(null)
    }
    const onChangePrice = (e) => {
        let value = Number(e.target.value);

        dispatch(changeEditPrice(value,))
        dispatch(setEditInDay())
        changeFormData({
            ...formData,
            price: value,
        })
    }
    const onChangeTickets = (e) => {
        let value = Number(e.target.value);

        dispatch(changeEditTickets(value))
        dispatch(setEditInDay())
        changeFormData({
            ...formData,
            tickets: value,
        })
    }
    const onChangeCountFrom = (e) => {
        let value = Number(e.target.value);

        dispatch(changeEditCountFrom(value))
        dispatch(setEditInDay())
        changeFormData({
            ...formData,
            count: {
                ...formData.count,
                from: value
            },
        })
    }
    const onChangeCountTo = (e) => {
        let value = Number(e.target.value);

        dispatch(changeEditCountTo(value))
        dispatch(setEditInDay())
        changeFormData({
            ...formData,
            count: {
                ...formData.count,
                to: value
            },
        })
    }
    const onChangeTimeStart = (e) => {
        let value = e.target.value;
        let hours = Number(value.split(':')[0])
        let minutes = Number(value.split(':')[1])
        let objDate = new Date(Number(day.date.year), Number(day.date.month) - 1, Number(day.date.day), hours, minutes)
        let newDateEnd = new Date(objDate.valueOf() + (durationExcursion * 60 * 1000))

        let newEndValue = `${newDateEnd.getHours() < 10 ? "0" + newDateEnd.getHours() : newDateEnd.getHours()}:${newDateEnd.getMinutes() < 10 ? "0" + newDateEnd.getMinutes() : newDateEnd.getMinutes()}`

        dispatch(changeEditTimeStart(value))
        dispatch(changeEditTimeEnd(newEndValue))
        dispatch(setEditInDay())

        changeErrorRequest(null)
        changeFormData({
            ...formData,
            time: {
                ...formData.time,
                start: value,
                end: newEndValue
            },
        })
    }
    const onChangeTimeEnd = (e) => {
        let value = e.target.value;
        let hours = Number(value.split(':')[0])
        let minutes = Number(value.split(':')[1])
        let objDate = new Date(Number(day.date.year), Number(day.date.month) - 1, Number(day.date.day), hours, minutes)
        let newDateStart = new Date(objDate.valueOf() - (durationExcursion * 60 * 1000))

        let newStartValue = `${newDateStart.getHours() < 10 ? "0" + newDateStart.getHours() : newDateStart.getHours()}:${newDateStart.getMinutes() < 10 ? "0" + newDateStart.getMinutes() : newDateStart.getMinutes()}`

        dispatch(changeEditTimeStart(newStartValue))
        dispatch(changeEditTimeEnd(value))
        dispatch(setEditInDay())

        changeErrorRequest(null)
        changeFormData({
            ...formData,
            time: {
                ...formData.time,
                start: newStartValue,
                end: value
            },
        })
    }
    const onChangeStatusDay = (value) => {
        dispatch(changeEditStatus(value))
        dispatch(setEditInDay())
        changeFormData({
            ...formData,
            status: value
        })
    }
    const onChangeAccept = (e) => {
        let value = e.target.checked;
        dispatch(changeAccept(value))
        dispatch(setEditInDay())

        changeFormData({
            ...formData,
            acceptAllTabel: value
        })
    }
    const deleteSLot = (e) => {
        e.preventDefault()
        const newDay = {...day}

        if (newDay.slots.find(slot => slot.acceptAllTabel)) {
            newDay.acceptAllTabel = true
            newDay.slots = [...newDay.slots].map(item => {
                if(item.id == edit.id) {
                    return {
                        ...item,
                        deleted: true,
                    }
                } else {
                    return item
                }
            })
        } else {
            newDay.slots = [...newDay.slots].filter(item => item.id != edit.id)
        }

        console.log(newDay.slots)

        dispatch(editSlot(newDay, {
            id: params.id,
            time: time.unix
        }))
        dispatch(changeEditObject(null))
        dispatch(changeEditDay(null))

    }
    const onSubmit = (e) => {
        e.preventDefault();
        let values = formData;
        //dispatch(setEditInDay())

        let sortDay = day
        sortDay.slots.sort(function compare(a, b) {
            let startA = a.time.start.time.split(':')
            let startB = b.time.start.time.split(':')
            let timeA = Number(startA[0] + startA[1])
            let timeB = Number(startB[0] + startB[1])

            if (startA < startB) {
                return -1;
            }
            if (startA > startB) {
                return 1;
            }
            return 0;
        })
        sortDay.slots = sortDay.slots.map(slot => {
            if(slot.time.start.time == edit.time.start.time && slot.time.end.time == edit.time.end.time ){
                return {
                    ...slot,
                    changed: true,
                }
            } else {
                return slot
            }
        })
        if (sortDay.slots.find(slot => slot.acceptAllTabel)) {
            sortDay.acceptAllTabel = true
            // sortDay.slots = sortDay.slots.map(slot => {
            //     if(slot.time.start.time == edit.time.start.time && slot.time.end.time == edit.time.end.time ){
            //         return {
            //             ...slot,
            //             acceptAllTabel: true,
            //         }
            //     } else {
            //         return slot
            //     }
            // })
        }

        for (let i = 0; i < sortDay.slots.length; i++) {
            if (i + 1 == sortDay.slots.length) continue

            let curSlot = sortDay.slots[i],
                curTimeArr = curSlot.time.start.time.split(":"),
                curDateArr = curSlot.time.start.date.split("."),
                curDateObj = new Date(Number(curDateArr[2]), Number(curDateArr[1]) - 1, Number(curDateArr[0]), Number(curTimeArr[0]), Number(curTimeArr[1]))
            let nextSlot = sortDay.slots[i + 1],
                nextTimeArr = nextSlot.time.start.time.split(":"),
                nextDateArr = nextSlot.time.start.date.split("."),
                nextDateObj = new Date(Number(nextDateArr[2]), Number(nextDateArr[1]) - 1, Number(nextDateArr[0]), Number(nextTimeArr[0]), Number(nextTimeArr[1]))
            let durationSlot = (bufferDuration ? bufferDuration : durationExcursion) * 60 * 1000

            if (nextDateObj.valueOf() - curDateObj.valueOf() < durationSlot) {
                changeErrorRequest(`Слоти не повиннні перетинатися. Змініть час початку і кінця слоту. Між початком слотів повинно бути не менше ${bufferDuration ? bufferDuration : durationExcursion} хвилин.`)
                return false
            }
        }

        console.log(sortDay.slots)
        dispatch(editSlot(sortDay, {
            id: params.id,
            time: time.unix
        }))
        dispatch(changeEditObject(null))
        dispatch(changeEditDay(null))
        changeFormData(null)

    }
    const callbackChangeMove = (e) => {
        e.preventDefault()

        if (moveStatus === true) {
            changeMoveStatus(false)
        } else {
            changeMoveStatus(true)
        }
    }

    useEffect(() => {
        let excursion = excursions.find(item => item.id == params.id)

        if (excursion) {
            changeDurationExcursion(excursion.durationExcursion)
            let bufferItem = excursion.bufferExcursions.find(item => item.id == excursion.id)
            if (bufferItem) {
                changeBufferDuration(bufferItem.time)
            }
        } else {
            changeBufferDuration(null)
            changeDurationExcursion(null)
        }
    }, [excursions])
    useEffect(() => {
        if (edit) {
            if (edit.type == "ticket") {
                const data = {
                    type: "ticket",
                    date: edit.time.start.date,
                    time: {
                        start: edit.time.start.time,
                        end: edit.time.end.time
                    },
                    price: edit.price,
                    tickets: edit?.tickets,
                    acceptAllTabel: false
                }
                changeFormData(data)
            }
            if (edit.type == "group") {
                const data = {
                    type: "group",
                    date: edit.time.start.date,
                    time: {
                        start: edit.time.start.time,
                        end: edit.time.end.time
                    },
                    price: edit.price,
                    count: {
                        from: edit?.count?.from,
                        to: edit?.count?.to,
                    },
                    acceptAllTabel: false
                }
                changeFormData(data)
            }
        }
    }, [edit])

    return (
        <div className={classSidebar.join(' ')}>

            <header className={classes.header}>

                <button className={classMove.join(" ")} onClick={callbackChangeMove}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                              fill="#000"></path>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z"
                              fill="#000"></path>
                    </svg>
                </button>

                <h2 className={classes.title}>Редагувати слот</h2>

                <button className={classes.close} onClick={onClickCloseEdit}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289Z"
                              fill="black"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
                              fill="black"/>
                    </svg>
                </button>

            </header>

            {edit &&
                <form className={classes.content} onSubmit={onSubmit}>

                    {errorRequest && typeof errorRequest == "string" &&
                        <Error style={{textAlign: "center", maxWidth: "300px", margin: "0 auto"}}>
                            {errorRequest}
                        </Error>
                    }

                    <div className={"row"}>
                        <label style={{marginBottom: "5px", marginRight: "10px"}}>
                            Активність слота
                        </label>
                        <ToggleCheckbox
                            name={"status"}
                            checked={edit?.status}
                            callBack={onChangeStatusDay}
                        />
                    </div>

                    <div className={classes.date}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 4H18V2H16V4H8V2H6V4H5C4.73786 4.00013 4.47833 4.05202 4.2363 4.1527C3.99427 4.25338 3.7745 4.40086 3.58961 4.58668C3.40471 4.77251 3.25833 4.99301 3.15887 5.23554C3.05941 5.47808 3.00882 5.73787 3.01 6L3 20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5299 21.9984 20.0377 21.7872 20.4125 21.4125C20.7872 21.0377 20.9984 20.5299 21 20V6C20.9984 5.47005 20.7872 4.96227 20.4125 4.58753C20.0377 4.2128 19.5299 4.00158 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM9 14H7V12H9V14ZM13 14H11V12H13V14ZM17 14H15V12H17V14ZM9 18H7V16H9V18ZM13 18H11V16H13V18ZM17 18H15V16H17V18Z"
                                fill="#B5B5B5"/>
                        </svg>
                        <p>{edit.time.start.date}</p>
                    </div>

                    <div className={"row"} style={{gap: "10px"}}>
                        <input
                            type={"time"}
                            name={"timeStart"}
                            value={edit.time.start.time}
                            onChange={onChangeTimeStart}
                        />
                        <input
                            type={"time"}
                            name={"timeEnd"}
                            value={edit.time.end.time}
                            onChange={onChangeTimeEnd}
                        />
                    </div>

                    {edit.type == "ticket" &&
                        <>
                            <div>
                                <label style={{marginBottom: "5px"}}>
                                    Ціна за квиток
                                </label>
                                <input
                                    type={"number"}
                                    name={"price"}
                                    value={edit.price}
                                    min={0}
                                    onChange={onChangePrice}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>

                            <div>
                                <label style={{marginBottom: "5px"}}>
                                    Квитків доступно
                                </label>
                                <input
                                    type={"number"}
                                    name={"tickets"}
                                    value={edit.tickets}
                                    min={0}
                                    onChange={onChangeTickets}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        </>
                    }
                    {edit.type == "group" &&
                        <>
                            <div>
                                <label style={{marginBottom: "5px"}}>
                                    {currentExcursion.typeExcursion.key == "small_group" &&
                                        "Ціна за групу"
                                    }
                                    {currentExcursion.typeExcursion.key == "big_group" &&
                                        "Ціна за 1 особу"
                                    }
                                </label>
                                <input
                                    type={"number"}
                                    name={"price"}
                                    value={edit.price}
                                    min={0}
                                    onChange={onChangePrice}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>

                            <div>
                                <label style={{marginBottom: "5px"}}>
                                    Мінімальна кількість осіб
                                </label>
                                <input
                                    id={"count_from"}
                                    name={"count_from"}
                                    type={"number"}
                                    value={edit.count['from']}
                                    onChange={onChangeCountFrom}
                                    min={1}
                                    max={edit.count.to}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>

                            <div>
                                <label style={{marginBottom: "5px"}}>
                                    Максимальна кількість осіб
                                </label>
                                <input
                                    id={"count_to"}
                                    name={"count_to"}
                                    type={"number"}
                                    value={edit.count.to}
                                    onChange={onChangeCountTo}
                                    min={edit.count['from']}
                                    max={100}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        </>
                    }

                    <div className={"row"}>
                        <input
                            id={"accept_all_tabel"}
                            name={"accept_all_tabel"}
                            type={"checkbox"}
                            onChange={onChangeAccept}
                        />
                        <label htmlFor={"accept_all_tabel"}>
                            Застосувати на увесь графік
                        </label>
                    </div>

                    <Button icon={'save'}>
                        Зберегти
                    </Button>

                    <ButtonHalfTransparent style={{marginTop: "auto"}} onClick={deleteSLot}>
                        <ReactSVG src={deleteSVG}/>
                        Видалити слот
                    </ButtonHalfTransparent>

                </form>
            }
            {edit != true && typeof edit != "object" &&
                <PageLoader/>
            }

        </div>
    );
};

export default EditSlot;