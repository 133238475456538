import BranchesApi from '../../api/BranchesApi';

const SET_BRANCHES = 'SET_BRANCHES'
const SET_CURRENT_BRANCH = 'SET_CURRENT_BRANCH'
const CHANGE_CURRENT_BRANCH = "CHANGE_CURRENT_BRANCH"
const SET_ACTIVE_DAY = "SET_ACTIVE_DAY";
const CHANGE_STATUS_BRANCH = "CHANGE_STATUS_BRANCH";
const ADD_BRANCH = "ADD_BRANCH";
const UPDATE_BRANCHES = "UPDATE_BRANCHES";
const CLEAR_CURRENT_BRANCH = "CLEAR_CURRENT_BRANCH";
const CHANGE_STATE_BRANCH_SAVE_BUTTON = "CHANGE_STATE_BRANCH_SAVE_BUTTON";

const defaultState = {
    branches: [],
    currentBranch: false,
    newBranch: {
        type: "branch",
        id: "",
        name: [],
        excursion: [],
        guides: [],
        status: {
            key: "noactive",
            name: "Деактивовано",
        },
        buffer: null,
        timeTable: [
            {
                key: 1,
                name: {
                    small: "Пн",
                    large: "Понеділок",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 2,
                name: {
                    small: "Вт",
                    large: "Вівторок",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 3,
                name: {
                    small: "Ср",
                    large: "Середа",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 4,
                name: {
                    small: "Чт",
                    large: "Четвер",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 5,
                name: {
                    small: "Пт",
                    large: "П'ятниця",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 6,
                name: {
                    small: "Сб",
                    large: "Субота",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
            {
                key: 7,
                name: {
                    small: "Нд",
                    large: "Неділя",
                },
                status: false,
                time: {
                    start: {
                        unix: 0,
                        time: "00:00",
                    },
                    end: {
                        unix: 0,
                        time: "00:00",
                    }
                }
            },
        ]
    },
    disabledButton: true,
}

export const branchReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_BRANCHES:
            return {...state, branches: [...action.branches]}
        case SET_CURRENT_BRANCH:
            return {...state, ...action.payload}
        case ADD_BRANCH:
            return {
                ...state,
                branches: [
                    ...state.branches,
                    action.branch
                ]
            }
        case CLEAR_CURRENT_BRANCH:
            return {...state, currentBranch: false}
        case UPDATE_BRANCHES:
            return {
                ...state,
                branches: state.branches.map(branch => {
                    if (branch.id == action.branch.id) {
                        return action.branch;
                    }
                    return branch;
                })
            }
        case CHANGE_CURRENT_BRANCH:
            return {...state, currentBranch: action.branch}
        case SET_ACTIVE_DAY:
            let stateCopy = {...state};
            stateCopy.currentBranch.timeTable.forEach(item => {
                if (item.key == action.key) {
                    item.status = action.value;
                }
            })
            return stateCopy;
        case CHANGE_STATUS_BRANCH:
            return {
                ...state,
                currentBranch: {
                    ...state.currentBranch,
                    status: {
                        key: action.key,
                        name: (action.key == "active") ? "Активовано" : "Деактивовано",
                    }
                }
            }
        case CHANGE_STATE_BRANCH_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        default:
            return state;
    }
}


export const clearCurrentBranch = () => ({type: CLEAR_CURRENT_BRANCH})
export const updateBranches = (branch) => ({type: UPDATE_BRANCHES, branch})
export const setActiveDay = (key, value) => ({type: SET_ACTIVE_DAY, key, value});
export const changeCurrentBranch = (branch) => ({type: CHANGE_CURRENT_BRANCH, branch});
export const setBranches = (branches) => ({type: SET_BRANCHES, branches: branches});
export const changeStatusBranch = (key) => ({type: CHANGE_STATUS_BRANCH, key});
export const addBranch = (branch) => ({type: ADD_BRANCH, branch});
export const changeStateBranchSaveButton = (value) => ({type: CHANGE_STATE_BRANCH_SAVE_BUTTON, value})


export const getBranches = () => async (dispatch) => {
    await BranchesApi.getAll(localStorage.getItem('auth_token')).then(response => {
        dispatch(setBranches(response.data));
    });
}
export const editBranch = (data) => async (dispatch) => {
    dispatch(changeStateBranchSaveButton(true));
    await BranchesApi.patch(data).then(response => {
        dispatch(changeCurrentBranch(response.data));
        dispatch(updateBranches(response.data));
    });
}
export const createBranch = (data, navigate) => async (dispatch) => {
    dispatch(changeStateBranchSaveButton(true));
    await BranchesApi.put(data).then(response => {
        dispatch(addBranch(response.data));
        dispatch(clearCurrentBranch());
        navigate(`/branches/${response.data.id}`)
    });
}
export const editBranchStatus = (key, id) => async (dispatch) => {
    dispatch(changeStateBranchSaveButton(true));
    await BranchesApi.setActiveStatus(key, id).then(response => {
        dispatch(changeCurrentBranch(response.data));
        dispatch(updateBranches(response.data));
    });
}