import React from 'react';
import Page from "../../../UI/Page/Page";
import {useForm} from "react-hook-form";
import LanguageInputs from "../../../UI/LanguageFields/LanguageInputs/LanguageInputs";
import TimeTable from "../../../UI/TimeTable/TimeTable";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import {ReactSVG} from "react-svg";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {
    changeCurrentBranch, changeStatusBranch, createBranch,
    editBranch, editBranchStatus,
    setActiveDay,
    changeStateBranchSaveButton
} from "../../../../redux/reducers/branchReducer";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import Button from "../../../UI/Button";
import { useRole } from '../../../../hooks/useRole';

const BranchesCreate = () => {
    const { register, handleSubmit, control, setValue, getValues, errors } = useForm();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const branches = useSelector(state => state.branches.branches)
    const stateSaveButton = useSelector(state => state.branches.disabledButton);
    let data = useSelector(state => state.branches.currentBranch);
    let newBranch = useSelector(state => state.branches.newBranch);
    let pageName = (data && data.name && data.name.length !== 0) ? data.name.find(name => name.default_language === true).text : "";

    const role = useRole();

    let branchSubmit = (dataForm) => {
        if(data.id){
            dataForm.id = data.id;
            dispatch(editBranch(dataForm));
        } else {
            dispatch(createBranch(dataForm, navigate));
        }
    }
    let onChangeInput = (key, day) => dispatch(setActiveDay(key, day));

    useEffect(() => {
        if (params?.id) {
            let branchForUrl = branches.find(branch => branch.id == params.id);
            dispatch(changeCurrentBranch(branchForUrl));

        } else {
            dispatch(changeCurrentBranch(newBranch));
        }
        return () => {
            dispatch(changeCurrentBranch(false));
        }
    }, []);

    if (data) {
        return (
            <Page
                breadcrumbs={{link: '/branches', text: 'Філії', }}
                pageName={pageName}
                rightRest={
                    <Button callBack={() => {dispatch(editBranchStatus(data.status.key == "active" ? false : true, data.id))}} icon={data.status.key == "active" ? "eyeOn" : "eyeOff"} color={data.status.key == "active" ? "#67FF7F" : "#B5B5B5"}>
                        {data.status.name}
                    </Button>
                }
                rightButton={{
                    content: 'Зберегти ',
                    callback: () => {},
                    icon: 'save',
                    color: '',
                    form: 'branch_form',
                    disabled: stateSaveButton
                }}
            >

                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>

                    <form id={"branch_form"} onSubmit={handleSubmit(branchSubmit)}>

                        <div>

                            <LanguageInputs
                                keyIndex={"name"}
                                label={"Назва філії"}
                                fields={data.name}
                                control={control}
                                required={true}
                                changeButton={() => { dispatch(changeStateBranchSaveButton(false)) }}
                                readOnly={role > 0}
                            />

                            {
                                data.id == true &&
                                <ButtonHalfTransparent
                                    onClick={(e) => {e.preventDefault(); alert('Видалити філію') }}
                                >
                                    <ReactSVG src={deleteSVG}/>
                                    В архів
                                </ButtonHalfTransparent>
                            }


                        </div>

                        <div>

                            <h3 style={{marginTop: '0px'}}> Буферний час для бронювання екскурсій (хв) </h3>

                            <input
                                type={"number"}
                                min={0}
                                max={1440}
                                onWheel={(e) => e.target.blur()}
                                {...register('bufferTime', {
                                    onChange: () => { dispatch(changeStateBranchSaveButton(false)) },
                                    minLength: 0,
                                    maxLength: 1440,
                                    value: data.buffer,
                                    valueAsNumber: true
                                })}
                            />

                        </div>

                        <TimeTable
                            control={control}
                            getValues={getValues}
                            timeTable={data.timeTable}
                            setValue={setValue}
                            onChangeInput={onChangeInput}
                            changeButton={() => { dispatch(changeStateBranchSaveButton(false)) }}
                            readOnly={role > 0}
                        />

                    </form>

                </div>
            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
}

export default BranchesCreate;