import React, { useState } from 'react'
import CustomSelect from '../../../UI/CustomSelect/CustomSelect'
import Error from '../../../UI/Error/Error'
import Row from '../BookingsCreate/Row'
import Calendar from './Calendar'
import InputMask from 'react-input-mask';
import ButtonHalfTransparent from '../../../UI/ButtonHalfTransparent/ButtonHalfTransparent'
import { ReactSVG } from 'react-svg'
import deleteSVG from '../../../../assets/images/Path 114.svg'
import Modal from '../../../UI/Modal/Modal'

const BookingsEditFirstColumn = ({data, setData, error, setError, setDeleteModal, turnOnSaveButton}) => {

    const [modal, setModal] = useState(false);

    const changeStartTime = e => {


        setError({
            ...error,
            startTime: '',
        });

        const min = data.currentExcursion.typeExcursion.key == 'ticket' ? null : data.time.start_list.tickets_min[data.time.start_list.start.findIndex(item => item.key == e.selectedItem.key)];
        const max = data.currentExcursion.typeExcursion.key == 'ticket' ? null : data.time.start_list.tickets_max[data.time.start_list.start.findIndex(item => item.key == e.selectedItem.key)];

        setData({
            ...data,
            time: {
                ...data.time,
                start_list: {
                    ...data.time.start_list,
                    start: data.time.start_list.start.map(item => ({...item, selected: item.key == e.selectedItem.key}))
                }
            },
            endTime: data.time.start_list.end[data.time.start_list.start.findIndex(item => item.key == e.selectedItem.key)],
            guestsCountMin: min,
            guestsCountMax: max,
        })

        turnOnSaveButton();
    }


    const changeName = e => {
        setError({
            ...error,
            name: '',
        });

        setData({
            ...data,
            name: e.target.value
        });

        turnOnSaveButton();
    }

    const changeSurname = e => {
        setError({
            ...error,
            surname: '',
        });

        setData({
            ...data,
            surname: e.target.value
        });

        turnOnSaveButton();
    }

    const changeEmail = e => {
        setError({
            ...error,
            email: '',
        });

        setData({
            ...data,
            email: e.target.value
        });

        turnOnSaveButton();
    }

    const changePhoneNumber = e => {
        setError({
            ...error,
            phoneNumber: '',
        });

        setData({
            ...data,
            phoneNumber: e.target.value
        });

        turnOnSaveButton();
    }

    const changeConfirmationMethod = e => {

        setError({
            ...error,
            confirmationMethod: '',
        })

        setData({
            ...data,
            confirmationMethod: data.confirmationMethod.map(item => ({...item, selected: item.key == e.selectedItem.key})),
        })

        turnOnSaveButton();

    }

    const changeGuestsCount = e => {

        setError({
            ...error,
            guestsCount: '',
        })

        setData({
            ...data,
            guestsCount: e.target.value,
        })

        turnOnSaveButton();

    }

    const changeLanguage = e => {
        setError({
            ...error,
            languages: '',
        });

        setData({
            ...data,
            languages: data.languages.map(lang => ({...lang, selected: lang.key == e.selectedItem.key}))
        })

        turnOnSaveButton();
    }

    const changeComment = e => {
        setData({
            ...data,
            comment: e.target.value,
        })

        turnOnSaveButton();
    }
    
    const changeAdditionalComment = e => {
        setData({
            ...data,
            additionalComment: e.target.value,
        })

        turnOnSaveButton();

    }

    // const readOnlyFlag = new Date(`${data.date.year}-${data.date.month}-${data.date.day} ${data.startTime}`) < Date.now();
    const readOnlyFlag = false;


    return (
        <div>

            <Calendar 
                modal={modal}
                setModal={setModal}
                data={data}
                setData={setData}
            />

            <Row
                title="Екскурсія"
            >
                <input
                    type="text" 
                    style={{backgroundColor: '#dedede'}}
                    disabled={true}
                    value={`${data.currentExcursion.name.find(item => item?.default_language).text} (${data.currentExcursion.typeExcursion.name})`}
                />
            </Row>

            <div
                className="time"
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridColumnGap: "10px"
                }}
            >
                <div style={{gridColumn: '1/-1'}}>
                    <input
                        className={error.date ? 'error' : ''}
                        id="start"
                        name="start"
                        type="date"
                        readOnly={readOnlyFlag}
                        onClick={e => {
                            if(readOnlyFlag) return;
                            setModal(!modal)
                        }}
                        value={`${data.date.year}-${data.date.month < 10 ? `0${data.date.month}` : data.date.month}-${data.date.day < 10 ? `0${data.date.day}` : data.date.day}`}
                        style={{
                            userSelect: 'none',
                            backgroundColor: readOnlyFlag ? '#dedede' : ''
                        }}
                    />
                    {error.date &&
                        <Error>{error?.date}</Error>
                    }
                </div>

                <div>
                    {readOnlyFlag &&
                        <input 
                            type="text"
                            value={data.startTime}
                            readOnly={true}
                            style={{
                                backgroundColor: '#dedede',
                                padding: '12px 16px'
                            }}
                        />
                    }
                    {!readOnlyFlag &&
                        <>
                            <CustomSelect
                                classError={error.startTime ? 'error' : ''}
                                items={data.time.start_list.start}
                                onChange={changeStartTime}
                            />
                            {error.startTime &&
                                <Error>{error.startTime}</Error>
                            }
                        </>
                    }
                </div>

                <div>
                    <input
                        className={error.endTime ? "error" : ""}
                        id="end"
                        name="end"
                        type="time"
                        value={data.endTime}
                        readOnly={true}
                        style={{
                            backgroundColor: readOnlyFlag ? '#dedede' : '#fff'
                        }}
                    />

                    {error.endTime &&
                        <Error> {error?.endTime} </Error>
                    }
                </div>

            </div>

            <Row
                className="name"
                title="Ім’я"
            >
                <input
                    type="text"
                    className={error?.name ? "error" : ""}
                    value={data.name}
                    onChange={changeName}
                    readOnly={readOnlyFlag}
                    // readOnly={true}
                    // style={{backgroundColor: new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now() ? '#dedede' : ''}}
                />

                {error?.name &&
                    <Error> {error?.name} </Error>
                }
            </Row>

            <Row
                className="surname"
                title="Прізвище"
            >
                <input
                    type="text"
                    className={error?.surname ? "error" : ""}
                    value={data.surname}
                    onChange={changeSurname}
                    readOnly={readOnlyFlag}
                    // readOnly={new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now()}
                    // style={{backgroundColor: new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now() ? '#dedede' : ''}}
                />

                {error?.surname &&
                    <Error> {error?.surname} </Error>
                }
            </Row>

            <Row
                className="email"
                title="E-mail"
            >
                <input
                    type="text"
                    className={error?.email ? "error" : ""}
                    value={data.email}
                    onChange={changeEmail}
                    readOnly={readOnlyFlag}
                    // readOnly={new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now()}
                    // style={{backgroundColor: new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now() ? '#dedede' : ''}}
                />

                {error?.email &&
                    <Error> {error?.email} </Error>
                }
            </Row>

            <Row
                className="phoneNumber"
                title="Телефон"
            >
                <InputMask 
                    type="tel" 
                    mask="+380999999999" 
                    maskChar={null}
                    className={error?.phoneNumber ? "error" : ""}
                    value={data.phoneNumber}
                    onChange={changePhoneNumber}
                    // readOnly={new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now()}
                    readOnly={readOnlyFlag}
                    style={{backgroundColor: readOnlyFlag ? '#dedede' : ''}}
                />

                {error?.phoneNumber &&
                    <Error> {error?.phoneNumber} </Error>
                }
            </Row>

            <Row
                className="confirmationMethod"
                title="Спосіб підтвердження бронювання"
                style={{marginBottom: 15}}
            >
                <CustomSelect 
                    style={{marginBottom: 15}}
                    onChange={changeConfirmationMethod}
                    placeholder="Виберіть спосіб підтвердження"
                    items={data.confirmationMethod}
                    readonly={readOnlyFlag}
                    // readonly={new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now()}
                    // style={{backgroundColor: new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now() ? '#dedede' : ''}}
                />
            </Row>

            {(data.currentExcursion.typeExcursion.key != 'ticket') &&
                <Row
                    className="guestsCount"
                    title="Кількість гостей"
                >
                    <input 
                        className={error?.guestsCount ? 'error' : ''}
                        type="number"
                        min={data.guestsCountMin}
                        max={data.guestsCountMax}
                        value={data.guestsCount}
                        onChange={changeGuestsCount}
                        readOnly={readOnlyFlag}
                        // readOnly={new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now()}
                        style={{backgroundColor: readOnlyFlag ? '#dedede' : ''}}
                    />

                    {error?.guestsCount &&
                        <Error> {error?.guestsCount} </Error>
                    }
                </Row>
            }

            <Row
                title="Мова проведення"
                style={{marginBottom: 10}}
            >
                <CustomSelect 
                    className={error.languages ? 'error' : ''}
                    items={data.languages}
                    onChange={changeLanguage}
                    readonly={readOnlyFlag}
                    // readonly={new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) < Date.now()}
                />
                {error?.languages &&
                    <Error>{error.languages}</Error>
                }
            </Row>

            <Row
                className="comment"
                title="Комментар"
            >
                <textarea 
                    value={data.comment}
                    onChange={changeComment}
                >
                </textarea>
            </Row>

            <Row
                className="additionalComment"
                title="Внутрішній комментар"
            >
                <textarea 
                    value={data.additionalComment}
                    onChange={changeAdditionalComment}
                >
                </textarea>
            </Row>

            <div>
                <div style={{marginTop: 25}}>
                    <ButtonHalfTransparent 
                        onClick={e => setDeleteModal(true)} 
                    >
                        <ReactSVG src={deleteSVG} />
                        <span>Видалити бронювання</span>
                    </ButtonHalfTransparent>
                </div>
            </div>
        </div>
    )
}

export default BookingsEditFirstColumn