import React from 'react';
import classes from "./BookingCart.module.scss";
import BookingGuides from "./BookingGuides/BookingGuides";

const BookingCart = ({id, guides, color, excursion, typeExcursion, start, duration, onClick, payment}) => {
    let startArr = start.split(':');
    let startHour = Number(startArr[0]);
    let startMinutes = Number(startArr[1]);
    let startMinutesWidth = (startMinutes / (60/100)) * (180/100)
    let translate = 180 * startHour + startMinutesWidth

    let durationArr = duration.split(':');
    let durationHour = Number(durationArr[0]);
    let durationMinutes = Number(durationArr[1]);
    let durationMinutesWidth = (durationMinutes / (60/100)) * (180/100)
    let width = (180 * durationHour) + durationMinutesWidth + 1;

    let colorDollar = "#FFF";
    if(payment == "paid") colorDollar = "#23ff00"
    if(payment == "nopaid") colorDollar = "#ff0000"

    let style = {
        background: color,
        //transform: `translateX(${translate}px)`,
        left: translate,
        width: `${width}px`,
    }
console.log(payment)
    return (
        <div className={classes.cart} style={style} onClick={onClick}>
            <div>
                <span className={classes.title}>
                     {excursion}
                </span>
                    <span className={classes.type_excursion}>
                    ({typeExcursion})
                </span>

                {payment &&
                    <svg className={classes.dollar} xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 30 30" version="1.1">
                            <path style={{ stroke:"none", fillRule:"nonzero", fill:colorDollar, fillOpacity:1 }} d="M 14.617188 0 C 6.519531 0.207031 0 6.847656 0 15 C 0 23.28125 6.71875 30 15 30 C 23.28125 30 30 23.28125 30 15 C 30 6.71875 23.28125 0 15 0 C 14.871094 0 14.746094 -0.00390625 14.617188 0 Z M 14.605469 2.578125 C 14.738281 2.574219 14.867188 2.578125 15 2.578125 C 21.855469 2.578125 27.421875 8.144531 27.421875 15 C 27.421875 21.855469 21.855469 27.421875 15 27.421875 C 8.144531 27.421875 2.578125 21.855469 2.578125 15 C 2.578125 8.277344 7.933594 2.789062 14.605469 2.578125 Z M 14.605469 2.578125 "/>
                            <path style={{ stroke:"none", fillRule:"nonzero", fill:colorDollar, fillOpacity:1 }} d="M 12.019531 18.160156 C 12.988281 19.511719 15.933594 19.609375 16.949219 19.035156 C 17.320312 18.828125 17.410156 18.21875 17.410156 17.804688 C 17.410156 17.523438 17.335938 17.300781 17.1875 17.136719 C 17.03125 16.976562 16.632812 16.566406 16.167969 16.445312 C 13.816406 15.835938 12.1875 15.078125 11.550781 14.527344 C 10.753906 13.839844 10.625 13.390625 10.625 12.195312 C 10.625 10.996094 10.96875 9.996094 11.75 9.273438 C 12.527344 8.554688 13.65625 8.195312 15.140625 8.195312 C 16.554688 8.195312 18.546875 8.566406 19.515625 9.71875 L 17.898438 11.480469 C 16.984375 10.714844 16.308594 10.644531 15.1875 10.644531 C 14.484375 10.644531 14.117188 10.746094 13.820312 10.949219 C 13.523438 11.144531 13.375 11.398438 13.375 11.714844 C 13.375 11.996094 13.511719 12.230469 13.785156 12.417969 C 14.058594 12.613281 14.769531 12.902344 16.195312 13.289062 C 17.792969 13.726562 18.878906 14.257812 19.449219 14.878906 C 20.011719 15.503906 20.292969 16.332031 20.292969 17.367188 C 20.292969 18.613281 19.855469 19.640625 18.976562 20.449219 C 18.109375 21.257812 17.039062 21.886719 15.496094 21.886719 C 13.515625 21.886719 10.867188 21.1875 10.140625 19.894531 "/>
                            <path style={{ stroke:"none", fillRule:"nonzero", fill:colorDollar, fillOpacity:1 }} d="M 14.238281 6.035156 L 14.238281 9.242188 L 15.761719 9.242188 L 15.761719 6.035156 Z M 14.238281 21.078125 L 14.238281 23.964844 L 15.761719 23.964844 L 15.761719 21.078125 Z M 14.238281 21.078125 "/>
                    </svg>
                }
            </div>

            <BookingGuides guides={guides}/>
        </div>
    );
};

export default BookingCart;