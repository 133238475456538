import React from 'react'
import Page from "../../UI/Page/Page";
import TableBranch from "../../UI/Table/TableBranch";
import {useNavigate} from "react-router-dom";
import {changeCurrentBranch} from "../../../redux/reducers/branchReducer";
import {useDispatch} from "react-redux";
import { useRole } from '../../../hooks/useRole';

const Branches = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const role = useRole();

    return (
        <Page
            pageName={"Філії"}
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/branches/create')
                    dispatch(changeCurrentBranch(false));
                }, 
                icon: 'add', 
                color: '',
                disabled: role > 0
            }}
        >
            <TableBranch/>
        </Page>
    )
}

export default Branches;