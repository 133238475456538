import React, {useEffect, useState} from "react";
import classes from "./Slot.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {changeEditDay, changeEditObject} from "../../../../../redux/reducers/timeTableExcursionReducer";
import {useParams} from "react-router-dom";

const Slot = ({data, day, minStart}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const edit = useSelector(state => state.timeTablesExcursions.editObject)
    const [editActive, changeEditActive] = useState(false)
    let height = data.time.duration.unix / 3600 * 60;
    let timeStart = data.time.start.time.split(":")
    let hour = Number(timeStart[0])
    let minute = Number(timeStart[1])

    let top = (hour * 60) + minute - minStart

    const classSlot = [classes.slot]
    if(data.status == false) classSlot.push(classes.off)
    // if(data.custom == true) classSlot.push(classes.custom)
    if(edit?.time?.start?.unix == data.time.start.unix && edit?.time?.end?.unix == data.time.end.unix) classSlot.push(classes.edit_slot)

    const styleAvailabel = {
        height: `${height}px`,
        top: `${top}px`
    }
    if( data.bookings ) {
        styleAvailabel.background = "#e5fff0"
    }
    if( data.custom == true ){
        styleAvailabel.background = "#ffd587"
    }
    if( data.custom == true && data.bookings ){
        styleAvailabel.background = "linear-gradient(90deg, #e5fff0, #ffd587)"
    }


    const onClickEdit = (e) => {
        e.preventDefault();
        changeEditActive(true)
        if(edit){
            dispatch(changeEditObject(false));
            setTimeout(() => {
                dispatch(changeEditObject(data));
                dispatch(changeEditDay(day, params.id))
            }, 800)
        } else {
            dispatch(changeEditObject(data))
            dispatch(changeEditDay(day, params.id))
        }

    }

    return(
        <div className={classSlot.join(" ")} style={styleAvailabel}>
            <div className={classes.row}>
                <div className={classes.icon_time}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z" fill="#B5B5B5"/><path fillRule="evenodd" clipRule="evenodd" d="M9 3.2C9.55228 3.2 10 3.64772 10 4.2V8.38197L12.6472 9.70558C13.1412 9.95257 13.3414 10.5532 13.0944 11.0472C12.8474 11.5412 12.2468 11.7414 11.7528 11.4944L8.55279 9.89443C8.214 9.72504 8 9.37878 8 9V4.2C8 3.64772 8.44772 3.2 9 3.2Z" fill="#B5B5B5"/></svg>
                </div>
                <span className={classes.time}>
                    {data.time.duration.time}
                </span>
                <button className={classes.edit} onClick={onClickEdit}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19 3.17157C18.7599 3.17157 18.5222 3.21887 18.3003 3.31075C18.0785 3.40264 17.8769 3.53732 17.7071 3.70711L4.39491 17.0193L3.42524 20.5748L6.9807 19.6051L20.2929 6.29289C20.4627 6.12311 20.5974 5.92154 20.6893 5.69971C20.7812 5.47788 20.8284 5.24012 20.8284 5C20.8284 4.75989 20.7812 4.52212 20.6893 4.30029C20.5974 4.07846 20.4627 3.87689 20.2929 3.70711C20.1231 3.53732 19.9216 3.40264 19.6997 3.31075C19.4779 3.21887 19.2401 3.17157 19 3.17157ZM17.5349 1.463C17.9994 1.2706 18.4973 1.17157 19 1.17157C19.5028 1.17157 20.0006 1.2706 20.4651 1.463C20.9296 1.65539 21.3516 1.93739 21.7071 2.29289C22.0626 2.6484 22.3446 3.07044 22.537 3.53492C22.7294 3.99941 22.8284 4.49725 22.8284 5C22.8284 5.50275 22.7294 6.00059 22.537 6.46508C22.3446 6.92956 22.0626 7.35161 21.7071 7.70711L8.20713 21.2071C8.08407 21.3302 7.93104 21.419 7.76314 21.4648L2.26314 22.9648C1.91693 23.0592 1.54667 22.9609 1.29292 22.7071C1.03917 22.4534 0.940838 22.0831 1.03526 21.7369L2.53526 16.2369C2.58105 16.069 2.66986 15.9159 2.79292 15.7929L16.2929 2.29289C16.6484 1.93739 17.0705 1.65539 17.5349 1.463Z" fill="#B5B5B5"/></svg>
                </button>
            </div>

            {data.type === "ticket" &&
                <div className={classes.row + " " + classes.info}>
                    <span>
                        Квитків: {data.tickets}
                    </span>
                    <span>
                        {data.price} грн
                    </span>
                </div>
            }

            {data.type === "group" &&
                <div className={classes.row + " " + classes.info}>
                    <span>
                        Осіб: {data.count.from} - {data.count.to}
                    </span>
                    <span>
                        від {data.price} грн
                    </span>
                </div>
            }

        </div>
    )
}

export default Slot