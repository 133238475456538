import React from "react";
import classes from "./Error.module.scss";

const Error = ({children, style}) => {
    return(
        <p className={classes.error} style={style}>
            {children}
        </p>
    )
}

export default Error;