import React, {useEffect} from 'react'
import Page from "../../UI/Page/Page";
import PageLoader from "../../UI/PageLoader/PageLoader";
import HeaderWorkSchedules from "./HeaderWorkSchedules/HeaderWorkSchedules";
import TableWorkSchedules from "./TableWorkSchedules/TableWorkSchedules";
import {useDispatch, useSelector} from "react-redux";
import {
    changeDate,
    getNextWorkTable,
    getPrevWorkTable,
    getWorkTable
} from "../../../redux/reducers/workSchedulesReducer";
import {useState} from "react";

const WorkSchedules = () => {

    const data = true
    const dispatch = useDispatch()
    const dateWorkTable = useSelector(state => state.workTable.date)
    const branch_id = useSelector(state => state.account.branch)

    useEffect(() => {
        if(dateWorkTable.month && dateWorkTable.year){
            dispatch(getWorkTable({
                month: dateWorkTable.month,
                year: dateWorkTable.year,
                branch_id: branch_id
            }))

            if(dateWorkTable.month == 1) {
                dispatch(getPrevWorkTable({
                    month: 12,
                    year: dateWorkTable.year - 1,
                    branch_id: branch_id
                }))
            } else {
                dispatch(getPrevWorkTable({
                    month: dateWorkTable.month - 1,
                    year: dateWorkTable.year,
                    branch_id: branch_id,
                }))
            }

            if(dateWorkTable.month == 12) {
                dispatch(getNextWorkTable({
                    month: 1,
                    year: dateWorkTable.year + 1,
                    branch_id: branch_id
                }))
            } else {
                dispatch(getNextWorkTable({
                    month: dateWorkTable.month + 1,
                    year: dateWorkTable.year,
                    branch_id: branch_id
                }))
            }
        }
    }, [dateWorkTable])
    useEffect(() => {
        const currentDate = new Date()
        let newDate = {
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear(),
        }
        dispatch(changeDate(newDate))
    }, [])

    if (data) {
        return (
            <Page
                breadcrumbs={{link: '/guides/', text: 'Гіди'}}
                pageName={"Робочі графіки"}
            >

                <HeaderWorkSchedules/>

                <div style={{height: "100%", background: "#FFF", padding: "0px 10px"}}>

                    <TableWorkSchedules/>

                </div>

            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
}

export default WorkSchedules
