import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import AccountApi from '../../../api/AccountApi'
import { setAccountAuthTrue } from '../../../redux/reducers/accountReducer'
import ButtonHalfTransparent from '../../UI/ButtonHalfTransparent/ButtonHalfTransparent'
import resetPasswordLogo from '../../../assets/images/pass.svg'
import { ReactSVG } from 'react-svg'
import { useNavigate } from 'react-router-dom'

const AuthForm = () => {

    const [user, setUser] = useState({email: '', password: ''})
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const auth = async e => {
        e.preventDefault()

        const response = await AccountApi.auth(user);

        if(response.data?.auth_token) {

            dispatch(setAccountAuthTrue(response.data.token, response.data.email))

            localStorage.setItem('auth_token', response.data.auth_token);
            localStorage.setItem('email', response.data.email);
            return;
        }
        localStorage.removeItem('auth_token');
        localStorage.removeItem('email');

    }

    const remindPass = e => {
        e.preventDefault()
        navigate('/reset-password')
    }

    return (
        <form action="">
            <div className="" style={{alignItems: 'center'}}>
                <div className="" style={{width: '100%'}}>
                    <label htmlFor="authEmail">Логін</label>
                    <input 
                        value={user.email}
                        type="email" 
                        name="email" 
                        id='authEmail'
                        onChange={e => setUser({...user, email: e.target.value})}
                    />
                </div>
                <div className="" style={{width: '100%'}}>
                    <label htmlFor="authPassword">Пароль</label>
                    <input 
                        value={user.password}
                        type="password" 
                        name="password" 
                        id='authPassword'
                        onChange={e => setUser({...user, password: e.target.value})}
                    />
                </div>

                <button onClick={auth} style={{width: 'unset'}}>Увійти</button>

                <div className="" style={{margin: '0 auto'}}>
                    <ButtonHalfTransparent onClick={remindPass}>
                        <ReactSVG src={resetPasswordLogo} />
                        <span>Нагадати пароль</span>
                    </ButtonHalfTransparent>
                </div>
            </div>
        </form>
    )
}

export default AuthForm