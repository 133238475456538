import RolesApi from "../../api/RolesApi"

const defaultState = {
    roles: [],
    currentAccount: {
        role: '',
        branch: '',
        email: '',
        password: '',
        name: '',
        phone_number: '',
    },
}

const SET_ROLES = "SET_ROLES"
const SET_CURRENT_ROLES = "SET_CURRENT_ROLES"

export const rolesReducer = (state = defaultState, action) => {

    switch(action.type) {
        case SET_ROLES: 
            return {...state, roles: [...action.payload]}
        case SET_CURRENT_ROLES: 
            return {...state, currentAccount: {...action.payload}}
        default:
            return state
    }

}


const setRoles = (roles) => ({type: SET_ROLES, payload: [...roles]})

export const getRoles = () => async (dispatch) => {
    await RolesApi.getAll(localStorage.getItem('auth_token')).then(response => {
        dispatch(setRoles(response.data))
    })
}
