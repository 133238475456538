import axios from "axios";

export default class BranchesApi {

    static async getAll(token) {
        const response = await axios.post('https://api.0300.com.ua/api/branches/getall', {
            token: token
        })

        return response;
    }

    static async get(token, id) {
        if(!id) return null;
        
        const response = await axios.post('https://api.0300.com.ua/api/branches/getall', {
            token: token,
            id: id
        })

        return response;
    }

    static async put(data) {
        const response = await axios.post('https://api.0300.com.ua/api/branches/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async patch(data) {
        const response = await axios.post('https://api.0300.com.ua/api/branches/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async setActiveStatus(status, id) {
        const response = await axios.post('https://api.0300.com.ua/api/branches/setactivestatus', {
            token: localStorage.getItem('auth_token'),
            status,
            id
        });

        return response
    }

}