import React from "react";
import classes from "./TableItemColorText.module.scss";

const TableItemColorText = ({text, status_key, style}) => {
    const colors = {
        'expected': '#0B9F2B',
        'completed': '#0052B4',
        'cancelled': '#FF1C1C',
        "active": "#00cb20",
        "used": "#ff5c5c",
        "overdue": "#000",
    }
    let color = colors[status_key];

    return(
        <div className={classes.cell} style={style}>
            <div style={{color: color}}>
                {text}
            </div>
        </div>
    )
}

export default TableItemColorText;