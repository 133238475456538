import React, {useEffect, useState} from "react";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemLogo from "./TableItem/Items/TableItemLogo/TableItemLogo";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";
import {getLanguages} from "../../../redux/reducers/languagesReducer";
import {useDispatch, useSelector} from 'react-redux'
import { useRole } from "../../../hooks/useRole";

const TableLanguage = ({visible}) => {
    const role = useRole();
    const dispatch = useDispatch();
    const languages = useSelector(state => state.languages.languages);
    const [items, changeItems] = useState(languages.filter(item => item.deleted === false))

    let header = [
        {
            width: 1,
            component: () => <TableHeaderText itemName="Фото"/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="Мова"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Ціна (грн)"/>
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName=" "/>,
        },
    ];
    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    useEffect(() => {
        dispatch(getLanguages());

    }, []);
    useEffect(() => {
        if(visible) {
            changeItems(languages.filter(item => item.deleted === false))
        } else {
            changeItems(languages.filter(item => item.deleted === true))
        }
    }, [languages, visible])

    return (
        <Table header={header} items={items} columns={columns}>
            {
                items.map(item => {
                    let styleItem = false
                    if(item.deleted === true){
                        styleItem = {background: "#f1f1f1"}
                    }
                    return (
                        <TableItem key={item.name} columns={columns} style={styleItem}>
                            <TableItemLogo link={item.logo} size={"small"}/>
                            <TableItemText text={item.name}/>
                            <TableItemText text={item.price}/>
                            {role <= 1 &&
                                <TableItemEdit type={item.type} id={item.id}/>
                            }
                        </TableItem>
                    )
                })
            }
        </Table>
    )
}

export default TableLanguage;
