import React, {useState} from 'react'
import classes from './Navbar.module.scss';
import logo from './../../assets/images/logo.png';
import {privateRoutes} from "../../routes/privateRoutes";
import {Link, NavLink, Route} from "react-router-dom";
import NavbarAccordion from "./NavbarAccordion/NavbarAccordion";
import {useSelector} from 'react-redux';
import {useRole} from '../../hooks/useRole'

const Navbar = (props) => {

    const clearRoutes = privateRoutes.filter(route => route.nav)
    const role = useRole();
    const routes = []
    const [activeItem, changeActiveItem] = useState(null)
    const [activeNavbar, changeActiveNavbar] = useState(false)

    const callbackChangeActive = (key) => {
        changeActiveItem(key)
    }

    clearRoutes.forEach(route => {
        if (route.role == undefined || (role <= route.role)) {
            if (route.nav) {
                if (route.group) {
                    if (!routes.find(item => item.key == route.group.key)) {
                        routes.push({
                            key: route.group.key,
                            name: route.group.name,
                            icon: route.group.icon,
                            routes: [route]
                        })
                    } else {
                        routes.find(item => item.key == route.group.key).routes.push(route)
                    }
                } else {
                    routes.push(route)
                }
            }
        }
    })

    return (
        <aside
            className={classes.navbar}
            onMouseEnter={() => changeActiveNavbar(true)}
            onMouseLeave={() => changeActiveNavbar(false)}
            style={{
                maxWidth: activeNavbar ? "256px" : "85px",
                transition: "0.2s ease",
            }}
        >

            <div style={{
                height: "146px",
            }}>
                <img src={logo} className={classes.logo} width="114px"/>
            </div>

            <nav className={classes.nav}>
                {
                    routes.map(route => {
                        if (route.key) {
                            return (
                                <NavbarAccordion
                                    key={route.name}
                                    activeNavbar={activeNavbar}
                                    route={route}
                                    activeItem={activeItem}
                                    changeActiveItem={callbackChangeActive}
                                />
                            )
                        } else {
                            return (
                                <NavLink
                                    key={route.path}
                                    className={({isActive}) => isActive ? classes.link__active : classes.link}
                                    to={route.path}
                                    onClick={() => {
                                        changeActiveItem(null)
                                    }}
                                >
                                    {/*{activeNavbar === false &&*/}
                                        <img
                                            style={{
                                                maxWidth: "30px",
                                                maxHeight: "30px",
                                                display: "block",
                                                objectFit: "cover",
                                            }}
                                            src={route.icon}
                                        />
                                    {/*}*/}
                                    {activeNavbar === true &&
                                        <span>{route.name}</span>
                                    }

                                </NavLink>
                            )
                        }

                    })
                }
            </nav>
        </aside>
    )
}

export default Navbar