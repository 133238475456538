import React from "react";
import classes from "./TableHeaderText.module.scss";
import {useState} from "react";

const TableHeaderText = ({itemName, style, fullName}) => {
    const [isShown, setIsShown] = useState(false);
    const classesFullName = [classes.full_name]

    if(isShown) classesFullName.push(classes.full_name__active)

    return(
        <div
            className={classes.item}
            style={style}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            <span className={classes.text}>
                {itemName}
                {fullName &&
                    <div className={classesFullName.join(" ")}>
                        { fullName }
                    </div>
                }
            </span>


        </div>
    )
}

export default TableHeaderText;