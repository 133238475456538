import React, {useState} from 'react';
import classes from "./HeaderTimeTable.module.scss";
import {useDispatch} from "react-redux";
import {changeTime, getTimeTable, setValidity} from "../../../../../redux/reducers/timeTableExcursionReducer";
import {useParams} from "react-router-dom";
import Button from "../../../../UI/Button";
import TabsGroup from "../TabsGroup/TabsGroup";
import {useSelector} from "react-redux";

Date.prototype.getWeek = function (dowOffset) {
    dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
    let newYear = new Date(this.getFullYear(),0,1);
    let day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    let daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
    let weeknum;
    //if the year starts before the middle of a week
    if(day < 4) {
        weeknum = Math.floor((daynum+day-1)/7) + 1;
        if(weeknum > 52) {
            let nYear = new Date(this.getFullYear() + 1,0,1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
            weeknum = nday < 4 ? 1 : 53;
        }
    }
    else {
        weeknum = Math.floor((daynum+day-1)/7);
    }
    return weeknum;
};

const HeaderTimeTable = ({data}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const time = data.time
    const timeObj = new Date(time.unix)
    const excursions = useSelector(state => state.excursions.excursions)
    const excursion = excursions.find(excursion => excursion.id == params.id)
    const week = `${timeObj.getUTCFullYear()}-W${timeObj.getWeek() >= 10 ? timeObj.getWeek() : "0" + String(timeObj.getWeek()) }`
    let validityArr = data.validity.date.split(".");
    let DateValidity = new Date(validityArr[2], validityArr[1] - 1, validityArr[0] )
    let [validity, changeValidity] = useState(`${ validityArr[2] }-${ (validityArr[1] < 10) ? "0" + validityArr[1] : validityArr[1] }-${ (validityArr[0] < 10) ? "0" + validityArr[0] : validityArr[0] }`)
    let [saveValidity, changeSaveValidity] = useState(false)
    let maxWeek = `${ DateValidity.getFullYear() }-W${ DateValidity.getWeek() >= 10 ? DateValidity.getWeek() - 1 : "0" + String(DateValidity.getWeek() - 1) }`


    const onChangeWeek = (e) => {
        e.preventDefault()
        let value = e.target.value;
        let valueArr = value.split("-W")
        let year = Number(valueArr[0])
        let week = Number(valueArr[1])
        let newDate = new Date(`01.01.${year}`)
        let unixWeek = week * 604799 * 1000

        let newTime = newDate.valueOf() + unixWeek

        dispatch(changeTime(newTime))
        dispatch(getTimeTable({
            id: params.id,
            time: newTime
        }))
    }
    const nextWeek = (e) => {
        e.preventDefault()
        let unixWeek = 604799 * 1000
        let newTime = time.unix + unixWeek
        if(DateValidity.valueOf() > newTime){
            dispatch(changeTime(newTime))
            dispatch(getTimeTable({
                id: params.id,
                time: newTime
            }))
        }

    }
    const prevWeek = (e) => {
        e.preventDefault()
        let unixWeek = 604799 * 1000
        let newTime = time.unix - unixWeek

        dispatch(changeTime(newTime))
        dispatch(getTimeTable({
            id: params.id,
            time: newTime
        }))
    }
    const onChangeValidity = (e) => {
        let value = e.currentTarget.value
        changeValidity(value)
        changeSaveValidity(true)
    }
    const callbackSaveValidity = (e) => {
        e.preventDefault()
        const arrDateValidity = validity.split("-")
        const newDateValidity = new Date( Number(arrDateValidity[0]), Number(arrDateValidity[1]) - 1, Number(arrDateValidity[2]) )

        dispatch(setValidity({
            id: params.id,
            validity: newDateValidity.valueOf() / 1000,
        }))
        changeSaveValidity(false)

    }
    return (
        <header className={classes.header}>

            <div style={{display: "flex"}}>
                <button className={classes.button} onClick={prevWeek}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z" fill="#676767"/><path fillRule="evenodd" clipRule="evenodd" d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z" fill="#676767"/></svg>
                </button>

                <input type="week" className={classes.week} value={week} onChange={onChangeWeek} max={maxWeek}/>

                <button className={classes.button} onClick={nextWeek}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z" fill="#676767"/><path fillRule="evenodd" clipRule="evenodd" d="M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z" fill="#676767"/></svg>
                </button>
            </div>

            <TabsGroup excursionId={excursion.id} style={{margin: "10px auto"}} />

            <div className={classes.container_date}>
                <div>
                    {saveValidity &&
                        <Button
                            icon={"save"}
                            callBack={callbackSaveValidity}
                            style={{
                                margin: "0px",
                                padding: "10px",
                                minHeight: "unset",
                                fontSize: "14px",
                            }}
                        >
                            Зберегти
                        </Button>
                    }
                </div>
                <div>
                    <h3 className={classes.container_title}>Графік діє до</h3>
                    <input type={"date"} className={classes.date} value={validity} onChange={onChangeValidity}/>
                </div>

            </div>

        </header>
    );
};

export default HeaderTimeTable;