import React from "react";
import classes from "./SelectItem.module.scss";
import {ReactSVG} from "react-svg";
import basket from '../../../assets/images/basket.svg';
import checked from '../../../assets/images/checked.svg';

const SelectItem = ({ logo, readonly, children, onClick, style, ...props}) => {

    let styleObj = {
        ...style
    }

    return (
        <div className={classes.item} style={styleObj}>

            <span className={classes.text}>
                { logo &&
                    <img src={logo} className={classes.logo}/>
                }
                {children}
            </span>
            {
                readonly == true
                ?
                    <ReactSVG className={classes.readonly} src={checked} />
                :
                    <ReactSVG onClick={onClick} className={classes.img} src={basket} />
            }


        </div>
    )
}

export default SelectItem;