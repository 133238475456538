import React from "react";
import classes from "./TableItemLogo.module.scss";

const TableItemLogo = ({link, size, style, text}) => {
    let classLogo = (size == "small") ? classes.logo + " " + classes.small : classes.logo;
    return(
        <div className={classes.cell} style={style}>
            <img className={classLogo} src={link}/>

            {text &&
                <span className={classes.text}>{text}</span>
            }

        </div>
    )
}

export default TableItemLogo;