import React, {useEffect, useState} from 'react';
import Filters from "./Filters";
import DateFilter from "./Items/DateFilter/DateFilter";
import {useDispatch, useSelector} from "react-redux";
import CustomSelect from "../CustomSelect/CustomSelect";
import {saveAs} from 'file-saver';
import {
    changeFilters,
    cancelBookings,
    cancelBooking,
    deleteBooking,
    invoiceBooking
} from "../../../redux/reducers/bookingsReducer";

const FiltersBookings = () => {
    const dispatch = useDispatch();
    const bookings = useSelector(state => state.bookings.bookings)
    const branchID = useSelector(state => state.account.branch);
    const filters = useSelector(state => state.bookings.filters)

    //Даты
    const changeDateFrom = (date) => {
        if(date){
            let valueDate = new Date(date)
            let newDate = new Date(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate())
            let from = {
                day: newDate.getDate(),
                month: newDate.getMonth() + 1,
                year: newDate.getFullYear(),
                unix: newDate.valueOf() / 1000,
            }
            let newFilters = {
                ...filters,
                page: 1,
                time: {
                    ...filters.time,
                    from: from,
                },
            }
            dispatch(changeFilters(newFilters))
        } else {
            let newFilters = {
                ...filters,
                page: 1,
                time: {
                    ...filters.time,
                    from: null,
                },
            }
            dispatch(changeFilters(newFilters))
        }

    }
    const changeDateTo = (date) => {
        if(date){
            let valueDate = new Date(date)
            let newDate = new Date(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate())
            let to = {
                day: newDate.getDate(),
                month: newDate.getMonth() + 1,
                year: newDate.getFullYear(),
                unix: (newDate.valueOf() / 1000) + 86399,
            }
            let newFilters = {
                ...filters,
                page: 1,
                time: {
                    ...filters.time,
                    to: to,
                },
            }
            dispatch(changeFilters(newFilters))
        } else {
            let newFilters = {
                ...filters,
                page: 1,
                time: {
                    ...filters.time,
                    to: null,
                },
            }
            dispatch(changeFilters(newFilters))
        }
    }

    //Экскурсии
    let excursions = useSelector(state => state.excursions.excursions);
    const [itemsExcursions, setItemsExcursions] = useState(excursions.map(excursion => {
        return {
            key: excursion.id,
            value: excursion.name.find(lang => lang.default_language).text + ` (${excursion.typeExcursion.name})`,
            selected: false,
        }
    }));
    useEffect(() => {
        let newStateExcursions = excursions.map(excursion => {
            return {
                key: excursion.id,
                value: excursion.name.find(lang => lang.default_language).text + ` (${excursion.typeExcursion.name})`,
                selected: false,
            }
        });
        newStateExcursions.unshift({
            key: "all",
            value: "Всі екскурсії",
            selected: false
        })
        setItemsExcursions(newStateExcursions);
    }, [excursions])
    const itemsExcursionsOnChange = (e) => {
        let selectedItem = e.selectedItem;
        let newItemsExcursions = itemsExcursions.map(excursion => {
            if (excursion.key == selectedItem.key) {
                excursion.selected = true;
                return excursion
            } else {
                excursion.selected = false;
                return excursion
            }
        })
        setItemsExcursions(newItemsExcursions);

        let newFilters = {
            ...filters,
            page: 1,
            excursion: selectedItem.key == "all" ? null : selectedItem.key,
        }
        dispatch(changeFilters(newFilters))
    }

    //Статусы
    const [itemsStatuses, changeStatuses] = useState([
        {
            key: "all",
            value: "Всі статуси",
            selected: false,
        },
        {
            key: "expected",
            value: "Очікується",
            selected: false,
        },
        {
            key: "completed",
            value: "Завершено",
            selected: false,
        },
        {
            key: "cancelled",
            value: "Скасовано",
            selected: false,
        },
        {
            key: "off_schedule",
            value: "Поза графіком",
            selected: false,
        },
    ])
    const itemsStatusesOnChange = (e) => {
        let selectedItem = e.selectedItem;
        let newItems = itemsStatuses.map(item => {
            if (item.key == selectedItem.key) {
                item.selected = true;
                return item
            } else {
                item.selected = false;
                return item
            }
        })
        changeStatuses(newItems);

        let newFilters = {
            ...filters,
            page: 1,
            status: selectedItem.key == "all" ? null : selectedItem.key,
        }
        dispatch(changeFilters(newFilters))
    }

    //Гіди
    const guides = useSelector(state => state.guides.guides)
    const [itemsGuides, changeGuides] = useState([])
    const itemsGuidesOnChange = (e) => {
        let selectedItem = e.selectedItem;
        let newItems = itemsGuides.map(item => {
            if (item.key == selectedItem.key) {
                item.selected = true;
                return item
            } else {
                item.selected = false;
                return item
            }
        })
        changeGuides(newItems);

        let newFilters = {
            ...filters,
            page: 1,
            guide: selectedItem.key == "all" ? null : selectedItem.key,
        }
        dispatch(changeFilters(newFilters))
    }
    useEffect(() => {
        if (guides && guides.length != 0) {
            let guidesList = guides.map(guide => {
                return {
                    key: guide.id,
                    value: guide.name,
                    selected: false
                }
            })
            guidesList.unshift({
                key: "all",
                value: "Всі гіди",
                selected: false,
            })
            changeGuides(guidesList)
        }
    }, [guides])

    //Действия
    const [itemsAction, changeAction] = useState([
        {
            key: "delete",
            value: "Видалити",
            selected: false,
        },
        {
            key: "cancel_booking",
            value: "Скасувати бронювання",
            selected: false,
        },
        {
            key: "export_to_csv",
            value: "Експорт в csv",
            selected: false,
        },
        {
            key: "invoice",
            value: "Виставити invoice",
            selected: false,
        },
    ])
    const itemsActionOnChange = (e) => {
        let value = e.selectedItem
        if (value.key == "export_to_csv") {
            export_to_csv()
        }
        if (value.key == "delete") {
            let checkedBookings = bookings.filter(booking => booking.checked).map(booking => booking.id)
            checkedBookings.forEach(booking => {
                dispatch(deleteBooking(booking))
            })
        }
        if (value.key == "cancel_booking") {
            let checkedBookings = bookings.filter(booking => booking.checked).map(booking => booking.id)
            checkedBookings.forEach(booking => {
                dispatch(cancelBooking(booking))
            })
        }
        if (value.key == "invoice") {
            let checkedBookings = bookings.filter(booking => booking.checked).map(booking => booking.id)
            checkedBookings.forEach(booking => {
                dispatch(invoiceBooking(booking))
            })
        }
    }
    const export_to_csv = () => {
        let data = [
            ["ID", "Экскурсія", "Дата створення", "Дата", "Час", "Гіди", "Мова", "ПІБ клієнта", "Телефон", "E-mail", "Вартість", "Статус оплати", "Статус"]
        ];

        if (bookings && Array.isArray(bookings)) {
            bookings.forEach(booking => {
                let arrBooking = []


                /* ID */
                arrBooking.push(`"${booking.id}"`)

                /* Экскурсія */
                arrBooking.push(`"${booking.excursion.name.find(item => item.default_language).text} (${booking.excursion.typeExcursion.name})"`)

                /* Дата створення */
                arrBooking.push(`"${booking.time.create.date}"`)

                /* Дата початку бронировання */
                arrBooking.push(`"${booking.time.start.date}"`)

                /* Тривалість бронирования */
                arrBooking.push(`"${booking.time.duration.time}"`)

                /* Гіди */
                arrBooking.push(`"${booking.guides.map(guide => guide.name + ' ' + guide.id).join(", ")}"`)

                /* Мова */
                arrBooking.push(`"${booking.language.name}"`)

                /* Клиєнт */
                arrBooking.push(`"${booking.client.name} ${booking.client.surname}"`)

                /* Телефон */
                arrBooking.push(`"${booking.client.phone}"`)

                /* Email */
                arrBooking.push(`"${booking.client.email}"`)

                /* Вартість */
                arrBooking.push(`"${booking.payment.price}"`)

                /* Статус оплати */
                arrBooking.push(`"${booking.payment.status.name}"`)

                /* Статус бронювання */
                arrBooking.push(`"${booking.status.name}"`)

                data.push(arrBooking)
            })
        }

        data = data.map(string => {
            let csvString = ""
            string.forEach(item => {
                csvString += String(item) + ","
            })
            csvString += "\r\n"
            return csvString
        })

        saveAs(new Blob(data, {type: 'text/csv;charset=utf-8'}), 'bookings.csv');
    }

    useEffect(() => {
        if (branchID) {
            let newFilters = {
                ...filters,
                page: 1,
                branch: branchID,
            }
            dispatch(changeFilters(newFilters))
        }
    }, [branchID])

    console.log(filters)

    return (
        <Filters>
            <DateFilter prefix={"від"} callback={changeDateFrom}/>
            <DateFilter prefix={"до"} callback={changeDateTo}/>
            <CustomSelect
                items={itemsExcursions}
                onChange={itemsExcursionsOnChange}
                placeholder={'Всі екскурсії'}
                contentAbsolute={true}
            />
            <CustomSelect
                items={itemsStatuses}
                onChange={itemsStatusesOnChange}
                placeholder={'Всі статуси'}
                contentAbsolute={true}
            />
            <CustomSelect
                items={itemsGuides}
                onChange={itemsGuidesOnChange}
                placeholder={'Всі гіди'}
                contentAbsolute={true}
            />
            <CustomSelect
                items={itemsAction}
                onChange={itemsActionOnChange}
                placeholder={'Виконати дію'}
                contentAbsolute={true}
            />
        </Filters>
    );
};

export default FiltersBookings;