import React, { useEffect } from 'react'
import Table from './Table'
import TableHeaderText from './TableHeader/Items/TableHeaderText'
import { useDispatch, useSelector } from 'react-redux'
import { getRoles } from '../../../redux/reducers/rolesReducer'
import TableItem from './TableItem/TableItem'
import TableItemText from './TableItem/Items/TableItemText/TableItemText'
import TableItemEdit from './TableItem/Items/TableItemEdit/TableItemEdit'

const TableRoles = () => {

    const dispatch = useDispatch()

    const items = useSelector(state => state.roles.roles)

    const header = [
        {
            width: 1,
            component: () => <TableHeaderText itemName="Пошта/Логін" /> 
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="Роль" /> 
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="ПІБ" /> 
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="Телефон" /> 
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="  " /> 
        }
    ]

    const columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    return (
        <Table
            header={header}
            columns={columns}
        >
            {items.map(item => 
                <TableItem key={item.id} columns={columns}>
                    <TableItemText text={item.email}/>
                    <TableItemText text={item.role_name}/>
                    <TableItemText text={item.name}/>
                    <TableItemText text={item.phone_number}/>
                    <TableItemEdit type={item.type} id={item.id}/>
                </TableItem>    
            )}
        </Table>
    )
}

export default TableRoles