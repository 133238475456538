import React, {useEffect} from "react";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";
import {getLanguages} from "../../../redux/reducers/languagesReducer";
import {useDispatch, useSelector} from 'react-redux'
import TableItemGuides from "./TableItem/Items/TableItemGuides/TableItemGuides";
import TableItemStatusActive from "./TableItem/Items/TableItemStatusActive/TableItemStatusActive";

const TableLanguage = () => {

    const dispatch = useDispatch();

    const items = useSelector(state => state.branches.branches);

    let header = [
        {
            width: 2,
            component: () => <TableHeaderText itemName="Назва"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Екскурсії"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Гіди"/>
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="Статус"/>
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Буферний час (хв)" fullName={"Буферний час для бронювання екскурсій (хв)"}/>
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName=" "/>,
        },
    ];

    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    return (
        <Table header={header} items={items} columns={columns}>
            {
                items.map(item => {
                    return (
                        <TableItem key={item.id} columns={columns}>
                            <TableItemText text={item.name.find(name => name?.default_language).text}/>
                            <TableItemText text={item.excursion.length}/>
                            <TableItemGuides guides={item.guides.filter(guide => guide.deleted === false)} />
                            <TableItemStatusActive status={item.status.key} />
                            <TableItemText text={item.buffer ? item.buffer : 0}/>
                            <TableItemEdit type={item.type} id={item.id}/>
                        </TableItem>
                    )
                })
            }
        </Table>
    )
}

export default TableLanguage;
