import axios from "axios";

export default class ReportsApi {

    static async getAll(token) {
        const response = await axios.post('https://api.0300.com.ua/api/reports/getall', {
            token: token
        })

        return response;
    }

    static async create(data) {
        const response = await axios.post('https://api.0300.com.ua/api/reports/create', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }


}