import {Controller, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const TimeTable = ({control, timeTable, onChangeInput, getValues, setValue, changeButton, readOnly}) => {
    const [state, setState] = useState(false);
    useEffect(() => {
        setState(getValues("timeTable"))
    }, [])

    return (
        <div>
            <h3 style={{marginTop: '0px'}}> Графік роботи </h3>
            {
                timeTable.map(day => {
                        return (
                            <div key={"status_" + day.key}>
                                <Controller
                                    control={control}
                                    name={`timeTable.${day.key}.status`}
                                    defaultValue={day.status}
                                    render={({
                                                 field: {onChange, value, name, ref},
                                                 formState,
                                             }) => (
                                        <div className={"row"}>
                                            <input
                                                onChange={() => {
                                                    if(readOnly) return;
                                                    changeButton()
                                                    onChangeInput(day.key, value ? false : true);
                                                    setValue(`timeTable.${day.key}.status`, value ? false : true);
                                                    setState(
                                                        {
                                                            ...state,
                                                            [day.key]: {
                                                                ...state[day.key],
                                                                status: value ? false : true
                                                            }


                                                        }
                                                    )
                                                }}
                                                id={"status_" + day.key}
                                                name={"status_" + day.key}
                                                type="checkbox"
                                                checked={value ? true : false}
                                            />
                                            <label htmlFor={"status_" + day.key}>{day.name.small}</label>
                                        </div>
                                    )}
                                />
                                {state[day.key]?.status == true &&

                                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px"}}>
                                        <Controller
                                            control={control}
                                            name={`timeTable.${day.key}.time.start`}
                                            defaultValue={day.time.start.time ? day.time.start.time : "00:00"}
                                            render={({
                                                         field: {onChange, value, name, ref},
                                                         formState,
                                                     }) => (
                                                <div>
                                                    <label htmlFor={"start_" + day.key}>Початок дня (час)</label>
                                                    <input
                                                        onChange={
                                                            (e) => {
                                                                onChange(e);
                                                                changeButton()
                                                            }
                                                        }
                                                        id={"start_" + day.key}
                                                        name={"start_" + day.key}
                                                        type="time"
                                                        value={value}
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`timeTable.${day.key}.time.end`}
                                            defaultValue={day.time.end.time ? day.time.end.time : "00:00"}
                                            render={({
                                                         field: {onChange, value, name, ref},
                                                         formState,
                                                     }) => (
                                                <div>
                                                    <label htmlFor={"end_" + day.key}>Кінець дня (час)</label>
                                                    <input
                                                        onChange={
                                                            (e) => {
                                                                onChange(e);
                                                                changeButton()
                                                            }
                                                        }
                                                        id={"end_" + day.key}
                                                        name={"end_" + day.key}
                                                        type="time"
                                                        value={value}
                                                        readOnly={readOnly}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                }
                            </div>
                        )
                    }
                )
            }
        </div>
    )
}

export default TimeTable;