import React, {useEffect, useState} from "react";
import Page from "../../../UI/Page/Page";
import TimeTableExcursion from "../../../UI/TimeTableExcursion/TimeTableExcursion";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCurrentExcursion,
    getTimeTable, resetShedule, setErrorMessage
} from "../../../../redux/reducers/timeTableExcursionReducer";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import HeaderTimeTable from "./HeaderTimeTable/HeaderTimeTable";
import Button from "../../../UI/Button";
import Modal from "../../../UI/Modal/Modal";

const ExcursionTimeTable = () => {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const excursions = useSelector(state => state.excursions.excursions)
    const errorMessage = useSelector(state => state.timeTablesExcursions.errorMessage)

    const data = useSelector(state => state.timeTablesExcursions.data);
    const table = useSelector(state => state.timeTablesExcursions.currentTable);
    const time = data.time;
    const excursion = excursions.find(excursion => excursion.id == params.id)
    const nameDescription = (data && excursion) ? excursion.name.find(lang => lang.default_language).text + ` (${excursion.typeExcursion.name})` : ""

    const [resetModal, changeResetModal] = useState(false)

    const resetTimeTable = (e) =>  {
        dispatch(resetShedule(params.id))
        changeResetModal(false)
    }
    const toggleReset = (e) => {
        e.preventDefault()
        changeResetModal(prevState => prevState ? false : true)
    }
    const hideErrorMessage = (e) => {
        e.preventDefault()
        dispatch(setErrorMessage(null))
    }
    useEffect(() => {
        if (params?.id) {
            let excursionsForUrl = excursions.find(item => item.id == params.id);
            dispatch(changeCurrentExcursion(excursionsForUrl));
            dispatch(getTimeTable({
                id: params.id,
                time: time.unix
            }))
        } else {
            navigate('/excursions')
        }
    }, [params?.id]);

    if (data) {
        return (
            <Page
                breadcrumbs={{link: '/excursions', text: 'Екскурсії'}}
                pageName={"Графік екскурсії"}
                nameDescription={nameDescription}
                contentStyle={{
                    height: "100%",
                    maxWidth: "100%",
                    overflow: "hidden",
                }}
                rightButton={ {color: "#ffbcbc", style:{margin: "0px"}, content: "Скинути графік", callback: toggleReset}}
            >
                {data.validity.date &&
                    <HeaderTimeTable data={data}/>
                }
                {table &&
                    <div style={{
                        // width: "calc(100vw - 32px - 32px - 261px)",
                        background: "#FFF",
                        padding: "19px",
                        overflowX: "scroll"
                    }}>
                        <TimeTableExcursion data={data} table={table} time={time}/>
                    </div>
                }
                {table == null &&
                    <div style={{
                        width: "calc(100vw - 32px - 32px - 261px)",
                        background: "#FFF",
                        padding: "19px",
                        overflowX: "scroll",
                        minHeight: "100%",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <PageLoader/>
                    </div>
                }
                <Modal active={errorMessage} title={"Помилка"} type={"error"}>
                    {errorMessage &&
                        <p>{errorMessage}</p>
                    }
                    <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                        <button onClick={hideErrorMessage} style={{background: "#ECECEC"}}>
                            Закрити
                        </button>
                    </div>
                </Modal>
                <Modal active={resetModal} type={"error"} title={"Ви впевнені що хочете скинуті графік?"}>
                    <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                        <button onClick={toggleReset} style={{background: "#ECECEC"}}>
                            Закрити
                        </button>
                        <button onClick={resetTimeTable}>
                            Скинути
                        </button>
                    </div>
                </Modal>
            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
}

export default ExcursionTimeTable;