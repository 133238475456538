import React, {useEffect, useState} from 'react'
import CalendarTable from "../../UI/CalendarTable/CalendarTable";
import Page from "../../UI/Page/Page";
import {useNavigate} from "react-router-dom";
import FiltersCalendar from "../../UI/Filters/FiltersCalendar";
import EditBooking from "./EditBooking/EditBooking";
import {changeParamsTime} from "../../../redux/reducers/calendarReducer";

const Calendar = () => {
    const navigate = useNavigate()

    return (
        <Page
            pageName={"Календар"}
            rightButton={{
                content: 'Створити бронювання', callback: () => {
                    navigate('/bookings/create')
                }, icon: 'add', color: ''
            }}
        >
            <FiltersCalendar style={{position: "relative", zIndex: "7"}}/>
            <CalendarTable />
            <EditBooking />
        </Page>
    )
}

export default Calendar