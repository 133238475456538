import React from "react";
import classes from "./TableItemGuides.module.scss";

const TableItemGuides = ({guides, style}) => {
    return(
        <div className={classes.cell} style={style}>
            {
                guides.map(guide => {
                    return(
                        <img 
                            key={guide.id}
                            className={classes.photo} 
                            src={guide.photo}
                        />
                    )
                })
            }

        </div>
    )
}

export default TableItemGuides;