import React from 'react'
import cl from './ButtonHalfTransparent.module.scss';

const ButtonHalfTransparent = ({children, onClick, className, style}) => {

    return (
        <button
            className={[className, cl.halfTransparentButton].join(' ')}
            onClick={onClick}
            style={style}
        >
            {children}
        </button>
    )
}

export default ButtonHalfTransparent