import React from "react";
import classes from "./TableItemEdit.module.scss";
import {Link} from "react-router-dom";

const TableItemEdit = ({type, id, style}) => {
    let link = `${id}`;
    return(
        <div className={classes.cell} style={style}>
            <Link to={link}>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.9999 2.17188C17.7598 2.17188 17.522 2.21917 17.3002 2.31106C17.0784 2.40294 16.8768 2.53762 16.707 2.70741L3.39479 16.0196L2.42512 19.5751L5.98058 18.6054L19.2928 5.2932C19.4626 5.12341 19.5973 4.92184 19.6891 4.70001C19.781 4.47818 19.8283 4.24042 19.8283 4.0003C19.8283 3.76019 19.781 3.52243 19.6891 3.30059C19.5973 3.07876 19.4626 2.87719 19.2928 2.70741C19.123 2.53762 18.9214 2.40294 18.6996 2.31106C18.4778 2.21917 18.24 2.17188 17.9999 2.17188ZM16.5348 0.463297C16.9993 0.2709 17.4971 0.171875 17.9999 0.171875C18.5027 0.171875 19.0005 0.2709 19.465 0.463297C19.9295 0.655693 20.3515 0.937693 20.707 1.2932C21.0625 1.6487 21.3445 2.07074 21.5369 2.53523C21.7293 2.99972 21.8283 3.49755 21.8283 4.0003C21.8283 4.50306 21.7293 5.00089 21.5369 5.46538C21.3445 5.92986 21.0625 6.35191 20.707 6.70741L7.20701 20.2074C7.08395 20.3305 6.93091 20.4193 6.76302 20.4651L1.26302 21.9651C0.916806 22.0595 0.546544 21.9612 0.292794 21.7074C0.0390442 21.4537 -0.0592842 21.0834 0.0351373 20.7372L1.53514 15.2372C1.58093 15.0693 1.66974 14.9163 1.79279 14.7932L15.2928 1.2932C15.6483 0.937693 16.0703 0.655693 16.5348 0.463297Z" fill="#B5B5B5"/>
                </svg>
            </Link>

        </div>
    )
}

export default TableItemEdit;