import React from 'react';
import classes from "./CertificateStatus.module.scss";

const CertificateStatus = ({keyStatus, name, style}) => {

    let colors = {
        "active": "#67ff7f",
        "used": "#ff5c5c",
        "overdue": "#FFD52F",
    }
    let styleObj = {
        background: colors[keyStatus],
        ...style
    }
    return (
        <div className={classes.container} style={styleObj}>
            {name}
        </div>
    );
};

export default CertificateStatus;