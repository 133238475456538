import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {ReactSVG} from 'react-svg'
import pathToSVG from '../../../assets/images/Path 182.svg';
import {_setChangeBranchModal} from '../../../redux/reducers/accountReducer';
import ButtonOutlined from '../../UI/ButtonOutlined/ButtonOutlined';
import cl from './HeaderBranches.module.scss'
import styles from './HeaderBranches.module.scss';

const HeaderBranches = ({modal, setModal}) => {

    const currentBranchId = useSelector(state => state.account.branch)
    const branchesSelector = useSelector(state => state.branches.branches);
    const [branch, setBranch] = useState(false);

    useEffect(() => {
        const currBranch = branchesSelector.find(br => br.id == currentBranchId)
        setBranch(currBranch)
    }, [currentBranchId])

    if (!branch) return;

    return (
        <div className={cl.container}>

            <div className={cl.headerBranches}>
                <ReactSVG src={pathToSVG}/>
                <div className={cl.branchAdress}>
                    {branch.name.find(name => name?.default_language).text}
                </div>
                <ButtonOutlined onClick={e => setModal(!modal)}>
                    Змінити
                </ButtonOutlined>
            </div>

            {branch &&
                <div>
                    Буфер філії - {branch.buffer ? branch.buffer : 0} хв
                </div>
            }

        </div>
    )
}

export default HeaderBranches