import axios from "axios";

export default class CertificatesApi {

    static async getAll(token, branch) {
        const response = await axios.post('https://api.0300.com.ua/api/certificates/getall', {
            token,
            branch
        })

        return response;
    }

    // static async get(token, id) {
    //     if(!id) return null;
    //
    //     const response = await axios.post('https://api.0300.com.ua/api/branches/getall', {
    //         token: token,
    //         id: id
    //     })
    //
    //     return response;
    // }

    static async put(data) {
        const response = await axios.post('https://api.0300.com.ua/api/certificates/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async patch(data) {
        const response = await axios.post('https://api.0300.com.ua/api/certificates/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async checkCertificate(data) {
        const response = await axios.post('https://api.0300.com.ua/api/certificates/checkcertificate', {
            token: localStorage.getItem('auth_token'),
            ...data,
        });
        
        return response;
    }

    static async deleteCertificate(data) {
        const response = await axios.post('https://api.0300.com.ua/api/certificates/delete', {
            token: localStorage.getItem('auth_token'),
            id: data,
        });

        return response;
    }

}