import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import cl from './Page.module.scss';
import ButtonHalfTransparent from './../ButtonHalfTransparent/ButtonHalfTransparent'
import Button from '../Button'
import { ReactSVG } from 'react-svg';
import backLogo from './../../../assets/images/back.svg'

const Page = ({children, breadcrumbs, pageName, nameDescription, rightButton, rightRest, contentStyle}) => {

    const navigate = useNavigate()

    const backFunc = () => {
        if(breadcrumbs?.callback) breadcrumbs.callback()
        navigate(breadcrumbs.link)
    }

    return (
        <div className={cl.page}>
            <div className={cl.pageHeader}>
                <div className={cl.pageHeaderTop}>
                    <div className={cl.pageHeaderTopLeft}>
                        {breadcrumbs ?
                            <div className={cl.breadcrumbs}>
                                <div onClick={backFunc}>
                                    <ButtonHalfTransparent>
                                        <ReactSVG src={backLogo} />
                                        <span>{breadcrumbs.text}</span>
                                    </ButtonHalfTransparent>
                                </div>
                            </div>
                            :
                            ''
                        }
                        {pageName ?
                            <div className={cl.pageName}>{pageName}</div>
                            :
                            ''
                        }
                        {nameDescription ?
                            <div className={cl.pageName} style={{
                                lineHeight: "1",
                                fontSize: "20px"
                            }}>
                                {nameDescription}
                            </div>
                            :
                            ''
                        }
                    </div>
                    <div className={cl.pageHeaderTopRight}>
                        {rightRest}
                        {rightButton ?
                            <div className={cl.rightButton}>
                                <Button
                                    icon={rightButton.icon}
                                    color={rightButton.color}
                                    callBack={rightButton.callback}
                                    form={rightButton.form}
                                    disabled={rightButton.disabled}
                                    style={rightButton.style}
                                >
                                    {rightButton.content}
                                </Button>
                            </div>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
            <div className={cl.pageContent} style={contentStyle}>
                {children}
            </div>
        </div>
    )
}

export default Page