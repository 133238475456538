import React, {useEffect} from 'react';
import classes from "./TableWorkSchedules.module.scss";
import DayWorkSchedules from "./DayWorkSchedules/DayWorkSchedules";
import Modal from "../../../UI/Modal/Modal";
import {useState} from "react";
import CustomSelect from "../../../UI/CustomSelect/CustomSelect";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import {useDispatch, useSelector} from "react-redux";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import {editDayWorkTable} from "../../../../redux/reducers/workSchedulesReducer";

const TableWorkSchedules = () => {
    const dispatch = useDispatch()
    const prevWorkTable = useSelector(state => state.workTable.prevWorkTable)
    const workTable = useSelector(state => state.workTable.workTable)
    const nextWorkTable = useSelector(state => state.workTable.nextWorkTable)
    const guides = useSelector(state => state.guides.guides)
    const branch_id = useSelector(state => state.account.branch)
    const branches = useSelector(state => state.branches.branches)

    const [currentBranch, changeCurrentBranch] = useState(null)

    useEffect(() => {
        if(branches && branch_id) {
            let branch = branches.find(item => item.id == branch_id)

            if(branch) {
                changeCurrentBranch(branch)
            }
        }
    }, [branches, branch_id])

    const [editSlotModal, changeEditSlotModal] = useState(false)

    const editSlot = (slot, id) => {
        changeEditSlotModal({
            ...slot,
            changeGuideId: id,
        })
    }
    const deleteGuide = (e) => {
        e.preventDefault()
        const newSlot = {
            ...editSlotModal.date,
            guides: [...editSlotModal.guides].filter(guide => guide.id != editSlotModal.changeGuideId).map(guide => {
                return {
                    id: guide.id,
                    start: guide.time.start.time,
                    end: guide.time.end.time,
                }
            }),
            branch_id: branch_id
        }
        console.log(newSlot)
        dispatch(editDayWorkTable(newSlot))
        changeEditSlotModal(false)
    }
    const deleteGuides = (date) => {
        const newSlot = {
            ...date,
            guides: [],
            branch_id: branch_id
        }
        dispatch(editDayWorkTable(newSlot))
    }
    const hideModal = () => {
        changeEditSlotModal(false)
    }
    const saveEditGuide = () => {
        const newSlot = {
            ...editSlotModal.date,
            guides: [...editSlotModal.guides].map(guide => {
                return {
                    id: guide.id,
                    start: guide.time.start.time,
                    end: guide.time.end.time,
                }
            }),
            branch_id: branch_id
        }

        dispatch(editDayWorkTable(newSlot))
        changeEditSlotModal(false)
    }
    const changeTimeStart = (e) => {
        const value = e.currentTarget.value;

        let newDataModal = {
            ...editSlotModal,
            guides: [...editSlotModal.guides].map(guide => {
                if (guide.id == editSlotModal.changeGuideId) {
                    return (
                        {
                            ...guide,
                            time: {
                                ...guide.time,
                                start: {
                                    ...guide.start,
                                    time: value
                                }
                            }
                        }
                    )
                } else {
                    return (
                        guide
                    )
                }

            })
        }
        changeEditSlotModal(newDataModal)
    }
    const changeTimeEnd = (e) => {
        const value = e.currentTarget.value;
        let newDataModal = {
            ...editSlotModal,
            guides: [...editSlotModal.guides].map(guide => {
                if (guide.id == editSlotModal.changeGuideId) {
                    return (
                        {
                            ...guide,
                            time: {
                                ...guide.time,
                                end: {
                                    ...guide.end,
                                    time: value
                                }
                            }
                        }
                    )
                } else {
                    return (
                        guide
                    )
                }

            })
        }
        changeEditSlotModal(newDataModal)
    }

    const [addGuide, changeAddGuide] = useState({
        active: false,
        guideId: null,
        slot: null,
        time: {
            start: "00:00",
            end: "00:00"
        }
    })
    const [addGuidesSelect, changeAddGuidesSelect] = useState(guides.filter(guide => guide.deleted === false).map(guide => {
        return {
            key: guide.id,
            value: guide.name,
            img: guide.photo,
            selected: false,
        }
    }))

    const showAddGuide = (slot) => {

        let dateObj = new Date(slot.date.year, slot.date.month - 1, slot.date.day)
        let dayOfWeek = dateObj.getDay() == 0 ? 7 : dateObj.getDay()
        let timeTable = currentBranch.timeTable
        let currentDay = timeTable.find(item => item.key == dayOfWeek)

        changeAddGuide({
            ...addGuide,
            active: true,
            slot: slot,
            time: {
                start: currentDay.time.start.time ? currentDay.time.start.time : "00:00",
                end: currentDay.time.end.time ? currentDay.time.end.time : "00:00",
            }
        })

        let newGuidesSelect = [...addGuidesSelect].map(guide => {
            if (slot.guides.find(item => item.id == guide.key)) {
                return false
            } else {
                return guide
            }
        }).filter(item => item)

        changeAddGuidesSelect(newGuidesSelect)
    }
    const hideModalGuide = (e) => {
        e.preventDefault()
        changeAddGuide({
            active: false,
            guideId: null,
            slot: null,
            time: {
                start: "00:00",
                end: "00:00"
            }
        })
        changeAddGuidesSelect(guides.filter(guide => guide.deleted === false).map(guide => {
            return {
                key: guide.id,
                value: guide.name,
                img: guide.photo,
                selected: false,
            }
        }))
    }
    const addGuideCallback = (e) => {
        e.preventDefault()

        const guide = guides.find(guide => guide.id == addGuide.guideId)
        const newGuides = [...addGuide.slot.guides].map(guide => {
            return {
                id: guide.id,
                start: guide.time.start.time,
                end: guide.time.end.time,
            }
        })
        newGuides.push({
            id: addGuide.guideId,
            ...addGuide.time,
        })

        const newSlot = {
            day: addGuide.slot.date.day,
            month: addGuide.slot.date.month,
            year: addGuide.slot.date.year,
            guides: newGuides,
            branch_id: branch_id
        }
        console.log(newSlot)
        dispatch(editDayWorkTable(newSlot))

        changeAddGuide({
            active: false,
            guideId: null,
            slot: null,
            time: {
                start: "00:00",
                end: "00:00"
            }
        })
        changeAddGuidesSelect(guides.filter(guide => guide.deleted === false).map(guide => {
            return {
                key: guide.id,
                value: guide.name,
                img: guide.photo,
                selected: false,
            }
        }))

    }
    const onChangeSelect = (e) => {
        const selectedItem = e.selectedItem;
        changeAddGuidesSelect([...addGuidesSelect].map(item => {
            if (item.key == selectedItem.key) {
                return {
                    ...item,
                    selected: true,
                }
            } else {
                return {
                    ...item,
                    selected: false
                }
            }
        }))
        changeAddGuide({
            ...addGuide,
            guideId: selectedItem.key,
        })
    }
    const onChangeAddGuideStart = (e) => {
        let value = e.currentTarget.value;
        changeAddGuide({
            ...addGuide,
            time: {
                ...addGuide.time,
                start: value
            }
        })
    }
    const onChangeAddGuideEnd = (e) => {
        let value = e.currentTarget.value;
        changeAddGuide({
            ...addGuide,
            time: {
                ...addGuide.time,
                end: value
            }
        })
    }

    const [modalDeleteGuides, changeDeleteGuides] = useState(false)

    const hideDeleteModal = (e) => {
        e.preventDefault()
        changeDeleteGuides(false)
    }
    const showDeleteModal = (e, dateDelete) => {
        e.preventDefault()
        changeDeleteGuides(dateDelete)
    }
    const acceptDelete = () => {
        deleteGuides(modalDeleteGuides)
        changeDeleteGuides(false)
    }

    console.log(addGuide)

    if (workTable && prevWorkTable && nextWorkTable) {
        const firstDayObj = new Date(workTable[0].date.year, workTable[0].date.month - 1, workTable[0].date.day)
        const firstDayOfWeek = firstDayObj.getDay() == 0 ? 7 : firstDayObj.getDay()
        const emptyDays = Array.from({length: firstDayOfWeek - 1}, (_, index) => index + 1);
        const emptyDaysArr = prevWorkTable ? prevWorkTable.slice(prevWorkTable.length - emptyDays.length) : emptyDays

        const lastDayObj = new Date(workTable[0].date.year, workTable[0].date.month - 1, workTable.length)
        const lastDayOfWeek = lastDayObj.getDay() == 0 ? 7 : lastDayObj.getDay()
        const emptyLastDaysArr = nextWorkTable ? nextWorkTable.slice(0, 7 - lastDayOfWeek) : []

        return (
            <div className={classes.table}>
                {
                    emptyDaysArr.map(slotDay => {
                        if (typeof slotDay == "object") {
                            return (
                                <DayWorkSchedules
                                    key={`${slotDay.date.day}.${slotDay.date.month}.${slotDay.date.year}`}
                                    blocked={true}
                                    slot={slotDay}
                                    editSlot={() => {}}
                                />
                            )
                        } else {
                            return (
                                <div
                                    key={`${Math.random()}_${Date.now()}`}
                                />
                            )
                        }
                    })
                }
                {
                    workTable.map(slot => {
                        return (
                            <DayWorkSchedules
                                key={`${slot.date.day}.${slot.date.month}.${slot.date.year}`}
                                slot={slot}
                                editSlot={editSlot}
                                showAddGuide={showAddGuide}
                                deleteCallback={deleteGuides}
                                showDeleteModal={showDeleteModal}
                            />
                        )
                    })
                }

                {emptyLastDaysArr &&
                    emptyLastDaysArr.map(slotDay => {
                        if (typeof slotDay == "object") {
                            return (
                                <DayWorkSchedules
                                    key={`${slotDay.date.day}.${slotDay.date.month}.${slotDay.date.year}`}
                                    blocked={true}
                                    slot={slotDay}
                                    editSlot={() => {
                                    }}
                                />
                            )
                        } else {
                            return (
                                <div
                                    key={`${Math.random()}_${Date.now()}`}
                                />
                            )
                        }
                    })
                }

                <Modal active={editSlotModal} title="Редагувати гіда">

                    {editSlotModal &&
                        <div>
                            <div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px"
                                }}>
                                    <img
                                        src={editSlotModal.guides.find(guide => guide.id == editSlotModal.changeGuideId).photo}
                                        style={{
                                            width: "25px",
                                            minWidth: "25px",
                                            maxWidth: "25px",
                                            height: "25px",
                                            maxHeight: "25px",
                                            minHeight: "25px",
                                            overflow: "hiden",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                        }}
                                    />

                                    <h3>
                                        <span>{editSlotModal.guides.find(guide => guide.id == editSlotModal.changeGuideId).name}</span>
                                        <span> {editSlotModal.guides.find(guide => guide.id == editSlotModal.changeGuideId).surname}</span>
                                    </h3>
                                </div>

                                <div>
                                    <input
                                        type="time"
                                        value={editSlotModal.guides.find(guide => guide.id == editSlotModal.changeGuideId).time.start.time}
                                        max={editSlotModal.guides.find(guide => guide.id == editSlotModal.changeGuideId).time.end.time}
                                        onChange={changeTimeStart}
                                    />
                                    <input
                                        type="time"
                                        value={editSlotModal.guides.find(guide => guide.id == editSlotModal.changeGuideId).time.end.time}
                                        min={editSlotModal.guides.find(guide => guide.id == editSlotModal.changeGuideId).time.start.time}
                                        onChange={changeTimeEnd}
                                    />
                                </div>

                                <ButtonHalfTransparent onClick={deleteGuide}>
                                    <ReactSVG src={deleteSVG}/>
                                    Видалити гіда
                                </ButtonHalfTransparent>

                            </div>

                            <div className="row" style={{justifyContent: "center", gap: "10px"}}>
                                <button onClick={hideModal} style={{background: "#ECECEC"}}>
                                    Скасувати
                                </button>

                                <button onClick={saveEditGuide}>
                                    Зберегти
                                </button>
                            </div>
                        </div>
                    }

                </Modal>

                <Modal active={addGuide.active} title={"Додати гіда"}>

                    <div>

                        <div style={{marginBottom: "10px"}}>
                            <CustomSelect
                                items={addGuidesSelect}
                                onChange={onChangeSelect}
                                placeholder={'Гіди'}
                            />
                        </div>

                        <div className="row" style={{gap: "10px"}}>
                            <input
                                type="time"
                                value={addGuide.time.start}
                                onChange={onChangeAddGuideStart}
                            />
                            <input
                                type="time"
                                value={addGuide.time.end}
                                onChange={onChangeAddGuideEnd}
                            />
                        </div>

                    </div>

                    <div className="row" style={{justifyContent: "center", gap: "10px"}}>

                        <button onClick={hideModalGuide} style={{background: "#ECECEC"}}>
                            Скасувати
                        </button>

                        {addGuide.guideId && addGuide.time.start != "00:00" && addGuide.time.end != "00:00" &&
                            <button onClick={addGuideCallback}>
                                Додати
                            </button>
                        }

                    </div>

                </Modal>

                <Modal active={modalDeleteGuides} title={"Видалити гідів?"} type={"error"}>

                    <div>
                        <p style={{textAlign: "center"}}>
                            Ви впевнені що хочете відалити всіх гідів?
                        </p>
                    </div>

                    <div className="row" style={{justifyContent: "center", gap: "10px"}}>

                        <button onClick={hideDeleteModal} style={{background: "#ECECEC"}}>
                            Скасувати
                        </button>

                        <button onClick={acceptDelete}>
                            Видалити
                        </button>

                    </div>

                </Modal>

            </div>
        );
    } else {
        <PageLoader/>
    }
};

export default TableWorkSchedules;