import React from 'react';
import Page from "../../../UI/Page/Page";
import LanguageInputs from "../../../UI/LanguageFields/LanguageInputs/LanguageInputs";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {changeCurrentField, deleteField, getFields, setFields} from "../../../../redux/reducers/translateReducer";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import {changeStateTranslateSaveButton, createField, editField} from "../../../../redux/reducers/translateReducer";
import Error from "../../../UI/Error/Error";
import LanguageAreas from "../../../UI/LanguageFields/LanguageAreas/LanguageAreas";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import publish from "../../../../assets/images/publish.svg";
import Modal from "../../../UI/Modal/Modal";
import {toArchiveCertificate} from "../../../../redux/reducers/сertificatesReducer";
import {useState} from "react";

const TranslateFieldsCreate = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const {register, handleSubmit, control, setError, setValue, getValues, formState: {errors}} = useForm();

    const fields = useSelector(state => state.translate.fields);
    const newField = useSelector(state => state.translate.newField);
    let data = useSelector(state => state.translate.currentField);
    const stateSaveButton = useSelector(state => state.translate.disabledButton);

    let pageName = (data && data.identifier) ? data.identifier : "";

    const [modalToArchive, changeModalToArchive] = useState(false)

    const showModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(true)
    }
    const hideModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(false)
    }
    const deleteTranslateField = (e) => {
        e.preventDefault()
        if(data.id){
            dispatch(deleteField(data.id, navigate))
        }
        changeModalToArchive(false)
    }
    let translateSubmit = (dataForm) => {
        const findItemKey = fields.find(field => field.identifier == dataForm.identifier && field.id != data.id)

        if(findItemKey) {
            setError('identifier', {message: "Такий ключ вже існує"})
            return false
        }
        if (data.id) {
            dataForm.id = data.id;
            dispatch(editField(dataForm));
        } else {
            dispatch(createField(dataForm, navigate));
        }
    }

    useEffect(() => {
        dispatch(setFields([]))
        dispatch(getFields())
    }, [])
    useEffect(() => {

        if (fields && fields.length != 0) {
            if (params?.id) {
                let forUrl = fields.find(item => item.id == params.id);
                dispatch(changeCurrentField(forUrl));
            } else {
                dispatch(changeCurrentField(newField));
            }
            return () => {
                dispatch(changeCurrentField(false));
            }
        }

    }, [fields]);

    if (data && fields) {
        return (
            <Page
                breadcrumbs={{link: '/translate_fields', text: 'Поля'}}
                pageName={pageName}
                rightButton={{
                    content: 'Зберегти ',
                    callback: () => {
                    },
                    icon: 'add',
                    color: '',
                    form: 'translate_form',
                    disabled: stateSaveButton,
                }}
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>

                    <form id={"translate_form"} onSubmit={handleSubmit(translateSubmit)}>

                        <div>

                            <div>
                                <h3 style={{marginTop: '0px'}}>Ключ</h3>

                                <input
                                    type={"text"}
                                    className={errors?.identifier ? "error" : ""}
                                    readOnly={data.id ? true : false}
                                    {...register("identifier", {
                                        onChange: () => {
                                            dispatch(changeStateTranslateSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data?.identifier,
                                    })}
                                />
                                {errors?.identifier &&
                                    <Error> {errors?.identifier?.message} </Error>
                                }
                            </div>

                            <LanguageAreas
                                keyIndex={"fields"}
                                label={"Переклади"}
                                fields={data.text}
                                control={control}
                                changeButton={() => {
                                    dispatch(changeStateTranslateSaveButton(false))
                                }}
                            />

                            {/*{params.id &&*/}
                            {/*    <ButtonHalfTransparent onClick={showModalToArchive}>*/}
                            {/*        <ReactSVG src={deleteSVG}/>*/}
                            {/*        Видалити*/}
                            {/*    </ButtonHalfTransparent>*/}
                            {/*}*/}

                        </div>

                        <div></div>

                        <div></div>

                    </form>

                </div>

                <Modal active={modalToArchive} title={"Видалити"} type={"error"}>

                    <div>
                        <p style={{textAlign: "center"}}>
                            Ви впевнені що хочете видалити поле?
                        </p>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        <button
                            style={{background: "#eee"}}
                            onClick={hideModalToArchive}
                        >
                            Скасувати
                        </button>
                        <button onClick={deleteTranslateField}>
                            Видалити
                        </button>
                    </div>

                </Modal>

            </Page>
        );
    } else {
        return (
            <PageLoader/>
        )
    }

};

export default TranslateFieldsCreate;