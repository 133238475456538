import axios from "axios";

export default class KeysApi {

    static async getPublicKey() {
        const response = await axios.post('https://api.0300.com.ua/api/options/get_public_key', {
            token: localStorage.getItem('auth_token'),
        })

        return response;
    }
    static async setPublicKey(value) {
        const response = await axios.post('https://api.0300.com.ua/api/options/set_public_key', {
            token: localStorage.getItem('auth_token'),
            value
        })

        return response;
    }
    static async getPrivateKey() {
        const response = await axios.post('https://api.0300.com.ua/api/options/get_private_key', {
            token: localStorage.getItem('auth_token'),
        })

        return response;
    }
    static async setPrivateKey(value) {
        const response = await axios.post('https://api.0300.com.ua/api/options/set_private_key', {
            token: localStorage.getItem('auth_token'),
            value
        })

        return response;
    }

}