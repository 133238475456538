import React, { useEffect, useLayoutEffect } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import './assets/styles/App.scss'
import Main from "./components/Main/Main";
import { useDispatch, useSelector } from 'react-redux'
import { publicRoutes } from './routes/publicRoutes'
import { useFetching } from './hooks/useFetching';
import AccountApi from './api/AccountApi';
import PageLoader from './components/UI/PageLoader/PageLoader';
import { getLanguages } from './redux/reducers/languagesReducer';
import { getBranches } from './redux/reducers/branchReducer';
import { getRoles } from './redux/reducers/rolesReducer';
import {getDiscounts} from "./redux/reducers/discountsReducer";
import {getCertificates} from "./redux/reducers/сertificatesReducer";
import {getPromocodes} from "./redux/reducers/promocodesReducer";
import {getExcursions} from "./redux/reducers/excursionsReducer";
import {getOptions} from "./redux/reducers/additionalOptionsReducer";
import bookingsApi from './api/BookingsApi';
import axios from 'axios';
import {getGroups} from "./redux/reducers/groupReducer";
import {getGuides} from "./redux/reducers/guidesReducer";

const App = () => {

    const isAuth = useSelector(state => state.account.isAuth);
    const branchId = useSelector(state => state.account.branch);
    const dispatch = useDispatch()

    const [fetching, isLoading, error] = useFetching(async () => {

        const response = await AccountApi.isAuth(localStorage.getItem('branchId'));

        if(response) {
            if(response.data?.isAuth) dispatch({type: 'SET_AUTH_TRUE', payload: {...response.data}})
            await dispatch(getLanguages())
            await dispatch(getBranches())
            await dispatch(getRoles())
            await dispatch(getDiscounts())
            await dispatch(getPromocodes())
            await dispatch(getOptions())
        }
    })

    const [_fetching, _isLoading, _error] = useFetching(async () => {
        const response = await AccountApi.isAuth(localStorage.getItem('branchId'));
        if(response && branchId != null) {
            await dispatch(getExcursions(branchId))
            await dispatch(getCertificates(branchId))
            await dispatch(getGroups(branchId))
            await dispatch(getGuides(branchId))
        }
    });

    useEffect(() => {
        fetching()
    }, [isAuth])

    useEffect(() => {
        _fetching();
    }, [branchId])

    if(isLoading || _isLoading) {
        return(
            <div className={`app ${isAuth ? 'isauth' : ''}`}
                 style={{height: '100vh'}}
            >
                <PageLoader />
            </div>
        );
    }

    return (
        <div className={`app ${isAuth ? 'isauth' : ''}`}>
            <BrowserRouter>
                {isAuth ?
                    <Main/>
                    :
                    <Routes>
                        {publicRoutes.map(route =>
                            <Route key={route.path} path={route.path} element={<route.component />}/>
                        )}
                    </Routes>
                }
            </BrowserRouter>
        </div>
    )
}

export default App