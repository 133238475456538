import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import TableHeaderCheckbox from "./TableHeader/Items/TableHeaderCheckbox";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemColorLabel from "./TableItem/Items/TableItemColorLabel/TableItemColorLabel";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";
import TableItemCheckbox from "./TableItem/Items/TableItemCheckbox/TableItemCheckbox";
import TableItemGuides from "./TableItem/Items/TableItemGuides/TableItemGuides";
import TableItemLogo from "./TableItem/Items/TableItemLogo/TableItemLogo";
import TableItemColorText from "./TableItem/Items/TableItemColorText/TableItemColorText";
import checkMark from "./../../../assets/images/check_mark.svg"
import comment from "./../../../assets/images/comment.svg"
import discount from "./../../../assets/images/discount.svg"
import TableItemImg from "./TableItem/Items/TableItemImg/TableItemImg";
import TabelItemComment from "./TableItem/Items/TabelItemComment/TabelItemComment";
import Modal from "../Modal/Modal";
import {
    changeFilters,
    checkAllBookings, checkBooking,
    getBookings,
    setAdditionalComment
} from "../../../redux/reducers/bookingsReducer";
import Pagination from "../Pagination/Pagination";
import {checkAllCertificates, checkCertificate} from "../../../redux/reducers/сertificatesReducer";
import TableItemNew from "./TableItem/Items/TableItemNew/TableItemNew";
import PageLoader from "../PageLoader/PageLoader";

const TableBookings = () => {
    const items = useSelector(state => state.bookings.bookings)
    const dispatch = useDispatch();
    const branchID = useSelector(state => state.account.branch);
    const [checked, changeChecked] = useState(false);
    const [modalComment, changeModalComment] = useState({
        status: false,
        title: null,
        id: null,
        comment: null,
    })
    const [modalGuestComment, changeModalGuestComment] = useState({
        status: false,
        title: null,
        id: null,
        comment: null,
    })
    const filters = useSelector(state => state.bookings.filters)
    const allPage = useSelector(state => state.bookings.countPages)
    const currentPage = filters.page

    let header = [
        {
            width: 1,
            component: () => <TableHeaderText itemName="#" style={{minWidth: "47px"}} fullName={"ID бронювання"}/>,
        },
        {
            width: 3,
            component: () => <TableHeaderText itemName="Ств." style={{minWidth: "118px"}} fullName={"Дата створення"}/>,
        },
        {
            width: 4,
            component: () => <TableHeaderText itemName="Екскурсія" style={{minWidth: "190px"}}/>,
        },
        {
            width: 3,
            component: () => <TableHeaderText itemName="Дата" style={{minWidth: "118px"}}/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Час" style={{minWidth: "76px"}}/>,
        },
        {
            width: 5,
            component: () => <TableHeaderText itemName="Клієнт" style={{minWidth: "197px"}}/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Гід" style={{minWidth: "92px"}}/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Мова" style={{minWidth: "79px"}}/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="%" style={{minWidth: "75px", justifyContent: "center"}}/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Ком-р" style={{minWidth: "75px"}} fullName={"Коментар"}/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Дод. опції" style={{minWidth: "75px"}} fullName={"Додаткові опції"}/>,
        },
        {
            width: 3,
            component: () => <TableHeaderText itemName="Вартість / Сплачено" style={{minWidth: "130px"}}/>,
        },
        {
            width: 3,
            component: () => <TableHeaderText itemName="Статус" style={{minWidth: "130px"}}/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Ос/кв" style={{minWidth: "65px"}} fullName={"Осіб, квитків"}/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="Вн. ком-р" style={{minWidth: "58px"}} fullName={"Внутрішній коментар"}/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName=" " style={{minWidth: "58px"}}/>,
        },
        {
            width: 1,
            component: () => <TableHeaderCheckbox value={checked} callback={allCheck}/>,
        },
    ];
    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    const addCommentModal = (bookingId, comment ) => {
        changeModalComment({
            status: true,
            title: `Бронювання ${bookingId}`,
            id: bookingId,
            comment: (comment == false || comment == null) ? "" : comment,
        })
    }
    const hideModal = (e) => {
        e.preventDefault()
        changeModalComment({
            status: false,
            title: null,
            id: null,
            comment: null,
        })
    }
    const saveAdditionalComment = (e) => {
        e.preventDefault()

        dispatch(setAdditionalComment({
            id: modalComment.id,
            additionalComment: modalComment.comment
        }))
        changeModalComment({
            status: false,
            title: null,
            id: null,
            comment: null,
        })
    }
    const onChangeAdditionalComment = (e) => {
        e.preventDefault()
        let value = e.currentTarget.value;
        changeModalComment({
            ...modalComment,
            comment: value
        })
    }
    const callbackDay = (page) => {
        let newFilters = {
            ...filters,
            page: page.selected + 1,
        }
        dispatch(changeFilters(newFilters))
    }
    const showGuestCommnet = (id, comment) => {
        changeModalGuestComment(prevState => {
            return {
                ...prevState,
                status: true,
                id: id,
                comment: comment,
            }
        })
    }
    const hideModalGuest = (e) => {
        e.preventDefault();
        changeModalGuestComment({
            status: false,
            title: null,
            id: null,
            comment: null,
        })
    }
    let allCheck = (value) => {
        dispatch(checkAllBookings(value))
        changeChecked(value)
    }
    let itemCheck = (id, value) => {
        dispatch(checkBooking(id, value))
    }

    useEffect(() => {
        if(filters.page && filters.branch){
            dispatch(getBookings(filters))
        }
    }, [branchID, filters])
    // setInterval(() => {
    //     if(items && items.length != 0){
    //         if(filters.page && filters.branch){
    //             dispatch(getBookings(filters))
    //         }
    //     }
    // }, 300000);


    if (items && items.length != 0) {
        return (
            <div style={{background: "#FFF"}}>

                <Table header={header} items={items} columns={columns} style={{overflowX: "scroll", marginBottom: "0px"}}>
                    {
                        items.map(item => {

                            let styleTableItem = false
                            if(item.new) styleTableItem = {background: "#fff0bf"}
                            if(item.status.key == "off_schedule") styleTableItem = {background: "#ffdddd"}

                            let colorPayment = "#000"
                            if(item.payment.status.key == "paid") colorPayment = "#0b9f2b"
                            if(item.payment.status.key == "nopaid") colorPayment = "#ff1c1c"

                            return (
                                <TableItem key={item.id} columns={columns} style={styleTableItem}>
                                    {item.new &&
                                        <TableItemNew />
                                    }
                                    <TableItemText text={item.id} style={{minWidth: "47px"}}/>
                                    <TableItemText text={item.time.create.date} description={item.time.create.time} style={{minWidth: "118px"}}/>
                                    <TableItemColorLabel text={item.excursion.name.find(item => item.default_language).text} description={`(${item.excursion.typeExcursion.name})`} color={item.excursion.color} style={{minWidth: "190px"}}/>
                                    <TableItemText text={item.time.start.date} style={{minWidth: "118px"}}/>
                                    <TableItemText text={`${item.time.start.time}-`} description={`${item.time.end.time}`} style={{minWidth: "76px"}}/>
                                    <TableItemText text={item.client.name} description={item.client.phone} style={{minWidth: "197px"}}/>
                                    <TableItemGuides guides={item.guides} style={{minWidth: "92px"}}/>
                                    <TableItemLogo link={item.language.logo} size={"small"} style={{minWidth: "79px", justifyContent: "center"}}/>
                                    <TableItemImg status={item.discountType == null ? false : true} link={discount} style={{minWidth: "75px"}}/>
                                    <TableItemImg status={item.comment ? true : false} callback={(e) => {e.preventDefault(); showGuestCommnet(item.id, item.comment)}} link={comment} style={{minWidth: "75px"}}/>
                                    <TableItemImg status={item.additionalOptions ? true : false}  link={checkMark} style={{minWidth: "75px"}}/>
                                    <TableItemText text={item.payment.price + ` (${item.payment_amount})`} description={item.payment.status.name} colorDescription={colorPayment} style={{minWidth: "130px"}}/>
                                    <TableItemColorText text={item.status.name} status_key={item.status.key} style={{minWidth: "130px"}}/>
                                    <TableItemText text={item.guests_count} style={{minWidth: "65px"}}/>
                                    <TabelItemComment callback={addCommentModal} bookingId={item.id} comment={item.additionalComment} style={{minWidth: "58px"}}/>
                                    <TableItemEdit type={item.type} id={item.id} style={{minWidth: "58px"}}/>
                                    <TableItemCheckbox type={item.type} id={item.id} callback={itemCheck} value={item.checked}/>
                                </TableItem>
                            )
                        })
                    }
                </Table>

                <div style={{display: "flex", justifyContent: "flex-end", padding: "10px"}}>
                    <Pagination currentPage={currentPage} allPage={allPage} callback={callbackDay}/>
                </div>

                <Modal active={modalComment.status} title={`Додатковий коментар (${modalComment.title})`} >
                    <textarea name="" id="" rows="3" value={modalComment.comment ? modalComment.comment : ""} onChange={onChangeAdditionalComment}/>
                    <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                        <button onClick={hideModal} style={{background: "#ECECEC"}}>
                            Закрити
                        </button>
                        <button onClick={saveAdditionalComment}>
                            Зберегти
                        </button>
                    </div>
                </Modal>
                <Modal active={modalGuestComment.status} title={`Коментар клієнта (Бронювання ${modalGuestComment.id})`} >
                    <textarea name="" id="" rows="3" value={modalGuestComment.comment ? modalGuestComment.comment : ""} readOnly={true}/>
                    <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                        <button onClick={hideModalGuest} style={{background: "#ECECEC"}}>
                            Закрити
                        </button>
                    </div>
                </Modal>

            </div>
        )
    } else if(Array.isArray(items) && items.length == 0) {
        return (
            <h3 style={{textAlign: "center", margin: "50px 0px"}}>
                Бронювань не знайдено
            </h3>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
}

export default TableBookings;