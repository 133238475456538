import React, {useEffect, useRef, useState} from 'react';
import PageLoader from "../../../UI/PageLoader/PageLoader";
import Page from "../../../UI/Page/Page";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {
    changeCurrentGuide,
    changeStateGuideSaveButton,
    editGuide,
    createGuide, toArchiveGuide
} from "../../../../redux/reducers/guidesReducer";
import Error from "../../../UI/Error/Error";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import Button from "../../../UI/Button";
import ChangeLogo from "../../../UI/ChangeLogo/ChangeLogo";
import {Controller} from "react-hook-form";
import Modal from "../../../UI/Modal/Modal";
import publish from "../../../../assets/images/publish.svg";
import InputMask from "react-input-mask";

const GuidesCreate = () => {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const {register, handleSubmit, control, formState: {errors}, setValue, clearErrors} = useForm()

    const guides = useSelector(state => state.guides.guides)
    const newGuide = useSelector(state => state.guides.newGuide)
    const data = useSelector(state => state.guides.currentGuide)
    const pageName = (data && data.name && data.surname && data.patronymic) ? data.name + " " + data.patronymic + " " + data.surname : "";
    const stateSaveButton = useSelector(state => state.guides.disabledButton)
    const formRef = useRef(null)
    const branchId = useSelector(state => state.account.branch)
    const redirectWorkSchedule = (e) => {
        e.preventDefault()
        navigate(`/workschedules/${data.id}`)
    }

    const [modalToArchive, changeModalToArchive] = useState(false)

    const showModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(true)
    }
    const hideModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(false)
    }
    const toArchive = (e) => {
        e.preventDefault()
        if(data.id){
            dispatch(toArchiveGuide(data.id))
        }
        changeModalToArchive(false)
    }
    const guidesSubmit = (dataForm) => {
        const formData = new FormData(formRef.current)
        if (data.id) {
            dataForm.id = data.id;
            formData.append("id", data.id)
            formData.append("branch", branchId)
            dispatch(editGuide(formData));
        } else {
            formData.append("branch", branchId)
            dispatch(createGuide(formData, navigate));
        }
    };

    useEffect(() => {
        if (params?.id) {
            let guideForUrl = guides.find(guide => guide.id == params.id);
            dispatch(changeCurrentGuide(guideForUrl));
        } else {

            dispatch(changeCurrentGuide(newGuide));
        }
        return () => {
            dispatch(changeCurrentGuide(false));
        }
    }, []);

    if (data) {
        return (
            <Page
                breadcrumbs={{link: '/guides', text: 'Гіди'}}
                pageName={pageName}
                rightButton={{
                    content: 'Зберегти', callback: () => {
                    }, icon: 'add', color: '', form: 'guides_form', disabled: stateSaveButton,
                }}
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>
                    <form id={"guides_form"} ref={formRef} onSubmit={handleSubmit(guidesSubmit)}>

                        <div>

                            <div className={"photo"} style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "20px"
                            }}>

                                <Controller
                                    key={"logo"}
                                    control={control}
                                    name={"logo"}
                                    defaultValue={data.photo}
                                    rules={{required: "Обов'язкове поле",}}
                                    render={({
                                                 field: {onChange, value, name},
                                                 fieldState: {invalid, error},
                                             }) => (
                                        <ChangeLogo
                                            error={error}
                                            link={value}
                                            onChange={() => {
                                                dispatch(changeStateGuideSaveButton(false))
                                            }}
                                            setValue={setValue}
                                            name={name}
                                            clearErrors={clearErrors}
                                        />

                                    )}
                                />

                                {/*{params?.id &&*/}
                                {/*    <Button icon={"calendar"} callBack={redirectWorkSchedule}>*/}
                                {/*        Графік гіда*/}
                                {/*    </Button>*/}
                                {/*}*/}

                            </div>

                            <div className={"name"}>
                                <h3 style={{marginTop: '0px'}}>Ім'я</h3>

                                <input
                                    type={"text"}
                                    className={errors?.name ? "error" : ""}
                                    {...register('name', {
                                        onChange: () => {
                                            dispatch(changeStateGuideSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data.name,
                                    })}
                                />

                                {errors?.name &&
                                    <Error> {errors?.name?.message} </Error>
                                }

                            </div>

                            <div className={"surname"}>
                                <h3 style={{marginTop: '0px'}}>Прізвище</h3>

                                <input
                                    type={"text"}
                                    className={errors?.surname ? "error" : ""}
                                    {...register('surname', {
                                        onChange: () => {
                                            dispatch(changeStateGuideSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data.surname,
                                    })}
                                />

                                {errors?.surname &&
                                    <Error> {errors?.surname?.message} </Error>
                                }

                            </div>

                            <div className={"patronymic"}>
                                <h3 style={{marginTop: '0px'}}>По-батькові</h3>

                                <input
                                    type={"text"}
                                    className={errors?.patronymic ? "error" : ""}
                                    {...register('patronymic', {
                                        onChange: () => {
                                            dispatch(changeStateGuideSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data.patronymic,
                                    })}
                                />

                                {errors?.patronymic &&
                                    <Error> {errors?.patronymic?.message} </Error>
                                }

                            </div>

                            <div className={"phone"}>
                                <h3 style={{marginTop: '0px'}}>Телефон</h3>

                                <input
                                    type={"tel"}
                                    className={errors?.phone ? "error" : ""}
                                    {...register('phone', {
                                        onChange: () => {
                                            dispatch(changeStateGuideSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data.phone,
                                        valueAsNumber: true,
                                    })}
                                />

                                {errors?.phone &&
                                    <Error> {errors?.phone?.message} </Error>
                                }

                            </div>


                            <div className={"email"}>
                                <h3 style={{marginTop: '0px'}}>Пошта</h3>

                                <input
                                    type={"email"}
                                    className={errors?.email ? "error" : ""}
                                    {...register('email', {
                                        onChange: () => {
                                            dispatch(changeStateGuideSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data.email,
                                    })}
                                />

                                {errors?.email &&
                                    <Error> {errors?.email?.message} </Error>
                                }

                            </div>

                            <div className={"basePrice"}>
                                <h3 style={{marginTop: '0px'}}>Базова вартість години (грн)</h3>

                                <input
                                    type={"number"}
                                    min={0}
                                    className={errors?.basePrice ? "error" : ""}
                                    onWheel={(e) => e.target.blur()}
                                    {...register('basePrice', {
                                        onChange: () => {
                                            dispatch(changeStateGuideSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data.basePrice,
                                        valueAsNumber: true,
                                        minLength: 0,
                                    })}
                                />

                                {errors?.basePrice &&
                                    <Error> {errors?.basePrice?.message} </Error>
                                }

                            </div>

                            <div className={"downTimePrice"}>
                                <h3 style={{marginTop: '0px'}}>Базова вартість години простою (грн)</h3>

                                <input
                                    type={"number"}
                                    min={0}
                                    className={errors?.downTimePrice ? "error" : ""}
                                    onWheel={(e) => e.target.blur()}
                                    {...register('downTimePrice', {
                                        onChange: () => {
                                            dispatch(changeStateGuideSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data.downTimePrice,
                                        valueAsNumber: true,
                                        minLength: 0,
                                    })}
                                />

                                {errors?.downTimePrice &&
                                    <Error> {errors?.downTimePrice?.message} </Error>
                                }

                            </div>

                            <div className={"surchargePrice"}>
                                <h3 style={{marginTop: '0px'}}>Доплата за нестандартний робочий час (%)</h3>

                                <input
                                    type={"number"}
                                    min={0}
                                    className={errors?.surchargePrice ? "error" : ""}
                                    onWheel={(e) => e.target.blur()}
                                    {...register('surchargePrice', {
                                        onChange: () => {
                                            dispatch(changeStateGuideSaveButton(false))
                                        },
                                        required: "Обов'язкове поле",
                                        value: data.surchargePrice,
                                        valueAsNumber: true,
                                        minLength: 0,
                                    })}
                                />

                                {errors?.surchargePrice &&
                                    <Error> {errors?.surchargePrice?.message} </Error>
                                }

                            </div>

                            {params?.id &&
                                <ButtonHalfTransparent onClick={showModalToArchive}>
                                    {data.deleted === false &&
                                        <>
                                            <ReactSVG src={deleteSVG}/>
                                            В архів
                                        </>
                                    }
                                    {data.deleted === true &&
                                        <>
                                            <ReactSVG src={publish}/>
                                            Опублікувати
                                        </>
                                    }
                                </ButtonHalfTransparent>
                            }

                        </div>

                        <div/>

                        <div/>

                    </form>
                </div>
                {params?.id &&
                    <Modal active={modalToArchive} title={data.deleted === false ? "Відправити в архів" : "Опублікувати"} type={"error"}>

                        <div>
                            {data.deleted === false &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете відправити гіда в архів?
                                </p>
                            }
                            {data.deleted === true &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете опублікувати гіда?
                                </p>
                            }
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px"
                        }}>
                            <button
                                style={{background: "#eee"}}
                                onClick={hideModalToArchive}
                            >
                                Скасувати
                            </button>
                            <button onClick={toArchive}>
                                {data.deleted === false &&
                                    <>
                                        В архів
                                    </>
                                }
                                {data.deleted === true &&
                                    <>
                                        Опублікувати
                                    </>
                                }
                            </button>
                        </div>

                    </Modal>
                }
            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
};

export default GuidesCreate;