import React, {useState} from 'react'
import Page from "../../UI/Page/Page";
import TableCertificates from "../../UI/Table/TableCertificates";
import {useNavigate} from "react-router-dom";
import FiltersCertificate from "../../UI/Filters/FiltersCertificate";
import Button from "../../UI/Button";

const Certificates = () => {
    const navigate = useNavigate()
    const [visible, changeVisible] = useState(true)

    const changeVisibleCallback = (e) => {
        e.preventDefault()
        changeVisible(visible ? false : true)
    }
    return (
        <Page
            pageName={"Сертифікати"}
            rightRest={
                <Button icon={"switchIcon"} callBack={changeVisibleCallback} color={"#d7d7d7"}>
                    {visible === true &&
                        <>Архів</>
                    }
                    {visible === false &&
                        <>Опубліковані</>
                    }
                </Button>
            }
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/certificates/create')
                }, icon: 'add', color: ''
            }}
        >
            <FiltersCertificate/>
            <TableCertificates visible={visible}/>
        </Page>
    )
}

export default Certificates