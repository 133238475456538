import React, {useEffect, useState} from "react";
import LanguageInputs from "../../../UI/LanguageFields/LanguageInputs/LanguageInputs";
import Page from "../../../UI/Page/Page";
import {useForm} from "react-hook-form";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import Button from "../../../UI/Button";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import {
    addVariation,
    changeCurrentOption,
    createOption,
    deleteVariation, editOption,
    changeStatePromocodSaveButton, changeStateOptionSaveButton, toArchiveOption
} from "../../../../redux/reducers/additionalOptionsReducer";
import Modal from "../../../UI/Modal/Modal";
import publish from "../../../../assets/images/publish.svg";

const AdditionalOptionsCreate = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const {register, handleSubmit, control, setValue, getValues, formState:{errors}} = useForm();
    const options = useSelector(state => state.options.options);
    const newOption = useSelector(state => state.options.newOption);
    const stateSaveButton = useSelector(state => state.options.disabledButton);
    let data = useSelector(state => state.options.currentOption);
    let pageName = (data && data.name && data.name.length !== 0) ? data.name.find(name => name.default_language === true).text : "";

    const [modalToArchive, changeModalToArchive] = useState(false)

    const showModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(true)
    }
    const hideModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(false)
    }
    const toArchive = (e) => {
        e.preventDefault()
        if(data.id){
            dispatch(toArchiveOption(data.id))
        }
        changeModalToArchive(false)
    }
    let additionalOptionsSubmit = (dataForm) => {
        if(data.id){
            dataForm.id = data.id;
            dispatch(editOption(dataForm));
        } else {
            dispatch(createOption(dataForm, navigate));
        }
    }
    let deleteVariationCallback = (id) => {
        let objVariations = getValues(`variations`);
        delete objVariations[`item_${id}`];
        setValue(`variations`, objVariations);
        dispatch(deleteVariation(id))
    }

    useEffect(() => {
        if (params?.id) {
            let optionForUrl = options.find(option => option.id == params.id);
            dispatch(changeCurrentOption(optionForUrl));
        } else {
            dispatch(changeCurrentOption(newOption));
        }
        return () => {
            dispatch(changeCurrentOption(false));
        }
    }, []);

    if(data) {

        return (
            <Page
                breadcrumbs={{link: '/additionaloptions', text: 'Додаткові опції'}}
                pageName={pageName}
                rightButton={{
                    content: 'Зберегти ',
                    callback: () => {},
                    icon: 'add',
                    color: '',
                    form: 'additional_form',
                    disabled: stateSaveButton,
                }}
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>

                    <form id={"additional_form"} onSubmit={handleSubmit(additionalOptionsSubmit)}>

                        <div>

                            <LanguageInputs
                                keyIndex={"name"}
                                label={"Опція"}
                                fields={data.name}
                                control={control}
                                changeButton={() => { dispatch(changeStateOptionSaveButton(false)) }}
                            />

                            <div>
                                <h3 style={{marginTop: '0px'}}>Вартість</h3>

                                <input
                                    type={"number"}
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    {...register("price", {
                                        onChange: () => {
                                            dispatch(changeStateOptionSaveButton(false))
                                        },
                                        minLength: 0,
                                        value: data?.price,
                                        valueAsNumber: true
                                    })}
                                />
                            </div>

                            {params?.id &&
                                <ButtonHalfTransparent onClick={showModalToArchive}>
                                    {data.deleted === false &&
                                        <>
                                            <ReactSVG src={deleteSVG}/>
                                            В архів
                                        </>
                                    }
                                    {data.deleted === true &&
                                        <>
                                            <ReactSVG src={publish}/>
                                            Опублікувати
                                        </>
                                    }
                                </ButtonHalfTransparent>
                            }

                        </div>

                        <div>

                        </div>

                        <div></div>

                    </form>

                </div>
                {params?.id &&
                    <Modal active={modalToArchive} title={data.deleted === false ? "Відправити в архів" : "Опублікувати"} type={"error"}>

                        <div>
                            {data.deleted === false &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете відправити опцію в архів?
                                </p>
                            }
                            {data.deleted === true &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете опублікувати опцію?
                                </p>
                            }
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px"
                        }}>
                            <button
                                style={{background: "#eee"}}
                                onClick={hideModalToArchive}
                            >
                                Скасувати
                            </button>
                            <button onClick={toArchive}>
                                {data.deleted === false &&
                                    <>
                                        В архів
                                    </>
                                }
                                {data.deleted === true &&
                                    <>
                                        Опублікувати
                                    </>
                                }
                            </button>
                        </div>

                    </Modal>
                }
            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
}

export default AdditionalOptionsCreate;