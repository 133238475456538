import KeysApi from "../../api/KeysApi";

const SET_PRIVATE = "SET_PRIVATE";
const SET_PUBLIC = "SET_PUBLIC";
const CHANGE_STATE_KEYS_SAVE_BUTTON = "CHANGE_STATE_KEYS_SAVE_BUTTON";

const defaultState = {
    privateKey: null,
    publicKey: null,
    stateKeysSaveButton: true,
}

export const keysReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_PRIVATE:
            return {...state, privateKey: action.key}
        case SET_PUBLIC:
            return {...state, publicKey: action.key}
        case CHANGE_STATE_KEYS_SAVE_BUTTON:
            return {...state, stateKeysSaveButton: action.value}
        default:
            return state;
    }
}


export const setPrivate = (key) => ({type: SET_PRIVATE, key});
export const setPublic = (key) => ({type: SET_PUBLIC, key});
export const changeStateKeysSaveButton = (value) => ({type: CHANGE_STATE_KEYS_SAVE_BUTTON, value});


export const getPrivateKey = () => async (dispatch) => {
    await KeysApi.getPrivateKey().then(response => {
        dispatch(setPrivate(response.data))
    })
}

export const setPrivateKey = (value) => async (dispatch) => {
    dispatch(changeStateKeysSaveButton(true))
    await KeysApi.setPrivateKey(value).then(response => {
    })
}

export const getPublicKey = () => async (dispatch) => {
    await KeysApi.getPublicKey().then(response => {
        dispatch(setPublic(response.data))
    })
}

export const setPublicKey = (value) => async (dispatch) => {
    dispatch(changeStateKeysSaveButton(true))
    await KeysApi.setPublicKey(value).then(response => {
    })
}