import React, {useRef, useState} from 'react';
import classes from "./ChangeLogo.module.scss";
import {useDispatch} from "react-redux";
import {deletePhoto} from "../../../redux/reducers/guidesReducer";
import error from "../Error/Error";

const ChangeLogo = ({link, error, setValue, name, onChange, clearErrors}) => {
    const dispatch = useDispatch()
    const refFile = useRef()
    const [stateLogo, changeLogo] = useState(link)


    const clickFile = (e) => {
        e.preventDefault()
        refFile.current.click()
    }
    const readFile = (e) => {
        let file = e.currentTarget.files[0];
        let reader = new FileReader();

        onChange()

        reader.readAsDataURL(file);

        reader.onload = function() {
            changeLogo(reader.result)
            setValue(name, reader.result)
            clearErrors(name)
        };

        reader.onerror = function() {
            console.log(reader.error);
        };

    }
    const callbackDeletePhoto = (e) => {
        e.preventDefault()
        // dispatch(deletePhoto())
        changeLogo(null)
        refFile.current.value = null
        setValue(name, "")
    }

    return (
        <div className={classes.container}>

            { stateLogo &&
                <img className={classes.logo} src={stateLogo} />
            }

            { stateLogo == null &&
                <div className={classes.not_image + " " + (error ? classes.error : "") }></div>
            }

            <div className={classes.row}>
                <button className={classes.edit} onClick={clickFile}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19 3.17139C18.7599 3.17139 18.5222 3.21868 18.3003 3.31057C18.0785 3.40245 17.8769 3.53714 17.7071 3.70692L4.39491 17.0191L3.42524 20.5746L6.9807 19.6049L20.2929 6.29271C20.4627 6.12292 20.5974 5.92136 20.6893 5.69952C20.7812 5.47769 20.8284 5.23993 20.8284 4.99981C20.8284 4.7597 20.7812 4.52194 20.6893 4.30011C20.5974 4.07827 20.4627 3.87671 20.2929 3.70692C20.1231 3.53714 19.9216 3.40245 19.6997 3.31057C19.4779 3.21868 19.2401 3.17139 19 3.17139ZM17.5349 1.46281C17.9994 1.27041 18.4973 1.17139 19 1.17139C19.5028 1.17139 20.0006 1.27041 20.4651 1.46281C20.9296 1.6552 21.3516 1.9372 21.7071 2.29271C22.0626 2.64821 22.3446 3.07025 22.537 3.53474C22.7294 3.99923 22.8284 4.49706 22.8284 4.99981C22.8284 5.50257 22.7294 6.0004 22.537 6.46489C22.3446 6.92938 22.0626 7.35142 21.7071 7.70692L8.20713 21.2069C8.08407 21.33 7.93104 21.4188 7.76314 21.4646L2.26314 22.9646C1.91693 23.059 1.54667 22.9607 1.29292 22.7069C1.03917 22.4532 0.940838 22.0829 1.03526 21.7367L2.53526 16.2367C2.58105 16.0688 2.66986 15.9158 2.79292 15.7927L16.2929 2.29271C16.6484 1.9372 17.0705 1.6552 17.5349 1.46281Z" fill="#B5B5B5"/></svg>
                </button>

                <button className={classes.delete} onClick={callbackDeletePhoto}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 19C6.00158 19.5299 6.2128 20.0377 6.58753 20.4125C6.96227 20.7872 7.47005 20.9984 8 21H16C16.5299 20.9984 17.0377 20.7872 17.4125 20.4125C17.7872 20.0377 17.9984 19.5299 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="#B5B5B5"/></svg>
                </button>
            </div>

            <input
                name={"logo"}
                ref={refFile}
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={readFile}
                style={{
                    display: "none",
                }}
            />


        </div>
    );
};

export default ChangeLogo;