import axios from "axios";

export default class CalendarApi {

    static async getTable(data) {
        const response = await axios.post('https://api.0300.com.ua/api/calendar/gettable', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }
    static async updateGuides(data) {
        const response = await axios.post('https://api.0300.com.ua/api/calendar/setguides', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }



}