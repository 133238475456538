import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Page from "../../../UI/Page/Page";
import LanguageInputs from "../../../UI/LanguageFields/LanguageInputs/LanguageInputs";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import publish from "../../../../assets/images/publish.svg";
import SelectItem from "../../../UI/SelectItem/SelectItem";
import excursions from "../../Excursions/Excursions";
import Button from "../../../UI/Button";
import Modal from "../../../UI/Modal/Modal";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCurrentPromocod,
    changeExcursionsForCurrentPromocod,
    createPromocod, editPromocod,
    changeStatePromocodSaveButton, toArchivePromocode
} from "../../../../redux/reducers/promocodesReducer";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import Error from "../../../UI/Error/Error";

const PromoCodesCreate = () => {
    const excursions = useSelector(state => state.excursions.excursions);

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const {register, handleSubmit, control, setValue, setError} = useForm();
    const [modalActive, changeModalActive] = useState(false);
    const promocodes = useSelector(state => state.promocodes.promocodes);
    const newPromocod = useSelector(state => state.promocodes.newPromocod);
    const stateSaveButton = useSelector(state => state.promocodes.disabledButton);
    let data = useSelector(state => state.promocodes.currentPromocod);
    let [stateExcursions, changeExcursions] = useState([]);

    const [modalToArchive, changeModalToArchive] = useState(false)

    const showModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(true)
    }
    const hideModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(false)
    }
    const toArchive = (e) => {
        e.preventDefault()
        if(data.id){
            dispatch(toArchivePromocode(data.id))
        }
        changeModalToArchive(false)
    }
    let promoCodesSubmit = (dataForm) => {
        let timeDataStart = dataForm.start.split("-");
        let timeDataEnd = dataForm.end.split("-");

        dataForm.start = timeDataStart[2] + "." + timeDataStart[1] + "." + timeDataStart[0];
        dataForm.end = timeDataEnd[2] + "." + timeDataEnd[1] + "." + timeDataEnd[0];

        if (data.id) {
            dataForm.id = data.id;
        }

        let errorDataForm = false

        if (
            dataForm.code == false ||
            dataForm.code == null ||
            dataForm.code < 100000 ||
            dataForm.code > 999999 ||
            isNaN(dataForm.code)
        ) {
            errorDataForm = true
            setError('code', {type: 'required', message: "Промокод має мати 6 цифр без 0"});
        }
        if (promocodes.find(item => item.code == dataForm.code && item.id !== dataForm.id)) {
            errorDataForm = true
            setError('code', {type: 'required', message: "Такий промокод вже існує"});
        }
        if (
            dataForm.applicationLimit == false ||
            dataForm.applicationLimit == null ||
            dataForm.applicationLimit < 1 ||
            dataForm.applicationLimit > 10000 ||
            isNaN(dataForm.applicationLimit)
        ) {
            errorDataForm = true
            setError('applicationLimit', {type: 'required', message: "Від 1 до 10000"});
        }
        if (
            dataForm.percent == false ||
            dataForm.percent == null ||
            dataForm.percent < 1 ||
            dataForm.percent > 100 ||
            isNaN(dataForm.percent)
        ) {
            errorDataForm = true
            setError('percent', {type: 'required', message: "Від 1% до 100%"});
        }

        if (errorDataForm) {
            return false
        }

        if(dataForm.excursions == undefined || dataForm.excursions == null) {
            dataForm.excursions = [];
        }
        //
        // console.log(dataForm)
        if (data.id) {
            dispatch(editPromocod(dataForm));
        } else {
            dispatch(createPromocod(dataForm, navigate));
        }

    };
    let deleteExcursion = (id) => {
        if (stateExcursions.find(item => item.id == id)) {
            let newStateExcursions = stateExcursions.filter(item => item.id !== id);

            changeExcursions(newStateExcursions)
            dispatch(changeExcursionsForCurrentPromocod(newStateExcursions));
            setValue('excursions', []);

            for (let i = 0; i < newStateExcursions.length; i++) {
                let excursion = newStateExcursions[i];

                setValue(`excursions.${i}`, {
                    id: excursion.id,
                    value: true,
                })
            }
        }
    }

    useEffect(() => {
        if (params?.id) {
            let promocodForUrl = promocodes.find(promocod => promocod.id == params.id);
            dispatch(changeCurrentPromocod(promocodForUrl));
        } else {
            dispatch(changeCurrentPromocod(newPromocod));
        }
        return () => {
            dispatch(changeCurrentPromocod(false));
        }
    }, []);
    useEffect(() => {
        if (data && data.excursion) {
            changeExcursions(data.excursion)
        }
    }, [data])

    if (data) {
        let pageName = (data && data.name && data.name.length !== 0) ? data.name.find(name => name.default_language === true).text : "";
        let timeStartArr = data.time.start.date.split(".");
        let timeStartInput = timeStartArr[2] + "-" + timeStartArr[1] + "-" + timeStartArr[0];
        let timeEndArr = data.time.end.date.split(".");
        let timeEndInput = timeEndArr[2] + "-" + timeEndArr[1] + "-" + timeEndArr[0];
        setValue('id', data.id);

        return (
            <Page
                breadcrumbs={{link: '/promocodes', text: 'Промокоди'}}
                pageName={pageName}
                rightButton={{
                    content: 'Зберегти',
                    callback: () => {
                    },
                    icon: 'add',
                    color: '',
                    form: 'promocodes_form',
                    disabled: stateSaveButton,
                }}
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>

                    <form id={"promocodes_form"} onSubmit={handleSubmit(promoCodesSubmit)}>

                        <div>

                            <div>

                                <LanguageInputs
                                    keyIndex={"name"}
                                    label={"Промокод"}
                                    fields={data.name}
                                    control={control}
                                    changeButton={() => { dispatch(changeStatePromocodSaveButton(false)) }}
                                />

                                <div>
                                    <h3 style={{marginTop: '0px'}}>Код</h3>

                                    <Controller
                                        control={control}
                                        name={"code"}
                                        defaultValue={Number(data.code)}
                                        rules={{
                                            required: "Обов'язкове поле",
                                            valueAsNumber: true,
                                        }}
                                        render={({
                                                     field: {onChange, value, name},
                                                     fieldState: {invalid, error},
                                                 }) => (
                                            <div>
                                                <input
                                                    type={"number"}
                                                    name={name}
                                                    value={Number(value)}
                                                    className={error ? "error" : ""}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => {
                                                        onChange(Number(e.currentTarget.value))
                                                        dispatch(changeStatePromocodSaveButton(false))
                                                    }}
                                                />

                                                {error &&
                                                    <Error> {error?.message} </Error>
                                                }

                                            </div>
                                        )
                                        }
                                    />

                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name={"start"}
                                        defaultValue={timeStartInput == "0000-00-00" ? false : timeStartInput}
                                        rules={{ required: "Обов'язкове поле",}}
                                        render={({
                                                     field: {onChange, value, name, ref},
                                                     fieldState: {invalid, isTouched, isDirty, error},
                                                     formState,
                                                 }) => (
                                            <div>
                                                <label style={{marginBottom: "15px"}} htmlFor={"start"}>Термін дії з</label>

                                                <input
                                                    onChange = {
                                                        (e) => {
                                                            onChange(e);
                                                            dispatch(changeStatePromocodSaveButton(false));
                                                        }

                                                    }
                                                    className={error ? "error" : ""}
                                                    id={"start"}
                                                    name={"start"}
                                                    type="date"
                                                    value={value}
                                                />

                                                {error &&
                                                    <Error> {error?.message} </Error>
                                                }

                                            </div>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name={"end"}
                                        defaultValue={timeEndInput == "0000-00-00" ? false : timeEndInput}
                                        rules={{ required: "Обов'язкове поле",}}
                                        render={({
                                                     field: {onChange, value, name, ref},
                                                     fieldState: {invalid, isTouched, isDirty, error},
                                                     formState,
                                                 }) => (
                                            <div>
                                                <label style={{marginBottom: "15px"}} htmlFor={"end"}>Термін дії
                                                    до</label>

                                                <input
                                                    onChange = {
                                                        (e) => {
                                                            onChange(e);
                                                            dispatch(changeStatePromocodSaveButton(false));
                                                        }

                                                    }
                                                    className={error ? "error" : ""}
                                                    id={"end"}
                                                    name={"end"}
                                                    type="date"
                                                    value={value}
                                                />

                                                {error &&
                                                    <Error> {error?.message} </Error>
                                                }

                                            </div>
                                        )}
                                    />
                                </div>

                                <div>
                                    <h3 style={{marginTop: '0px'}}>Ліміт на застосування</h3>

                                    <Controller
                                        control={control}
                                        name={"applicationLimit"}
                                        defaultValue={Number(data.applicationLimit)}
                                        rules={{
                                            required: "Обов'язкове поле",
                                            valueAsNumber: true,
                                        }}
                                        render={({
                                                     field: {onChange, value, name},
                                                     fieldState: {invalid, error},
                                                 }) => (
                                            <div>
                                                <input
                                                    type={"number"}
                                                    name={name}
                                                    value={Number(value)}
                                                    className={error ? "error" : ""}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => {
                                                        onChange(Number(e.currentTarget.value))
                                                        dispatch(changeStatePromocodSaveButton(false))
                                                    }}
                                                />

                                                {error &&
                                                    <Error> {error?.message} </Error>
                                                }

                                            </div>
                                        )
                                        }
                                    />

                                </div>

                                <div>
                                    <h3 style={{marginTop: '0px'}}>%</h3>

                                    <Controller
                                        control={control}
                                        name={"percent"}
                                        defaultValue={Number(data.percent)}
                                        rules={{
                                            required: "Обов'язкове поле",
                                            valueAsNumber: true,
                                        }}
                                        render={({
                                                     field: {onChange, value, name},
                                                     fieldState: {invalid, error},
                                                 }) => (
                                            <div>
                                                <input
                                                    type={"number"}
                                                    name={name}
                                                    value={Number(value)}
                                                    className={error ? "error" : ""}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => {
                                                        onChange(Number(e.currentTarget.value))
                                                        dispatch(changeStatePromocodSaveButton(false))
                                                    }}
                                                />

                                                {error &&
                                                    <Error> {error?.message} </Error>
                                                }

                                            </div>
                                        )
                                        }
                                    />

                                </div>

                                {params?.id &&
                                    <ButtonHalfTransparent onClick={showModalToArchive}>
                                        {data.deleted === false &&
                                            <>
                                                <ReactSVG src={deleteSVG}/>
                                                В архів
                                            </>
                                        }
                                        {data.deleted === true &&
                                            <>
                                                <ReactSVG src={publish}/>
                                                Опублікувати
                                            </>
                                        }
                                    </ButtonHalfTransparent>
                                }

                            </div>

                        </div>

                        <div>
                            <h3 style={{marginTop: '0px'}}>Екскурсії</h3>
                            {
                                data.excursion.map((excursion, index) => {

                                    return (
                                        <Controller
                                            key={`excursion__${excursion.id}`}
                                            control={control}
                                            name={`excursions.${index}`}
                                            defaultValue={{
                                                id: excursion.id,
                                                value: true
                                            }}
                                            render={({field: {onChange, value, name}}) => (
                                                <SelectItem
                                                    key={`excursion-item_${excursion.id}`}
                                                    onClick={() => {
                                                        deleteExcursion(excursion.id);
                                                        dispatch(changeStatePromocodSaveButton(false));
                                                    }}
                                                >
                                                    {excursion.name.find(item => item.default_language == true).text} ({excursion.typeExcursion.name})
                                                </SelectItem>
                                            )
                                            }
                                        />

                                    )

                                })
                            }

                            <Button icon={'add'} callBack={(e) => {
                                e.preventDefault();
                                changeModalActive(true)
                            }}>Додати</Button>

                        </div>

                        <div></div>

                        <Modal active={modalActive} title={'Екскурсії'}>
                            <div>
                                {
                                    excursions.map(excursion => {
                                        let item = excursion.name.find(lang => lang.default_language == true);
                                        let text = item.text;
                                        return (
                                            <div
                                                key={`excursion__item__${excursion.id}`}
                                                className={'row'}
                                                style={{
                                                    justifyContent: "space-between",
                                                    padding: "9px 16px",
                                                    border: "1px solid #ECECEC",
                                                    borderRadius: "6px"
                                                }}>
                                                <label htmlFor={`excursion_${excursion.id}`}
                                                       style={{fontWeight: 400}}>{text}</label>
                                                <input
                                                    id={`excursion_${excursion.id}`}
                                                    type={"checkbox"}
                                                    onChange={() => {
                                                        if (stateExcursions.find(item => item.id == excursion.id)) {
                                                            changeExcursions(
                                                                stateExcursions.filter(item => item.id !== excursion.id)
                                                            )
                                                        } else {
                                                            changeExcursions([...stateExcursions, excursion])
                                                        }
                                                    }}
                                                    checked={stateExcursions.find(item => item.id == excursion.id) ? true : false}
                                                />
                                            </div>

                                        )
                                    })
                                }
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px"
                            }}>
                                <Button
                                    color={"#ECECEC"}
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeModalActive(false);
                                    }}
                                >
                                    Скасувати
                                </Button>
                                <Button
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeModalActive(false)
                                        dispatch(changeExcursionsForCurrentPromocod(stateExcursions));
                                        setValue('excursions', []);

                                        for (let i = 0; i < stateExcursions.length; i++) {
                                            let excursion = stateExcursions[i];

                                            setValue(`excursions.${i}`, {
                                                id: excursion.id,
                                                value: true,
                                            })
                                        }

                                        dispatch(changeStatePromocodSaveButton(false));
                                    }}
                                >
                                    Додати
                                </Button>
                            </div>

                        </Modal>

                    </form>

                </div>
                {params?.id &&
                    <Modal active={modalToArchive} title={data.deleted === false ? "Відправити в архів" : "Опублікувати"} type={"error"}>

                        <div>
                            {data.deleted === false &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете відправити промокод в архів?
                                </p>
                            }
                            {data.deleted === true &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете опублікувати промокод?
                                </p>
                            }
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px"
                        }}>
                            <button
                                style={{background: "#eee"}}
                                onClick={hideModalToArchive}
                            >
                                Скасувати
                            </button>
                            <button onClick={toArchive}>
                                {data.deleted === false &&
                                    <>
                                        В архів
                                    </>
                                }
                                {data.deleted === true &&
                                    <>
                                        Опублікувати
                                    </>
                                }
                            </button>
                        </div>

                    </Modal>
                }
            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }

}

export default PromoCodesCreate;
