import React, {useEffect, useState} from "react";
import classes from "./ToggleCheckbox.module.scss";
import {changeActiveDay, changeDayStatus} from "../../../redux/reducers/timeTableExcursionReducer";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

const ToggleCheckbox = ({checked, name, callBack}) => {

    const changeActive = (e) => {
        let value = e.target.checked;
        callBack(value)
    }

    return(
        <input
            type="checkbox"
            name={name}
            className={classes.input}
            onChange={changeActive}
            checked={checked}/>
    )
}

export default ToggleCheckbox