import axios from "axios";

export default class TranslateApi {

    static async getAll(data) {
        const response = await axios.post('https://api.0300.com.ua/api/translates/getall', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async create(data) {
        const response = await axios.post('https://api.0300.com.ua/api/translates/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async edit(data) {
        const response = await axios.post('https://api.0300.com.ua/api/translates/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        })

        return response;
    }

    static async delete(data) {
        const response = await axios.post('https://api.0300.com.ua/api/translates/delete', {
            token: localStorage.getItem('auth_token'),
            id: data
        })

        return response;
    }

}