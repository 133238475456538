import axios from "axios";

export default class ExcursionsApi {

    static async getAll(token, branch) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/getall', {
            token,
            branch
        })

        return response;
    }

    static async put(data) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async patch(data) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async setActiveStatus(status, id) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/setactivestatus', {
            token: localStorage.getItem('auth_token'),
            status,
            id
        });

        return response
    }

    static async getMonth(excursionId, year, month, bookingId = null) {
        const response = await axios.post('https://api.0300.com.ua/api/excursions/getmonth', {
            token: localStorage.getItem('auth_token'),
            excursionId,
            year,
            month,
            bookingId,
        });

        return response
    }

}