import axios from "axios";

export default class bookingsApi {

    static async getAll(token, data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/getall', {
            token: token,
            ...data
        })

        return response;
    }

    static async put(data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async setAdditionalComment(data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/setcomment', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async cancelBooking(data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/cancelbooking', {
            token: localStorage.getItem('auth_token'),
            id: data
        });

        return response
    }
    static async invoiceBooking(data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/sendinvoice', {
            token: localStorage.getItem('auth_token'),
            id: data
        });

        return response
    }

    static async deleteBooking(data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/delete', {
            token: localStorage.getItem('auth_token'),
            bookingId: data
        });

        return response
    }

    static async patch(data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async get(data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/get', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }
    
    static async delete(data) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/delete', {
            token: localStorage.getItem('auth_token'),
            ...data
        });
    
        return response;
    }

    static async setnewfalse(id) {
        const response = await axios.post('https://api.0300.com.ua/api/bookings/setnewfalse', {
            token: localStorage.getItem('auth_token'),    
            id
        });
    
        return response;
    }


}