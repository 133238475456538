import AdditionalOptionsApi from "../../api/AdditionalOptionsApi";

const CHANGE_CURRENT_OPTION = "CHANGE_CURRENT_OPTION";
const CLEAR_CURRENT_OPTION = "CLEAR_CURRENT_OPTION";
const ADD_OPTION = "ADD_OPTION";
const SET_OPTIONS = "SET_OPTIONS";
const UPDATE_OPTIONS = "UPDATE_OPTIONS";
const ADD_VARIATION = "ADD_VARIATION";
const DELETE_VARIATION = "DELETE_VARIATION";
const CHANGE_STATE_OPTION_SAVE_BUTTON = "CHANGE_STATE_OPTION_SAVE_BUTTON";

const defaultState = {
    options: [],
    currentOption: false,
    newOption: {
        type: "additionaloptions",
        id: "",
        name: [],
        variations: []
    },
    disabledButton: true,
}

export const optionsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_OPTIONS:
            return {
                ...state,
                options: action.options,
            }
        case CHANGE_CURRENT_OPTION:
            return {...state, currentOption: action.option}
        case CLEAR_CURRENT_OPTION:
            return {...state, currentOption: false}
        case ADD_OPTION:
            return {
                ...state,
                options: [
                    ...state.options,
                    action.option
                ]
            }
        case UPDATE_OPTIONS:
            return {
                ...state,
                options: state.options.map(option => {
                    if (option.id == action.option.id) {
                        return action.option;
                    }
                    return option;
                })
            }
        case ADD_VARIATION:
            return {
                ...state,
                currentOption: {
                    ...state.currentOption,
                    variations: [
                        ...state.currentOption.variations,
                        {
                            id: Date.now(),
                            price: 0,
                            name: [
                                {
                                    id: 1,
                                    text: false,
                                    default_language: true,
                                },
                                {
                                    id: 2,
                                    text: false,
                                },
                                {
                                    id: 3,
                                    text: false,
                                },
                            ],
                        }
                    ]
                },
            }
        case DELETE_VARIATION:
            let updateVariations = [...state.currentOption.variations].filter(variation => variation.id !== action.id);

            return {
                ...state,
                currentOption: {
                    ...state.currentOption,
                    variations: updateVariations,
                },
            }
        case CHANGE_STATE_OPTION_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        default:
            return state;
    }
}

export const setOptions = (options) => ({type: SET_OPTIONS, options});
export const changeCurrentOption = (option) => ({type: CHANGE_CURRENT_OPTION, option});
export const clearCurrentOption = () => ({type: CLEAR_CURRENT_OPTION});
export const addOption = (option) => ({type: ADD_OPTION, option});
export const updateOptions = (option) => ({type: UPDATE_OPTIONS, option});
export const addVariation = () => ({type: ADD_VARIATION});
export const deleteVariation = (id) => ({type: DELETE_VARIATION, id});
export const changeStateOptionSaveButton = (value) => ({type: CHANGE_STATE_OPTION_SAVE_BUTTON, value})

export const createOption = (data, navigate) => async (dispatch) => {
    dispatch(changeStateOptionSaveButton(true))
    await AdditionalOptionsApi.put(data).then(response => {
        dispatch(addOption(response.data));
        dispatch(clearCurrentOption());
        navigate(`/additionaloptions/${response.data.id}`)

    });
}
export const editOption = (data) => async (dispatch) => {
    dispatch(changeStateOptionSaveButton(true))
    await AdditionalOptionsApi.patch(data).then(response => {
        dispatch(changeCurrentOption(response.data));
        dispatch(updateOptions(response.data));

    });
}
export const getOptions = () => async (dispatch) => {
    await AdditionalOptionsApi.getAll(localStorage.getItem('auth_token')).then(response => {
        dispatch(setOptions(response.data))
    })
}
export const toArchiveOption = (data) => async (dispatch) => {
    dispatch(changeStateOptionSaveButton(true))
    await AdditionalOptionsApi.deleteOption(data).then(response => {
        dispatch(changeCurrentOption(response.data));
        dispatch(updateOptions(response.data));
    })
}