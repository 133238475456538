import axios from "axios";

export default class GroupApi {

    static async getAll(token, branch) {
        const response = await axios.post('https://api.0300.com.ua/api/group/getall', {
            token,
            branch_id: branch
        })

        return response;
    }

    static async put(data) {
        const response = await axios.post('https://api.0300.com.ua/api/group/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async patch(data) {
        const response = await axios.post('https://api.0300.com.ua/api/group/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async deleteGroup(data) {
        const response = await axios.post('https://api.0300.com.ua/api/group/delete', {
            token: localStorage.getItem('auth_token'),
            id: data
        });

        return response
    }

}