import WorkTableApi from "../../api/WorkTableApi";

const SET_WORK_TABLE = "SET_WORK_TABLE";
const SET_PREV_WORK_TABLE = "SET_PREV_WORK_TABLE";
const SET_NEXT_WORK_TABLE = "SET_NEXT_WORK_TABLE";
const CHANGE_STATE_WORK_TABLE_SAVE_BUTTON = "CHANGE_STATE_WORK_TABLE_SAVE_BUTTON";
const CHANGE_GUIDE_TABLE = "CHANGE_GUIDE_TABLE"
const CHANGE_DATE = "CHANGE_DATE"

const defaultState = {
    prevWorkTable: null,
    workTable: null,
    nextWorkTable: null,
    date: {
        month: null,
        year: null,
    },
    disabledButton: true,
}

export const workTableReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_WORK_TABLE:
            return {
                ...state,
                workTable: action.table,
            }
        case SET_PREV_WORK_TABLE:
            return {
                ...state,
                prevWorkTable: action.table,
            }
        case SET_NEXT_WORK_TABLE:
            return {
                ...state,
                nextWorkTable: action.table,
            }
        case CHANGE_GUIDE_TABLE:
            return {
                ...state,
                workTable: [...state.workTable].map(day => {
                    if(day.date.day == action.day.date.day && day.date.month == action.day.date.month && day.date.year == action.day.date.year){
                        return action.day
                    } else {
                        return day
                    }
                }),
            }
        case CHANGE_STATE_WORK_TABLE_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        case CHANGE_DATE:
            return {
                ...state,
                date: action.date,
            }

        default:
            return state;
    }
}

export const setWorkTable= (table) => ({type: SET_WORK_TABLE, table});
export const setPrevWorkTable= (table) => ({type: SET_PREV_WORK_TABLE, table});
export const setNextWorkTable= (table) => ({type: SET_NEXT_WORK_TABLE, table});
export const changeStateWorkTableSaveButton = (value) => ({type: CHANGE_STATE_WORK_TABLE_SAVE_BUTTON, value})
export const changeGuideTable = (day) => ({type: CHANGE_GUIDE_TABLE, day})
export const changeDate = (date) => ({type: CHANGE_DATE, date})


export const getWorkTable = (data) => async (dispatch) => {
    await WorkTableApi.getWorkTable(data).then(response => {
        dispatch(setWorkTable(response.data))
    });
}
export const getPrevWorkTable = (data) => async (dispatch) => {
    await WorkTableApi.getWorkTable(data).then(response => {
        dispatch(setPrevWorkTable(response.data))
    });
}
export const getNextWorkTable = (data) => async (dispatch) => {
    await WorkTableApi.getWorkTable(data).then(response => {
        dispatch(setNextWorkTable(response.data))
    });
}

export const editDayWorkTable = (data) => async (dispatch) => {
    await WorkTableApi.edit(data).then(response => {
        dispatch(changeGuideTable(response.data))
    });
}

