
const defaultState = {
    isAuth: false,
    token: null,
    email: null,
    role: null,
    branch: null,
}

const SET_AUTH_TRUE = 'SET_AUTH_TRUE'
const SET_AUTH_FALSE = 'SET_AUTH_FALSE'
const SET_BRANCH = 'SET_BRANCH'

export const accountReducer = (state = defaultState, action) => {

    switch(action.type) {
        case SET_AUTH_TRUE:
            return {...state, ...action.payload, isAuth: true}
        case SET_AUTH_FALSE: 
            return {...state, ...action.payload, isAuth: false}
        case SET_BRANCH: 
            return {...state, branch: action.payload}
        default:
            return state;
    }

}

export const setAccountAuthTrue = (data) => {

    return {
        type: SET_AUTH_TRUE,
        payload: {
            ...data
        }
    }
}

export const setBranch = (data) => {
    return {
        type: SET_BRANCH,
        payload: data
    }
}