import React from 'react';
import classes from "./TableItemImg.module.scss";

const TableItemImg = ({status, link, style, callback}) => {
    let styleImg = {
        cursor: callback ? "pointer" : "auto"
    }
    return (
        <div className={classes.cell} style={style}>
            {status &&
                <img className={classes.img} src={link} style={styleImg} onClick={callback}/>
            }
            {status != true &&
                <span>_</span>
            }

        </div>
    );
};

export default TableItemImg;