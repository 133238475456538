import React from "react";
import classes from "./Modal.module.scss";

const Modal = ({ active, title, children, type, style}) => {
    let classModal = [classes.modal]
    let classHeader = [classes.header]
    if (type == "error"){
        classHeader.push(classes.header_error)
    }
    if(active) classModal.push(classes.modal__active)

    let classPopup = [classes.popup]
    if(active) classPopup.push(classes.popup__active)

    return(
        <div className={classModal.join(" ")} >

            <div className={classPopup.join(" ")}>

                <header className={classHeader.join(" ")}>
                    <p className={classes.title}>{title}</p>
                </header>

                <div className={classes.content}>
                    {children}
                </div>

            </div>

        </div>
    )
}

export default Modal;