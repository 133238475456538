import React, {useEffect, useState} from "react";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";
import {useSelector} from "react-redux";
import { useRole } from "../../../hooks/useRole";

const TableAdditionalOptions = ({visible}) => {
    const options = useSelector(state => state.options.options);
    const role = useRole();
    const [items, changeItems] = useState(options.filter(item => item.deleted === false))

    let header = [
        {
            width: 5,
            component: () => <TableHeaderText itemName="Опція"/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName="Вартість"/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName=" "/>,
        },
    ];
    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    useEffect(() => {
        if(visible) {
            changeItems(options.filter(item => item.deleted === false))
        } else {
            changeItems(options.filter(item => item.deleted === true))
        }
    }, [visible])

    return (
        <Table header={header} items={items} columns={columns}>
            {
                items.map(item => {
                    let styleItem = false

                    if(item.deleted === true){
                        styleItem = {background: "#f1f1f1"}
                    }

                    return (

                        <TableItem key={item.id} columns={columns} style={styleItem}>
                            <TableItemText text={item.name.find(item => item.default_language).text}/>
                            <TableItemText text={item?.price}/>
                            {role <= 1 &&
                                <TableItemEdit type={item.type} id={item.id}/>
                            }
                        </TableItem>
                    )
                })
            }
        </Table>
    )

}

export default TableAdditionalOptions;