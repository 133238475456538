import React, {useEffect, useState} from 'react';
import classes from "./NavbarAccordion.module.scss";
import {NavLink, useLocation} from "react-router-dom";

const NavbarAccordion = ({activeNavbar, route, activeItem, changeActiveItem}) => {
    const location = useLocation();
    const [activeHeader, changeActiveHeader] = useState(false)
    const classesHeader = [classes.header]
    const classesList = [classes.list]
    if(activeHeader) classesHeader.push(classes.header__active)
    if(activeNavbar === true) {

        if(activeHeader) classesList.push(classes.list__active)
    }

    const showList = () => {
        if(activeHeader == false){
            changeActiveItem(route.key)
        } else {
            changeActiveItem(null)
        }
    }

    useEffect(() => {
        if(activeItem == route.key){
            changeActiveHeader(true)
        } else {
            changeActiveHeader(false)
        }
    }, [activeItem])
    useEffect(() => {
        route.routes.forEach( item => {
            if(item.path == location.pathname){
                changeActiveItem(route.key)
            }
        })
    }, [])


    if(route.key){
        return (
            <div className={classes.container}>

                <div className={classesHeader.join(" ")} onClick={showList}>
                    {/*{activeNavbar === false &&*/}
                    <img className={classes.icon} src={route.icon} alt=""/>
                     {/*}*/}
                    {activeNavbar === true &&
                        <span>{route.name}</span>
                    }

                    <svg width="12" height="7" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#000"/>
                    </svg>
                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>*/}
                </div>

                <div className={classesList.join(" ")}>
                    {
                        route.routes.map( item => {
                            return(
                                <NavLink
                                    key={item.path}
                                    to={item.path}
                                    className={({isActive}) => isActive ? classes.link__active : classes.link}
                                >
                                    {item.name}
                                </NavLink>
                            )
                        })
                    }
                </div>

            </div>
        );
    } else {
        return (
            <></>
        )
    }

};

export default NavbarAccordion;