import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import Page from "../../../UI/Page/Page";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import {
    changeCurrentGroup,
    changeExcursionsForCurrentGroup,
    changeStateGroupSaveButton, createGroup, deleteGroup,
    editGroup
} from "../../../../redux/reducers/groupReducer";
import LanguageInputs from "../../../UI/LanguageFields/LanguageInputs/LanguageInputs";
import Modal from "../../../UI/Modal/Modal";
import Button from "../../../UI/Button";
import SelectItem from "../../../UI/SelectItem/SelectItem";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../assets/images/Path 114.svg";

const GroupCreate = () => {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const {register, handleSubmit, control, formState: {errors}, setValue, clearErrors} = useForm()
    const branch_id = useSelector(state => state.account.branch)
    const groups = useSelector(state => state.groups.groups)
    const newGroup = useSelector(state => state.groups.newGroup)
    const data = useSelector(state => state.groups.currentGroup)
    const excursions = useSelector(state => state.excursions.excursions)
    const stateSaveButton = useSelector(state => state.groups.disabledButton)

    const [modalActive, changeModalActive] = useState(false);
    let [stateExcursions, changeExcursions] = useState([]);

    let pageName = (data && data.name.length !== 0) ? data.name.find(lang => lang.default_language).text : "";

    const [modalToArchive, changeModalToArchive] = useState(false)

    const showModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(true)
    }
    const hideModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(false)
    }
    const deleteGroupCallback = (e) => {
        e.preventDefault()
        if(data.id){
            dispatch(deleteGroup(data.id, navigate))
        }
        changeModalToArchive(false)
    }
    const groupsSubmit = (dataForm) => {
        dataForm.branch_id = branch_id
        if (data.id) {
            dataForm.id = data.id;
            dispatch(editGroup(dataForm));
        } else {
            dispatch(createGroup(dataForm, navigate));
        }
    };
    let deleteExcursion = (id) => {
        if (stateExcursions.find(item => item.id == id)) {
            let newStateExcursions = stateExcursions.filter(item => item.id !== id);

            changeExcursions(newStateExcursions)
            dispatch(changeExcursionsForCurrentGroup(newStateExcursions));
            setValue('excursions', []);

            for (let i = 0; i < newStateExcursions.length; i++) {
                let excursion = newStateExcursions[i];

                setValue(`excursions.${i}`, {
                    id: excursion.id,
                    value: true,
                })
            }
        }
    }

    useEffect(() => {
        if (params?.id) {
            let groupForUrl = groups.find(group => group.id == params.id);
            dispatch(changeCurrentGroup(groupForUrl));
        } else {
            dispatch(changeCurrentGroup(newGroup));
        }
        return () => {
            dispatch(changeCurrentGroup(false));
        }
    }, []);
    useEffect(() => {
        if (data && data.excursions) {
            changeExcursions(data.excursions)
        }
    }, [data])

    if (data) {
        return (
            <Page
                breadcrumbs={{link: '/group', text: 'Групи'}}
                pageName={pageName}
                rightButton={{
                    content: 'Зберегти', callback: () => {
                    }, icon: 'add', color: '', form: 'groups_form', disabled: stateSaveButton,
                }}
            >

                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>
                    <form id={"groups_form"} onSubmit={handleSubmit(groupsSubmit)}>

                        <div>
                            <LanguageInputs
                                keyIndex={"name"}
                                label={"Назва групи"}
                                fields={data.name}
                                control={control}
                                changeButton={() => {
                                    dispatch(changeStateGroupSaveButton(false))
                                }}
                            />
                            {params.id &&
                                <ButtonHalfTransparent onClick={showModalToArchive}>
                                    <ReactSVG src={deleteSVG}/>
                                    Видалити
                                </ButtonHalfTransparent>
                            }

                        </div>

                        <div>
                            <h3 style={{marginTop: '0px'}}>Екскурсії</h3>
                            {
                                data.excursions.map((excursion, index) => {

                                    return (
                                        <Controller
                                            key={`excursion__${excursion.id}`}
                                            control={control}
                                            name={`excursions.${index}`}
                                            defaultValue={{
                                                id: excursion.id,
                                                value: true
                                            }}
                                            render={({field: {onChange, value, name}}) => (
                                                <SelectItem
                                                    key={`excursion-item_${excursion.id}`}
                                                    onClick={() => {
                                                        deleteExcursion(excursion.id);
                                                        dispatch(changeStateGroupSaveButton(false));
                                                    }}
                                                >
                                                    {excursion.name.find(item => item.default_language == true).text} ({excursion.typeExcursion.name})
                                                </SelectItem>
                                            )
                                            }
                                        />

                                    )

                                })
                            }

                            <Button icon={'add'} callBack={(e) => {
                                e.preventDefault();
                                changeModalActive(true)
                            }}>Додати</Button>
                        </div>

                        <div></div>

                        <Modal active={modalActive} title={'Екскурсії'}>
                            <div>
                                {
                                    excursions.map(excursion => {
                                        let nameExcursion = excursion.name.find(lang => lang.default_language == true).text;
                                        let typeExcursion = excursion.typeExcursion.name
                                        return (
                                            <div
                                                key={`excursion__item__${excursion.id}`}
                                                className={'row'}
                                                style={{
                                                    justifyContent: "space-between",
                                                    padding: "9px 16px",
                                                    border: "1px solid #ECECEC",
                                                    borderRadius: "6px"
                                                }}>
                                                <label
                                                    htmlFor={`excursion_${excursion.id}`}
                                                    style={{fontWeight: 400}}
                                                >
                                                    {nameExcursion} ({typeExcursion})
                                                </label>
                                                <input
                                                    id={`excursion_${excursion.id}`}
                                                    type={"checkbox"}
                                                    onChange={() => {
                                                        if (stateExcursions.find(item => item.id == excursion.id)) {
                                                            changeExcursions(
                                                                stateExcursions.filter(item => item.id !== excursion.id)
                                                            )
                                                        } else {
                                                            changeExcursions([...stateExcursions, excursion])
                                                        }
                                                    }}
                                                    checked={stateExcursions.find(item => item.id == excursion.id) ? true : false}
                                                />
                                            </div>

                                        )
                                    })
                                }
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px"
                            }}>
                                <Button
                                    color={"#ECECEC"}
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeModalActive(false);
                                    }}
                                >
                                    Скасувати
                                </Button>
                                <Button
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeModalActive(false)
                                        dispatch(changeExcursionsForCurrentGroup(stateExcursions));
                                        setValue('excursions', []);

                                        for (let i = 0; i < stateExcursions.length; i++) {
                                            let excursion = stateExcursions[i];

                                            setValue(`excursions.${i}`, {
                                                id: excursion.id,
                                                value: true,
                                            })
                                        }

                                        dispatch(changeStateGroupSaveButton(false));
                                    }}
                                >
                                    Додати
                                </Button>
                            </div>

                        </Modal>

                    </form>
                </div>

                <Modal active={modalToArchive} title={"Видалити"} type={"error"}>

                    <div>
                        <p style={{textAlign: "center"}}>
                            Ви впевнені що хочете видалити группу?
                        </p>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        <button
                            style={{background: "#eee"}}
                            onClick={hideModalToArchive}
                        >
                            Скасувати
                        </button>
                        <button onClick={deleteGroupCallback}>
                            Видалити
                        </button>
                    </div>

                </Modal>

            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }

};

export default GroupCreate;