import React from 'react';
import classes from "./TableItemNew.module.scss"

const TableItemNew = () => {
    return (
        <div className={classes.item}>
            new
        </div>
    );
};

export default TableItemNew;