import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
import { accountReducer } from "./reducers/accountReducer";
import { languagesReducer } from "./reducers/languagesReducer";
import { rolesReducer } from "./reducers/rolesReducer";
import { branchReducer } from "./reducers/branchReducer";
import { discountsReducer } from "./reducers/discountsReducer"
import { сertificatesReducer } from "./reducers/сertificatesReducer"
import { promocodesReducer } from "./reducers/promocodesReducer"
import { excursionsReducer } from "./reducers/excursionsReducer"
import { optionsReducer } from "./reducers/additionalOptionsReducer";
import { bookingsReducer } from "./reducers/bookingsReducer";
import {tablesReducer} from "./reducers/timeTableExcursionReducer";
import {calendarReducer} from "./reducers/calendarReducer";
import {guidesReducer} from "./reducers/guidesReducer";
import {reportsReducer} from "./reducers/reportsReducer";
import {groupReducer} from "./reducers/groupReducer";
import {workTableReducer} from "./reducers/workSchedulesReducer";
import {translateReducer} from "./reducers/translateReducer";
import {keysReducer} from "./reducers/keysReducer";

let reducers = combineReducers({
    account: accountReducer,
    languages: languagesReducer,
    roles: rolesReducer,
    branches: branchReducer,
    discounts: discountsReducer,
    сertificates: сertificatesReducer,
    promocodes: promocodesReducer,
    options: optionsReducer,
    excursions: excursionsReducer,
    bookings: bookingsReducer,
    timeTablesExcursions: tablesReducer,
    calendar: calendarReducer,
    guides: guidesReducer,
    reports: reportsReducer,
    groups: groupReducer,
    workTable: workTableReducer,
    translate: translateReducer,
    keys: keysReducer,
});
// const customMiddleWare = store => next => action => {
//     next(action);
// }
const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

export default store;