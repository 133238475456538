import axios from "axios";

export default class RolesApi {

    static async getAll(token) {

        const response = await axios.post('https://api.0300.com.ua/api/roles/getall', {
            token: token,
        })

        return response;
    }

    static async put(formData) {
        const response = await axios.post('https://api.0300.com.ua/api/roles/put', formData, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })

        return response
    }

    static async patch(formData) {
        const response = await axios.post('https://api.0300.com.ua/api/roles/patch', formData, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })

        return response
    }

    static async delete(formData) {
        const response = await axios.post('https://api.0300.com.ua/api/roles/delete', formData, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })

        return response
    }

}