import React from 'react';
import classes from "./Main.module.scss";
import {privateRoutes} from "../../routes/privateRoutes";
import {Routes, Route} from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import TableLanguage from "../UI/Table/TableLanguage";
import { useSelector } from 'react-redux';
import {useRole} from '../../hooks/useRole'

const Main = (props) => {

    const role = useRole();


    return (
        <div className={classes.page}>

            <Navbar/>

            <div className={classes.container}>

                <Header/>

                <div className={classes.content}>

                    <Routes>
                        {privateRoutes.filter(route => route.role == undefined || (role <= route.role)).map(route =>
                            <Route key={route.path} path={route.path} element={<route.component/>}/>
                        )}
                    </Routes>
                </div>
            </div>

        </div>
    )
}

export default Main;