import axios from "axios";

export default class PromocodesApi {

    static async getAll(token) {
        const response = await axios.post('https://api.0300.com.ua/api/promocodes/getall', {
            token: token
        })

        return response;
    }

    static async put(data) {
        const response = await axios.post('https://api.0300.com.ua/api/promocodes/put', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async patch(data) {
        const response = await axios.post('https://api.0300.com.ua/api/promocodes/patch', {
            token: localStorage.getItem('auth_token'),
            ...data
        });

        return response
    }

    static async deletePromocode(data) {
        const response = await axios.post('https://api.0300.com.ua/api/promocodes/delete', {
            token: localStorage.getItem('auth_token'),
            id: data
        });

        return response
    }


}