import React, { useState, useRef } from 'react'
import CustomSelect from '../../../UI/CustomSelect/CustomSelect'
import Page from '../../../UI/Page/Page'
import { useDispatch, useSelector } from 'react-redux'
import RolesApi from '../../../../api/RolesApi'
import { getRoles } from '../../../../redux/reducers/rolesReducer'
import { useNavigate } from 'react-router-dom'
import Error from '../../../UI/Error/Error'
import cl from './RolesCreate.module.scss'
import generatePasswordSVG from '../../../../assets/images/add.svg'
import { ReactSVG } from 'react-svg'

const RolesCreate = () => {

    const state = useSelector(state => state.roles.currentAccount)
    const dispatch = useDispatch()
    const [password, setPassword] = useState('')
    const allBranches = useSelector(state => state.branches.branches)
    const navigate = useNavigate()
    const passRef = useRef();

    const users = useSelector(state => state.roles.roles);

    const [errors, setErrors] = useState({
        role: '',
        email: '',
        pass: '',
        name: '',
        phone_number: '',
        branch: '',
    })

    const [roles, setRoles] = useState([
        {key: 0, value: 'Суперадмін'},
        {key: 1, value: 'Старший адмін'},
        {key: 2, value: 'Адміністратор'},
    ])

    const [branches, setBranches] = useState(allBranches.map(branch => ({key: branch.id, value: branch.name.find(n => n?.default_language).text})))

    const changeData = (obj) => {
        if(obj.email) setErrors({...errors, email: ''})
        if(obj.name) setErrors({...errors, name: ''})
        if(obj.phone_number) setErrors({...errors, phone_number: ''})
        dispatch({type: 'SET_CURRENT_ROLES', payload: {...state, ...obj}})
    }

    const changeRole = (e) => {
        setErrors({...errors, role: ''})
        setRoles(
            roles.map(role => {
                if(role.selected) role.selected = false
                if(role.key == e.selectedItem.key) role.selected = true
                return role
            }),
        )
    }

    const changeBranch = (e) => {
        setErrors({...errors, branch: ''})
        setBranches(
            branches.map(branch => {
                if(branch.selected) branch.selected = false
                if(branch.key == e.selectedItem.key) branch.selected = true
                return branch
            }),
        )
    }

    const changePhoneNumber = (e) => {
        changeData({phone_number: e.target.value})
    }

    const validate = () => {
        if(errors.role || errors.email || errors.pass || errors.name || errors.phone_number || errors.branch) return false;
        else if(!roles.find(role => role?.selected == true)) {
            setErrors({...errors, role: 'Виберіть роль'})
            return false
        }
        else if(!branches.find(branch => branch?.selected == true)) {
            setErrors({...errors, branch: 'Виберіть філію'})
            return false
        }
        else if(!state.email) {
            setErrors({...errors, email: 'Вкажіть емейл адресу'})
            return false
        }
        else if(!password) {
            setErrors({...errors, pass: 'Вкажіть пароль'})
            return false
        }
        else if(!state.name) {
            setErrors({...errors, name: 'Вкажіть ПІБ'})
            return false
        }
        else if(!state.phone_number) {
            setErrors({...errors, phone_number: 'Вкажіть номер телефону'})
            return false
        }
        else if(checkUniqueEmail()) {
            setErrors({...errors, email: 'Така адреса вже зареєстрована'})
            return false
        }
        return true
    }

    const checkUniqueEmail = () => {
        return users.find(user => user.email == state.email);
    }

    const saveFunc = async () => {
        if(!validate()) return;
        const formData = new FormData();
        formData.append('token', localStorage.getItem('auth_token'))
        formData.append('email', state.email)
        formData.append('name', state.name)
        formData.append('password', password)
        formData.append('phone_number', state.phone_number)
        formData.append('role', roles.find(role => role?.selected).key)
        formData.append('branch', branches.find(branch => branch?.selected).key)

        const response = await RolesApi.put(formData)
        await dispatch(getRoles())
        navigate('/roles')
    }

    const generatePassword = (e) => {
        e.preventDefault();
        setErrors({...errors, pass: ''});
        const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const passwordLength = 16;
        let password = '';
        for(let i = 0; i <= passwordLength; i++) {
            let randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber +1);
        }
        passRef.current.type = 'text';
        setPassword(password)
    }

    return(
        <Page
            breadcrumbs={{link: '/roles', text: 'Ролі'}}
            rightButton={{content: 'Зберегти', callback: saveFunc, icon: 'save', color: ''}}
        >
            <div className={cl.formEdit} style={{height: '100%', background: '#fff'}}>
                <form action="" style={{padding: 19}}>
                    <div className="">
                        <label htmlFor="">
                            Роль
                            <div className="" style={{marginBottom: 15}}>
                                <CustomSelect 
                                    items={roles}
                                    onChange={changeRole}
                                    classError={errors.role ? true : false}
                                />
                            </div>
                            {errors.role &&
                                <Error>{errors.role}</Error>
                            }
                        </label>
                        <label htmlFor="">
                            Пошта/Логін
                            <input 
                                type="text"
                                value={state.email}
                                style={{borderColor: errors.email ? '#ff5c5c' : ''}}
                                onChange={(e) => changeData({email: e.target.value})}
                            />
                            {errors.email &&
                                <Error>{errors.email}</Error>
                            }
                        </label>
                        <label htmlFor="">
                            Пароль
                            <div
                                style={{
                                    marginTop:5,
                                    marginBottom: 15,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 10,
                                    height: 40
                                }}
                            >
                                <input 
                                    ref={passRef}
                                    type="password"
                                    value={password}
                                    style={{borderColor: errors.pass ? '#ff5c5c' : '', margin: 0}}
                                    onChange={e => {setErrors({...errors, pass: ''});setPassword(e.target.value)}}
                                />
                                <button
                                    style={{
                                        minHeight: 'unset',
                                        minWidth: 'unset',
                                        height: '100%',
                                        // width: 40,
                                        margin: 0,
                                        padding: 15,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={generatePassword}
                                    className={cl.generatePassswordButton}
                                >
                                    <ReactSVG src={generatePasswordSVG}/>
                                </button>
                            </div>
                            {errors.pass &&
                                <Error>{errors.pass}</Error>
                            }
                        </label>
                        <label htmlFor="">
                            ПІБ
                            <input 
                                type="text"
                                value={state.name}
                                style={{borderColor: errors.name ? '#ff5c5c' : ''}}
                                onChange={(e) => changeData({name: e.target.value})}
                            />
                            {errors.name &&
                                <Error>{errors.name}</Error>
                            }
                        </label>
                        <label htmlFor="">
                            Телефон
                            <input 
                                type="text"
                                value={state.phone_number}
                                style={{borderColor: errors.phone_number ? '#ff5c5c' : ''}}
                                onChange={changePhoneNumber}
                            />
                            {errors.phone_number &&
                                <Error>{errors.phone_number}</Error>
                            }
                        </label>
                    </div>
                    <div className="">
                        <label htmlFor="">
                            Філія
                            <CustomSelect 
                                items={branches}
                                onChange={changeBranch}
                                classError={errors.branch ? true : false}
                            />
                            {errors.branch &&
                                <Error>{errors.branch}</Error>
                            }
                        </label>
                    </div>
                </form>
            </div>
        </Page>
    )
}

export default RolesCreate