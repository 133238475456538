import React from 'react';
import classes from "./BookingGuides.module.scss";

const BookingGuides = ({guides}) => {
    return (
        <div className={classes.container}>
            {guides.map(guide => {
                return(
                    <div key={`guide_${guide.id}`} className={classes.guide}>
                        <img src={guide.photo}/>
                        <span>{guide.name}</span>
                    </div>
                )
            })}

        </div>
    );
};

export default BookingGuides;