import React, {useEffect, useState} from 'react'
import {Controller, useForm} from "react-hook-form";
import Page from "../../../UI/Page/Page";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import CustomSelect from "../../../UI/CustomSelect/CustomSelect";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCurrentCertificat,
    changeStateCertificatSaveButton,
    createCertificat,
    editCertificat, toArchiveCertificate
} from "../../../../redux/reducers/сertificatesReducer";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import Error from "../../../UI/Error/Error";
import CertificateStatus from "../../../UI/CertificateStatus/CertificateStatus";
import {changeStateDiscountSaveButton} from "../../../../redux/reducers/discountsReducer";
import Modal from "../../../UI/Modal/Modal";
import publish from "../../../../assets/images/publish.svg";

const CertificatesCreate = () => {
    const excursions = useSelector(state => state.excursions.excursions);

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const {register, handleSubmit, control, setValue, setError, errors} = useForm();
    const certificates = useSelector(state => state.сertificates.сertificates);
    const newCertificat = useSelector(state => state.сertificates.newCertificat);
    const stateSaveButton = useSelector(state => state.сertificates.disabledButton);
    let data = useSelector(state => state.сertificates.currentCertificat);
    const [selectedItems, changeSelect] = useState(null);
    let pageName = (data && data.excursion && data.excursion.name) ? data.excursion.name.find(lang => lang.default_language).text + ` (квитків: ${data.quantityPerson})` : "";
    
    const [modalToArchive, changeModalToArchive] = useState(false)

    const showModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(true)
    }
    const hideModalToArchive = (e) => {
        e.preventDefault()
        changeModalToArchive(false)
    }
    const toArchive = (e) => {
        e.preventDefault()
        if(data.id){
            dispatch(toArchiveCertificate(data.id))
        }
        changeModalToArchive(false)
    }
    let onChangeSelect = (e) => {
        let selectedItem = e.selectedItem;

        selectedItems.map(item => {
            if (item.key == selectedItem.key) {
                item.selected = true;
                return item;
            } else {
                item.selected = false;
                return item;
            }
        })
        setValue('excursion', selectedItem)
        changeSelect(selectedItems);
    }
    let certificatesSubmit = (dataForm) => {
        let timeDataStart = dataForm.start.split("-");
        let timeDataEnd = dataForm.end.split("-");

        dataForm.start = timeDataStart[2] + "." + timeDataStart[1] + "." + timeDataStart[0];
        dataForm.end = timeDataEnd[2] + "." + timeDataEnd[1] + "." + timeDataEnd[0];

        if (data.id) {
            dataForm.id = data.id;
        }

        let errorDataForm = false

        if (
            dataForm.code == false ||
            dataForm.code == null ||
            dataForm.code < 100000 ||
            dataForm.code > 999999 ||
            isNaN(dataForm.code)
        ) {
            errorDataForm = true
            setError('code', {type: 'required', message: "Сертифікат має мати 6 цифр без 0"});
        }
        if (certificates.find(item => item.code === dataForm.code && item.id !== dataForm.id)) {
            errorDataForm = true
            setError('code', {type: 'required', message: "Такий сертифікат вже існує"});
        }
        if (
            dataForm.quantityPerson == false ||
            dataForm.quantityPerson == null ||
            dataForm.quantityPerson < 1 ||
            isNaN(dataForm.quantityPerson)
        ) {
            errorDataForm = true
            setError('quantityPerson', {type: 'required', message: "Кількість квитків не може бути меншою за 1"});
        }
        if (
            dataForm.percent == false ||
            dataForm.percent == null ||
            dataForm.percent < 1 ||
            dataForm.percent > 100 ||
            isNaN(dataForm.percent)
        ) {
            errorDataForm = true
            setError('percent', {type: 'required', message: "Від 1% до 100%"});
        }
        if (errorDataForm) {
            return false
        }

        if (data.id) {
            dispatch(editCertificat(dataForm));
        } else {
            dispatch(createCertificat(dataForm, navigate));
        }
    };

    useEffect(() => {
        if (params?.id) {
            let certificatForUrl = certificates.find(certificat => certificat.id == params.id);
            dispatch(changeCurrentCertificat(certificatForUrl));
        } else {
            dispatch(changeCurrentCertificat(newCertificat));
        }
        return () => {
            dispatch(changeCurrentCertificat(false));
        }
    }, []);
    useEffect(() => {
        if (data) {
            changeSelect(
                excursions.map(item => {
                    let newItem = {
                        key: item.id,
                        value: `${item.name.find(item => item.default_language).text} (${item.typeExcursion.name})`,
                        selected: (item.id == data.excursion.id) ? true : false
                    }
                    return newItem;
                })
            )
        }
    }, [data])
    //
    // if(Object.keys(errors).length !== 0){
    //     dispatch(changeStateSaveButton(true))
    // } else {
    //     dispatch(changeStateSaveButton(false))
    // }

    if (data) {
        let timeStartArr = data.time.start.date.split(".");
        let timeStartInput = timeStartArr[2] + "-" + timeStartArr[1] + "-" + timeStartArr[0];
        let timeEndArr = data.time.end.date.split(".");
        let timeEndInput = timeEndArr[2] + "-" + timeEndArr[1] + "-" + timeEndArr[0];

        return (
            <Page
                breadcrumbs={{link: '/certificates', text: 'Сертифікати'}}
                pageName={pageName}
                rightButton={{
                    content: 'Зберегти',
                    callback: () => {
                    },
                    icon: 'add',
                    color: '',
                    form: 'certificates_form',
                    disabled: stateSaveButton
                }}
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>

                    <form id={"certificates_form"} onSubmit={handleSubmit(certificatesSubmit)}>

                        <div>

                            {data.deleted &&
                                <p>Архів</p>
                            }

                            <div>
                                <CertificateStatus
                                    keyStatus={data.status.key}
                                    name={data.status.name}
                                />
                            </div>

                            <div style={{marginBottom: '15px'}}>
                                <h3 style={{marginTop: '0px'}}>Екскурсія</h3>

                                {selectedItems &&
                                    <Controller
                                        key={`excursion`}
                                        control={control}
                                        name={`excursion`}
                                        defaultValue={selectedItems.find(item => item.selected == true)}
                                        rules={{required: "Обов'язкове поле",}}
                                        render={({
                                                     field: {onChange, value, name},
                                                     fieldState: {invalid, error},
                                                 }) => (
                                            <div>

                                                <CustomSelect
                                                    classError={error ? "error" : ""}
                                                    onChange={
                                                        (e) => {
                                                            dispatch(changeStateCertificatSaveButton(false));
                                                            onChangeSelect(e);
                                                        }
                                                    }
                                                    items={selectedItems}
                                                />

                                                {error &&
                                                    <Error> {error?.message} </Error>
                                                }

                                            </div>
                                        )
                                        }
                                    />
                                }


                            </div>

                            <div>
                                <h3 style={{marginTop: '0px'}}>Квитків/осіб</h3>

                                <Controller
                                    control={control}
                                    name={"quantityPerson"}
                                    defaultValue={Number(data.quantityPerson)}
                                    rules={{
                                        required: "Обов'язкове поле",
                                        valueAsNumber: true,
                                    }}
                                    render={({
                                                 field: {onChange, value, name},
                                                 fieldState: {invalid, error},
                                             }) => (
                                        <div>
                                            <input
                                                type={"number"}
                                                name={name}
                                                value={Number(value)}
                                                className={error ? "error" : ""}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                    onChange(Number(e.currentTarget.value))
                                                    dispatch(changeStateCertificatSaveButton(false))
                                                }}
                                            />

                                            {error &&
                                                <Error> {error?.message} </Error>
                                            }

                                        </div>
                                    )
                                    }
                                />

                            </div>

                            <div>
                                <h3 style={{marginTop: '0px'}}>Код сертифікату</h3>

                                <Controller
                                    control={control}
                                    name={"code"}
                                    defaultValue={Number(data.code)}
                                    rules={{
                                        required: "Обов'язкове поле",
                                        valueAsNumber: true,
                                    }}
                                    render={({
                                                 field: {onChange, value, name},
                                                 fieldState: {invalid, error},
                                             }) => (
                                        <div>
                                            <input
                                                type={"number"}
                                                name={name}
                                                value={Number(value)}
                                                className={error ? "error" : ""}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                    onChange(Number(e.currentTarget.value))
                                                    dispatch(changeStateCertificatSaveButton(false))
                                                }}
                                            />

                                            {error &&
                                                <Error> {error?.message} </Error>
                                            }

                                        </div>
                                    )
                                    }
                                />

                            </div>

                            <div>
                                <Controller
                                    control={control}
                                    name={"start"}
                                    defaultValue={timeStartInput == "0000-00-00" ? false : timeStartInput}
                                    rules={{required: "Обов'язкове поле",}}
                                    render={({
                                                 field: {onChange, value, name, ref},
                                                 fieldState: {invalid, isTouched, isDirty, error},
                                                 formState,
                                             }) => (
                                        <div>
                                            <label style={{marginBottom: "15px"}} htmlFor={"start"}>Термін дії з</label>

                                            <input
                                                onChange={
                                                    (e) => {
                                                        onChange(e);
                                                        dispatch(changeStateCertificatSaveButton(false));
                                                    }
                                                }
                                                className={error ? "error" : ""}
                                                id={"start"}
                                                name={"start"}
                                                type="date"
                                                value={value}
                                            />

                                            {error &&
                                                <Error> {error?.message} </Error>
                                            }

                                        </div>
                                    )}
                                />
                            </div>

                            <div>
                                <Controller
                                    control={control}
                                    name={"end"}
                                    defaultValue={timeEndInput == "0000-00-00" ? false : timeEndInput}
                                    rules={{required: "Обов'язкове поле",}}
                                    render={({
                                                 field: {onChange, value, name, ref},
                                                 fieldState: {invalid, isTouched, isDirty, error},
                                                 formState,
                                             }) => (
                                        <div>
                                            <label style={{marginBottom: "15px"}} htmlFor={"end"}>Термін дії до</label>

                                            <input
                                                onChange={
                                                    (e) => {
                                                        onChange(e);
                                                        dispatch(changeStateCertificatSaveButton(false));
                                                    }

                                                }
                                                className={error ? "error" : ""}
                                                id={"end"}
                                                name={"end"}
                                                type="date"
                                                value={value}
                                            />

                                            {error &&
                                                <Error> {error?.message} </Error>
                                            }

                                        </div>
                                    )}
                                />
                            </div>

                            <div>
                                <h3 style={{marginTop: '0px'}}>%</h3>

                                <Controller
                                    control={control}
                                    name={"percent"}
                                    defaultValue={Number(data.percent)}
                                    rules={{
                                        required: "Обов'язкове поле",
                                        valueAsNumber: true,
                                    }}
                                    render={({
                                                 field: {onChange, value, name},
                                                 fieldState: {invalid, error},
                                             }) => (
                                        <div>
                                            <input
                                                type={"number"}
                                                name={name}
                                                value={Number(value)}
                                                className={error ? "error" : ""}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => {
                                                    onChange(Number(e.currentTarget.value))
                                                    dispatch(changeStateCertificatSaveButton(false))
                                                }}
                                            />

                                            {error &&
                                                <Error> {error?.message} </Error>
                                            }

                                        </div>
                                    )
                                    }
                                />

                            </div>

                            {params?.id &&
                                <ButtonHalfTransparent onClick={showModalToArchive}>
                                    {data.deleted === false &&
                                        <>
                                            <ReactSVG src={deleteSVG}/>
                                            В архів
                                        </>
                                    }
                                    {data.deleted === true &&
                                        <>
                                            <ReactSVG src={publish}/>
                                            Опублікувати
                                        </>
                                    }
                                </ButtonHalfTransparent>
                            }


                        </div>

                        <div>
                        </div>

                        <div></div>

                    </form>

                </div>

                {params?.id &&
                    <Modal active={modalToArchive} title={data.deleted === false ? "Відправити в архів" : "Опублікувати"} type={"error"}>

                        <div>
                            {data.deleted === false &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете відправити сертифікат в архів?
                                </p>
                            }
                            {data.deleted === true &&
                                <p style={{textAlign: "center"}}>
                                    Ви впевнені що хочете опублікувати сертифікат?
                                </p>
                            }
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px"
                        }}>
                            <button
                                style={{background: "#eee"}}
                                onClick={hideModalToArchive}
                            >
                                Скасувати
                            </button>
                            <button onClick={toArchive}>
                                {data.deleted === false &&
                                    <>
                                        В архів
                                    </>
                                }
                                {data.deleted === true &&
                                    <>
                                        Опублікувати
                                    </>
                                }
                            </button>
                        </div>

                    </Modal>
                }

            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
}

export default CertificatesCreate