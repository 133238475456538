import React from 'react';
import classes from "./GuideWorkSchedules.module.scss";

const GuideWorkSchedules = ({guide, editSlot}) => {

    let styleItem = {}

    if(guide.deleted === true){
        styleItem.background = "#ffdcdc"
    }


    return (
        <div className={classes.container} style={styleItem}>
            <div className={classes.row}>
                <div className={classes.row}>
                    <img
                        className={classes.img}
                        src={guide.photo}
                    />
                    <span>
                        {guide.name}
                    </span>
                </div>

                <button className={classes.edit} onClick={ (e) => { e.preventDefault(); editSlot() } }>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M18 2.17139C17.7599 2.17139 17.5222 2.21868 17.3003 2.31057C17.0785 2.40245 16.8769 2.53714 16.7071 2.70692L3.39491 16.0191L2.42524 19.5746L5.9807 18.6049L19.2929 5.29271C19.4627 5.12292 19.5974 4.92136 19.6893 4.69952C19.7812 4.47769 19.8284 4.23993 19.8284 3.99981C19.8284 3.7597 19.7812 3.52194 19.6893 3.30011C19.5974 3.07827 19.4627 2.87671 19.2929 2.70692C19.1231 2.53714 18.9216 2.40245 18.6997 2.31057C18.4779 2.21868 18.2401 2.17139 18 2.17139ZM16.5349 0.462808C16.9994 0.270412 17.4973 0.171387 18 0.171387C18.5028 0.171387 19.0006 0.270412 19.4651 0.462808C19.9296 0.655205 20.3516 0.937204 20.7071 1.29271C21.0626 1.64821 21.3446 2.07025 21.537 2.53474C21.7294 2.99923 21.8284 3.49706 21.8284 3.99981C21.8284 4.50257 21.7294 5.0004 21.537 5.46489C21.3446 5.92938 21.0626 6.35142 20.7071 6.70692L7.20713 20.2069C7.08407 20.33 6.93104 20.4188 6.76314 20.4646L1.26314 21.9646C0.916928 22.059 0.546667 21.9607 0.292916 21.7069C0.0391662 21.4532 -0.0591621 21.0829 0.0352593 20.7367L1.53526 15.2367C1.58105 15.0688 1.66986 14.9158 1.79292 14.7927L15.2929 1.29271C15.6484 0.937204 16.0705 0.655205 16.5349 0.462808Z"
                              fill="#B5B5B5"/>
                    </svg>
                </button>


            </div>

            <div className={classes.time}>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                          fill="#B5B5B5"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 6.2002C12.5523 6.2002 13 6.64791 13 7.2002V11.3822L15.6472 12.7058C16.1412 12.9528 16.3414 13.5534 16.0944 14.0474C15.8474 14.5414 15.2468 14.7416 14.7528 14.4946L11.5528 12.8946C11.214 12.7252 11 12.379 11 12.0002V7.2002C11 6.64791 11.4477 6.2002 12 6.2002Z"
                          fill="#B5B5B5"/>
                </svg>

                <span>
                    {guide.time.start.time}-{guide.time.end.time}
                </span>

            </div>


        </div>
    );
};

export default GuideWorkSchedules;