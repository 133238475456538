import React, { useEffect, useState } from 'react'
import cl from "./Header.module.scss";
import HeaderBranches from './HeaderBranches/HeaderBranches';
import HeaderUserInfo from './HeaderUserInfo/HeaderUserInfo';
import Modal from '../UI/Modal/Modal'
import { useDispatch, useSelector } from 'react-redux';
import { setBranch as setBranchReducer } from '../../redux/reducers/accountReducer'; 
import {setBookings} from "../../redux/reducers/bookingsReducer";
import {setBookingsInCalendar, setStartDay} from "../../redux/reducers/calendarReducer";

const Header = () => {

    const currentBranchId = useSelector(state => state.account.branch)
    const branchesSelector = useSelector(state => state.branches.branches);
    const [branches, setBranches] = useState([])
    const [branch, setBranch] = useState(false);
    const [modal, setModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const currBranch = branchesSelector.find(br => br.id == currentBranchId)
        setBranches(branchesSelector.map(branch => ({id: branch.id, name: branch.name.find(item => item?.default_language).text, selected: currBranch.id == branch.id})))
    }, [modal])

    const saveBranch = e => {
        dispatch(setBookings(null))
        dispatch(setStartDay(null))
        dispatch(setBookingsInCalendar(null));
        dispatch(setBranchReducer(branches.find(br => br.selected).id))
    }

    const changeBranch = branch => {
        setBranches(branches.map(br => ({...br, selected: branch.id == br.id})));
        localStorage.setItem('branchId', branch.id)
    }

    return (
        <>
            <header className={cl.header}>
                <HeaderBranches 
                    branches={branches} 
                    modal={modal}
                    setModal={setModal}
                    branch={branch}
                />
                <HeaderUserInfo />
            </header>
            <Modal 
                active={modal}
                title={"Філії"}
            >
                <div className={cl.branchList}>
                    {branches.map(branch => 
                        <div 
                            key={branch.id}
                            className={cl.branchListItem}
                            style={{background: branch.selected ? 'rgb(202, 202, 202)' : ''}}
                            onClick={e => changeBranch(branch)}
                        >
                            {branch.name}
                        </div>    
                    )}
                </div>
                <div className="bttns" style={{display: 'flex', gap: 20, justifyContent: 'center'}}>
                    <button onClick={e => setModal(!modal)} style={{background: "#EEE"}}>Скасувати</button>
                    <button onClick={saveBranch}>Змінити</button>
                </div>
            </Modal>
        </>
    )
}

export default Header