import React, {useEffect, useRef} from 'react';
import classes from "./BookingsContainer.module.scss";
import BookingCart from "../BookingCart/BookingCart";
import {setEditObject} from "../../../../redux/reducers/calendarReducer";
import {useDispatch, useSelector} from "react-redux";

const BookingsContainer = ({startDay, arrBooking}) => {
    const dispatch = useDispatch()
    const edit = useSelector(state => state.calendar.editBooking)
    const showSidebar = (booking) => {
        if(edit) {
            dispatch(setEditObject(false));
            setTimeout(() =>{
                dispatch(setEditObject(booking));
            }, 600)
        } else {
            dispatch(setEditObject(booking));
        }
    }
    return (
        <div className={classes.container}>
            {
                arrBooking.map(bookingRows => {
                    return(
                        bookingRows.map((row, index) => {
                            return(
                                <div key={`row_${index}`} className={classes.row}>
                                    {
                                        row.map(booking => {
                                            console.log(booking);
                                            return(
                                                <BookingCart
                                                    key={`booking_${booking.id}`}
                                                    id={booking.id}
                                                    guides={booking.guides}
                                                    color={booking.excursion.color}
                                                    excursion={booking.excursion.name.find(lang => lang.default_language).text}
                                                    typeExcursion={booking.excursion.typeExcursion.name}
                                                    start={booking.time.start.time}
                                                    duration={booking.time.duration.time}
                                                    payment={booking?.payment?.status?.key}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        showSidebar(booking)
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>

                            )
                        })
                    )
                })
            }

        </div>
    );
};

export default BookingsContainer;