import React from 'react';
import classes from "./TabsGroup.module.scss";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";

const TabsGroup = ({excursionId, style}) => {

    const groups = useSelector(state => state.groups.groups)
    const [groupExcursions, changeGroupExcursions] = useState(null)

    useEffect(() => {
        if(groups && groups.length !== 0){
            groups.forEach(group => {
                let findExcursion = group.excursions.find(groupExcursion => groupExcursion.id == excursionId)
                if(findExcursion){
                    changeGroupExcursions(group)
                }
            })
        }
    }, [])

    if(groupExcursions){
        return (
            <div style={style}>
                {groupExcursions &&
                    groupExcursions.excursions.map( item => {
                        let id = item.id
                        let name = item.name.find(lang => lang.default_language).text
                        let typeExcursion = item.typeExcursion.name

                        return(
                            <NavLink
                                key={`${name}_${typeExcursion}_${id}`}
                                to={`/excursions/timetable/${id}`}
                                className={({isActive}) => isActive ? classes.link__active : classes.link }
                            >
                                {typeExcursion}
                            </NavLink>
                        )
                    })
                }
            </div>
        );
    } else {
        return (
            <></>
        )
    }

};

export default TabsGroup;