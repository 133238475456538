import React from "react";
import HeaderTimeTable from "./HeaderTimeTable/HeaderTimeTable";
import Slots from "./Slots/Slots";
import EditSlot from "./Slots/EditSlot/EditSlot";

const TimeTableExcursion = ({data, table, time}) => {

    return(
        <div>
            <div style={{
                width: "100%",
                maxWidth: "100%",
                overflow: "inherit",
                display: "grid"
            }}>
                <HeaderTimeTable table={table} time={time}/>
                <Slots table={table} />
                <EditSlot data={data}/>
            </div>

        </div>
    )
}

export default TimeTableExcursion;