import React from 'react'
import Page from "../../UI/Page/Page";
import TableReports from "../../UI/Table/TableReports";
import FiltersReports from "../../UI/Filters/FiltersReports";
import {useState} from "react";
import Modal from "../../UI/Modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {createReports} from "../../../redux/reducers/reportsReducer";

Date.prototype.daysInMonth = function() {
    return 32 - new Date(this.getFullYear(), this.getMonth(), 32).getDate();
};

const Reports = () => {
    const dispatch = useDispatch()

    const branchId = useSelector(state => state.account.branch)

    const [stateModal, changeStateModal] = useState(false)
    const [dateReport, changeDateReport] = useState({
        from: "",
        to: "",
    })
    const [fromError, changeFromError] = useState(false)
    const [toError, changeToError] = useState(false)

    const showModal = (e) => {
        e.preventDefault()
        changeStateModal(true)
    }
    const hideModal = (e) => {
        e.preventDefault()
        changeStateModal(false)
    }
    const createReport = (e) => {
        e.preventDefault()

        if(dateReport.from == false || dateReport.to == false){
            if(dateReport.from == false) changeFromError(true)
            if(dateReport.to == false) changeToError(true)

            return false
        } else {

            let arrValueFrom = dateReport.from.split("-")
            let arrValueTo = dateReport.to.split("-")

            let objRequest = {
                branch_id: branchId,
                date: {
                    from: {
                        day: Number(arrValueFrom[2]),
                        month: Number(arrValueFrom[1]),
                        year: Number(arrValueFrom[0]),
                    },
                    to: {
                        day: Number(arrValueTo[2]),
                        month: Number(arrValueTo[1]),
                        year: Number(arrValueTo[0]),

                    }
                }
            }

            dispatch(createReports(objRequest))

            changeDateReport({
                from: "",
                to: "",
            })
            changeStateModal(false)
        }

    }
    const onChangeFrom = (e) => {
        e.preventDefault()
        let value = e.currentTarget.value
        changeDateReport({
            ...dateReport,
            from: value,
        })
        changeFromError(false)
    }
    const onChangeTo = (e) => {
        e.preventDefault()
        let value = e.currentTarget.value
        changeDateReport({
            ...dateReport,
            to: value,
        })
        changeToError(false)
    }

    return (
        <Page
            pageName={"Звіти"}
            // rightButton={{
            //     content: 'Cтворити звіт',
            //     callback: showModal,
            //     icon: 'add',
            //     color: '',
            // }}
        >
            <FiltersReports/>
            <TableReports/>
            <Modal active={stateModal} title={"Cтворити звіт"}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px"}}>
                    <input
                        type="date"
                        name="from"
                        onChange={onChangeFrom}
                        value={dateReport.from}
                        className={fromError ? "error" : ""}
                    />
                    <input
                        type="date"
                        name="to"
                        onChange={onChangeTo}
                        value={dateReport.to}
                        className={toError ? "error" : ""}
                    />
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "10px"
                }}>
                    <button onClick={hideModal} style={{background: "#ececec"}}>
                        Закрити
                    </button>

                    <button onClick={createReport}>
                        Створити
                    </button>
                </div>

            </Modal>
        </Page>
    )
}

export default Reports