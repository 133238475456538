import React from 'react';
import Status from "../../../../UI/Status/Status";
import classes from "../EditBooking.module.scss";
import Button from "../../../../UI/Button";
import {useNavigate} from "react-router-dom";

const BookingInfo = ({index, edit, slider, active}) => {
    const navigate = useNavigate()

    const editRedirect = (e) => {
        e.preventDefault()
        navigate(`/bookings/${edit.id}`)
    }
    const style = {
        display: `${(slider && active) ? "block" : "none"}`
    }

    return (
        <div style={slider ? style : {}}>

            {index &&
                <h3> Бронювання {edit.id} </h3>
            }

            <Status>
                {edit.status.name}
            </Status>

            <div className={classes.row} style={{marginBottom: "30px"}}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.55 17.625C6.6 16.9583 7.6375 16.4542 8.6625 16.1125C9.6875 15.7708 10.8 15.6 12 15.6C13.2 15.6 14.3167 15.7708 15.35 16.1125C16.3833 16.4542 17.425 16.9583 18.475 17.625C19.2083 16.725 19.7292 15.8167 20.0375 14.9C20.3458 13.9833 20.5 13.0167 20.5 12C20.5 9.58333 19.6875 7.5625 18.0625 5.9375C16.4375 4.3125 14.4167 3.5 12 3.5C9.58333 3.5 7.5625 4.3125 5.9375 5.9375C4.3125 7.5625 3.5 9.58333 3.5 12C3.5 13.0167 3.65833 13.9833 3.975 14.9C4.29167 15.8167 4.81667 16.725 5.55 17.625ZM12 12.75C11.0333 12.75 10.2208 12.4208 9.5625 11.7625C8.90417 11.1042 8.575 10.2917 8.575 9.325C8.575 8.35833 8.90417 7.54583 9.5625 6.8875C10.2208 6.22917 11.0333 5.9 12 5.9C12.9667 5.9 13.7792 6.22917 14.4375 6.8875C15.0958 7.54583 15.425 8.35833 15.425 9.325C15.425 10.2917 15.0958 11.1042 14.4375 11.7625C13.7792 12.4208 12.9667 12.75 12 12.75ZM12 22C10.6 22 9.29167 21.7375 8.075 21.2125C6.85833 20.6875 5.8 19.9708 4.9 19.0625C4 18.1542 3.29167 17.0917 2.775 15.875C2.25833 14.6583 2 13.3583 2 11.975C2 10.6083 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.02917 5.825 4.9375 4.925C5.84583 4.025 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6417 2 12.025 2C13.3917 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.975 18.1542 19.075 19.0625C18.175 19.9708 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
                        fill="#B5B5B5"/>
                </svg>
                <span>{edit.client.name} {edit.client.surname}</span>
                <span>{edit.client.email}</span>
                <span>{edit.client.phone}</span>
            </div>

            <div className={classes.row} style={{marginBottom: "30px"}}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="#B5B5B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 16V12" stroke="#B5B5B5" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M12 8H12.01" stroke="#B5B5B5" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>

                <span>Підтвердження: {edit.confirmationMethod}</span>
                <span>Кількість квитків: {edit.excursion.typeExcursion.key == "tickets" ? edit?.tickets?.length : edit?.guests_count}</span>
                <span>Мова: {edit.language.name}</span>
            </div>

            <div className={classes.row}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289Z"
                          fill="#B5B5B5"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5ZM3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5Z"
                          fill="#B5B5B5"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M17.5 16C16.6716 16 16 16.6716 16 17.5C16 18.3284 16.6716 19 17.5 19C18.3284 19 19 18.3284 19 17.5C19 16.6716 18.3284 16 17.5 16ZM14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5C21 19.433 19.433 21 17.5 21C15.567 21 14 19.433 14 17.5Z"
                          fill="#B5B5B5"/>
                </svg>

                <span>{edit?.discounts?.key}</span>
            </div>

            <div className={classes.row}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V18.5858L6.29289 16.2929C6.48043 16.1054 6.73478 16 7 16H19C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4H5ZM2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H19C19.7957 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5V15C22 15.7957 21.6839 16.5587 21.1213 17.1213C20.5587 17.6839 19.7957 18 19 18H7.41421L3.70711 21.7071C3.42111 21.9931 2.99099 22.0787 2.61732 21.9239C2.24364 21.7691 2 21.4045 2 21V5C2 4.20435 2.31607 3.44129 2.87868 2.87868Z"
                          fill="#B5B5B5"/>
                </svg>
                <span>{edit.comment}</span>
            </div>

            <div className={classes.price}>
                <span className={classes.price_count}>{edit.payment.price} UAH</span>
                <span
                    className={classes.price_status}
                    style={{color: "#0B9F2B"}}
                >
                                {edit.payment.status.name}
                            </span>
            </div>

            <Button icon={"edit"} callBack={editRedirect}>
                Редагувати
            </Button>
        </div>
    );
};

export default BookingInfo;