import React from "react";
import classes from "./LanguageField.module.scss";

const LanguageField = ({ addClass, onChange, value, logo, type, readOnly}) => {
    let classNameInput = classes.input + " " + addClass;
    let classNameTextarea = classes.textarea + " " + addClass;
    return(
        <label className={classes.label}>
            {type == "field" &&
                <input readOnly={readOnly} className={classNameInput} type={"text"} onChange={onChange} value={value}  />
            }
            {type == "area" &&
                <textarea readOnly={readOnly} className={classNameTextarea} type={"text"} onChange={onChange} value={value}  />
            }
            <img className={classes.logo} src={logo}/>
        </label>
    )
}

export default LanguageField;