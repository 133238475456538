import React, {useState} from 'react'
import Page from "../../UI/Page/Page";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import TableGuides from "../../UI/Table/TableGuides";
import Button from "../../UI/Button";

const Guides = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [visible, changeVisible] = useState(true)

    const changeVisibleCallback = (e) => {
        e.preventDefault()
        changeVisible(visible ? false : true)
    }

    return (
        <Page
            pageName={"Гіди"}
            rightRest={
                <Button icon={"switchIcon"} callBack={changeVisibleCallback} color={"#d7d7d7"}>
                    {visible === true &&
                        <>Архів</>
                    }
                    {visible === false &&
                        <>Опубліковані</>
                    }
                </Button>
            }
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/guides/create')
                }, icon: 'add', color: ''
            }}
        >
            <TableGuides visible={visible}/>
        </Page>
    )
}

export default Guides