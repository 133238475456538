import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import bookingsApi from '../../../../api/BookingsApi';
import Page from '../../../UI/Page/Page'
import BookingsCreateFirstColumn from './BookingsCreateFirstColumn';
import BookingsCreateSecondColumn from './BookingsCreateSecondColumn';
import {useNavigate} from 'react-router-dom'

const BookingsCreate = () => {

    // const [stateSaveButton, setStateSaveButton] = useState(true);

    const [error, setError] = useState({
        date: '',
        solo: {},
        tickets: [],
    });

    const navigate = useNavigate();

    const excursions = useSelector(state => state.excursions.excursions).filter(item => item.status.key == 'active');

    const discounts = useSelector(state => state.discounts.discounts);
    const certificates = useSelector(state => state.сertificates.сertificates);
    const promocodes = useSelector(state => state.promocodes.promocodes);

    const languagesSelector = useSelector(state => state.languages.languages);

    const [data, setData] = useState({
        currentExcursion: null,
        excursionsItems: excursions.map(item => ({
            key: item.id, 
            value: item.name.find(name => name.default_language && name.default_language == true).text + ' (' + item.typeExcursion.name + ')', 
            selected: false
        })),
        excursionType: null,
        excursionId: null,
        date: '',
        startTime: '',
        endTime: '',
        name: '',
        surname: '',
        email: 'test@test.test',
        phoneNumber: '',
        confirmationMethod: [
            {key: 0, value: 'По телефону'},
            {key: 1, value: 'E-mail'},
            {key: 2, value: 'Telegram'},
            {key: 3, value: 'Viber'},
        ],
        guestsCount: 0,
        guestsCountMin: 0,
        guestsCountMax: 0,
        languages: [],
        comment: '',
        solo: {
            selectList: [
                {key: null, value: '...', selected: true},
                {key: 0, value: 'Знижка'},
                {key: 1, value: 'Сертифікат'},
                {key: 2, value: 'Промокод'},
            ],
            discounts: [],
            certificate: '',
            promocodes: [],
        },
        additionalComment: '',
        tickets: [],
        cost: 0,
        paymentStatus: [
            {key: 0, value: 'Не оплачено', selected: true},
            {key: 1, value: 'Оплачено'},
        ],
        additionalOptions: []
    });

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    useEffect(() => {

        let cost = 0;
        const exType = data.excursionType;

        if(exType && exType == 'ticket') {

            const appliedCerts = {},
                  appliedPromocodes = {};

            data.tickets.forEach(item => {

                let ticketCost = data.baseCost;
                
                const selectedDiscount = item.selectList.find(_item => _item.selected);

                if(selectedDiscount && selectedDiscount.key == 0 && item.discounts.find(_item => _item.selected)) {

                    const discount = item.discounts.find(_item => _item.selected).key,
                          percent = discounts.find(disc => disc.id == discount).percent;

                    ticketCost -= ticketCost * percent / 100

                }
                else if(selectedDiscount && selectedDiscount.key == 1 && item.certificate && data.date) {

                    const cert = certificates.find(cert => cert.code == item.certificate);

                    if(cert && cert.excursion_id == data.excursionId && cert.status.key == 'active' && new Date(data.date) / 1000 < cert.time.end.unix && !(appliedCerts.hasOwnProperty(cert.code) && appliedCerts[cert.code] >= cert.quantityPerson)) {

                        ticketCost -= ticketCost / cert.percent * 100;

                        if(appliedCerts.hasOwnProperty(cert.code)) appliedCerts[cert.code]++;
                        else appliedCerts[cert.code] = 1;
                    }

                }
                else if(selectedDiscount && selectedDiscount.key == 2 && item.promocodes.find(_item => _item.selected)) {

                    const prom_id = item.promocodes.find(_item => _item.selected).key,
                          promocode = promocodes.find(_item => _item.id == prom_id);

                    if(!appliedPromocodes.hasOwnProperty(promocode.code)) appliedPromocodes[promocode.code] = promocode.numberOfApplications;

                    if(promocode && new Date(data.date) / 1000 < promocode.time.end.unix && appliedPromocodes[promocode.code] < promocode.applicationLimit) {
                        ticketCost -= ticketCost * promocode.percent / 100;

                        appliedPromocodes[promocode.code] = appliedPromocodes[promocode.code] + 1;
                    }


                }

                item.additionalOptions.filter(opt => opt.selected).forEach(opt => ticketCost += opt.price)

                cost += ticketCost;

            });

            if(data.languages.find(lang => lang.selected)) cost += data.languages.find(lang => lang.selected).price

        }
        else if(exType && exType == 'small_group') {
            cost = Number(data.baseCost);

            if(data.solo.selectList.find(item => item.selected)?.key == 0 && data.solo.discounts.find(item => item.selected)) {
                const percent = discounts.find(item => item.id == data.solo.discounts.find(item => item.selected).key).percent
                cost -= (cost * percent / 100);
            }
            else if(data.solo.selectList.find(item => item.selected)?.key == 1 && data.solo.certificate) {
                const cert = certificates.find(item => item.code == data.solo.certificate && item.excursion_id == data.excursionId);
                
                if( cert &&
                    cert.excursion.id == data.excursionId &&
                    cert.status.key == 'active' &&
                    new Date(data.date) / 1000 < cert.time.end.unix) {

                    cost -= (cost * cert.percent / 100);
                }
            }
            else if(data.solo.selectList.find(item => item.selected)?.key == 2 && data.solo.promocodes.find(item => item.selected)) {

                const promocode = promocodes.find(item => item.id == data.solo.promocodes.find(_item => _item.selected).key);

                if(new Date(data.date) / 1000 < promocode.time.end.unix && promocode.numberOfApplications < promocode.applicationLimit) {
                    const percent = promocode.percent;
                    cost -= (cost * percent / 100);
                }

            }

            data.additionalOptions.filter(opt => opt.selected).forEach(opt => {
                cost += opt.price;
            });
            if(data.languages.find(lang => lang.selected)) cost += data.languages.find(lang => lang.selected).price
        }
        else if(exType && exType != 'ticket') {
            const pricePerPerson = data.currentExcursion.pricePerPerson.map(item => ({price: Number(item.price), persons: Number(item.persons)}));

            if(pricePerPerson.length && data.guestsCount >= pricePerPerson[0].persons) {

                cost += Number(data.baseCost) * (pricePerPerson[0].persons - 1);

                for(let i = 0; i < pricePerPerson.length; i++) {
                    if(i + 1 < pricePerPerson.length) {
                        const persons = clamp(data.guestsCount, pricePerPerson[i].persons, pricePerPerson[i + 1].persons - 1) - pricePerPerson[i].persons + 1,
                              calculatedCost = data.baseCost - (data.baseCost * pricePerPerson[i].price / 100);
                        cost += persons * calculatedCost;

                        if(data.guestsCount < pricePerPerson[i + 1].persons) break;
                    } else {

                        const persons = clamp(data.guestsCount, pricePerPerson[i].persons, data.guestsCountMax) - pricePerPerson[i].persons + 1,
                              calculatedCost = data.baseCost - (data.baseCost * pricePerPerson[i].price / 100);

                        cost += persons * calculatedCost;
                        break;
                    }

                }

            } else {
                cost = Number(data.guestsCount) * Number(data.baseCost);
            }

            if(data.solo.selectList.find(item => item.selected)?.key == 0 && data.solo.discounts.find(item => item.selected)) {
                const percent = discounts.find(item => item.id == data.solo.discounts.find(item => item.selected).key).percent
                cost -= (cost * percent / 100);
            }
            else if(data.solo.selectList.find(item => item.selected)?.key == 1 && data.solo.certificate) {
                const cert = certificates.find(item => item.code == data.solo.certificate && item.excursion_id == data.excursionId);
                
                if( cert &&
                    cert.excursion.id == data.excursionId &&
                    cert.status.key == 'active' &&
                    new Date(data.date) / 1000 < cert.time.end.unix) {

                    cost -= (cost * cert.percent / 100);
                }
            }
            else if(data.solo.selectList.find(item => item.selected)?.key == 2 && data.solo.promocodes.find(item => item.selected)) {

                const promocode = promocodes.find(item => item.id == data.solo.promocodes.find(_item => _item.selected).key);

                if(new Date(data.date) / 1000 < promocode.time.end.unix && promocode.numberOfApplications < promocode.applicationLimit) {
                    const percent = promocode.percent;
                    cost -= (cost * percent / 100);
                }

            }

            data.additionalOptions.filter(opt => opt.selected).forEach(opt => {
                cost += opt.price;
            });
            if(data.languages.find(lang => lang.selected)) cost += data.languages.find(lang => lang.selected).price

        }

        setData({
            ...data,
            cost: Math.floor(cost),
        })


    }, [
        data.baseCost, 
        data.guestsCount, 
        data.solo.discounts, 
        data.solo.certificate, 
        data.solo.promocodes, 
        data.tickets,
        data.additionalOptions,
        data.languages
    ]);

    const validate = () => {

        const selectedDiscountType = data.solo.selectList.find(item => item.selected);
        const newErrorObj = {
                ...error,
                excursion: data.excursionId ? false : 'Виберіть екскурсію',
                date: data.date ? false : 'Виберіть дату',
                startTime: data.startTime ? false : 'Виберіть час',
                endTime: data.endTime ? false : 'Виберіть час',
                email: data.email ? false : 'Введіть e-mail',
                phoneNumber: data.phoneNumber ? (data.phoneNumber.length == 13 ? false : 'Номер телефону складається з 13 символів') : 'Введіть номер телефону',
                guestsCount: data.excursionType == 'ticket' ? false : (data.guestsCount < data.guestsCountMin ? `Мінімальна кількість гостей ${data.guestsCountMin}` : (data.      guestsCount > data.guestsCountMax ? `Максимальна кількість гостей ${data.guestsCountMax}` : false)),
                languages: data.languages.find(item => item.selected) ? false : 'Виберіть мову проведення',
                confirmationMethod: data.confirmationMethod.find(item => item.selected) ? false : 'Виберіть спосіб підтвердження',
                solo: {
                    discount: selectedDiscountType && selectedDiscountType.key == 0 && data.solo.discounts.find(item => item.selected) === undefined ? 'Виберіть знижку' : '',
                    certificate: error.solo.certificate ? error.solo.certificate : (selectedDiscountType && selectedDiscountType.key == 1 && !data.solo.certificate ? 'Введіть       сертифікат' : ''),
                    promocode: selectedDiscountType && selectedDiscountType.key == 2 && data.solo.promocodes.find(item => item.selected) === undefined ? 'Виберіть промокод' :       '',
                },
                tickets: data.tickets.map(ticket => {
                    const selectedTicketDiscountType = ticket.selectList.find(item => item.selected);

                    return {
                        id: ticket.id,
                        discount: selectedTicketDiscountType && selectedTicketDiscountType.key == 0 && ticket.discounts.find(item => item.selected) == undefined ? 'Виберіть знижку' : '',
                        certificate: error.tickets.find(item => item.id == ticket.id)?.certificate ? error.tickets.find(item => item.id == ticket.id).certificate :
                            (selectedTicketDiscountType && selectedTicketDiscountType.key == 1 && !ticket.certificate ? 'Введіть сертифікат' : ''),
                        promocode: selectedTicketDiscountType && selectedTicketDiscountType.key == 2 && ticket.promocodes.find(item => item.selected) == undefined ? 'Виберіть промокод' : '',
                    }
                }),
                emptyTickets: data.excursionType == 'ticket' && !data.tickets.length ? 'Додайте квиток' : false,
            };

        setError(newErrorObj);

        for(let property in newErrorObj) {
            if(newErrorObj.hasOwnProperty(property) && property != 'solo' && property != 'tickets' && newErrorObj[property]) {
                return false;
            }
            else if(property == 'solo') {
                for(let soloProperty in newErrorObj[property]) {
                    if(newErrorObj[property].hasOwnProperty(soloProperty) && newErrorObj[property][soloProperty]) {
                        return false;
                    }
                }
            }
            else if(property == 'tickets') {
                for(let key in newErrorObj[property]) {
                    if(newErrorObj[property].discount || newErrorObj[property].certificate || newErrorObj[property].promocode) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    const submitForm = async () => {

        if(!validate()) return;

        const sendedObj = {
            excursion: data.excursionId,
            date: data.date,
            startTime: data.startTime,
            endTime: data.endTime,
            name: data.name,
            surname: data.surname,
            email: data.email,
            phoneNumber: data.phoneNumber,
            confirmationMethod: data.confirmationMethod.find(item => item.selected).key,
            guestsCount: data.guestsCount,
            languages: data.languages.find(item => item.selected).key,
            comment: data.comment,
            additionalComment: data.additionalComment,
            cost: data.cost,
            paymentStatus: data.paymentStatus.find(item => item.selected).key,
            additionalOptions: data.additionalOptions.filter(opt => opt.selected).map(opt => opt.key)
        };

        if(data.solo.selectList.find(item => item.selected)) {
            sendedObj.discountType = data.solo.selectList.find(item => item.selected).key;
            if(data.solo.selectList.find(item => item.selected)?.key == 0 && data.solo.discounts.find(item => item.selected)) sendedObj.discount = data.solo.discounts.find(item => item.selected).key;
            if(data.solo.selectList.find(item => item.selected)?.key == 1 && data.solo.certificate) sendedObj.discount = data.solo.certificate;
            if(data.solo.selectList.find(item => item.selected)?.key == 2 && data.solo.promocodes.find(item => item.selected).key) sendedObj.discount = data.solo.promocodes.find(item => item.selected).key;
        }

        if(data.tickets.length > 0) {
            sendedObj.tickets = data.tickets.map(ticket => ({
                discountType: ticket.selectList.find(item => item.selected)?.key,
                discount: ticket.selectList.find(item => item.selected) && ticket.selectList.find(item => item.selected).key != '...' ?
                            (ticket.selectList.find(item => item.selected).key == 0 ? ticket.discounts.find(item => item.selected)?.key :
                            (ticket.selectList.find(item => item.selected).key == 1 ? ticket.certificate :
                            ticket.promocodes.find(item => item.selected)?.key)) : 
                            null,
                additionalOptions: ticket.additionalOptions.filter(opt => opt.selected).map(opt => opt.key),
            }));
        }

        const response = await bookingsApi.put(sendedObj);

        navigate(`/bookings/${response.data.id}`);
    }

    return (
        <Page
            breadcrumbs={{link: '/bookings', text: 'Бронювання'}}
            rightButton={{
                content: 'Зберегти',
                callback: submitForm,
                icon: 'add',
                color: '',
                form: 'bookings_form',
                // disabled: stateSaveButton
            }}
            rightRest={
                <div style={{display: 'flex', flexDirection: 'column', gap: 18, marginTop: 10, marginRight: 75}}>
                    <div style={{fontSize: 16, color: '#676767', fontWeight: 500}}>Сума замовлення</div>
                    <div style={{fontSize: 30, fontWeight: 700}}>{data.cost} грн</div>
                </div>
            }
        >
            <div style={{height: "100%", background: "#FFF", padding: "19px"}}>
            
                <form id="bookings_form" onSubmit={e => e.preventDefault()}>

                    <BookingsCreateFirstColumn 
                        data={data}
                        setData={setData}
                        error={error}
                        setError={setError}
                    />
                    <BookingsCreateSecondColumn 
                        data={data}
                        setData={setData}
                        error={error}
                        setError={setError}
                    />

                    <div></div>

                </form>
                
            </div>

        </Page>
    )
}

export default BookingsCreate



/**
 * 29.12
 * 12:00
 * 555 uah
 * min 12
 * max 15
 */