import React, {useState} from 'react'
import Page from "../../UI/Page/Page";
import TableDiscounts from "../../UI/Table/TableDiscounts";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useRole} from '../../../hooks/useRole';
import Button from "../../UI/Button";

const Discounts = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const role = useRole();

    const [visible, changeVisible] = useState(true)

    const changeVisibleCallback = (e) => {
        e.preventDefault()
        changeVisible(visible ? false : true)
    }

    return (
        <Page
            pageName={"Знижки"}
            rightRest={
                <Button icon={"switchIcon"} callBack={changeVisibleCallback} color={"#d7d7d7"}>
                    {visible === true &&
                        <>Архів</>
                    }
                    {visible === false &&
                        <>Опубліковані</>
                    }
                </Button>
            }
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/discounts/create')
                }, 
                icon: 'add', 
                color: '',
                disabled: role > 1
            }}
        >
            <TableDiscounts visible={visible}/>
        </Page>
    )
}

export default Discounts