import React from 'react'
import {useNavigate} from "react-router-dom";
import Page from "../../UI/Page/Page";
import TableExcursions from "../../UI/Table/TableExcursions";

const Excursions = () => {
    const navigate = useNavigate()
    return (
        <Page
            pageName={"Екскурсії"}
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/excursions/create')
                }, icon: 'add', color: ''
            }}
        >
            <TableExcursions/>
        </Page>
    )
}

export default Excursions