import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import {changeCurrentPageReports, getReports} from "../../../redux/reducers/reportsReducer";
import TableItemLogo from "./TableItem/Items/TableItemLogo/TableItemLogo";
import TableHeaderCheckbox from "./TableHeader/Items/TableHeaderCheckbox";
import TableItemCheckbox from "./TableItem/Items/TableItemCheckbox/TableItemCheckbox";
import PageLoader from "../PageLoader/PageLoader";
import Pagination from "../Pagination/Pagination";
import ReactPaginate from "react-paginate"

const TableReports = () => {
    const dispatch = useDispatch()
    const reports = useSelector(state => state.reports.reports);
    const currentPage = useSelector(state => state.reports.currentPage);
    const filters = useSelector(state => state.reports.filters)

    const [items, changeItems] = useState([])
    const [sumAll, changeSumAll] = useState(0)

    let header = [
        {
            width: 2,
            component: () => <TableHeaderText itemName="Дата"/>,
        },
        {
            width: 3,
            component: () => <TableHeaderText itemName="Гід"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Відпрац. год."/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Години простою"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Вартість год.пр."/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Сума"/>,
        },
        // {
        //     width: 1,
        //     component: () => <TableHeaderCheckbox value={false} callback={() => {
        //     }}/>,
        // },
    ];
    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    const sum = (arr) => {
        const array1 = arr;
        const initialValue = 0;
        const sumWithInitial = array1.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            initialValue
        );

        return sumWithInitial;
    }
    const changePage = (page) => {
        dispatch(changeCurrentPageReports(page.selected + 1))
    }

    useEffect(() => {
        if (items.length == 0) {
            dispatch(getReports({}))
        }
    }, [])
    useEffect(() => {
        if (reports) {
            let arrSum = reports.flat().map(item => {
                return item.totalPrice
            })

            let sumValue = sum(arrSum)
            changeSumAll(sumValue)
        }
    }, [reports])
    useEffect(() => {
        if (reports && reports.length != 0) {
            changeItems(reports[currentPage - 1])
        }
    }, [reports, currentPage])
    useEffect(() => {
        changeItems([])
    }, [filters])

    if (items && items.length != 0) {
        return (
            <div>
                <Table header={header} items={items} columns={columns}>
                    {
                        items.map((item, index) => {
                            return (
                                <TableItem
                                    key={`${item.guide.name} ${item.guide.surname} ${item.guide.patronymic}` + item.totalPrice + item.hoursWorked + index}
                                    columns={columns}
                                >
                                    <TableItemText text={item.time.date}/>
                                    <TableItemLogo
                                        link={item.guide.photo}
                                        size={"small"}
                                        text={`${item.guide.name} ${item.guide.surname} ${item.guide.patronymic}`}
                                    />
                                    <TableItemText text={item.hoursWorked}/>
                                    <TableItemText text={item.downTime}/>
                                    <TableItemText text={item.guide.downTimePrice}/>
                                    <TableItemText text={Number(item.totalPrice.toFixed(0))}/>
                                    {/*<TableItemCheckbox*/}
                                    {/*    type={item.type}*/}
                                    {/*    id={item.id}*/}
                                    {/*    callback={() => {*/}
                                    {/*}} value={false}*/}
                                    {/*/>*/}
                                </TableItem>
                            )
                        })
                    }
                    {items && items.length != 0 &&
                        <TableItem>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                alignItems: "center",
                                padding: "0px 15px"
                            }}>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "30px",
                                }}>
                                    <p style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        color: "#676767",
                                    }}>
                                        Сума за період
                                    </p>
                                    <p style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "30px",
                                        lineHeight: "37px",
                                        color: "#000000",
                                    }}> {Number(sumAll.toFixed(0))} грн </p>
                                </div>

                                {/*<ReactPaginate*/}
                                {/*    pageCount={reports.length} onPageChange={changePage}*/}
                                {/*    previousLabel={}*/}
                                {/*/>*/}

                                <Pagination
                                    style={{marginLeft: "auto"}}
                                    currentPage={currentPage}
                                    allPage={reports.length}
                                    callback={changePage}
                                />

                            </div>
                        </TableItem>
                    }

                </Table>
            </div>
        );
    } else {
        return (
            <div style={{background: "#FFF", height: "100%"}}>
                <PageLoader/>
            </div>
        )

    }

};

export default TableReports;