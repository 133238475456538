import TimeTableApi from "../../api/TimeTableApi";

const SET_TABLES = "SET_TABLES";
const CHANGE_CURRENT_EXCURSION = "CHANGE_CURRENT_EXCURSION";
const CHANGE_EDIT_OBJECT = "CHANGE_EDIT_OBJECT";
const CHANGE_EDIT_PRICE = "CHANGE_EDIT_PRICE"
const CHANGE_EDIT_TICKETS = "CHANGE_EDIT_TICKETS"
const CHANGE_EDIT_COUNT_FROM = "CHANGE_EDIT_COUNT_FROM"
const CHANGE_EDIT_COUNT_TO = "CHANGE_EDIT_COUNT_TO"
const CHANGE_EDIT_TIME_START = "CHANGE_EDIT_TIME_START"
const CHANGE_EDIT_TIME_END = "CHANGE_EDIT_TIME_END"
const CHANGE_EDIT_STATUS = "CHANGE_EDIT_STATUS"
const CHANGE_TIME = "CHANGE_TIME"
const CHANGE_ACTIVE_DAY = "CHANGE_ACTIVE_DAY"
const CHANGE_CURRENT_TABLE = "CHANGE_CURRENT_TABLE"
const CHANGE_DATA = "CHANGE_DATA"
const CHANGE_EDIT_DAY = "CHANGE_EDIT_DAY"
const SET_EDIT_IN_DAY = "SET_EDIT_IN_DAY"
const CHANGE_ACCEPT = "CHANGE_ACCEPT"
const ADD_NEW_SLOT = "ADD_NEW_SLOT"
const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE"

const dateObj = new Date()
const defaultState = {
    data: {
        time: {
            unix: dateObj.valueOf(),
        },
        validity: {
            unix: 0,
            date: null,
        },
        excursion: null,
    },
    currentTable: null,
    disabledButton: true,
    editObject: null,
    editDay: null,
    errorMessage: null,
}

export const tablesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_TABLES:
            return {
                ...state,
                tables: action.tables,
            }
        case CHANGE_CURRENT_EXCURSION:
            return {
                ...state,
                data: {
                    ...state.data,
                    excursion: action.excursion
                }
            }
        case CHANGE_CURRENT_TABLE:
            return {
                ...state,
                currentTable: action.table
            }
        case CHANGE_DATA:
            return {
                ...state,
                data: action.data
            }
        case SET_EDIT_IN_DAY:
            return {
                ...state,
                editDay: {
                    ...state.editDay,
                    slots: [...state.editDay.slots].map(slot => {
                        if(slot.id == state.editObject.id){
                            return (state.editObject)
                        } else {
                            return slot
                        }
                    })
                }
            }
        case CHANGE_EDIT_OBJECT:
            return {
                ...state,
                editObject: action.value,
            }
        case CHANGE_EDIT_DAY:
            if(action.value){
                return {
                    ...state,
                    editDay: {
                        ...action.value,
                        excursion_id: Number(action.id)
                    },
                }
            } else {
                return {
                    ...state,
                    editDay: null,
                }
            }

        case CHANGE_EDIT_PRICE:
            return {
                ...state,
                editObject: {
                    ...state.editObject,
                    price: action.price,
                }
            }
        case CHANGE_EDIT_TICKETS:
            return {
                ...state,
                editObject: {
                    ...state.editObject,
                    tickets: action.tickets,
                }

            }
        case CHANGE_EDIT_COUNT_FROM:
            return {
                ...state,
                editObject: {
                    ...state.editObject,
                    count: {
                        ...state.editObject.count,
                        from: action.countFrom
                    },
                }

            }
        case CHANGE_EDIT_COUNT_TO:
            return {
                ...state,
                editObject: {
                    ...state.editObject,
                    count: {
                        ...state.editObject.count,
                        to: action.countTo
                    },
                }

            }
        case CHANGE_EDIT_TIME_START:
            return {
                ...state,
                editObject: {
                    ...state.editObject,
                    time: {
                        ...state.editObject.time,
                        start: {
                            ...state.editObject.time.start,
                            time: action.time
                        }
                    },
                }

            }
        case CHANGE_EDIT_TIME_END:
            return {
                ...state,
                editObject: {
                    ...state.editObject,
                    time: {
                        ...state.editObject.time,
                        end: {
                            ...state.editObject.time.end,
                            time: action.time
                        }
                    },
                }
            }
        case CHANGE_EDIT_STATUS:
            return {
                ...state,
                editObject: {
                    ...state.editObject,
                    status: action.status
                }
            }
        case CHANGE_TIME:
            return {
                ...state,
                data: {
                    ...state.data,
                    time: {
                        ...state.data.time,
                        unix: action.time,
                    }
                }
            }
        case CHANGE_ACTIVE_DAY:

            return {
                ...state,
                currentTable: state.currentTable.map((day, index) => {
                    if (day.dayOfWeek == action.dayOfWeek) {

                        let newDay = day
                        newDay.status = action.value
                        return newDay;
                    } else {
                        return day
                    }
                })
            }
        case CHANGE_ACCEPT:
            return {
                ...state,
                editObject: {
                    ...state.editObject,
                    acceptAllTabel: action.value,
                }
            }
        case ADD_NEW_SLOT:
            return {
                ...state,
                editDay: {
                    ...state.editDay,
                    slots: [...state.editDay.slots, {...state.editObject} ]
                }
            }
        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.message
            }
        default:
            return state;
    }
}


export const setTables = (tables) => ({type: SET_TABLES, tables});
export const changeCurrentExcursion = (excursion) => ({type: CHANGE_CURRENT_EXCURSION, excursion});
export const changeCurrentTable= (table) => ({type: CHANGE_CURRENT_TABLE, table});
export const changeData = (data) => ({type: CHANGE_DATA, data});

export const changeEditObject = (value) => ({type: CHANGE_EDIT_OBJECT, value});
export const changeEditDay = (value, id) => ({type: CHANGE_EDIT_DAY, value, id});
export const setEditInDay = () => ({type: SET_EDIT_IN_DAY })
export const changeEditPrice = (price) => ({type: CHANGE_EDIT_PRICE, price})
export const changeEditTickets = (tickets) => ({type: CHANGE_EDIT_TICKETS, tickets})
export const changeEditCountFrom = (countFrom) => ({type: CHANGE_EDIT_COUNT_FROM, countFrom})
export const changeEditCountTo = (countTo) => ({type: CHANGE_EDIT_COUNT_TO, countTo})
export const changeEditTimeStart = (time) => ({type: CHANGE_EDIT_TIME_START, time})
export const changeEditTimeEnd = (time) => ({type: CHANGE_EDIT_TIME_END, time});
export const changeEditStatus = (status) => ({type: CHANGE_EDIT_STATUS, status});
export const changeTime = (time) => ({type: CHANGE_TIME, time});
export const changeActiveDay = (value, dayOfWeek) => ({type: CHANGE_ACTIVE_DAY, value, dayOfWeek})
export const changeAccept = (value) => ({type: CHANGE_ACCEPT, value})
export const addNewSlot = () => ({type: ADD_NEW_SLOT})
export const setErrorMessage = (message) => ({type: SET_ERROR_MESSAGE, message})


export const getTimeTable = (data) => async (dispatch) => {
    await TimeTableApi.post(data).then(response => {
        //console.log(response.data)
        dispatch(changeCurrentTable(null))
        dispatch(changeData(response.data.data))
        setTimeout(() => {
            dispatch(changeCurrentTable(response.data.currentTable))
        }, 300)
    });
}

export const resetShedule = (id) => async (dispatch) => {
    await TimeTableApi.resetShedule(id).then(response => {
        document.location.reload()
    });
}
export const resetDay = (data) => async (dispatch) => {
    await TimeTableApi.resetDay(data).then(response => {
        document.location.reload()
    });
}

export const editSlot = (data, dataTable) => async (dispatch) => {
    await TimeTableApi.edit(data).then(response => {
        console.log(response.data)
        if(response.data?.error) {
            dispatch(setErrorMessage(response.data.error))
        }
        dispatch(getTimeTable(dataTable))
    });
}
export const changeDayStatus = (data) => async (dispatch) => {
    await TimeTableApi.setDayStatus(data).then(response => {
    });
}
export const setValidity = (data) => async (dispatch) => {
    await TimeTableApi.setValidity(data).then(response => {
    });
}

