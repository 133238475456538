import React, {useEffect, useState} from "react";
import classes from "./TableItemCheckbox.module.scss";
import {useDispatch} from "react-redux";
import {checkCertificate} from "../../../../../../redux/reducers/сertificatesReducer";

const TableItemCheckbox = ({type, id, callback, value}) => {

    let onChange = () => {
        callback(id, value);
    }
    return(
        <div className={classes.cell}>
            <input type={"checkbox"} checked={value} onChange={onChange}/>
        </div>
    )
}

export default TableItemCheckbox;