import React, {useRef, useState} from 'react';
import classes from "./Search.module.scss";

const Search = ({onSubmit, onDefaultState, placeholder}) => {
    const [stateButton, changeStateButton] = useState(true)

    const onChangeInput = (e) => {
        let value = e.currentTarget.value;
        if(value) {
            changeStateButton(false)
        } else {
            changeStateButton(true)
            if(onDefaultState) {
                onDefaultState()
            }
        }
    }

    return (
        <form className={classes.form} onSubmit={onSubmit}>

            <input
                className={classes.input}
                type="text"
                name="field"
                placeholder={placeholder}
                onChange={onChangeInput}
            />

            <button className={classes.button} disabled={stateButton}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11Z" fill="#B5B5B5"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.9429 15.9428C16.3334 15.5523 16.9666 15.5523 17.3571 15.9428L21.7071 20.2928C22.0977 20.6833 22.0977 21.3165 21.7071 21.707C21.3166 22.0975 20.6834 22.0975 20.2929 21.707L15.9429 17.357C15.5524 16.9665 15.5524 16.3333 15.9429 15.9428Z" fill="#B5B5B5"/>
                </svg>
            </button>
        </form>
    );
};

export default Search;