import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Table from "./Table";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";
import {getFields} from "../../../redux/reducers/translateReducer";
import {useEffect} from "react";
import PageLoader from "../PageLoader/PageLoader";

const TableTranslate = () => {
    const dispatch = useDispatch()
    const items = useSelector(state => state.translate.fields);

    let header = [
        {
            width: 2,
            component: () => <TableHeaderText itemName="Ключ"/>,
        },
        {
            width: 5,
            component: () => <TableHeaderText itemName="Текст"/>,
        },
        // {
        //     width: 2,
        //     component: () => <TableHeaderText itemName="Перекладів"/>,
        // },
        {
            width: 1,
            component: () => <TableHeaderText itemName=""/>,
        },
    ];

    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    useEffect(() => {
        if (items) {
            dispatch(getFields())
        }
    }, [])

    if(items && items.length != 0){
        return (
            <div>
                <Table header={header} items={items} columns={columns}>
                    {
                        items.map(item => {
                            return (
                                <TableItem key={item.identifier} columns={columns}>
                                    <TableItemText text={item.identifier} strong={true}/>
                                    <TableItemText text={item.text.find(lang => lang.default_language).text}/>
                                    {/*<TableItemText text={""}/>*/}
                                    <TableItemEdit type={item.type} id={item.id} style={{minWidth: "58px"}}/>
                                </TableItem>
                            )
                        })
                    }
                </Table>
            </div>
        );
    } else {
        return (
            <PageLoader/>
        )
    }

};

export default TableTranslate;