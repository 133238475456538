import axios from "axios";

export default class AccountApi {

    static async auth(userObj) {
        const {email, password} = userObj;

        const response = await axios.post('https://api.0300.com.ua/api/auth', {
            email: email,
            password: password,
        });

        return response;
    }

    static async isAuth(branchId = null) {

        const token = localStorage.getItem('auth_token')
        if(!token) return false

        const response = await axios.post('https://api.0300.com.ua/api/isauth', {
            token: token,
            branchId
        })

        return response

    }

    static async resetPassword(email) {
        const response = await axios.post('https://api.0300.com.ua/api/resetpassword', {
            email,
        });

        return response;
    }

    static async sendNewPassword(obj) {
        const response = await axios.post('https://api.0300.com.ua/api/sendnewpassword', {
            ...obj,
        });

        return response;
    }

    static async logout() {

        const token = localStorage.getItem('auth_token')
        
        if(!token) return false

        const response = await axios.post('https://api.0300.com.ua/api/logout', {
            token: token
        })

        return response
    }

}