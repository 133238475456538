import LanguagesApi from "./../../api/LanguagesApi";

const SET_LANGUAGES = "SET_LANGUAGES";
const CHANGE_CURRENT_LANGUAGE = "CHANGE_CURRENT_LANGUAGE";
const RESET_CURRENT_LANGUAGE = "RESET_CURRENT_LANGUAGE";

let initialState = {
    languages: [],
    currentEditLanguage: {
        logo: {
            file: null,
            src: '',
        },
        name: '',
        price: '',
        slug: '',
        default_language: false,
    },
};

export const languagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANGUAGES: {
            let stateCopy = {
                ...state,
                languages: action.languages,
            }
            return stateCopy;
        }
        case CHANGE_CURRENT_LANGUAGE: {
            return {...state, currentEditLanguage: {...state.currentEditLanguage, ...action.payload}}
        }
        case RESET_CURRENT_LANGUAGE: {
            return {...state, currentEditLanguage: {logo: {file: null, src: ''}, name: '', price: '', default_language: false}}
        }
        default:
            return state;
    }
}

export default languagesReducer;

export const setLanguages = (languages) => ({type: SET_LANGUAGES, languages});
export const getLanguages = () => async (dispatch) => {
    await LanguagesApi.getAll().then(response => {
        dispatch(setLanguages(response.data));
    });
}
export const toArchiveLanguage = (data) => async (dispatch) => {
    await LanguagesApi.deleteLanguage(data).then(response => {
        document.location.reload()
    });
}