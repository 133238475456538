import React from "react";
import classes from "./Status.module.scss";

const Status = ({children}) => {
    return(
        <div className={classes.status}>
            {children}
        </div>
    )
}

export default Status;