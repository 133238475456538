import React, {useRef, useState} from 'react';
import classes from "./CalendarTable.module.scss";
import Schedule from "./Schedule/Schedule";
import BookingsContainer from "./BookingsContainer/BookingsContainer";
import {useEffect} from "react";
import {
    changeParamsExcursion,
    changeParamsTime,
    getTimeTable,
    setEditObject
} from "../../../redux/reducers/calendarReducer";
import {useDispatch, useSelector} from "react-redux";
import PageLoader from "../PageLoader/PageLoader";

const CalendarTable = () => {
    const dispatch = useDispatch()
    const bookings = useSelector(state => state.calendar.bookings)
    const startDay = useSelector(state => state.calendar.startDay)
    const paramsRequest = useSelector(state => state.calendar.params)
    const branch = useSelector(state => state.account.branch)

    const containerRef = useRef(null);
    const [heightSchedule, changeHeightSchedule] = useState(null)
    const [translateTime, changeTranslateTime] = useState(0)

    const onScroll = (e) => {
        let target = e.currentTarget.scrollLeft;
        changeTranslateTime(target)
    }

    useEffect(() => {
        if (containerRef.current && startDay) {
            changeHeightSchedule(containerRef.current.scrollHeight + 20)
            let startDayArr = startDay.split(':')
            let startHour = Number(startDayArr[0]);
            containerRef.current.scrollLeft = startHour * 180
        }
    }, [startDay])
    useEffect(() => {
        if (paramsRequest.time) {
            const objTime = new Date(paramsRequest.time)
            const objRequest = {
                time: {
                    day: objTime.getDate(),
                    month: objTime.getMonth() + 1,
                    year: objTime.getFullYear(),
                },
                excursion: paramsRequest.excursion,
                guide: paramsRequest.guide,
                branch_id: branch
            }
            dispatch(getTimeTable(objRequest))
        }
    }, [paramsRequest])
    useEffect(() => {
        let currentTimeObj = new Date()
        dispatch(changeParamsTime(currentTimeObj.getTime()))
        dispatch(changeParamsExcursion(null));
        dispatch(setEditObject(false));
    }, [])

    if (bookings && bookings.length != 0) {
        return (
            <div className={classes.container}>
                <div ref={containerRef} className={classes.tabel} onScroll={onScroll}>
                    <Schedule
                        // style={{height: heightSchedule}}
                        translateTime={translateTime}
                    />
                    <BookingsContainer startDay={startDay} arrBooking={bookings}/>
                </div>
            </div>
        );
    } else if(Array.isArray(bookings) && bookings.length == 0) {
        return (
            <h3 style={{textAlign: "center", margin: "50px 0px"}}>
                Бронювань не знайдено
            </h3>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
};

export default CalendarTable;