import React, {useEffect, useState} from "react";
import DayWeek from "./Items/DayWeek";
import ToggleCheckbox from "../../ToggleCheckbox/ToggleCheckbox";
import ButtonAdd from "../../ButtonAdd/ButtonAdd";
import ButtonDelete from "../../ButtonDelete/ButtonDelete";
import classes from "./HeaderTimeTable.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {
    addNewSlot,
    changeActiveDay,
    changeDayStatus,
    changeEditDay,
    changeEditObject, editSlot, resetDay
} from "../../../../redux/reducers/timeTableExcursionReducer";
import {useParams} from "react-router-dom";
import ButtonHeaderDay from "../../ButtonHeaderDay/ButtonHeaderDay";
import Modal from "../../Modal/Modal";

const HeaderTimeTable = ({table, time}) => {
    const timeObj = new Date(time.unix);
    const dispatch = useDispatch()
    const params = useParams()
    const timeReducer = useSelector(state => state.timeTablesExcursions.data.time)
    const excursions = useSelector(state => state.excursions.excursions)
    const [currentExcursions, changeCurrentExcursions] = useState(excursions.find(item => item.id == params.id))

    const [resetDayModal, changeResetDayModal] = useState({
        active: false,
        excursion_id: params.id,
        day: null,
        month: null,
        year: null
    })
    const [deleteSlotsModal, changeDeleteSlotsModal] = useState(false)

    const callbackChangeStatusDay = (value, day) => {
        dispatch(changeActiveDay(value, day.dayOfWeek))
        dispatch(changeDayStatus({
            excursion_id: params.id,
            status: day.status,
            date: day.date,
            slots: day.slots
        }))
    }
    const addSlot = (day) => {
        dispatch(changeEditObject({
            id: 'new',
            type: currentExcursions.typeExcursion.key == "ticket" ? "ticket" : "group",
            time: {
                start: {
                    unix: null,
                    time: null,
                    date: `${day.date.day < 10 ? "0" + day.date.day : day.date.day}.${day.date.month < 10 ? "0" + day.date.month : day.date.month}.${day.date.year}`
                },
                end: {
                    unix: null,
                    time: null,
                    date: `${day.date.day < 10 ? "0" + day.date.day : day.date.day}.${day.date.month < 10 ? "0" + day.date.month : day.date.month}.${day.date.year}`
                },
                duration: {
                    unix: null,
                    time: null
                }
            },
            price: null,
            status: false,
            count: {
                from: null,
                to: null
            }
        }))
        dispatch(changeEditDay(day, params.id))
        dispatch(addNewSlot())
    }
    const deleteSlots = (day) => {
        let newDay = day
        newDay.slots = []
        newDay.excursion_id = Number(params.id)
        dispatch(editSlot(newDay, {
            id: params.id,
            time: timeReducer.unix
        }))
        dispatch(changeEditObject(null))
        dispatch(changeEditDay(null))
    }

    const showReset = (day) => {
        changeResetDayModal(prevState => {
            return  {
                ...prevState,
                active: true,
                ...day.date,
            }
        })
    }
    const hideReset = (e) => {
        e.preventDefault()
        changeResetDayModal(prevState => {
            return  {
                ...prevState,
                active: false,
                day: null,
                month: null,
                year: null
            }
        })
    }
    const acceptResetDay = (e) => {
        e.preventDefault()

        dispatch(resetDay(resetDayModal))

        changeResetDayModal(prevState => {
            return  {
                ...prevState,
                active: false,
                day: null,
                month: null,
                year: null
            }
        })
    }

    return (
        <>
            <div className={classes.header}>
                {
                    table.map((day, index) => {
                        const classesDay = [classes.day]
                        if (day.custom) classesDay.push(classes.custom)

                        return (
                            <div key={day.dayOfWeek} className={classesDay.join(" ")}>

                                <div className={classes.col_left}>
                                    <ToggleCheckbox
                                        checked={day.status}
                                        callBack={(value) => {
                                            callbackChangeStatusDay(value, day)
                                        }}
                                    />
                                    <DayWeek day={day.date.day} dayOfWeek={day.dayOfWeek} month={day.date.month}/>
                                </div>

                                <div className={classes.col_right}>

                                    <ButtonHeaderDay
                                        callback={(e) => {
                                            e.preventDefault()
                                            showReset(day)
                                        }}
                                        description={"Скинути день"}
                                    >
                                        <svg width="20px" height="20px" viewBox="0 0 1300 1094.37">
                                            <path
                                                fill="#ff1f1f"
                                                d="M1194.92,597.4q2.25-24.9,2.27-50.21A546.77,546.77,0,0,0,650,0C535.24,0,425.39,35.14,332.31,101.64L350.45,127l18.39,25.73L387,178.15A449.83,449.83,0,0,1,650,94c249.87,0,453.15,203.29,453.15,453.16q0,8.05-.28,16.09l-98.5-36.51,41.17,241.75,11.75,69,98.86-81.95L1300,636.36Z"
                                            />
                                            <path
                                                fill="#ff1f1f"
                                                d="M939.88,953.81l-8.71-12.19h0L913,916.22a449.83,449.83,0,0,1-263,84.12c-249.87,0-453.15-203.29-453.15-453.15q0-8.07.28-16.1l98.5,36.52L254.46,325.85l-11.75-69-98.86,81.95L0,458l105.08,39q-2.25,24.91-2.27,50.22A546.77,546.77,0,0,0,650,1094.37c114.76,0,224.61-35.14,317.69-101.64l-18.14-25.39Z"
                                            />
                                        </svg>
                                    </ButtonHeaderDay>

                                    <ButtonHeaderDay
                                        callback={(e) => {
                                            e.preventDefault()
                                            addSlot(day)
                                        }}
                                        description={"Додати слот"}
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="#FFD52F"/>
                                        </svg>
                                    </ButtonHeaderDay>

                                    <ButtonHeaderDay
                                        callback={(e) => {
                                            e.preventDefault()
                                            changeDeleteSlotsModal(day)
                                        }}
                                        description={"Видалити слоти"}
                                    >
                                        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 16C1.00158 16.5299 1.2128 17.0377 1.58753 17.4125C1.96227 17.7872 2.47005 17.9984 3 18H11C11.5299 17.9984 12.0377 17.7872 12.4125 17.4125C12.7872 17.0377 12.9984 16.5299 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#B5B5B5"/>
                                        </svg>
                                    </ButtonHeaderDay>

                                </div>

                            </div>
                        )
                    })
                }
            </div>

            <Modal
                active={resetDayModal.active}
                title={"Ви впевнені що хочете скинути день?"}
                type={"error"}
            >
                <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                    <button onClick={hideReset} style={{background: "#ECECEC"}}>
                        Закрити
                    </button>
                    <button onClick={acceptResetDay}>
                        Скинути
                    </button>
                </div>
            </Modal>

            <Modal
                active={deleteSlotsModal}
                title={"Ви впевнені що хочете видалити всі слоти?"}
                type={"error"}
            >
                <p style={{textAlign: "center"}}>Всі слоти цього дня будуть видалені</p>
                <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                    <button onClick={(e) => {e.preventDefault(); changeDeleteSlotsModal(false)}} style={{background: "#ECECEC"}}>
                        Закрити
                    </button>
                    <button onClick={(e) => {e.preventDefault(); deleteSlots(deleteSlotsModal); changeDeleteSlotsModal(false)}}>
                        Видалити
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default HeaderTimeTable;
