import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkAllCertificates, checkCertificate} from "../../../redux/reducers/сertificatesReducer";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import TableHeaderCheckbox from "./TableHeader/Items/TableHeaderCheckbox";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemColorLabel from "./TableItem/Items/TableItemColorLabel/TableItemColorLabel";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";
import TableItemCheckbox from "./TableItem/Items/TableItemCheckbox/TableItemCheckbox";
import TableItemStatusActive from "./TableItem/Items/TableItemStatusActive/TableItemStatusActive";
import TableItemColor from "./TableItem/Items/TableItemColor/TableItemColor";
import TableItemCalendar from "./TableItem/Items/TableItemCalendar/TableItemCalendar";

const TableExcursions = () => {
    const items = useSelector(state => state.excursions.excursions);
    const dispatch = useDispatch();
    const [checked, changeChecked] = useState(false);

    let header = [
        {
            width: 3,
            component: () => <TableHeaderText itemName="Колір"/>,
        },
        {
            width: 5,
            component: () => <TableHeaderText itemName="Назва"/>,
        },
        {
            width: 4,
            component: () => <TableHeaderText itemName="Тип"/>,
        },
        {
            width: 10,
            component: () => <TableHeaderText itemName="Статус"/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName=""/>,
        },
        {
            width: 1,
            component: () => <TableHeaderText itemName=""/>,
        },
    ];
    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }

    if (items.length !== 0) {
        return (
            <Table header={header} items={items} columns={columns}>
                {
                    items.map(item => {
                        return (
                            <TableItem key={item.id} columns={columns}>
                                <TableItemColor color={item.color}/>
                                <TableItemText text={item.name.find(item => item.default_language).text}/>
                                <TableItemText text={item.typeExcursion.name}/>
                                <TableItemStatusActive status={item.status.key} />
                                <TableItemCalendar id={item.id}/>
                                <TableItemEdit type={item.type} id={item.id}/>
                            </TableItem>
                        )
                    })
                }
            </Table>
        )
    } else {
        return (
            <h3 style={{textAlign: "center", margin: "50px 0px"}}>
                Екскурсій не знайдено
            </h3>
        )
    }
}

export default TableExcursions;