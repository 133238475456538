import React from "react";
import save from '../../assets/images/save.svg';
import add from '../../assets/images/add.svg';
import calendar from '../../assets/images/calendar.svg';
import eyeOn from "../../assets/images/eye-on.svg"
import eyeOff from "../../assets/images/eye-off.svg"
import edit from "../../assets/images/edit.svg"
import switchIcon from "../../assets/images/switch.svg"

const Button = ({icon, color, style, callBack, children, form, disabled, ...props}) => {
    let icons = {
        save,
        add,
        calendar,
        eyeOn,
        eyeOff,
        edit,
        switchIcon,
    }

    let styleButton = {
        ...style,
        background: color,
    }

    return (

        <button onClick={callBack} style={styleButton} form={form} disabled={disabled ? true : false}>

            {icon &&
                <img src={icons[icon]}/>
            }

            {children}

        </button>


    )
}

export default Button;