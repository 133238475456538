import React, {useEffect, useState} from 'react';
import Filters from "./Filters";
import {useDispatch, useSelector} from "react-redux";
import DateFilter from "./Items/DateFilter/DateFilter";
import CustomSelect from "../CustomSelect/CustomSelect";
import {saveAs} from 'file-saver';
import {changeCurrentPageReports, changeFiltersReports, createReports} from "../../../redux/reducers/reportsReducer";

const FiltersReports = () => {
    const dispatch = useDispatch();

    const branchId = useSelector(state => state.account.branch)
    const reports = useSelector(state => state.reports.reports)
    const filters = useSelector(state => state.reports.filters)
    const guides = useSelector(state => state.guides.guides)

    //Даты
    const [from, changeFrom] = useState("0000-00-00")
    const [to, changeTo] = useState("0000-00-00")
    const onChangeFrom = (date) => {
        let maxDate = new Date(Date.now() - 86400000)
        let newDate = new Date(date)

        let newFromObj = {}

        if(maxDate.valueOf() < newDate.valueOf()) {
            newFromObj = {
                day: maxDate.getDate(),
                month: maxDate.getMonth() + 1,
                year: maxDate.getFullYear()
            }
        } else {
            newFromObj = {
                day: newDate.getDate(),
                month: newDate.getMonth() + 1,
                year: newDate.getFullYear()
            }
        }
        let newFiltersData = {
            ...filters,
            date: {
                ...filters.date,
                from: newFromObj
            },
            guide: filters.guide
        }
        dispatch(changeFiltersReports(newFiltersData))
        dispatch(changeCurrentPageReports(1))
    }
    const onChangeTo = (date) => {
        let maxDate = new Date(Date.now() - 86400000)
        let newDate = new Date(date)

        let newToObj = {}

        if(maxDate.valueOf() < newDate.valueOf()) {
            newToObj = {
                day: maxDate.getDate(),
                month: maxDate.getMonth() + 1,
                year: maxDate.getFullYear()
            }
        } else {
            newToObj = {
                day: newDate.getDate(),
                month: newDate.getMonth() + 1,
                year: newDate.getFullYear()
            }
        }

        let newFiltersData = {
            ...filters,
            date: {
                ...filters.date,
                to: newToObj
            },
            guide: filters.guide
        }
        dispatch(changeFiltersReports(newFiltersData))
        dispatch(changeCurrentPageReports(1))
    }

    //Гіди
    const [itemsGuides, changeGuides] = useState([
        {
            key: "all",
            value: "Всі гіди",
            selected: false,
        },
    ])
    const itemsGuidesOnChange = (e) => {
        let selectedItem = e.selectedItem;
        let newItems = itemsGuides.map(item => {
            if (item.key == selectedItem.key) {
                item.selected = true;
                return item
            } else {
                item.selected = false;
                return item
            }
        })

        let newFiltersData = {
            ...filters,
            date: {...filters.date},
            guide: selectedItem.key == "all" ? null : selectedItem.key
        }

        changeGuides(newItems);
        dispatch(changeFiltersReports(newFiltersData))
        dispatch(changeCurrentPageReports(1))
    }
    useEffect(() => {
        if (guides && guides.length != 0) {
            let guidesList = guides.map(guide => {
                return {
                    key: guide.id,
                    value: guide.name,
                    selected: false
                }
            })
            guidesList.unshift({
                key: "all",
                value: "Всі гіди",
                selected: false,
            })
            changeGuides(guidesList)
        }
    }, [guides])

    //Действия
    const [itemsAction, changeAction] = useState([
        {
            key: "export_to_csv",
            value: "Експорт в csv",
            selected: false,
        },
    ])
    const itemsActionOnChange = (e) => {
        let value = e.selectedItem
        if (value.key == "export_to_csv") {
            export_to_csv()
        }
    }
    const export_to_csv = () => {
        let data = [
            [
                "Дата",
                "Гід",
                "Відпрац. год.",
                "Години простою",
                "Вартість год.пр.",
                "Сума",
            ],
        ];

        if (reports && Array.isArray(reports) && reports.length != 0) {
            let arrReports = reports.flat()

            arrReports.forEach(report => {
                let arrGuides = []

                /* Дата */
                arrGuides.push(`"${report.time.date}"`)

                /* Гід */
                arrGuides.push(`"${report.guide.name} ${report.guide.surname} ${report.guide.patronymic}"`)

                /* Відпрацьовано годин */
                arrGuides.push(`"${report.hoursWorked}"`)

                /* Години простою */
                arrGuides.push(`"${report.downTime}"`)

                /* Вартість години простою */
                arrGuides.push(`"${report.guide.downTimePrice}"`)

                /* Сума */
                arrGuides.push(`"${report.totalPrice}"`)

                data.push(arrGuides)
            })
        }

        data = data.map(string => {
            let csvString = ""
            string.forEach(item => {
                csvString += String(item) + ","
            })
            csvString += "\r\n"
            return csvString
        })

        saveAs(new Blob(data, {type: 'text/csv;charset=utf-8'}), 'reports.csv');
    }


    const objDate = new Date()
    const date = {
        from: {
            day: 1,
            month: objDate.getMonth() + 1,
            year: objDate.getFullYear(),
        },
        to: {
            day: objDate.daysInMonth(),
            month: objDate.getMonth() + 1,
            year: objDate.getFullYear(),
        }
    }

    useEffect(() => {
        // if(reports == false || reports.length == 0) {
            const objDate = new Date()
            const date = {
                date: {
                    from: {
                        day: 1,
                        month: objDate.getMonth() + 1,
                        year: objDate.getFullYear(),
                    },
                    to: {
                        day: objDate.getDate() - 1,
                        month: objDate.getMonth() + 1,
                        year: objDate.getFullYear(),
                    }
                },
                guide: null,

            }
            dispatch(changeFiltersReports(date))
        // }
    }, [])
    useEffect(() => {
        if(filters.date.from.day && filters.date.from.month && filters.date.from.year) {
            let replaceMonth = filters.date.from.month < 10 ? "0" + filters.date.from.month : filters.date.from.month
            let replaceDay = filters.date.from.day < 10 ? "0" + filters.date.from.day : filters.date.from.day
            let newValue = `${filters.date.from.year}-${replaceMonth}-${replaceDay}`

            changeFrom(newValue)
        } else {
            changeFrom("0000-00-00")
        }

        if(filters.date.to.day && filters.date.to.month && filters.date.to.year) {
            let replaceMonth = filters.date.to.month < 10 ? "0" + filters.date.to.month : filters.date.to.month
            let replaceDay = filters.date.to.day < 10 ? "0" + filters.date.to.day : filters.date.to.day
            let newValue = `${filters.date.to.year}-${replaceMonth}-${replaceDay}`

            changeTo(newValue)
        } else {
            changeTo("0000-00-00")
        }

        let objRequest = {
            ...filters,
            branch_id: branchId
        }

        dispatch(createReports(objRequest))
    }, [filters])



    return (
        <Filters>
            <DateFilter
                callback={onChangeFrom}
                value={from}
            />
            <DateFilter
                callback={onChangeTo}
                value={to}
            />
            <CustomSelect
                items={itemsGuides}
                onChange={itemsGuidesOnChange}
                placeholder={'Всі гіди'}
                contentAbsolute={true}
            />
            <CustomSelect
                items={itemsAction}
                onChange={itemsActionOnChange}
                placeholder={'Виконати дію'}
                contentAbsolute={true}
            />
        </Filters>
    );
};

export default FiltersReports;