import React from "react";
import classes from "./Slots.module.scss";
import Slot from "./Slot/Slot";

const Slots = ({table}) => {
    function getHeightContainer(arr) {
        const slots = arr.map(day => day.slots).flat()
        const timesStart = slots.map(slot => slot.time.start.time).sort(function (a, b) {
            let firstNumberTimeArr = a.split(":")
            let secondNumberTimeArr = b.split(":")

            let firstValue = Number(firstNumberTimeArr[0]) + Number(firstNumberTimeArr[1])
            let secondValue = Number(secondNumberTimeArr[0]) + Number(secondNumberTimeArr[1])

            if (firstValue < secondValue) {
                return -1;
            }
            if (firstValue > secondValue) {
                return 1;
            }
            // a должно быть равным b
            return 0;
        })
        const timesEnd = slots.map(slot => slot.time.end.time).sort(function (a, b) {
            let firstNumberTimeArr = a.split(":")
            let secondNumberTimeArr = b.split(":")

            let firstValue = Number(firstNumberTimeArr[0]) + Number(firstNumberTimeArr[1])
            let secondValue = Number(secondNumberTimeArr[0]) + Number(secondNumberTimeArr[1])

            if (firstValue < secondValue) {
                return -1;
            }
            if (firstValue > secondValue) {
                return 1;
            }
            // a должно быть равным b
            return 0;
        })
        let minStart = timesStart.length != 0 ? timesStart[0] : 0
        let maxEnd = timesEnd.length != 0 ? timesEnd[timesEnd.length - 1] : 1440

        let minStartArr = minStart.split(":")
        let hourMinStart = Number(minStartArr[0])
        let minuteMinStart = Number(minStartArr[1])
        let heightStart = (hourMinStart * 60) + minuteMinStart

        let maxEndArr = maxEnd.split(":")
        let hourMaxEnd = Number(maxEndArr[0])
        let minuteMaxEnd = Number(maxEndArr[1])
        let heightEnd = (hourMaxEnd * 60) + minuteMaxEnd

        let heightContainer = 1440 - heightStart - (1440 - heightEnd)

        return [heightStart, heightEnd, heightContainer]

    }

    const [minStart, maxEnd, heightContainer] = getHeightContainer(table)

    return (
        <div className={classes.slots} style={{height: `${heightContainer + 20}px`}}>
            {
                table.map(day => {
                    let slots  = day.slots.map( slot => {
                        return(
                            <Slot key={`slot_${Math.random()}`} data={slot} day={day} minStart={minStart}/>
                        )
                    })
                    return (
                        <div key={`day_${Math.random()}`} className={classes.day} style={{height: `${heightContainer + 20}px`}}>

                            {slots}

                            {   day.status == false &&
                                <div className={classes.block_day}/>
                            }
                        </div>
                    )

                })
            }
        </div>
    )
}

export default Slots;