import React from "react";
import classes from "./Filters.module.scss";

const Filters = ({children, style}) => {
    return(
        <div className={classes.filters} style={style}>
            {children}
        </div>
    )
}

export default Filters;