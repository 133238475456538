import React, { useEffect, useState } from 'react'
import Page from '../../UI/Page/Page'
import TableLanguage from '../../UI/Table/TableLanguage'
import { useNavigate  } from "react-router-dom";
import {useRole} from '../../../hooks/useRole'
import Button from "../../UI/Button";

const Languages = () => {
    const role = useRole();
    const navigate = useNavigate ()
    const [visible, changeVisible] = useState(true)

    const changeVisibleCallback = (e) => {
        e.preventDefault()
        changeVisible(visible ? false : true)
    }
    const redirectToCreate = () => {
        navigate('/languages/create')
    }

    return (
        <Page
            pageName={'Мова проведення'}
            rightRest={
                <Button icon={"switchIcon"} callBack={changeVisibleCallback} color={"#d7d7d7"}>
                    {visible === true &&
                        <>Архів</>
                    }
                    {visible === false &&
                        <>Опубліковані</>
                    }
                </Button>
            }
            rightButton={{
                content: 'Створити', 
                callback: redirectToCreate, 
                icon: 'add', 
                color: '',
                disabled: role > 1,
            }}
        >
            <TableLanguage visible={visible}/>
        </Page>
    )
}

export default Languages