import React, { useState } from 'react'
import AuthBlock from '../AuthBlock/AuthBlock'
import Error from '../../UI/Error/Error'
import AccountApi from '../../../api/AccountApi';

const ResetPassword = () => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isSending, setIsSending] = useState(false);

    const changeEmail = e => {
        setError('');
        setEmail(e.target.value);
    }

    const validate = () => {
        const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
        if(!email || !regexExp.test(email)) {
            setError('Введіть пошту');
            return false;
        }
        setError('');
        return true;
    }

    const send = async () => {
        if(!validate()) return;
        setIsSending(true);
        const response = await AccountApi.resetPassword(email);
        if(response.data) {
            console.log(response.data);
            setIsSending(false);
        }
    }

    return (
        <AuthBlock
            contentStyle={{alignItems: 'flex-start', paddingTop: 100}}
        >
            <div className="">
                <div style={{
                    width: 516
                }}>
                    <h3 style={{fontSize: 16, fontWeight: 500, margin: 0}}>Пошта</h3>
                    <input 
                        className={error ? 'error' : ''}
                        type="email"
                        value={email}
                        onChange={changeEmail}
                    />
                    {error && 
                        <Error>{error}</Error>
                    }
                </div>
                <div className="bttn" style={{display: 'flex', justifyContent: 'center'}}>
                    <button 
                        style={{fontWeight: 700}} 
                        disabled={isSending}
                        onClick={send}
                    >
                        {!isSending && <span>Нагадати пароль</span>}
                        {isSending && <span>Зачекайте...</span>}
                    </button>
                </div>
            </div>
        </AuthBlock>
    )
}

export default ResetPassword