import React from 'react';
import {useSelector} from "react-redux";
import TableHeaderText from "./TableHeader/Items/TableHeaderText";
import Table from "./Table";
import TableItem from "./TableItem/TableItem";
import TableItemText from "./TableItem/Items/TableItemText/TableItemText";
import TableItemEdit from "./TableItem/Items/TableItemEdit/TableItemEdit";

const TableGroup = () => {
    const items = useSelector(state => state.groups.groups);

    let header = [
        {
            width: 1,
            component: () => <TableHeaderText itemName="#"/>,
        },
        {
            width: 3,
            component: () => <TableHeaderText itemName="Группа"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName="Кількість екскурсій"/>,
        },
        {
            width: 2,
            component: () => <TableHeaderText itemName=""/>,
        },
    ];

    let columns = {
        'gridTemplateColumns': header.map(item => item.width + 'fr').join(' '),
    }


    return (
        <div>
            <Table header={header} items={items} columns={columns}>
                {
                    items.map(item => {
                        return (
                            <TableItem key={item.id} columns={columns}>
                                <TableItemText text={item.id}/>
                                <TableItemText text={item.name.find(lang => lang.default_language).text}/>
                                <TableItemText text={item.excursions.length}/>
                                <TableItemEdit type={item.type} id={item.id}/>
                            </TableItem>
                        )
                    })
                }
            </Table>
        </div>
    );
};

export default TableGroup;