import React, {useEffect, useState} from 'react';
import classes from "./Pagination.module.scss";
import {type} from "@testing-library/user-event/dist/type";
import ReactPaginate from "react-paginate";

const Pagination = ({style, currentPage, allPage, callback}) => {
    return (
        <div style={style}>
            <ReactPaginate
                pageCount={allPage}
                onPageChange={callback}
                containerClassName={classes.container}
                pageClassName={classes.number_page}
                activeClassName={classes.current_page}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                previousClassName={classes.button_container}
                previousLabel={
                    <div className={classes.button}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" fillRule="evenodd"
                                  d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
                                  fill="#676767"/>
                            <path fillRule="evenodd" fillRule="evenodd"
                                  d="M12.7071 4.29289C13.0976 4.68342 13.0976 5.31658 12.7071 5.70711L6.41421 12L12.7071 18.2929C13.0976 18.6834 13.0976 19.3166 12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289Z"
                                  fill="#676767"/>
                        </svg>
                    </div>

                }
                nextClassName={classes.button_container}
                nextLabel={
                    <div className={classes.button}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" fillRule="evenodd"
                                  d="M4.09424 12C4.09424 11.4477 4.54195 11 5.09424 11H19.0942C19.6465 11 20.0942 11.4477 20.0942 12C20.0942 12.5523 19.6465 13 19.0942 13H5.09424C4.54195 13 4.09424 12.5523 4.09424 12Z"
                                  fill="#676767"/>
                            <path fillRule="evenodd" fillRule="evenodd"
                                  d="M11.3871 4.29289C11.7777 3.90237 12.4108 3.90237 12.8013 4.29289L19.8013 11.2929C20.1919 11.6834 20.1919 12.3166 19.8013 12.7071L12.8013 19.7071C12.4108 20.0976 11.7777 20.0976 11.3871 19.7071C10.9966 19.3166 10.9966 18.6834 11.3871 18.2929L17.68 12L11.3871 5.70711C10.9966 5.31658 10.9966 4.68342 11.3871 4.29289Z"
                                  fill="#676767"/>
                        </svg>
                    </div>
                }
                breakLabel={

                        <svg width="13" height="4" viewBox="0 0 13 4" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.08067 3.128C1.654 3.128 1.286 2.984 0.976671 2.696C0.678004 2.39733 0.528671 2.01867 0.528671 1.56C0.528671 1.10133 0.678004 0.733333 0.976671 0.456C1.286 0.168 1.654 0.0239999 2.08067 0.0239999C2.518 0.0239999 2.886 0.168 3.18467 0.456C3.48334 0.733333 3.63267 1.10133 3.63267 1.56C3.63267 2.01867 3.48334 2.39733 3.18467 2.696C2.886 2.984 2.518 3.128 2.08067 3.128ZM6.26817 3.128C5.8415 3.128 5.4735 2.984 5.16417 2.696C4.8655 2.39733 4.71617 2.01867 4.71617 1.56C4.71617 1.10133 4.8655 0.733333 5.16417 0.456C5.4735 0.168 5.8415 0.0239999 6.26817 0.0239999C6.7055 0.0239999 7.0735 0.168 7.37217 0.456C7.67084 0.733333 7.82017 1.10133 7.82017 1.56C7.82017 2.01867 7.67084 2.39733 7.37217 2.696C7.0735 2.984 6.7055 3.128 6.26817 3.128ZM10.4557 3.128C10.029 3.128 9.661 2.984 9.35167 2.696C9.053 2.39733 8.90367 2.01867 8.90367 1.56C8.90367 1.10133 9.053 0.733333 9.35167 0.456C9.661 0.168 10.029 0.0239999 10.4557 0.0239999C10.893 0.0239999 11.261 0.168 11.5597 0.456C11.8583 0.733333 12.0077 1.10133 12.0077 1.56C12.0077 2.01867 11.8583 2.39733 11.5597 2.696C11.261 2.984 10.893 3.128 10.4557 3.128Z"
                                fill="#B5B5B5"/>
                        </svg>
                }
                breakClassName={classes.number_page}


            />

        </div>

    )

}

export default Pagination;