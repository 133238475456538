import CertificatesApi from "../../api/CertificatesApi";

const CHANGE_CURRENT_CERTIFICAT = "CHANGE_CURRENT_CERTIFICAT";
const CLEAR_CURRENT_CERTIFICAT = "CLEAR_CURRENT_CERTIFICAT";
const ADD_CERTIFICAT = "ADD_CERTIFICAT";
const SET_CERTIFICATES = "SET_CERTIFICATES";
const UPDATE_CERTIFICATES = "UPDATE_CERTIFICATES";
const CHECK_ALL_CERTIFICATES = "CHECK_ALL_CERTIFICATES";
const CHECK_CERTIFICATE = "CHECK_CERTIFICATE";
const CHANGE_STATE_CERTIFICAT_SAVE_BUTTON = "CHANGE_STATE_CERTIFICAT_SAVE_BUTTON";
const CHANGE_FILTERS_CERTIFICATE = "CHANGE_FILTERS_CERTIFICATE"
const APPLY_FILTERS_CERTIFICATE = "APPLY_FILTERS_CERTIFICATE"

const defaultState = {
    сertificates: [],
    currentCertificat: false,
    newCertificat: {
        type: 'certificates',
        id: "",
        code: null,
        percent: null,
        quantityPerson: null,
        status: {
            key: 'active',
            name: "Активний",
        },
        excursion: false,
        time: {
            start: {
                unix: 0,
                date: "00.00.0000",
            },
            end: {
                unix: 0,
                date: "00.00.0000",
            },
        },
        checked: false,
    },
    filters: {
        date: {
            from: null,
            to: null,
        },
        excursion: null,
        status: null,
    },
    disabledButton: true,
}

export const сertificatesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_CERTIFICATES:
            return {
                ...state,
                сertificates: action.сertificates,
            }
        case CHANGE_CURRENT_CERTIFICAT:
            return {...state, currentCertificat: action.certificat}
        case CLEAR_CURRENT_CERTIFICAT:
            return {...state, currentCertificat: false}
        case ADD_CERTIFICAT:
            return {
                ...state,
                сertificates: [
                    ...state.сertificates,
                    action.certificat
                ]
            }
        case UPDATE_CERTIFICATES:
            return {
                ...state,
                сertificates: state.сertificates.map(certificat => {
                    if (certificat.id == action.certificat.id) {
                        return action.certificat;
                    }
                    return certificat;
                })
            }
        case CHECK_ALL_CERTIFICATES:
            return {
                ...state,
                сertificates: state.сertificates.map(certificat => {
                    certificat.checked = action.value;
                    return certificat;
                })
            }
        case CHECK_CERTIFICATE:
            return {
                ...state,
                сertificates: state.сertificates.map(certificat => {
                    if (certificat.id == action.id) {
                        certificat.checked = action.value == true ? false : true;
                    }
                    return certificat;

                })
            }
        case CHANGE_STATE_CERTIFICAT_SAVE_BUTTON:
            return {
                ...state,
                disabledButton: action.value,
            }
        case CHANGE_FILTERS_CERTIFICATE:
            return {
                ...state,
                filters: action.filters
            }
        case APPLY_FILTERS_CERTIFICATE:

            let filters = {...state.filters}
            let newCertificates = [...state.сertificates]

            if (filters.date.from) {
                newCertificates = newCertificates.filter(certificate => certificate.time.start.unix >= filters.date.from)
            }
            if (filters.date.to) {
                newCertificates = newCertificates.filter(certificate => certificate.time.end.unix <= filters.date.to)
            }
            if (filters.excursion) {
                newCertificates = newCertificates.filter(certificate => certificate.excursion.id == filters.excursion)
            }
            if (filters.status) {
                newCertificates = newCertificates.filter(certificate => certificate.status.key == filters.status)
            }
            return {
                ...state,
                сertificates: newCertificates,
            }
        default:
            return state;
    }
}

export const setCertificates = (сertificates) => ({type: SET_CERTIFICATES, сertificates});
export const changeCurrentCertificat = (certificat) => ({type: CHANGE_CURRENT_CERTIFICAT, certificat});
export const clearCurrentCertificat = () => ({type: CLEAR_CURRENT_CERTIFICAT});
export const addCertificat = (certificat) => ({type: ADD_CERTIFICAT, certificat});
export const updateCertificates = (certificat) => ({type: UPDATE_CERTIFICATES, certificat});
export const checkAllCertificates = (value) => ({type: CHECK_ALL_CERTIFICATES, value})
export const checkCertificate = (id, value) => ({type: CHECK_CERTIFICATE, id, value})
export const changeStateCertificatSaveButton = (value) => ({type: CHANGE_STATE_CERTIFICAT_SAVE_BUTTON, value})
export const changeFiltersCertificate = (filters) => ({type: CHANGE_FILTERS_CERTIFICATE, filters})
export const applyFiltersCertificate = () => ({type: APPLY_FILTERS_CERTIFICATE})

export const createCertificat = (data, navigate) => async (dispatch) => {
    dispatch(changeStateCertificatSaveButton(true))
    await CertificatesApi.put(data).then(response => {
        dispatch(addCertificat(response.data));
        dispatch(clearCurrentCertificat());
        navigate(`/certificates/${response.data.id}`)
    });
}
export const editCertificat = (data) => async (dispatch) => {
    dispatch(changeStateCertificatSaveButton(true))
    await CertificatesApi.patch(data).then(response => {
        dispatch(changeCurrentCertificat(response.data));
        dispatch(updateCertificates(response.data));
    });
}
export const getCertificates = (branch) => async (dispatch) => {
    await CertificatesApi.getAll(localStorage.getItem('auth_token'), branch).then(response => {
        let data = response.data.map(item => {
            item.checked = false;
            return item
        });
        dispatch(setCertificates(data))
        dispatch(applyFiltersCertificate())
    })
}
export const toArchiveCertificate = (data) => async (dispatch) => {
    dispatch(changeStateCertificatSaveButton(true))
    await CertificatesApi.deleteCertificate(data).then(response => {
        dispatch(changeCurrentCertificat(response.data));
        dispatch(updateCertificates(response.data));
    })
}
