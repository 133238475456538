import React, { useState, useRef } from 'react'
import AuthBlock from '../AuthBlock/AuthBlock'
import { useParams, useNavigate } from 'react-router-dom'
import Error from '../../UI/Error/Error'
import cl from './WritePassword.module.scss';
import { ReactSVG } from 'react-svg'
import generatePasswordSVG from '../../../assets/images/add.svg'
import AccountApi from '../../../api/AccountApi';

const WritePassword = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState({
        token: '',
        email: '',
        password: '',
        message: '',
    })
    const [data, setData] = useState({
        token: params.token,
        email: '',
        password: '',
    })
    const passRef = useRef();

    const [disabled, setDisabled] = useState(false);

    const changeDisabled = () => {
        if(error.token || error.email || error.password) setDisabled(true);
        else setDisabled(false);
    }

    const changeEmail = (e) => {
        setData({...data, email: e.target.value});
        setError({...error, email: '',});
        changeDisabled();
    }

    const changeToken = (e) => {
        setData({...data, token: e.target.value});
        setError({...error, token: '',});
        changeDisabled();
    }

    const changePassword = (e) => {
        setData({...data, password: e.target.value});
        setError({...error, password: '',});
        changeDisabled();
        passRef.current.type = 'password';
    }

    const generatePassword = (e) => {
        e.preventDefault();
        const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const passwordLength = 16;
        let password = '';
        for(let i = 0; i <= passwordLength; i++) {
            let randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber +1);
        }
        passRef.current.type = 'text';
        setData({...data, password: password});
        setError({...error, password: '',});
        changeDisabled();
    }

    const validate = () => {
        if(!data.token) {
            setError({...error, token: 'Введіть токен'});
            changeDisabled();
            return false;
        }
        if(!data.email) {
            setError({...error, email: 'Введіть пошту'});
            changeDisabled();
            return false;
        }
        if(!data.password) {
            setError({...error, password: 'Введіть пароль'});
            changeDisabled();
            return false;
        }
        return true;
    }

    const send = async () => {
        if(!validate()) return;

        const response = await AccountApi.sendNewPassword(data);

        if(response.data.success) navigate('/auth')
        else {
            setError({...error, message: response.data.message})
        }
    }


    return (
        <AuthBlock
            contentStyle={{alignItems: 'flex-start', paddingTop: 100}}
        >
            <div className="">
                <div style={{
                    width: 516
                }}>
                    <div className={cl.row}>
                        <h3 style={{fontSize: 16, fontWeight: 500, margin: 0}}>Токен</h3>
                        <input 
                            className={error.token? 'error' : ''}
                            type="text"
                            value={data.token}
                            onChange={changeToken}
                        />
                        {error.token && 
                            <Error>{error.token}</Error>
                        }
                    </div>
                    <div className={cl.row}>
                        <h3 style={{fontSize: 16, fontWeight: 500, margin: 0}}>Пошта</h3>
                        <input 
                            className={error.email? 'error' : ''}
                            type="email"
                            value={data.email}
                            onChange={changeEmail}
                        />
                        {error.email && 
                            <Error>{error.email}</Error>
                        }
                    </div>
                    <div className={cl.row}>
                        <h3 style={{fontSize: 16, fontWeight: 500, margin: 0}}>Пароль</h3>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10,
                            marginBottom: 15
                        }}>
                            <input 
                                className={error.password? 'error' : ''}
                                type="password"
                                value={data.password}
                                onChange={changePassword}
                                style={{margin: 0}}
                                ref={passRef}
                            />
                            <button
                                style={{
                                    minHeight: 'unset',
                                    minWidth: 'unset',
                                    height: '100%',
                                    // width: 40,
                                    margin: 0,
                                    padding: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={generatePassword}
                                className={cl.generatePassswordButton}
                            >
                                <ReactSVG src={generatePasswordSVG}/>
                            </button>
                        </div>
                        {error.password && 
                            <Error>{error.password}</Error>
                        }
                    </div>
                </div>
                <div className="bttn" style={{display: 'flex', justifyContent: 'center'}}>
                    <button 
                        style={{fontWeight: 700}} 
                        onClick={send}
                        disabled={disabled}
                    >
                        Змінити пароль
                    </button>
                    {error?.message &&
                        <Error>{error?.message}</Error>
                    }
                </div>
            </div>
        </AuthBlock>
    )
}

export default WritePassword