import axios from "axios";

export default class GuidesApi {

    static async getAll(data) {
        const response = await axios.post('https://api.0300.com.ua/api/guides/getall', {
            token: localStorage.getItem('auth_token'),
            branch: data
        })

        return response;
    }

    static async put(data) {
        data.append("token", localStorage.getItem('auth_token'))
        const response = await axios.post('https://api.0300.com.ua/api/guides/put', data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });

        return response
    }

    static async patch(data) {
        data.append("token", localStorage.getItem('auth_token'))
        const response = await axios.post('https://api.0300.com.ua/api/guides/patch', data, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        });

        return response
    }

    static async deleteGuide(data) {
        const response = await axios.post('https://api.0300.com.ua/api/guides/delete', {
            token: localStorage.getItem('auth_token'),
            id: data
        })

        return response;
    }

}