import React, { useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import bookingsApi from '../../../../api/BookingsApi';
import Page from '../../../UI/Page/Page';
import PageLoader from '../../../UI/PageLoader/PageLoader';
import BookingsEditFirstColumn from './BookingsEditFirstColumn';
import BookingsEditSecondColumn from './BookingsEditSecondColumn';
import BookingsEditThirdColumn from './BookingsEditThirdColumn';
import Modal from '../../../UI/Modal/Modal';
import { useDispatch } from 'react-redux';
import { updateBookings } from '../../../../redux/reducers/bookingsReducer';

const BookingsEdit = () => {
    
    const [error, setError] = useState({
        date: '',
        solo: {},
        tickets: [],
    });

    const [stateSaveButton, setStateSaveButton] = useState(true);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const excursions = useSelector(state => state.excursions.excursions)

    const discounts = useSelector(state => state.discounts.discounts);
    const certificates = useSelector(state => state.сertificates.сertificates);
    const promocodes = useSelector(state => state.promocodes.promocodes);

    const languagesSelector = useSelector(state => state.languages.languages);

    const [data, setData] = useState({
        currentExcursion: null,
        excursionType: null,
        excursionId: null,
        date: '',
        startTime: '',
        endTime: '',
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
        confirmationMethod: [
            {key: 0, value: 'По телефону'},
            {key: 1, value: 'E-mail'},
            {key: 2, value: 'Telegram'},
            {key: 3, value: 'Viber'},
        ],
        guestsCount: 0,
        guestsCountMin: 0,
        guestsCountMax: 0,
        languages: [],
        comment: '',
        solo: {
            selectList: [
                {key: null, value: '...', selected: true},
                {key: 0, value: 'Знижка'},
                {key: 1, value: 'Сертифікат'},
                {key: 2, value: 'Промокод'},
            ],
            discounts: [],
            certificate: '',
            promocodes: [],
        },
        additionalComment: '',
        tickets: [],
        cost: 0,
        paymentStatus: [
            {key: 0, value: 'Не оплачено', selected: true},
            {key: 1, value: 'Оплачено'},
        ],
    });

    const [isLoading, setIsLoading] = useState(true);

    const params = useParams();

    const [warningModal, setWarningModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const turnOnSaveButton = () => {

        const startTime = data.startTime;
        const startTimeList = data.time.start_list.start;

        if(startTimeList.find(item => item.key == startTime && !item.disabled) === undefined) {
            return;
        }
        setStateSaveButton(false);
    }

    const fetchData = async () => {

        const response = await bookingsApi.get(params);

        const ex = excursions.find(ex => ex.id == response.data.excursion_id),
              flag = ex.typeExcursion.key == 'ticket',
              day = response.data._day;


        const newData = {
            ...data,
            currentExcursion: excursions.find(ex => ex.id == response.data.excursion_id),
            excursionType: ex.typeExcursion.key,
            excursionId: ex.id,
            bookingId: response.data.id,
            date: {
                day: response.data.day,
                month: response.data.month,
                year: response.data.year,
            },
            startTime: response.data.start_time,
            endTime: response.data.end_time,
            time: {
                ...response.data._day.time,
                start_list: {
                    ...response.data.time.start_list,
                    start: response.data.time.start_list.start.map(item => ({
                        key: item,
                        value: `${item} ${flag ? `(${day.time.tickets[day.time.start.findIndex(i => i == item)]})` : `(${day.time.tickets_min[day.time.start.findIndex(i => i == item)]}-${day.time.tickets_max[day.time.start.findIndex(i => i == item)]})`}`,
                        selected: item == response.data.start_time
                    }))
                }
            },
            baseCost: response.data.time.start_list.costs[response.data.time.start_list.start.findIndex(item => item == response.data.start_time)],
            baseTickets: ex.typeExcursion.key == 'ticket' ? response.data.time.start_list.tickets[response.data.time.start_list.start.findIndex(item => item == response.data.start_time)] : null,
            name: response.data.name,
            surname: response.data.surname,
            email: response.data.email,
            phoneNumber: response.data.phone_number,
            confirmationMethod: [
                {key: 0, value: 'По телефону', selected: response.data.confirmation_method == 0},
                {key: 1, value: 'E-mail', selected: response.data.confirmation_method == 1},
                {key: 2, value: 'Telegram', selected: response.data.confirmation_method == 2},
                {key: 3, value: 'Viber', selected: response.data.confirmation_method == 3},
            ],
            guestsCount: response.data.guests_count,
            guestsCountMin: ex.typeExcursion.key == 'ticket' ? null : response.data._day.time.tickets_min[response.data._day.time.start.findIndex(item => item == response.data.start_time)],
            guestsCountMax: ex.typeExcursion.key == 'ticket' ? null : response.data._day.time.tickets_max[response.data._day.time.start.findIndex(item => item == response.data.start_time)],
            languages: ex.languages.map(item => ({
                key: item.id, 
                value: item.name, 
                selected: item.id == response.data.language_id,
                price: item.price
            })),
            comment: response.data.comment? response.data.comment : '',
            solo: {
                selectList: [
                    {key: null, value: '...', selected: response.data.discountType == null},
                    {key: 0, value: 'Знижка', selected: response.data.discountType == 0},
                    {key: 1, value: 'Сертифікат', selected: response.data.discountType == 1},
                    {key: 2, value: 'Промокод', selected: response.data.discountType == 2},
                ],
                discounts: ex.discounts.filter(disc => !disc?.deleted || (disc.id == response.data.discountId)).map(item => ({
                    key: item.id,
                    value: item.name.find(item => item?.default_language).text,
                    selected: response.data.discountType == 0 && item.id == response.data.discountId
                })),
                certificate: response.data.discountType == 1 ? response.data.discountId : '',
                promocodes: promocodes.filter(item => item.excursion.find(_ex => _ex.id == ex.id) !== undefined && (item.numberOfApplications < item.applicationLimit || item.id == response.data.discountId)  && new Date(`${response.data.year}-${response.data.month}-${response.data.day}`) / 1000 < item.time.end.unix)?.map(item => ({
                    key: item.id,
                    value: item.name.find(name => name?.default_language).text,
                    selected: response.data.discountType == 2 && response.data.discountId == item.id
                })),
            },
            baseSolo: {
                selectList: [
                    {key: null, value: '...', selected: response.data.discountType == null},
                    {key: 0, value: 'Знижка', selected: response.data.discountType == 0},
                    {key: 1, value: 'Сертифікат', selected: response.data.discountType == 1},
                    {key: 2, value: 'Промокод', selected: response.data.discountType == 2},
                ],
                discounts: ex.discounts.map(item => ({
                    key: item.id,
                    value: item.name.find(item => item?.default_language).text,
                    selected: response.data.discountType == 0 && item.id == response.data.discountId
                })),
                certificate: response.data.discountType == 1 ? response.data.discountId : '',
                promocodes: promocodes.filter(item => item.excursion.find(_ex => _ex.id == ex.id) !== undefined && (item.numberOfApplications < item.applicationLimit || item.id == response.data.discountId)  && new Date(`${response.data.year}-${response.data.month}-${response.data.day}`) / 1000 < item.time.end.unix)?.map(item => ({
                    key: item.id,
                    value: item.name.find(name => name?.default_language).text,
                    selected: response.data.discountType == 2 && response.data.discountId == item.id
                })),
            },
            additionalComment: response.data.additional_comment ? response.data.additional_comment : '',
            cost: response.data.cost,
            paymentStatus: [
                {key: 0, value: 'Не оплачено', selected: response.data.payment_status == 0},
                {key: 1, value: 'Оплачено', selected: response.data.payment_status == 1},
            ],
            paymentAmount: response.data.payment_amount,
            action: [
                {key: null, value: 'Виберіть опцію', selected: true},
                {key: 0, value: 'Виставити invoice на email'},
                {key: 1, value: 'Скасувати бронювання'},
            ],
            status: response.data.status,
            discountId: response.data.discountId,
            additionalOptions: ex.typeExcursion.key == 'ticket' ? [] : ex.additionalOptions.filter(opt => !opt?.deleted || (!opt?.deleted && response.data.additionalOptions.includes(opt.id))).map(opt => ({
                key: opt.id, 
                value: opt.name.find(n => n.default_language).text, 
                price: opt.price,
                selected: response.data.additionalOptions.includes(opt.id),
            })),
            responseCost: response.data.cost,
        }

        if(ex.typeExcursion.key == 'ticket') {
            newData._tickets = response.data.tickets;

            console.log(ex.additionalOptions);

            newData.tickets = response.data.tickets.map(ticket => ({
                id: ticket.id,
                selectList: [
                    {key: null, value: '...', selected: ticket.discount_type == null},
                    {key: 0, value: 'Знижка', selected: ticket.discount_type == 0},
                    {key: 1, value: 'Сертифікат', selected: ticket.discount_type == 1},
                    {key: 2, value: 'Промокод', selected: ticket.discount_type == 2},
                ],
                baseDiscountType: ticket.discount_type,
                discounts: discounts.filter(disc => !disc?.deleted || ticket.discount_id == disc.id).map(item => ({
                    key: item.id, 
                    value: item.name.find(name => name?.default_language == true).text,
                    selected: ticket.discount_type == 0 && ticket.discount_id == item.id
                })),
                certificate: ticket.discount_type == 1 && ticket.discount_id ? ticket.discount_id : '',
                promocodes: promocodes.filter(prom => !prom?.deleted || ticket.discount_id == prom.id).filter(item => {

                    const usedPromocodes = data.tickets.filter(t => t.selectList.find(sl => sl.selected)?.key == 2 && t.promocodes.find(pr => pr.selected)?.key == item.id)
                    const usedInExcursions = newData._tickets.filter(_item => _item.discount_type == 2 && _item.discount_id == item.id).length

                    return  ticket.discount_id == item.id || (item.excursion.find(ex => ex.id == newData.excursionId) !== undefined && 
                        new Date(`${newData.date.year}-${newData.date.month}-${newData.date.day}`) / 1000 < item.time.end.unix &&
                        item.numberOfApplications + usedPromocodes.length < item.applicationLimit + usedInExcursions)

                })
                ?.map(item => ({
                    key: item.id, 
                    value: item.name.find(name => name?.default_language == true).text,
                    selected: item.id == ticket.discount_id
                })),
                additionalOptions: ex.additionalOptions.filter(opt => !opt?.deleted || ticket.additionalOptions.includes(opt.id)).map(opt => ({
                    key: opt.id, 
                    value: opt.name.find(n => n.default_language).text, 
                    price: opt.price,
                    selected: ticket.additionalOptions.includes(opt.id),
                })),
                defaulted: true,
            }));
        }

        setData(newData);
        setIsLoading(false);
        setStateSaveButton(true);
    }

    useEffect(() => {

        fetchData();

        const setnewfalse = async () => {
            const response = await bookingsApi.setnewfalse(params.id);

            dispatch(updateBookings(response.data));
        }

        setnewfalse();

    }, []);

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    useEffect(() => {

        let cost = 0;
        const exType = data.excursionType;

        if(new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) > Date.now()) {

            if(exType && exType == 'ticket') {
    
                const appliedCerts = {},
                      appliedPromocodes = {};
    
                data.tickets.forEach(item => {
    
                    let ticketCost = data.baseCost;
    
                    const selectedDiscount = item.selectList.find(_item => _item.selected);
    
                    if(selectedDiscount && selectedDiscount.key == 0 && item.discounts.find(_item => _item.selected)) {
    
                        const discount = item.discounts.find(_item => _item.selected).key,
                              percent = discounts.find(disc => disc.id == discount).percent;
    
                        ticketCost -= ticketCost * percent / 100
    
                    }
                    else if(selectedDiscount && selectedDiscount.key == 1 && item.certificate && data.date) {
    
                        const cert = certificates.find(cert => cert.code == item.certificate);
    
                        if(cert && cert.excursion_id == data.excursionId && cert.status.key == 'active' && new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) / 1000 < cert.time.end.unix && !(appliedCerts.hasOwnProperty(cert.code) && appliedCerts[cert.code] >= cert.quantityPerson)) {
    
                            ticketCost -= ticketCost / cert.percent * 100;
    
                            if(appliedCerts.hasOwnProperty(cert.code)) appliedCerts[cert.code]++;
                            else appliedCerts[cert.code] = 1;
                        }
    
                    }
                    else if(selectedDiscount && selectedDiscount.key == 2 && item.promocodes.find(_item => _item.selected)) {
    
                        const prom_id = item.promocodes.find(_item => _item.selected).key,
                              promocode = promocodes.find(_item => _item.id == prom_id);
    
                        if(!appliedPromocodes.hasOwnProperty(promocode.code)) appliedPromocodes[promocode.code] = promocode.numberOfApplications;
    
                        if(promocode && new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) / 1000 < promocode.time.end.unix && appliedPromocodes[promocode.code] < promocode.applicationLimit) {
                            ticketCost -= ticketCost * promocode.percent / 100;
    
                            appliedPromocodes[promocode.code] = appliedPromocodes[promocode.code] + 1;
                        }
    
    
                    }
    
                    item.additionalOptions.filter(opt => opt.selected).forEach(opt => {
                        ticketCost += opt.price;
                    });
    
                    cost += ticketCost;
    
                });
    
                if(data.languages.find(lang => lang.selected)) cost += data.languages.find(lang => lang.selected).price
    
            }
            else if(exType && exType == 'small_group') {
                cost = Number(data.baseCost);

                if(data.solo.selectList.find(item => item.selected)?.key == 0 && data.solo.discounts.find(item => item.selected)) {
                    const percent = discounts.find(item => item.id == data.solo.discounts.find(item => item.selected).key).percent
                    cost -= (cost * percent / 100);
                }
                else if(data.solo.selectList.find(item => item.selected)?.key == 1 && data.solo.certificate) {
                    const cert = certificates.find(item => item.code == data.solo.certificate && item.excursion_id == data.excursionId);
                    
                    if( cert.code == data.discountId || (cert &&
                        cert.excursion.id == data.excursionId &&
                        cert.status.key == 'active' &&
                        new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) / 1000 < cert.time.end.unix)) {
    
                        cost -= (cost * cert.percent / 100);
                    }
                }
                else if(data.solo.selectList.find(item => item.selected)?.key == 2 && data.solo.promocodes.find(item => item.selected)) {
    
                    const promocode = promocodes.find(item => item.id == data.solo.promocodes.find(_item => _item.selected).key);
    
                    if(promocode.id == data.discountId || (new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) / 1000 < promocode.time.end.unix && promocode.numberOfApplications < promocode.applicationLimit)) {
                        const percent = promocode.percent;
                        cost -= (cost * percent / 100);
                    }
    
                }
    
                data.additionalOptions.filter(opt => opt.selected).forEach(opt => {
                    cost += opt.price;
                });
    
                if(data.languages.find(lang => lang.selected)) cost += data.languages.find(lang => lang.selected).price
            }
            else if(exType && exType != 'ticket') {
                const pricePerPerson = data.currentExcursion.pricePerPerson.map(item => ({price: Number(item.price), persons: Number(item.persons)}));
    
                if(pricePerPerson.length && data.guestsCount >= pricePerPerson[0].persons) {
    
                    cost += Number(data.baseCost) * (pricePerPerson[0].persons - 1);
    
                    for(let i = 0; i < pricePerPerson.length; i++) {
                        if(i + 1 < pricePerPerson.length) {
                            const persons = clamp(data.guestsCount, pricePerPerson[i].persons, pricePerPerson[i + 1].persons - 1) - pricePerPerson[i].persons + 1,
                                  calculatedCost = data.baseCost - (data.baseCost * pricePerPerson[i].price / 100);
                            cost += persons * calculatedCost;
    
                            if(data.guestsCount < pricePerPerson[i + 1].persons) break;
                        } else {
    
                            const persons = clamp(data.guestsCount, pricePerPerson[i].persons, data.guestsCountMax) - pricePerPerson[i].persons + 1,
                                  calculatedCost = data.baseCost - (data.baseCost * pricePerPerson[i].price / 100);
    
                            cost += persons * calculatedCost;
                            break;
                        }
    
                    }
    
                } else {
                    cost = Number(data.guestsCount) * Number(data.baseCost);
                }
    
                if(data.solo.selectList.find(item => item.selected)?.key == 0 && data.solo.discounts.find(item => item.selected)) {
                    const percent = discounts.find(item => item.id == data.solo.discounts.find(item => item.selected).key).percent
                    cost -= (cost * percent / 100);
                }
                else if(data.solo.selectList.find(item => item.selected)?.key == 1 && data.solo.certificate) {
                    const cert = certificates.find(item => item.code == data.solo.certificate && item.excursion_id == data.excursionId);
                    
                    if( cert.code == data.discountId || (cert &&
                        cert.excursion.id == data.excursionId &&
                        cert.status.key == 'active' &&
                        new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) / 1000 < cert.time.end.unix)) {
    
                        cost -= (cost * cert.percent / 100);
                    }
                }
                else if(data.solo.selectList.find(item => item.selected)?.key == 2 && data.solo.promocodes.find(item => item.selected)) {
    
                    const promocode = promocodes.find(item => item.id == data.solo.promocodes.find(_item => _item.selected).key);
    
                    if(promocode.id == data.discountId || (new Date(`${data.date.year}-${data.date.month}-${data.date.day}`) / 1000 < promocode.time.end.unix && promocode.numberOfApplications < promocode.applicationLimit)) {
                        const percent = promocode.percent;
                        cost -= (cost * percent / 100);
                    }
    
                }
    
                data.additionalOptions.filter(opt => opt.selected).forEach(opt => {
                    cost += opt.price;
                });
    
    
                if(data.languages.find(lang => lang.selected)) cost += data.languages.find(lang => lang.selected).price
            }
        } else {
            cost = data.responseCost;
        }

        setData({
            ...data,
            cost: Math.floor(cost),
        })

    }, [
        data.baseCost, 
        data.guestsCount, 
        data.solo.discounts, 
        data.solo.certificate, 
        data.solo.promocodes, 
        data.tickets,
        data.languages,
        data.additionalOptions
    ]);

    const validate = () => {

        const newErrorObj = {
            ...error,
            date: data.date.day && data.date.month && data.date.year ? false : 'Виберіть дату',
            startTime: data.time.start_list.start.find(item => item.selected) ? false : 'Виберіть час',
            email: data.email ? false : 'Ввдіть email',
            phoneNumber: data.phoneNumber ? (data.phoneNumber.length == 13 ? false : 'Номер телефону складається з 13 символів')  : 'Ввдіть email',
            confirmationMethod: data.confirmationMethod.find(item => item.selected) ? false : 'Виберіть спосіб підтвердження',
            guestsCount: data.currentExcursion.typeExcursion.key == 'ticket' ? false : (Number(data.guestsCount) && Number(data.guestsCount) >= Number(data.guestsCountMin) && Number(data.guestsCount) <= Number(data.guestsCountMax) ? false : `Введіть кількість гостей (${data.guestsCountMin}-${data.guestsCountMax})`),
            languages: data.languages.find(lang => lang.selected) ? false : 'Виберіть мову проведення',
            emptyTickets: !(data.excursionType == 'ticket' && !data.tickets.length) ? false : 'Додайте квитки',
        }

        setError(newErrorObj);

        for(let property in newErrorObj) {
            if(newErrorObj.hasOwnProperty(property) && property != 'solo' && property != 'tickets' && newErrorObj[property]) {
                return false;
            }
            else if(property == 'solo') {
                for(let soloProperty in newErrorObj[property]) {
                    if(newErrorObj[property].hasOwnProperty(soloProperty) && newErrorObj[property][soloProperty]) {
                        return false;
                    }
                }
            }
            else if(property == 'tickets') {
                for(let key in newErrorObj[property]) {
                    if(newErrorObj[property].discount || newErrorObj[property].certificate || newErrorObj[property].promocode) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    const submitForm = async () => {
        if(!validate()) return;

        const sendedObj = {
            booking_id: data.bookingId,
            date: data.date,
            startTime: data.startTime,
            endTime: data.endTime,
            name: data.name,
            surname: data.surname,
            email: data.email,
            phoneNumber: data.phoneNumber,
            confirmationMethod: data.confirmationMethod.find(item => item.selected).key,
            guestsCount: data.guestsCount,
            languages: data.languages.find(item => item.selected).key,
            comment: data.comment,
            additionalComment: data.additionalComment,
            cost: data.cost,
            paymentStatus: data.paymentStatus.find(item => item.selected).key,
            action: data.action.find(item => item.selected).key,
            additionalOptions: data.additionalOptions.filter(opt => opt.selected).map(item => item.key),
        };

        if(data.solo.selectList.find(item => item.selected)) {
            sendedObj.discountType = data.solo.selectList.find(item => item.selected).key;
            if(data.solo.selectList.find(item => item.selected)?.key == 0 && data.solo.discounts.find(item => item.selected)) sendedObj.discount = data.solo.discounts.find(item => item.selected).key;
            if(data.solo.selectList.find(item => item.selected)?.key == 1 && data.solo.certificate) sendedObj.discount = data.solo.certificate;
            if(data.solo.selectList.find(item => item.selected)?.key == 2 && data.solo.promocodes.find(item => item.selected).key) sendedObj.discount = data.solo.promocodes.find(item => item.selected).key;
        }

        if(data.tickets.length > 0) {
            sendedObj.tickets = data.tickets.map(ticket => ({
                id: ticket.id,
                discountType: ticket.selectList.find(item => item.selected)?.key,
                discount: ticket.selectList.find(item => item.selected) && ticket.selectList.find(item => item.selected).key != '...' ?
                            (ticket.selectList.find(item => item.selected).key == 0 ? ticket.discounts.find(item => item.selected)?.key :
                            (ticket.selectList.find(item => item.selected).key == 1 ? ticket.certificate :
                            ticket.promocodes.find(item => item.selected)?.key)) : 
                            null,
                additionalOptions: ticket.additionalOptions.filter(opt => opt.selected).map(opt => opt.key),
            }));
        }

        if(sendedObj.action == 1) {

            setData({
                ...data,
                sendedObj: sendedObj,
            });
            setWarningModal(true);

        } else {
            const response = await bookingsApi.patch(sendedObj);

            fetchData();
        }

    }

    if(isLoading) {

        return(
            <PageLoader />
        )

    }
    
    return (
        <div>
            <Modal
                active={warningModal}
                type='error'
                title="Ви впевнені що хочете скасувати бронювання?"
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 15
                    }}
                >
                    <button onClick={e => {
                        setWarningModal(false);
                    }}>Ні</button>
                    <button onClick={async e => {
                        const response = await bookingsApi.patch(data.sendedObj);
                
                        navigate(`/bookings`);
                    }}>Так</button>
                </div>
            </Modal>
            <Modal
                active={deleteModal}
                type='error'
                title="Ви впевнені що хочете видалити бронювання?"
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 15
                    }}
                >
                    <button onClick={e => {
                        setDeleteModal(false);
                    }}>Ні</button>
                    <button onClick={async e => {

                        const response = await bookingsApi.delete(data);
                
                        navigate(`/bookings`);
                    }}>Так</button>
                </div>
            </Modal>
            <Page
                breadcrumbs={{link: '/bookings', text: 'Бронювання'}}
                pageName={`Бронювання - ${data.bookingId}`}
                nameDescription={`${data.currentExcursion.name.find(item => item?.default_language).text} (${data.currentExcursion.typeExcursion.name})`}
                rightButton={{
                    content: 'Зберегти',
                    callback: submitForm,
                    icon: 'add',
                    color: '',
                    form: 'bookings_form',
                    disabled: stateSaveButton
                }}
                rightRest={
                    <div style={{display: 'flex', flexDirection: 'column', gap: 18, marginTop: 10, marginRight: 75}}>
                        <div style={{fontSize: 16, color: '#676767', fontWeight: 500}}>Сума замовлення</div>
                        <div style={{fontSize: 30, fontWeight: 700}}>{data.cost} грн</div>
                        {/* <div>
                        </div> */}
                    </div>
                }
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>
                
                    <form id="bookings_form" onSubmit={e => e.preventDefault()}>

                        <BookingsEditFirstColumn 
                            data={data}
                            setData={setData}
                            error={error}
                            setError={setError}
                            setDeleteModal={setDeleteModal}
                            turnOnSaveButton={turnOnSaveButton}
                        />

                        <BookingsEditSecondColumn 
                            data={data}
                            setData={setData}
                            error={error}
                            setError={setError}
                            turnOnSaveButton={turnOnSaveButton}
                        />

                        <BookingsEditThirdColumn 
                            data={data}
                            setData={setData}
                            error={error}
                            setError={setError}
                            turnOnSaveButton={turnOnSaveButton}
                        />

                    </form>

                </div>
            </Page>
        </div>
    )
}

export default BookingsEdit