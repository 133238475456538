import React, {useState} from 'react'
import Page from "../../UI/Page/Page";
import TableAdditionalOptions from "../../UI/Table/TableAdditionalOptions";
import {useNavigate} from "react-router-dom";
import {useRole} from '../../../hooks/useRole';
import Button from "../../UI/Button";

const AdditionalOptions = () => {
    const navigate = useNavigate()
    const role = useRole();

    const [visible, changeVisible] = useState(true)

    const changeVisibleCallback = (e) => {
        e.preventDefault()
        changeVisible(visible ? false : true)
    }

    return (
        <Page
            pageName={"Додаткові опції"}
            rightRest={
                <Button icon={"switchIcon"} callBack={changeVisibleCallback} color={"#d7d7d7"}>
                    {visible === true &&
                        <>Архів</>
                    }
                    {visible === false &&
                        <>Опубліковані</>
                    }
                </Button>
            }
            rightButton={{
                content: 'Створити ', callback: () => {
                    navigate('/additionaloptions/create')
                }, 
                icon: 'add', 
                color: '',
                disabled: role > 1
            }}
        >
            <TableAdditionalOptions visible={visible}/>
        </Page>
    )
}

export default AdditionalOptions