import React, {useEffect, useState} from 'react'
import classes from "./ExcursionsCreate.module.scss";
import PageLoader from "../../../UI/PageLoader/PageLoader";
import Page from "../../../UI/Page/Page";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import CustomSelect from "../../../UI/CustomSelect/CustomSelect";
import Error from "../../../UI/Error/Error";
import LanguageInputs from "../../../UI/LanguageFields/LanguageInputs/LanguageInputs";
import LanguageAreas from "../../../UI/LanguageFields/LanguageAreas/LanguageAreas";
import InputColor from "../../../UI/InputColor/InputColor";
import {
    changeColor,
    changeCurrentExcursion,
    changeDiscountsForCurrentExcursion,
    changeLanguagesForCurrentExcursion,
    changeOptionsForCurrentExcursion,
    changeStateExcursionSaveButton,
    changeGuidesForCurrentExcursion,
    setActiveDayExcursion,
    changeBufferExcursionsForCurrentExcursion,
    changeOnlinePayment,
    createExcursion,
    editExcursionStatus,
    editExcursion,
    changeTypeExcursion,
    addPricePerPerson,
    removePricePerPerson,
    changeTimeBufferExcursion, setBranchTimeTable
} from "../../../../redux/reducers/excursionsReducer";
import TimeTable from "../../../UI/TimeTable/TimeTable";
import SelectItem from "../../../UI/SelectItem/SelectItem";
import Button from "../../../UI/Button";
import Modal from "../../../UI/Modal/Modal";
import ButtonHalfTransparent from "../../../UI/ButtonHalfTransparent/ButtonHalfTransparent";
import {ReactSVG} from "react-svg";
import deleteSVG from "../../../../assets/images/Path 114.svg";
import branches from "../../Branches/Branches";

const ExcursionsCreate = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const {register, handleSubmit, control, setError, setValue, getValues, formState: {errors}} = useForm();

    const newExcursion = useSelector(state => state.excursions.newExcursion);
    let data = useSelector(state => state.excursions.currentExcursion);
    let branchId = useSelector(state => state.account.branch);
    const branches = useSelector(state => state.branches.branches)
    const guides = useSelector(state => state.guides.guides)
    const stateSaveButton = useSelector(state => state.excursions.disabledButton);
    let pageName = (data && data.name.length !== 0) ? data.name.find(lang => lang.default_language).text + ` (${data.typeExcursion.name})` : "";
    const [errorGuides, changeErrorGuides] = useState(null)
    const [errorLanguages, changeErrorLanguages] = useState(null)

    const typeExcursionsOnChange = (e) => {
        let selectedItem = e.selectedItem;
        let newItems = typeExcursions.map(item => {
            if (item.key == selectedItem.key) {
                item.selected = true;
                return item
            } else {
                item.selected = false;
                return item
            }
        })
        changeTypeExcursions(newItems);
    }
    const onChangeInput = (key, day) => dispatch(setActiveDayExcursion(key, day))
    const excursionSubmit = (dataForm) => {
        dataForm.bufferExcursions = dataForm.bufferExcursions.filter(item => item)
        dataForm.branch = branchId;

        let error = false

        if (dataForm.guides) {
            dataForm.guides = dataForm.guides.filter(guide => guide)
        } else {
            changeErrorGuides("Додайте гідів")
            error = true
        }
        if (dataForm.languages) {

        } else {
            changeErrorLanguages("Додайте мову")
            error = true
        }
        if (error) return false

        if (dataForm.pricePerPerson) {
            dataForm.pricePerPerson = dataForm.pricePerPerson.filter(item => item)
        }

        if (data.id) {
            dataForm.id = data.id;
            dispatch(editExcursion(dataForm));
        } else {
            dispatch(createExcursion(dataForm, navigate));
        }
        console.log(dataForm)
    }

    //EXCURSIONS
    const excursions = useSelector(state => state.excursions.excursions);
    const dataBufferExcursions = data?.bufferExcursions
    const [restExcursions, changeRestExcursions] = useState([]);
    const [stateBufferExcursionsModal, changeBufferExcursionsModal] = useState(false);
    const setDefaultRestExcursion = () => {
        if (excursions && data) {
            changeRestExcursions(excursions.map(excursion => {
                return ({
                    type: "bufferExcursion",
                    id: excursion.id,
                    name: [...excursion.name],
                    time: data.bufferExcursions.find(item => item.id == excursion.id) ? data.bufferExcursions.find(item => item.id == excursion.id).time : 0,
                    typeExcursion: {...excursion.typeExcursion},
                    checked: data.bufferExcursions.find(item => item.id == excursion.id) ? true : false
                })
            }))
        }
    }
    const deleteBufferExcursion = (id) => {
        let newRestExcursions = [...restExcursions]

        newRestExcursions = newRestExcursions.map(excursion => {
            if (excursion.id == id) {
                return {
                    ...excursion,
                    checked: false
                }
            } else {
                return excursion
            }
        })
        changeRestExcursions(newRestExcursions)

        let newBufferExcursions = newRestExcursions.filter(item => item.checked == true)
        dispatch(changeBufferExcursionsForCurrentExcursion(newBufferExcursions))


    }
    useEffect(() => {
        setDefaultRestExcursion()
    }, [data])
    useEffect(() => {
        if (data) {
            setValue(`bufferExcursions`, [])

            data.bufferExcursions.forEach(excursion => {
                setValue(`bufferExcursions.${excursion.id}`, {
                    id: excursion.id,
                    time: Number(excursion.time),
                })
            })
        }
    }, [dataBufferExcursions])

    //LANGUAGES
    const languages = useSelector(state => state.languages.languages);
    const [stateLanguages, changeLanguages] = useState([]);
    const [stateLanguagesModal, changeLanguagesModal] = useState(false);
    useEffect(() => {
        if (data && data.languages) {
            changeLanguages(data.languages)
        }
    }, [data])
    const deleteLanguages = (id) => {
        if (stateLanguages.find(item => item.id == id)) {
            let newStateLanguages = stateLanguages.filter(item => item.id !== id);

            changeLanguages(newStateLanguages)
            dispatch(changeLanguagesForCurrentExcursion(newStateLanguages));
            setValue('languages', []);

            for (let i = 0; i < newStateLanguages.length; i++) {
                let language = newStateLanguages[i];

                setValue(`languages.${i}`, language.id)
            }
        }
    }

    //DISCOUNTS
    const discounts = useSelector(state => state.discounts.discounts);
    const [stateDiscounts, changeDiscounts] = useState([]);
    const [stateDiscountsModal, changeDiscountsModal] = useState(false);
    useEffect(() => {
        if (data && data.discounts) {
            changeDiscounts(data.discounts)
        }
    }, [data])
    const deleteDiscounts = (id) => {
        if (stateDiscounts.find(item => item.id == id)) {
            let newStateDiscounts = stateDiscounts.filter(item => item.id !== id);

            changeDiscounts(newStateDiscounts)
            dispatch(changeDiscountsForCurrentExcursion(newStateDiscounts));
            setValue('discounts', []);

            for (let i = 0; i < newStateDiscounts.length; i++) {
                let discount = newStateDiscounts[i];

                setValue(`discounts.${i}`, discount.id)
            }
        }
    }

    //OPTIONS
    const additionalOptions = useSelector(state => state.options.options);
    const [stateOptions, changeOptions] = useState([]);
    const [stateOptionsModal, changeOptionsModal] = useState(false);
    useEffect(() => {
        if (data && data.additionalOptions) {
            changeOptions(data.additionalOptions)
        }
    }, [data])
    const deleteOptions = (id) => {
        if (stateOptions.find(item => item.id == id)) {
            let newStateOptions = stateOptions.filter(item => item.id !== id);

            changeOptions(newStateOptions)
            dispatch(changeOptionsForCurrentExcursion(newStateOptions));
            setValue('additionalOptions', []);

            for (let i = 0; i < newStateOptions.length; i++) {
                let option = newStateOptions[i];

                setValue(`additionalOptions.${i}`, option.id)
            }
        }
    }

    //GUIDES
    const [stateGuides, changeGuides] = useState([]);
    const [stateGuidesModal, changeGuidesModal] = useState(false);
    useEffect(() => {
        if (data && data.guides) {
            changeGuides(data.guides)
        }
    }, [data])
    const deleteGuides = (id) => {
        if (stateGuides.find(item => item.id == id)) {
            let newStateGuides = stateGuides.filter(item => item.id !== id);

            changeGuides(newStateGuides)
            dispatch(changeGuidesForCurrentExcursion(newStateGuides));
            setValue('guides', []);

            for (let i = 0; i < newStateGuides.length; i++) {
                let guide = newStateGuides[i];

                setValue(`guides.${i}`, guide.id)
            }
        }
    }

    //TYPE EXCURSIONS
    const [typeExcursions, changeTypeExcursions] = useState([
        {
            key: "ticket",
            value: "Поквитково",
            selected: false,
        },
        {
            key: "small_group",
            value: "Мала група",
            selected: false,
        },
        {
            key: "big_group",
            value: "Велика група",
            selected: false,
        },
        {
            key: "school",
            value: "Шкільна",
            selected: false,
        },
    ])
    useEffect(() => {
        if (data && data.typeExcursion) {

            let newTypeExcursions = typeExcursions.map(item => {
                if (item.key == data.typeExcursion.key) {
                    item.selected = true;
                    return item;
                } else {
                    item.selected = false;
                    return item;
                }
            })

            changeTypeExcursions(newTypeExcursions);
        }
    }, [data])
    const onChangeTypeExcursions = (e) => {
        let selectedItem = e.selectedItem;
        let newTypeExcursions = typeExcursions.map(item => {
            if (item.key == selectedItem.key) {
                item.selected = true;
                return item;
            } else {
                item.selected = false;
                return item;
            }
        })
        dispatch(changeTypeExcursion(selectedItem.key, selectedItem.value))
        setValue('typeExcursion', selectedItem)
        changeTypeExcursions(newTypeExcursions);
    }

    //PRICE_PER_PERSON
    useEffect(() => {
        if (params?.id) {
            if (params.id == "timetable") {
                navigate('/excursions')
            }
            let excursionForUrl = excursions.find(excursion => excursion.id == params.id)
            dispatch(changeCurrentExcursion(excursionForUrl));
        } else {
            dispatch(changeCurrentExcursion(newExcursion))
            let currentBranch = branches.find(item => item.id == branchId)
            dispatch(setBranchTimeTable(currentBranch.timeTable))
        }
        return () => {
            dispatch(changeCurrentExcursion(false))
        }
    }, []);


    if (data) {
        return (
            <Page
                breadcrumbs={{link: '/excursions', text: 'Екскурсії'}}
                pageName={pageName}
                rightButton={{
                    content: 'Графік екскурсії',
                    callback: (e) => {
                        e.preventDefault();
                        if (params.id) {
                            navigate(`/excursions/timetable/${params.id}`)
                        }
                    },
                    icon: 'calendar',
                    disabled: params?.id ? false : true,
                    style: {
                        padding: "12px 15px",
                        minHeight: "unset",
                        whiteSpace: "nowrap"
                    }
                }}
                rightRest={
                    <>
                        <Button
                            callBack={() => {
                                dispatch(editExcursionStatus(data.status.key == "active" ? false : true, data.id))
                            }}
                            icon={data.status.key == "active" ? "eyeOn" : "eyeOff"}
                            color={data.status.key == "active" ? "#67FF7F" : "#B5B5B5"}
                            style={{
                                padding: "12px 15px",
                                minHeight: "unset"
                            }}
                        >
                            {data.status.name}
                        </Button>
                        <Button
                            icon={'add'}
                            callBack={() => {
                            }}
                            form={'excursion_form'}
                            disabled={stateSaveButton}
                            style={{
                                padding: "12px 15px",
                                minHeight: "unset"
                            }}
                        >
                            Зберегти
                        </Button>
                    </>
                }
            >
                <div style={{height: "100%", background: "#FFF", padding: "19px"}}>

                    <form className={classes.form} id={"excursion_form"} onSubmit={handleSubmit(excursionSubmit)}>

                        <div>

                            <LanguageInputs
                                keyIndex={"name"}
                                label={"Назва екскурсії"}
                                fields={data.name}
                                control={control}
                                changeButton={() => {
                                    dispatch(changeStateExcursionSaveButton(false))
                                }}
                            />

                            <LanguageAreas
                                keyIndex={"description"}
                                label={"Опис екскурсії"}
                                fields={data.description}
                                control={control}
                                changeButton={() => {
                                    dispatch(changeStateExcursionSaveButton(false))
                                }}
                            />

                            <div className={'typeExcursion'} style={{marginBottom: '15px'}}>

                                <h3 style={{marginTop: '0px'}}>Тип екскурсії</h3>

                                {typeExcursions &&
                                    <Controller
                                        control={control}
                                        name={"typeExcursion"}
                                        defaultValue={data.typeExcursion}
                                        rules={{required: "Обов'язкове поле",}}
                                        render={({
                                                     field: {onChange, value, name},
                                                     fieldState: {invalid, error},
                                                 }) => (
                                            <div>

                                                <CustomSelect
                                                    classError={error ? "error" : ""}
                                                    onChange={
                                                        (e) => {
                                                            dispatch(changeStateExcursionSaveButton(false));
                                                            onChangeTypeExcursions(e);
                                                        }
                                                    }
                                                    items={typeExcursions}
                                                />

                                                {error &&
                                                    <Error> {error?.message} </Error>
                                                }

                                            </div>
                                        )
                                        }
                                    />
                                }

                            </div>

                            {data.typeExcursion.key == "ticket" &&
                                <div className={'baseTickets'}>
                                    <h3 style={{marginTop: '0px'}}>Максимальна кількість квитків</h3>

                                    <input
                                        type={"number"}
                                        className={errors?.baseTickets ? "error" : ""}
                                        min={0}
                                        max={100}
                                        onWheel={(e) => e.target.blur()}
                                        {...register('baseTickets', {
                                            onChange: () => {
                                                dispatch(changeStateExcursionSaveButton(false))
                                            },
                                            minLength: 0,
                                            maxLength: 100,
                                            required: "Обов'язкове поле",
                                            value: data.baseTickets,
                                            valueAsNumber: true
                                        })}
                                    />

                                    {errors?.baseTickets &&
                                        <Error> {errors?.baseTickets?.message} </Error>
                                    }

                                </div>
                            }

                            <div className={'durationExcursion'}>
                                <h3 style={{marginTop: '0px'}}>Тривалість програми (хв)</h3>

                                <input
                                    type={"number"}
                                    className={errors?.durationExcursion ? "error" : ""}
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    {...register('durationExcursion', {
                                        onChange: () => {
                                            dispatch(changeStateExcursionSaveButton(false))
                                        },
                                        minLength: 0,
                                        required: "Обов'язкове поле",
                                        value: data.durationExcursion,
                                        valueAsNumber: true
                                    })}
                                />

                                {errors?.durationExcursion &&
                                    <Error> {errors?.durationExcursion?.message} </Error>
                                }

                            </div>

                            <div className={'basePrice'}>
                                <h3 style={{marginTop: '0px'}}>
                                    {data.typeExcursion.key == "small_group" &&
                                        "Ціна за групу"
                                    }
                                    {data.typeExcursion.key == "big_group" &&
                                        "Базова ціна за 1 ос"
                                    }
                                    {data.typeExcursion.key == "ticket" &&
                                        "Ціна за квиток"
                                    }
                                </h3>

                                <input
                                    type={"number"}
                                    className={errors?.basePrice ? "error" : ""}
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    {...register('basePrice', {
                                        onChange: () => {
                                            dispatch(changeStateExcursionSaveButton(false))
                                        },
                                        onScroll: (e) => {
                                            e.preventDefault()
                                        },
                                        minLength: 0,
                                        required: "Обов'язкове поле",
                                        value: data.basePrice,
                                        valueAsNumber: true
                                    })}
                                />

                                {errors?.basePrice &&
                                    <Error> {errors?.basePrice?.message} </Error>
                                }

                            </div>

                            {data.typeExcursion.key != "ticket" &&
                                <>
                                    <div className={'ticketsMin'}>
                                        <h3 style={{marginTop: '0px'}}>Мінімальна кількість осіб</h3>

                                        <input
                                            type={"number"}
                                            className={errors?.ticketsMin ? "error" : ""}
                                            // min={1}
                                            // max={data.ticketsMax ? data.ticketsMax : 100}
                                            onWheel={(e) => e.target.blur()}
                                            {...register('ticketsMin', {
                                                onChange: () => {
                                                    dispatch(changeStateExcursionSaveButton(false))
                                                },
                                                minLength: 1,
                                                maxLength: 100,
                                                required: "Обов'язкове поле",
                                                value: data.ticketsMin,
                                                valueAsNumber: true
                                            })}
                                        />

                                        {errors?.ticketsMin &&
                                            <Error> {errors?.ticketsMin?.message} </Error>
                                        }

                                    </div>

                                    <div className={'ticketsMax'}>
                                        <h3 style={{marginTop: '0px'}}>Максимальна кількість осіб</h3>

                                        <input
                                            type={"number"}
                                            className={errors?.ticketsMax ? "error" : ""}
                                            // min={data.ticketsMin ? data.ticketsMin : 1}
                                            // max={100}
                                            onWheel={(e) => e.target.blur()}
                                            {...register('ticketsMax', {
                                                onChange: () => {
                                                    dispatch(changeStateExcursionSaveButton(false))
                                                },
                                                minLength: 1,
                                                maxLength: 100,
                                                required: "Обов'язкове поле",
                                                value: data.ticketsMax,
                                                valueAsNumber: true
                                            })}
                                        />

                                        {errors?.ticketsMax &&
                                            <Error> {errors?.ticketsMax?.message} </Error>
                                        }

                                    </div>

                                    <div className={''}>
                                        <h3 style={{marginTop: '0px'}}>Зміна ціни за 1 особу в залежності від кількості
                                            осіб</h3>
                                        {data.pricePerPerson &&
                                            data.pricePerPerson.map((item, index) => {
                                                let key = item.id
                                                return (
                                                    <div key={key} className={"row"} style={{
                                                        display: "grid",
                                                        gridTemplateColumns: "1fr 1fr",
                                                        gap: "10px"
                                                    }}>
                                                        <div style={{position: "relative"}}>
                                                            <h3 style={{marginTop: '0px'}}>Ціна (%)</h3>
                                                            <input
                                                                type={"number"}
                                                                className={errors?.pricePerPerson?.[key]?.price ? "error" : ""}
                                                                min="0"
                                                                max="100"
                                                                onWheel={(e) => e.target.blur()}
                                                                {...register(`pricePerPerson.${key}.price`, {
                                                                    onChange: () => {
                                                                        dispatch(changeStateExcursionSaveButton(false))
                                                                    },
                                                                    required: "Обов'язкове поле",
                                                                    value: item.price,
                                                                    valueAsNumber: true,
                                                                    minLength: "0",
                                                                    maxLength: "100"
                                                                })}
                                                            />
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "45px",
                                                                    right: "10px",
                                                                    zIndex: "3",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    dispatch(removePricePerPerson(key))
                                                                    setValue(`pricePerPerson.${key}`, null)
                                                                }}
                                                            >
                                                                <svg width="14" height="18" viewBox="0 0 14 18"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M1 16C1.00158 16.5299 1.2128 17.0377 1.58753 17.4125C1.96227 17.7872 2.47005 17.9984 3 18H11C11.5299 17.9984 12.0377 17.7872 12.4125 17.4125C12.7872 17.0377 12.9984 16.5299 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                                                                        fill="#B5B5B5"/>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <h3 style={{marginTop: '0px'}}>Діапазон осіб</h3>

                                                            <input
                                                                type={"text"}
                                                                className={errors?.pricePerPerson?.[key]?.persons ? "error" : ""}
                                                                {...register(`pricePerPerson.${key}.persons`, {
                                                                    onChange: () => {
                                                                        dispatch(changeStateExcursionSaveButton(false))
                                                                    },
                                                                    value: item.persons,
                                                                    required: "Обов'язкове поле",
                                                                })}
                                                            />
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                        <Button
                                            icon={"add"}
                                            callBack={
                                                (e) => {
                                                    e.preventDefault()
                                                    dispatch(addPricePerPerson())
                                                    dispatch(changeStateExcursionSaveButton(false))
                                                }
                                            }
                                        >
                                            Додати
                                        </Button>

                                    </div>
                                </>
                            }

                            <div className={'color'}>
                                <h3 style={{marginTop: '0px'}}>Колір в календарі</h3>

                                <Controller
                                    control={control}
                                    name={"color"}
                                    defaultValue={data.color}
                                    rules={{required: "Обов'язкове поле",}}
                                    render={({
                                                 field: {onChange, onBlur, onFocus, value, name, ref},
                                                 fieldState: {invalid, isTouched, isDirty, error},
                                                 formState,
                                             }) => (
                                        <div>
                                            <InputColor
                                                color={data.color}
                                                error={error}
                                                onChange={(color) => {
                                                    dispatch(changeStateExcursionSaveButton(false))
                                                    setValue(name, color)
                                                    dispatch(changeColor(color))


                                                }}
                                                // onBlur={(color)=>{

                                                //     // let color = e.target.value
                                                //     dispatch(changeColor(color))
                                                //     setValue(name, color)
                                                // }}
                                            />

                                            {error &&
                                                <Error> {error?.message} </Error>
                                            }

                                        </div>
                                    )}
                                />

                            </div>

                            <div className={"onlinePayment"}>
                                <Controller
                                    control={control}
                                    name={"onlinePayment"}
                                    defaultValue={data.onlinePayment}
                                    render={({
                                                 field: {onChange, value, name, ref},
                                                 formState,
                                             }) => (
                                        <div className={"row"}>
                                            <input
                                                onChange={(e) => {
                                                    let checked = e.target.checked;
                                                    dispatch(changeStateExcursionSaveButton(false))
                                                    dispatch(changeOnlinePayment(checked))
                                                    setValue("onlinePayment", checked)
                                                }}
                                                id={"onlinePayment"}
                                                name={"onlinePayment"}
                                                type="checkbox"
                                                checked={value}
                                            />
                                            <label htmlFor={"onlinePayment"}>Онлайн оплата</label>
                                        </div>
                                    )}
                                />
                            </div>

                        </div>

                        <div>

                            <div>
                                <h3 style={{marginTop: '0px'}}>Мови проведення</h3>
                                {
                                    data.languages.map((language, index) => {

                                        return (
                                            <Controller
                                                key={`language__${language.id}`}
                                                control={control}
                                                name={`languages.${index}`}
                                                defaultValue={language.id}
                                                render={({field: {onChange, value, name}}) => (
                                                    <SelectItem
                                                        key={`language-item_${language.id}`}
                                                        style={language.deleted === true ? {background: "#ffdcdc"} : {}}
                                                        onClick={() => {
                                                            deleteLanguages(language.id);
                                                            dispatch(changeStateExcursionSaveButton(false));
                                                        }}
                                                    >
                                                        {language.name}
                                                    </SelectItem>
                                                )
                                                }
                                            />

                                        )

                                    })
                                }

                                <Button icon={'add'} callBack={(e) => {
                                    e.preventDefault();
                                    changeLanguagesModal(true)
                                    dispatch(changeStateExcursionSaveButton(false))
                                }}>Додати</Button>

                                {errorLanguages && typeof errorLanguages == "string" &&
                                    <Error> {errorLanguages} </Error>
                                }

                            </div>

                            <div>
                                <h3 style={{marginTop: '0px'}}>Знижки, що діють</h3>
                                {
                                    data.discounts.map((discount, index) => {

                                        return (
                                            <Controller
                                                key={`discount__${discount.id}`}
                                                control={control}
                                                name={`discounts.${index}`}
                                                defaultValue={discount.id}
                                                render={({field: {onChange, value, name}}) => (
                                                    <SelectItem
                                                        key={`discount-item_${discount.id}`}
                                                        style={discount.deleted === true ? {background: "#ffdcdc"} : {}}
                                                        onClick={() => {
                                                            deleteDiscounts(discount.id);
                                                            dispatch(changeStateExcursionSaveButton(false));
                                                        }}
                                                    >
                                                        {discount.name.find(item => item.default_language == true).text}
                                                    </SelectItem>
                                                )
                                                }
                                            />

                                        )

                                    })
                                }

                                <Button icon={'add'} callBack={(e) => {
                                    e.preventDefault();
                                    dispatch(changeStateExcursionSaveButton(false))
                                    changeDiscountsModal(true)
                                }}>Додати</Button>

                            </div>

                            <div>
                                <h3 style={{marginTop: '0px'}}>Додаткові опції</h3>
                                {
                                    data.additionalOptions.map((option, index) => {

                                        return (
                                            <Controller
                                                key={`option__${option.id}`}
                                                control={control}
                                                name={`additionalOptions.${index}`}
                                                defaultValue={option.id}
                                                render={({field: {onChange, value, name}}) => (
                                                    <SelectItem
                                                        key={`option-item_${option.id}`}
                                                        style={option.deleted === true ? {background: "#ffdcdc"} : {}}
                                                        onClick={() => {
                                                            deleteOptions(option.id);
                                                            dispatch(changeStateExcursionSaveButton(false));
                                                        }}
                                                    >
                                                        {option.name.find(item => item.default_language == true).text}
                                                    </SelectItem>
                                                )
                                                }
                                            />

                                        )

                                    })
                                }

                                <Button icon={'add'} callBack={(e) => {
                                    e.preventDefault();
                                    changeOptionsModal(true)
                                    dispatch(changeStateExcursionSaveButton(false))
                                }}>Додати</Button>

                            </div>

                            <div>
                                <h3 style={{marginTop: '0px'}}>Гіди, що можуть проводити</h3>
                                {
                                    data.guides.map((guide, index) => {

                                        return (
                                            <Controller
                                                key={`guide__${guide.id}`}
                                                control={control}
                                                name={`guides.${index}`}
                                                defaultValue={guide.id}
                                                render={({field: {onChange, value, name}}) => (
                                                    <SelectItem
                                                        key={`guide-item_${guide.id}`}
                                                        style={guide.deleted === true ? {background: "#ffdcdc"} : {}}
                                                        onClick={() => {
                                                            deleteGuides(guide.id);
                                                            dispatch(changeStateExcursionSaveButton(false));
                                                        }}
                                                    >
                                                        {guide.name + " " + guide.surname}
                                                    </SelectItem>
                                                )
                                                }
                                            />

                                        )

                                    })
                                }

                                <Button icon={'add'} callBack={(e) => {
                                    e.preventDefault();
                                    changeGuidesModal(true)
                                    dispatch(changeStateExcursionSaveButton(false))
                                }}>Додати</Button>

                                {errorGuides && typeof errorGuides == "string" &&
                                    <Error> {errorGuides} </Error>
                                }

                            </div>

                        </div>

                        <div>
                            <TimeTable
                                control={control}
                                getValues={getValues}
                                timeTable={data.timeTable}
                                setValue={setValue}
                                onChangeInput={onChangeInput}
                                changeButton={() => {
                                    dispatch(changeStateExcursionSaveButton(false))
                                }}
                            />

                            <div>
                                <h3>Наступне бронювання можливе через (хв) від поч. екскурсії:</h3>
                                {
                                    data.bufferExcursions.map((bufferExcursion, index) => {
                                        if (bufferExcursion) {
                                            return (
                                                <div key={`bufferExcursion__${bufferExcursion.id}`}>

                                                    <Controller
                                                        control={control}
                                                        name={`bufferExcursions.${bufferExcursion.id}`}
                                                        defaultValue={{
                                                            id: bufferExcursion.id,
                                                            time: Number(bufferExcursion.time ? bufferExcursion.time : 0)
                                                        }}
                                                        render={({field: {onChange, value, name, error}}) => (
                                                            <div style={{
                                                                display: "grid",
                                                                gridTemplateColumns: "70% 30%",
                                                                gap: "10px",
                                                                alignItems: "stretch"
                                                            }}>
                                                                <SelectItem
                                                                    onClick={() => {
                                                                        deleteBufferExcursion(bufferExcursion.id);
                                                                        dispatch(changeStateExcursionSaveButton(false));
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <div>
                                                                            {bufferExcursion.name.find(lang => lang.default_language).text}
                                                                        </div>
                                                                        <div>
                                                                            ({bufferExcursion.typeExcursion.name})
                                                                        </div>
                                                                    </div>
                                                                </SelectItem>
                                                                <input
                                                                    type={"number"}
                                                                    name={name}
                                                                    className={error ? "error" : ""}
                                                                    style={{height: "auto", marginBottom: "10px"}}
                                                                    min={0}
                                                                    value={bufferExcursion.time}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onChange={(e) => {
                                                                        let newValue = {
                                                                            id: bufferExcursion.id,
                                                                            time: Number(e.currentTarget.value)
                                                                        }
                                                                        setValue(`bufferExcursions.${bufferExcursion.id}`, newValue)
                                                                        dispatch(changeTimeBufferExcursion(newValue.id, newValue.time))
                                                                        dispatch(changeStateExcursionSaveButton(false))
                                                                    }}
                                                                />
                                                            </div>

                                                        )}
                                                    />
                                                </div>
                                            )
                                        }

                                    })
                                }

                                <Button icon={'add'} callBack={(e) => {
                                    e.preventDefault();
                                    changeBufferExcursionsModal(true)
                                }}>Додати</Button>
                            </div>

                        </div>

                        <Modal active={stateLanguagesModal} title={'Мови проведення'}>
                            <div>
                                {
                                    languages.map(language => {
                                        if (language.deleted == false) {
                                            //let item = language.name.find(lang => lang.default_language == true);
                                            let text = language.name;

                                            return (
                                                <div
                                                    key={`language__item__${language.id}`}
                                                    className={'row'}
                                                    style={{
                                                        justifyContent: "space-between",
                                                        padding: "9px 16px",
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "6px"
                                                    }}>
                                                    <label
                                                        htmlFor={`language_${language.id}`}
                                                        style={{fontWeight: 400}}
                                                    >
                                                        {text}
                                                    </label>
                                                    <input
                                                        id={`language_${language.id}`}
                                                        type={"checkbox"}
                                                        onChange={() => {
                                                            if (stateLanguages.find(item => item.id == language.id)) {
                                                                changeLanguages(
                                                                    stateLanguages.filter(item => item.id !== language.id)
                                                                )
                                                            } else {
                                                                changeLanguages([...stateLanguages, language])
                                                            }
                                                        }}
                                                        checked={stateLanguages.find(item => item.id == language.id) ? true : false}
                                                    />
                                                </div>

                                            )
                                        }

                                    })
                                }
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px"
                            }}>
                                <Button
                                    color={"#ECECEC"}
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeLanguagesModal(false);
                                    }}
                                >
                                    Скасувати
                                </Button>
                                <Button
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeLanguagesModal(false);
                                        dispatch(changeLanguagesForCurrentExcursion(stateLanguages));
                                        setValue('languages', []);

                                        for (let i = 0; i < stateLanguages.length; i++) {
                                            let language = stateLanguages[i];

                                            setValue(`languages.${i}`, language.id)
                                        }

                                        dispatch(changeStateExcursionSaveButton(false));

                                        changeErrorLanguages(null)
                                    }}
                                >
                                    Додати
                                </Button>
                            </div>

                        </Modal>

                        <Modal active={stateDiscountsModal} title={'Знижки'}>
                            <div>
                                {
                                    discounts.map(discount => {
                                        if (discount.deleted === false) {
                                            let text = discount.name.find(lang => lang.default_language == true).text;

                                            return (
                                                <div
                                                    key={`discount__item__${discount.id}`}
                                                    className={'row'}
                                                    style={{
                                                        justifyContent: "space-between",
                                                        padding: "9px 16px",
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "6px"
                                                    }}>
                                                    <label
                                                        htmlFor={`discount_${discount.id}`}
                                                        style={{fontWeight: 400}}
                                                    >
                                                        {text}
                                                    </label>
                                                    <input
                                                        id={`discount_${discount.id}`}
                                                        type={"checkbox"}
                                                        onChange={() => {
                                                            if (stateDiscounts.find(item => item.id == discount.id)) {
                                                                changeDiscounts(
                                                                    stateDiscounts.filter(item => item.id !== discount.id)
                                                                )
                                                            } else {
                                                                changeDiscounts([...stateDiscounts, discount])
                                                            }
                                                        }}
                                                        checked={stateDiscounts.find(item => item.id == discount.id) ? true : false}
                                                    />
                                                </div>

                                            )
                                        }
                                    })
                                }
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px"
                            }}>
                                <Button
                                    color={"#ECECEC"}
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeDiscountsModal(false);
                                    }}
                                >
                                    Скасувати
                                </Button>
                                <Button
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeDiscountsModal(false);
                                        dispatch(changeDiscountsForCurrentExcursion(stateDiscounts));
                                        setValue('discounts', []);

                                        for (let i = 0; i < stateDiscounts.length; i++) {
                                            let discount = stateDiscounts[i];

                                            setValue(`discounts.${i}`, discount.id)
                                        }

                                        dispatch(changeStateExcursionSaveButton(false));
                                    }}
                                >
                                    Додати
                                </Button>
                            </div>

                        </Modal>

                        <Modal active={stateOptionsModal} title={'Додаткові опції'}>
                            <div>
                                {
                                    additionalOptions.map(option => {
                                        if (option.deleted === false) {
                                            let text = option.name.find(lang => lang.default_language == true).text;

                                            return (
                                                <div
                                                    key={`option__item__${option.id}`}
                                                    className={'row'}
                                                    style={{
                                                        justifyContent: "space-between",
                                                        padding: "9px 16px",
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "6px"
                                                    }}>
                                                    <label
                                                        htmlFor={`option_${option.id}`}
                                                        style={{fontWeight: 400}}
                                                    >
                                                        {text}
                                                    </label>
                                                    <input
                                                        id={`option_${option.id}`}
                                                        type={"checkbox"}
                                                        onChange={() => {
                                                            if (stateOptions.find(item => item.id == option.id)) {
                                                                changeOptions(
                                                                    stateOptions.filter(item => item.id !== option.id)
                                                                )
                                                            } else {
                                                                changeOptions([...stateOptions, option])
                                                            }
                                                        }}
                                                        checked={stateOptions.find(item => item.id == option.id) ? true : false}
                                                    />
                                                </div>

                                            )
                                        }
                                    })
                                }
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px"
                            }}>
                                <Button
                                    color={"#ECECEC"}
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeOptionsModal(false);
                                    }}
                                >
                                    Скасувати
                                </Button>
                                <Button
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeOptionsModal(false);
                                        dispatch(changeOptionsForCurrentExcursion(stateOptions));
                                        setValue('additionalOptions', []);

                                        for (let i = 0; i < stateOptions.length; i++) {
                                            let options = stateOptions[i];

                                            setValue(`additionalOptions.${i}`, options.id)
                                        }

                                        dispatch(changeStateExcursionSaveButton(false));
                                    }}
                                >
                                    Додати
                                </Button>
                            </div>

                        </Modal>

                        <Modal active={stateGuidesModal} title={'Додати гіда'}>
                            <div>
                                {
                                    guides.map(guide => {
                                        if (guide.deleted === false) {
                                            let text = guide.name + " " + guide.surname

                                            return (
                                                <div
                                                    key={`guide__item__${guide.id}`}
                                                    className={'row'}
                                                    style={{
                                                        justifyContent: "space-between",
                                                        padding: "9px 16px",
                                                        border: "1px solid #ECECEC",
                                                        borderRadius: "6px"
                                                    }}>
                                                    <label
                                                        htmlFor={`guide_${guide.id}`}
                                                        style={{fontWeight: 400}}
                                                    >
                                                        {text}
                                                    </label>
                                                    <input
                                                        id={`guide_${guide.id}`}
                                                        type={"checkbox"}
                                                        onChange={() => {
                                                            if (stateGuides.find(item => item.id == guide.id)) {
                                                                changeGuides(
                                                                    stateGuides.filter(item => item.id !== guide.id)
                                                                )
                                                            } else {
                                                                changeGuides([...stateGuides, guide])
                                                            }
                                                        }}
                                                        checked={stateGuides.find(item => item.id == guide.id) ? true : false}
                                                    />
                                                </div>

                                            )
                                        }
                                    })
                                }
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px"
                            }}>
                                <Button
                                    color={"#ECECEC"}
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeGuidesModal(false);
                                    }}
                                >
                                    Скасувати
                                </Button>
                                <Button
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeGuidesModal(false);
                                        dispatch(changeGuidesForCurrentExcursion(stateGuides));
                                        setValue('guides', []);

                                        for (let i = 0; i < stateGuides.length; i++) {
                                            let guide = stateGuides[i];

                                            setValue(`guides.${i}`, guide.id)
                                        }

                                        dispatch(changeStateExcursionSaveButton(false));
                                        changeErrorGuides(null)
                                    }}
                                >
                                    Додати
                                </Button>
                            </div>

                        </Modal>

                        <Modal active={stateBufferExcursionsModal} title={'Екскурсії'}>
                            <div>
                                {restExcursions.length !== 0 &&
                                    restExcursions.map(restExcursion => {
                                        let name = restExcursion.name.find(lang => lang.default_language == true).text;
                                        let type = restExcursion.typeExcursion.name
                                        return (
                                            <div
                                                key={`excursion__item__${restExcursion.id}`}
                                                className={'row'}
                                                style={{
                                                    justifyContent: "space-between",
                                                    padding: "9px 16px",
                                                    border: "1px solid #ECECEC",
                                                    borderRadius: "6px"
                                                }}>
                                                <label
                                                    htmlFor={`excursion_${restExcursion.id}`}
                                                    style={{fontWeight: 400}}
                                                >
                                                    {name} ({type})
                                                </label>
                                                <input
                                                    id={`excursion_${restExcursion.id}`}
                                                    type={"checkbox"}
                                                    onChange={() => {
                                                        let newRestExcursions = [...restExcursions]

                                                        newRestExcursions = newRestExcursions.map(excursion => {
                                                            if (excursion.id == restExcursion.id) {
                                                                return {
                                                                    ...excursion,
                                                                    checked: excursion.checked ? false : true
                                                                }
                                                            } else {
                                                                return excursion
                                                            }
                                                        })
                                                        changeRestExcursions(newRestExcursions)
                                                    }}
                                                    checked={restExcursion.checked}
                                                />
                                            </div>

                                        )
                                    })
                                }
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px"
                            }}>
                                <Button
                                    color={"#ECECEC"}
                                    callBack={(e) => {
                                        e.preventDefault();
                                        setDefaultRestExcursion()
                                        changeBufferExcursionsModal(false);
                                    }}
                                >
                                    Скасувати
                                </Button>
                                <Button
                                    callBack={(e) => {
                                        e.preventDefault();
                                        changeBufferExcursionsModal(false);
                                        dispatch(changeStateExcursionSaveButton(false));
                                        let newBufferExcursions = [...restExcursions].filter(item => item.checked == true)
                                        dispatch(changeBufferExcursionsForCurrentExcursion(newBufferExcursions));
                                    }}
                                >
                                    Додати
                                </Button>
                            </div>

                        </Modal>

                    </form>

                </div>
            </Page>
        )
    } else {
        return (
            <PageLoader/>
        )
    }
}

export default ExcursionsCreate;